import Environments from './Environments';

export const BASE_URL_MAP: { [url: string]: Environments } = {
  'savings.rubicon.com': Environments.production,
  'savings.qa.aws.rubicon.com': Environments.qa,
  'customer-temp.dev.aws.rubicon.com': Environments.development,
  'savings.dev.aws.rubicon.com': Environments.development,
  // micro frontend
  'marketplace.rubicon.com': Environments.production,
  'qa.rubiconsandbox.fabric.zone': Environments.qa,
  'dev.rubiconsandbox.fabric.zone': Environments.development,
};

export const API_BASE_URL_MAP = {
  zuora: {
    [Environments.production]: 'https://api.zuora.com/rest/v1',
    [Environments.qa]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.development]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.local]: 'https://apisandbox-api.zuora.com/rest/v1',
  },
  savingsCore: {
    [Environments.production]: 'https://sales-api.prod.aws.rubicon.com',
    [Environments.qa]: 'https://sales-api.qa.aws.rubicon.com',
    [Environments.development]: 'https://sales-api.dev.aws.rubicon.com',
    [Environments.local]: 'https://sales-api.dev.aws.rubicon.com',
  },
};

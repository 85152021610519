import { AxiosError } from 'axios';
import { UrlParamTypes } from 'contracts/core/routing';
import { GetShoppingCartResponseError } from 'contracts/models/ShoppingCartRedirectDataView';
import LoadingSection from 'core/components/LoadingSection';
import { Button, ButtonSet } from 'core/components/styled';
import translate from 'core/helpers/translate';
import cart from 'ducks/cart';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Routes } from 'routing/routes';
import { getShoppingCartByID } from 'services/services';
import {
  InvalidCartContainer,
  InvalidCartContent,
  InvalidCartError,
} from './styled';
import { editServiceUrl } from 'routing/routes';

const OpenCartPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartId } = useParams<Partial<UrlParamTypes>>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (cartId) {
      setIsLoading(true);
      getShoppingCartByID(cartId)
        .then(apiCart => {
          dispatch(cart.actions.setCartFromUrl(apiCart));
          let preselectLocationIndex = -1;
          let preselectServiceIndex = -1;
          apiCart.locations.forEach((location, indexLoc) => {
            location.services.forEach((service, indexService) => {
              if (service.isInEditMode) {
                preselectLocationIndex = indexLoc;
                preselectServiceIndex = indexService;
              }
            });
          });
          if (preselectLocationIndex >= 0 && preselectServiceIndex >= 0) {
            navigate(editServiceUrl(preselectLocationIndex, preselectServiceIndex))
          }
          else {
            navigate(Routes.summary);
          }
        })
        .catch((error: AxiosError<GetShoppingCartResponseError>) => {
          setIsLoading(false);
          if (error && error.response && error.response.data) {
            if (error.response.data.redirectCartId) {
              navigate(
                Routes.openCart.replace(
                  ':cartId',
                  error.response.data.redirectCartId,
                ),
              );
            } else {
              setErrorMessage(error.response.data.exceptionMessage);
            }
          } else {
            setErrorMessage(
              'An error occurred loading this page. We are working to address this error as soon as possible.',
            );
          }
        });
    } else {
      setErrorMessage(
        'An error occurred loading this page. We are working to address this error as soon as possible.',
      );
    }
  }, [cartId, dispatch, navigate]);

  const createNewCart = () => {
    dispatch(cart.actions.reset());
    navigate(Routes.location);
  };

  return (
    <InvalidCartContainer>
      <LoadingSection isLoading={isLoading}>
        {errorMessage ? (
          <InvalidCartContent>
            <InvalidCartError>{errorMessage}</InvalidCartError>
            <ButtonSet>
              <Button width='215px' onClick={createNewCart}>
                {translate('createNewCart')}
              </Button>
            </ButtonSet>
          </InvalidCartContent>
        ) : null}
      </LoadingSection>
    </InvalidCartContainer>
  );
};

export default OpenCartPage;

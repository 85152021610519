export enum Routes {
  savingsBase = '/savings',
  contactInformation = '/contact-information', 
  location = '/location',
  summary = '/summary',
  service = '/location/:locationIndex/service/:serviceIndex',
  startDate = '/service/:serviceIndex/start-date',
  serviceAgreement = '/service-agreement',
  billing = '/location/:locationIndex/billing',
  openCart = '/open-cart/:cartId',
  recognizedSelection = '/recognized-selection/:alias',
  recognizedConfirmation = '/recognized-confirmation/:recognizedId/:collateralId/:alias',
  recognizedAcknowledgement = '/recognized-acknowledgement',
}

export enum RoutesRegex {
  contactInformation = '^/contact-information$',
  location = '^/location$',
  summary = '^/summary$',
  service = '^/location/\\d*/service/\\d*$',
  startDate = '^/service/\\d*/start-date$',
  serviceAgreement = '^/service-agreement$',
  billing = '^/location/\\d*/billing$',
}

export const editServiceUrl = (indexLocation: number, indexService: number) => {
  let serviceRedirectUrl = Routes.service.replace(
    ':locationIndex',
    (indexLocation + 1).toString(),
  );
  serviceRedirectUrl = serviceRedirectUrl.replace(
    ':serviceIndex',
    (indexService + 1).toString(),
  );
  return serviceRedirectUrl;
};

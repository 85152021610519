import React, { useEffect, useState, } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import translate from 'core/helpers/translate';
import {
  ServiceDetailsOverviewContainer,
  ServiceAddressContainer,
  ServiceAddressLabel,
  ServiceAddressDetails,
  ServiceCardOverview,
  ServiceCardDetails,
  ServiceNameOverview,
  ServiceDetailsDivider,
  ServiceDetailsDividerSubTitle,
  ServiceDetailsItemsContainer,
  ServiceDetailOverviewItem,
  ServiceDetailLabel,
  ServiceDetailValue,
  ServiceCardInfoRatesContainer,
  ServiceCardInfoRate,
  ServiceCardInfoRateSubtitle,
  ServiceBottomDetailsContainer
} from '../styled';
import { AddressDataView } from 'contracts/models/AddressDataView';
import { ServiceAvatar } from 'core/components/styled/ServiceAvatar';
import { ApplicationState } from 'contracts/core/state';
import { getLabelFromCode, getServiceRatesAndExtras } from 'core/helpers/service';
import { currency } from 'core/services/formatter';
import { CurrentServiceForm } from 'contracts/core/form';

const ServiceDetailsOverview: React.FC<ComponentProps> = ({ address, service }) => {
  const [serviceRates, setServiceRates] = useState([] as any[]);
  const [extrasLabels, setExtrasLabels] = useState([] as string[]);

  const { quotes, formSettings } = useSelector((state: ApplicationState) => state.service, shallowEqual);
  const { formOptions } = formSettings;

  useEffect(() => {
    if (quotes && service && service.equipment) {
      const { serviceRates, extrasLabels } = getServiceRatesAndExtras(service, quotes);
      setServiceRates(serviceRates);
      setExtrasLabels(extrasLabels);
    }
  }, [quotes, service]);

  return (
    <ServiceDetailsOverviewContainer>
      <ServiceAddressContainer>
        <ServiceAddressLabel>{translate('serviceAddressLabel')}</ServiceAddressLabel>
        <ServiceAddressDetails>{address.line1}</ServiceAddressDetails>
      </ServiceAddressContainer>
      <ServiceCardOverview>
        <ServiceAvatar equipmentType={service.service} maxWidth={true} />
        <ServiceCardDetails>
          <ServiceNameOverview>{translate(`serviceTypeLabel${service.service}`)}</ServiceNameOverview>
          <ServiceDetailsDivider>
            <ServiceDetailsDividerSubTitle>
              {translate('summarySubtitle')}
            </ServiceDetailsDividerSubTitle>
          </ServiceDetailsDivider>
          <ServiceBottomDetailsContainer>
            <ServiceDetailsItemsContainer>
              <ServiceDetailOverviewItem>
                <ServiceDetailLabel>{translate('quantityOverviewLabel')}</ServiceDetailLabel>
                <ServiceDetailValue>{service.quantity || 1}</ServiceDetailValue>
              </ServiceDetailOverviewItem>
              <ServiceDetailOverviewItem>
                <ServiceDetailLabel>{translate('wasteTypeOverviewLabel')}</ServiceDetailLabel>
                <ServiceDetailValue>
                  {service.material
                    ? getLabelFromCode(service.material, formOptions.materialLabels)
                    : '-'
                  }
                </ServiceDetailValue>
              </ServiceDetailOverviewItem>
              <ServiceDetailOverviewItem>
                <ServiceDetailLabel>{translate('sizeOverviewLabel')}</ServiceDetailLabel>
                <ServiceDetailValue>
                  {service.equipment
                    ? getLabelFromCode(service.equipment, formOptions.equipmentLabels)
                    : '-'
                  }
                </ServiceDetailValue>
              </ServiceDetailOverviewItem>
              <ServiceDetailOverviewItem>
                <ServiceDetailLabel>{translate('frequencyOverviewLabel')}</ServiceDetailLabel>
                <ServiceDetailValue>
                  {service.frequency
                    ? getLabelFromCode(service.frequency, formOptions.frequencyLabels)
                    : '-'
                  }
                </ServiceDetailValue>
              </ServiceDetailOverviewItem>
              {extrasLabels && extrasLabels.length > 0 &&
                <ServiceDetailOverviewItem>
                  <ServiceDetailLabel>{translate('extrasOverviewLabel')}</ServiceDetailLabel>
                  {extrasLabels.map((extra: any) => (
                    <ServiceDetailValue key={extra}>
                      {extra}
                    </ServiceDetailValue>)
                  )}
                </ServiceDetailOverviewItem>
              }
            </ServiceDetailsItemsContainer>
            <ServiceCardInfoRatesContainer>
              {serviceRates && serviceRates.length > 0 &&
                serviceRates.map((serviceRate: any) => (
                  <ServiceCardInfoRate
                    key={serviceRate.value + '_' + serviceRate.uom}
                  >
                    {serviceRate
                      ? currency(serviceRate.value)
                      : 'Market Rate'}
                    {serviceRate ? (
                      <ServiceCardInfoRateSubtitle>
                        per {serviceRate.uom}
                      </ServiceCardInfoRateSubtitle>
                    ) : null}
                  </ServiceCardInfoRate>
                ))}
            </ServiceCardInfoRatesContainer>
          </ServiceBottomDetailsContainer>
        </ServiceCardDetails>
      </ServiceCardOverview>
    </ServiceDetailsOverviewContainer>
  );
};

interface StateProps { }

interface OwnProps {
  address: AddressDataView;
  service: CurrentServiceForm;
}

type ComponentProps = StateProps & OwnProps;

export default ServiceDetailsOverview;

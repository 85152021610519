import { loading } from 'core/styles';
import styled from 'styled-components';

const PageLoading = styled.div`
  flex-grow: 1;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    ${loading(32)};
  }
`;

export default PageLoading;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'contracts/core/state';
import translate from 'core/helpers/translate';
import { Checkbox } from 'core/components';
import useCheckIfMobileView from 'core/helpers/useCheckIfMobileView';
import { actionIsRunning } from 'core/ducks/running';
import { Button } from 'core/components/styled';
import agreementDuck from 'ducks/agreementSign';
import cart from 'ducks/cart';
import billingDuck from 'ducks/billing';
import { getSessionCart } from 'core/services/session';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import {
  SERVICE_AGREEMENT,
  LETTER_OF_AUTHORIZATION,
  TERMS_AND_CONDTIONS,
} from '../constants/variables';
import {
  Panel,
  PanelContainer,
  PanelTitle,
} from 'core/components/styled/Panel2';
import ServiceAgreementFormatted from './ServiceAgreementFormatted';
import LetterOfAuthorizationFormatted from './LetterOfAuthorizationFormatted';
import TermsAndConditionsFormatted from './TermsAndConditionsFormatted';
import { AgreementContainer, AgreementWrapper } from './styled';
import ServicesPanel from 'components/Summary/ServicesPanel';
import ServiceAgreementAdvancedFormatted from './ServiceAgreementAdvancedFormatted';
import { useNavigate } from 'react-router';
import { Routes } from 'routing/routes';

const ServiceAgreementPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobileView = useCheckIfMobileView();

  // component state
  const [isServiceAgreementRed, setServiceAgreementRed] = useState(false);
  const [isServiceAgreementChecked, setServiceAgreementChecked] =
    useState(false);
  const [isTermsRed, setTermsRed] = useState(false);
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [isLOARed, setLOARed] = useState(false);
  const [isLOAChecked, setLOAChecked] = useState(false);
  const [wasServiceAgreementsSigned, setServiceAgreementsSigned] =
    useState(false);
  const [wasLOAAgreementsSigned, setLOAAgreementsSigned] = useState(false);
  const [wasTermsSigned, setTermsSigned] = useState(false);

  // agreement state
  const rtcAgreementId = useSelector(
    (state: ApplicationState) => state.agreement.rtcAgreementId,
  );
  const serviceAgreementId = useSelector(
    (state: ApplicationState) => state.agreement.serviceAgreementId,
  );
  const loaAgreementId = useSelector(
    (state: ApplicationState) => state.agreement.loaAgreementId,
  );
  const isSigingService = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, agreementDuck.actionKeys.SIGN_SERVICE),
  );
  const isSigingLOA = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, agreementDuck.actionKeys.SIGN_LOA),
  );
  const isSigingTerms = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, agreementDuck.actionKeys.SIGN_TERMS),
  );

  // cart state
  const isTermsSigned = useSelector(
    (state: ApplicationState) => state.cart.rtcAgreementId,
  );
  const isServiceSigned = useSelector(
    (state: ApplicationState) => state.cart.serviceAgreementId,
  );
  const isLOASigned = useSelector(
    (state: ApplicationState) => state.cart.loaAgreementId,
  );
  const shoppingCartId = cart.selectors.getshoppingCartId();
  const isAdvancedProgram = cart.flags.isAdvancedProgram();
  const isRecognition = cart.flags.isRecognition();

  useEffect(() => {
    if (isMobileView) {
      setServiceAgreementRed(true);
      setLOARed(true);
    } else {
      if (!isLOAChecked && !isLOARed) {
        setLOARed(false);
      }
      if (!isServiceAgreementChecked && !isServiceAgreementRed) {
        setServiceAgreementRed(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  useEffect(() => {
    if (Boolean(isTermsSigned)) {
      setTermsRed(true);
      setTermsChecked(true);
      setTermsSigned(true);
    }
    if (Boolean(isServiceSigned)) {
      setServiceAgreementRed(true);
      setServiceAgreementChecked(true);
      setServiceAgreementsSigned(true);
    }
    if (Boolean(isLOASigned)) {
      setLOARed(true);
      setLOAChecked(true);
      setLOAAgreementsSigned(true);
    }
    dispatch(setLoadingBarProgress(40));
    return () => {
      dispatch(agreementDuck.actions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e: any, agreementName: string) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 20) {
      if (agreementName === SERVICE_AGREEMENT) {
        setServiceAgreementRed(true);
      }
      if (agreementName === LETTER_OF_AUTHORIZATION) {
        setLOARed(true);
      }
      if (agreementName === TERMS_AND_CONDTIONS) {
        setTermsRed(true);
      }
    }
  };

  const agreeServiceTerms = (e: any) => {
    setServiceAgreementChecked(e.target.checked);
    if (e.target.checked) {
      const storedCart = getSessionCart();
      dispatch(agreementDuck.thunks.signServiceAgreement(storedCart));
    } else if (wasServiceAgreementsSigned) {
      setServiceAgreementsSigned(false);
      setServiceAgreementRed(false);
      dispatch(cart.actions.setServiceAgreement(''));
    }
  };

  const agreeLOATerms = (e: any) => {
    setLOAChecked(e.target.checked);
    if (e.target.checked) {
      const storedCart = getSessionCart();
      dispatch(agreementDuck.thunks.signLOAAgreement(storedCart));
    } else if (wasLOAAgreementsSigned) {
      setLOAAgreementsSigned(false);
      setLOARed(false);
      dispatch(cart.actions.setLOAAgreement(''));
    }
  };

  const agreeTermsAndConditions = (e: any) => {
    setTermsChecked(e.target.checked);
    if (e.target.checked) {
      const storedCart = getSessionCart();
      dispatch(agreementDuck.thunks.signTermsAgreement(storedCart));
    } else if (wasTermsSigned) {
      setTermsSigned(false);
      setTermsRed(false);
      dispatch(cart.actions.setTermsAgreement(''));
    }
  };

  const setAgreementsAndGoToBilling = () => {
    if (serviceAgreementId) {
      dispatch(cart.actions.setServiceAgreement(serviceAgreementId));
    }
    if (loaAgreementId) {
      dispatch(cart.actions.setLOAAgreement(loaAgreementId));
    }
    if (rtcAgreementId) {
      dispatch(cart.actions.setTermsAgreement(rtcAgreementId));
    }
    dispatch(
      billingDuck.thunks.getBillingOptionsMethod(
        shoppingCartId === '' ? null : shoppingCartId,
      ),
    );
    navigate(Routes.billing.replace(':locationIndex', '1'));
  };

  return (
    <PanelContainer>
      <Panel
        primary
        width='52%'
        isLoading={isSigingService || isSigingLOA || isSigingTerms}
      >
        {isRecognition ? (
          <AgreementWrapper heightShrinked={isTermsChecked}>
            <PanelTitle fontSize={20}>
              {translate('termsAndConditions')}
            </PanelTitle>
            <AgreementContainer>
              <TermsAndConditionsFormatted
                handleScroll={handleScroll}
                isDownloadDisabled={!isTermsRed}
              />
              <Checkbox
                size={15}
                name='agreeTermsAndConditions'
                checked={isTermsChecked}
                label={translate('iAcceptTermsAndConditions')}
                disabled={!isTermsRed}
                onChange={agreeTermsAndConditions}
              />
            </AgreementContainer>
          </AgreementWrapper>
        ) : null}

        <AgreementWrapper heightShrinked={isServiceAgreementChecked}>
          <PanelTitle fontSize={20}>{translate('serviceAgreement')}</PanelTitle>
          <AgreementContainer>
            {isAdvancedProgram ? (
              <ServiceAgreementAdvancedFormatted
                handleScroll={handleScroll}
                isDownloadDisabled={!isServiceAgreementRed}
              />
            ) : (
              <ServiceAgreementFormatted
                handleScroll={handleScroll}
                isDownloadDisabled={!isServiceAgreementRed}
              />
            )}
            <Checkbox
              size={15}
              name='agreeServiceTerms'
              checked={isServiceAgreementChecked}
              label={translate('iAcceptTermsOfService')}
              disabled={!isServiceAgreementRed}
              onChange={agreeServiceTerms}
            />
          </AgreementContainer>
        </AgreementWrapper>

        <AgreementWrapper heightShrinked={isLOAChecked}>
          <PanelTitle fontSize={20}>
            {translate('letterOfAuthorization')}
          </PanelTitle>
          <AgreementContainer>
            <LetterOfAuthorizationFormatted
              handleScroll={handleScroll}
              isDownloadDisabled={!isLOARed}
            />
            <Checkbox
              size={15}
              name='agreeLOATerms'
              checked={isLOAChecked}
              label={translate('iAcceptTheLOA')}
              disabled={!isLOARed}
              onChange={agreeLOATerms}
            />
          </AgreementContainer>
        </AgreementWrapper>
        <Button
          center
          block
          onClick={setAgreementsAndGoToBilling}
          disabled={
            !(
              isLOAChecked &&
              isServiceAgreementChecked &&
              (isRecognition ? isTermsChecked : true)
            )
          }
          marginTop
          marginBottom
          data-automation='SetUpBillingDetails'
        >
          {translate('setUpBillingDetails')}
        </Button>
      </Panel>
      <Panel secondary width='48%'>
        <ServicesPanel
          subtitle={translate('pleaseSignTheServiceAgreement')}
          hideSummary
        />
      </Panel>
    </PanelContainer>
  );
};

export default ServiceAgreementPage;

import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionIsRunning } from 'core/ducks/running';
import { Checkbox, RadioGroup } from 'core/components';
import LoadingSection from 'core/components/LoadingSection';
import {
  PaymentFormWrapper,
  PaymentMethodRadioButtonContainer,
  ZuoraWrapper,
} from './styled';
import ZuoraPaymentIframe from './ZuoraPaymentIframe';
import { useForm } from 'react-hook-form';
import translate from 'core/helpers/translate';
import { OptionType, PaymentMethodFormData } from 'contracts/core/form';
import billingDuck from 'ducks/billing';
import { ApplicationState } from 'contracts/core/state';
import { usePrevious } from 'components/Service/ServiceDetailsConfiguration/hooks';
import { SectionTitle } from 'core/components/styled/Panel2';
import CheckPaymentModal from './CheckPaymentModal';
import PaymentMethodType from 'contracts/enums/PaymentMethodType';
import { useModalManager } from 'core/helpers/hooks';

const paymentOptions = [
  { label: 'Credit Card', code: PaymentMethodType.creditCard },
  { label: 'ACH', code: PaymentMethodType.ach },
  { label: 'Paper Payment', code: PaymentMethodType.check },
];

const PaymentMethodSection: React.FC<ComponentProps> = forwardRef(
  (
    { paymentSubmit, onZuoraFieldErros, continueWithCheckPayment },
    ref,
  ) => {
    const dispatch = useDispatch();
    const { openConfirmation } = useModalManager();

    const [showPaperPaymentModal, setShowPaperPaymentModal] = useState(false);

    const { getValues, watch, register, setValue } =
      useForm<PaymentMethodFormData>({
        mode: 'onChange',
        defaultValues: {
          paymentMethodType: PaymentMethodType.creditCard,
          paymentAutoPay: true,
        },
      });

    const paymentMethodType = watch('paymentMethodType');
    const prevPaymentMethodTypes = usePrevious(paymentMethodType);

    const { zuoraACHFormData, zuoraCCFormData, allowCheckPayment,
      isNewCreditCardAutopayEditable, newCreditCardAutopayDefaultValue } =
      useSelector((state: ApplicationState) => state.billing);
    const isLoadingSigniture = useSelector(
      (state: ApplicationState): boolean =>
        actionIsRunning(state, billingDuck.actionKeys.GET_CC_FORM_DATA) ||
        actionIsRunning(state, billingDuck.actionKeys.GET_ACH_FORM_DATA),
    );

    const onChangeDigitalPayments = () => {
      setShowPaperPaymentModal(false);
      setValue('paymentMethodType', PaymentMethodType.creditCard);
    };

    useImperativeHandle(ref, () => ({
      getPaymentMethodType() {
        return paymentMethodType;
      },
      showCheckPaymentModal() {
        setShowPaperPaymentModal(true);
      },
    }));

    useEffect(() => {
      if (
        paymentMethodType !== prevPaymentMethodTypes &&
        paymentMethodType === PaymentMethodType.ach
      ) {
        loadZuoraACHFormData();
      }
      if (
        paymentMethodType !== prevPaymentMethodTypes &&
        paymentMethodType === PaymentMethodType.creditCard
      ) {
        loadZuoraCCFormData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethodType]);

    const loadZuoraACHFormData = () => {
      dispatch(billingDuck.actions.reset());
      dispatch(billingDuck.thunks.loadPaymentFormData(PaymentMethodType.ach));
    };

    const loadZuoraCCFormData = () => {
      dispatch(billingDuck.actions.reset());
      dispatch(billingDuck.thunks.loadPaymentFormData(PaymentMethodType.creditCard));
    };

    const forwardPaymentResponse = (paymentResponse: any) => {
      const paymentMethod = getValues('paymentMethodType');
      const autoPay = getValues('paymentAutoPay');
      paymentSubmit(paymentResponse, paymentMethod, autoPay);
    };

    const filterPaymentOptions = (
      paymentOption: OptionType[],
    ): OptionType[] => {
      if (!allowCheckPayment) {
        return paymentOptions.filter(option => option.code !== PaymentMethodType.check);
      }
      return paymentOptions;
    };

    const onAutopayCheck = async () => {
      const autoPay = getValues('paymentAutoPay');
      // when gets unchecked, check it back and ask for confirmation
      if (!autoPay) {
        setValue('paymentAutoPay', true);
        openConfirmation(translate('removingAutoPayWarning'), response => {
          if (response) {
            setValue('paymentAutoPay', false);
          }
        });
      }
    };

    return (
      <>
        <PaymentFormWrapper>
          <SectionTitle>{translate('paymentMethodSubtitle')}</SectionTitle>
          <PaymentMethodRadioButtonContainer>
            <RadioGroup
              options={filterPaymentOptions(paymentOptions)}
              {...register('paymentMethodType', {
                required: true,
              })}
            />
          </PaymentMethodRadioButtonContainer>
          {paymentMethodType !== PaymentMethodType.check && (
            <>
              <ZuoraWrapper>
                <LoadingSection
                  isLoading={isLoadingSigniture}
                  transparent
                  loadText='Loding billing form...'
                >
                  {paymentMethodType === PaymentMethodType.creditCard && zuoraCCFormData && (
                    <ZuoraPaymentIframe
                      formData={zuoraCCFormData}
                      forwardPaymentResponse={forwardPaymentResponse}
                      onZuoraFieldErros={onZuoraFieldErros}
                    />
                  )}
                  {paymentMethodType === PaymentMethodType.ach && zuoraACHFormData && (
                    <ZuoraPaymentIframe
                      formData={zuoraACHFormData}
                      forwardPaymentResponse={forwardPaymentResponse}
                      onZuoraFieldErros={onZuoraFieldErros}
                    />
                  )}
                </LoadingSection>
              </ZuoraWrapper>
              <Checkbox
                label={translate('enableAutoPayFormLabel')}
                {...register('paymentAutoPay')}
                disabled={!isNewCreditCardAutopayEditable}
                onChange={e => {
                  setValue('paymentAutoPay', e.target.checked)
                  onAutopayCheck();
                }}
                checked={newCreditCardAutopayDefaultValue}
              />
            </>
          )}
        </PaymentFormWrapper>
        {allowCheckPayment && showPaperPaymentModal && (
          <CheckPaymentModal
            continueWithCheckPayment={continueWithCheckPayment}
            onChangeDigitalPayments={onChangeDigitalPayments}
          />
        )}
      </>
    );
  },
);

interface OwnStateProps { }

interface StateProps { }

interface OwnProps {
  ref: ForwardedRef<unknown>;
  paymentSubmit: (
    zuoraResponse: any,
    paymentMethod: string,
    autoPay: boolean,
  ) => void;
  onZuoraFieldErros: () => void;
  continueWithCheckPayment: () => void;
}

type ComponentProps = StateProps & OwnProps & OwnStateProps;

export default PaymentMethodSection;

import React from 'react';
import { Modal } from 'core/components';
import { Button } from 'core/components/styled';
import translate from 'core/helpers/translate';
import { CheckPaymentButtonsWrapper, CheckPaymentModalContentWrapper, CheckPaymentModalDescription, CheckPaymentModalTitle, SavePaperImg } from './styled';
import savePaperImg from 'core/assets/images/save-paper.png';

const CheckPaymentModal: React.FC<ComponentProps> = ({ onChangeDigitalPayments, continueWithCheckPayment }) => {
  return (
    <Modal size='medium'>
      <CheckPaymentModalContentWrapper>
        <CheckPaymentModalTitle>{translate('checkPaymentModalTitle')}</CheckPaymentModalTitle>
        <SavePaperImg src={savePaperImg} />
        <CheckPaymentModalDescription>{translate('checkPaymentModalDescription')}</CheckPaymentModalDescription>
        <CheckPaymentButtonsWrapper>
          <Button
            onClick={() => { onChangeDigitalPayments() }}
            marginBottom
          >
            {translate('checkPaymentDigitalBtnLabel')}
          </Button>
          <Button
            simpleText
            onClick={() => { continueWithCheckPayment() }}
          >
            {translate('checkPaymentContinueBtnLabel')}
          </Button>
        </CheckPaymentButtonsWrapper>
      </CheckPaymentModalContentWrapper>

    </Modal>
  );
};

interface ComponentProps {
  onChangeDigitalPayments: () => void;
  continueWithCheckPayment: () => void;
}

export default CheckPaymentModal;

import { StartDateFormData } from 'contracts/core/form';
import { HolidayCalendarDataView } from 'contracts/models/HolidayCalendarDataView';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { Input } from 'core/components';
import DatePicker from 'core/components/DatePicker';
import { Button } from 'core/components/styled';
import translate from 'core/helpers/translate';
import { isNumber } from 'lodash-es';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Modifier } from 'react-day-picker';
import { Controller, useForm } from 'react-hook-form';
import { StartDateForm as Form } from './styled';

const StartDateForm: React.FC<ComponentProps> = ({
  service,
  holidays,
  onSubmitDone,
}) => {
  const { getValues, register, setValue, handleSubmit, control, formState: { errors } } =
    useForm<StartDateFormData>({
      mode: 'onChange',
    });

  let disabledDays: Modifier[] = [{ daysOfWeek: [0, 6] } as Modifier]
  if (holidays && holidays.length > 0) {
    disabledDays = disabledDays.concat(
      holidays.map(h => new Date(h.date) as Modifier),
    );
  }
  if (service.startDayOffset) {
    let disabledDaysCounter = 0
    const startDate = moment();
    const endDate = moment()
      .startOf('day')
      .add(
        service && isNumber(service.startDayOffset)
          ? service.startDayOffset - 1
          : 0,
        'days',
      )
      .toDate();

    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
      const dayOfWeek = m.day();
      const isHoliday = disabledDays.includes(new Date(m.format('YYYY-MM-DD')));

      if (dayOfWeek === 0 || dayOfWeek === 6 || isHoliday) {
        disabledDaysCounter += 1;
      }
    }

    disabledDays = disabledDays.concat({
      before: moment()
        .startOf('day')
        .add(
          service && isNumber(service.startDayOffset)
            ? service.startDayOffset + disabledDaysCounter - 1
            : 0,
          'days',
        )
        .toDate(),
    } as Modifier);
  }


  useEffect(() => {
    if (service) {
      setValue('startDate', service.startDate || '');
      setValue('endDate', service.endDate || '');
      setValue('notes', service.notes || '');
    }
  }, [service, setValue]);

  const onEndDateChanged = (date: string) => {
    setValue('endDate', date);
  };

  const onSubmit = () => {
    onSubmitDone(getValues());
  };

  return service ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {service.editStartDate ? (
        <Controller
          control={control}
          name='startDate'
          render={({ field: { onChange, ...field } }) => (
            <DatePicker
              {...field}
              onDateChanged={onChange}
              value={getValues('startDate') || ''}
              isThreeDayRange={true}
              label={translate('startDateFormFieldStartDate')}
              disabledDays={disabledDays}
              name='startDate'
            />
          )}
        />
      ) : null}

      {service.editEndDate ? (
        <DatePicker
          {...register('endDate', {
            required: {
              value: service.requiredEndDate,
              message: translate('pleaseChooseEndDate'),
            },
            validate: (data) => {
              if (data && service.editStartDate) {
                const startDate = getValues('startDate');
                if (startDate && moment(data).isBefore(moment(startDate))) {
                  return `${translate('serviceCantBeEndedBefore')} ${translate(
                    'startDateFormFieldStartDate',
                  )} ${startDate}.`;
                }
              }
            },
          })}
          name='endDate'
          label={translate('startDateFormFieldEndDate')}
          disabledDays={disabledDays}
          errors={errors && errors.endDate}
          onDateChanged={onEndDateChanged}
          value={getValues('endDate') || ''}
        />
      ) : null}
      {service.editNotes && (
        <Input
          {...register('notes', {
            required: {
              value: service.requiredNotes,
              message: translate('pleaseAddNotes'),
            },
          })}
          name='notes'
          type='text'
          label={translate('startDateFormFieldNotes')}
          errors={errors && errors.notes}
          defaultValue={service.notes}
          autoComplete={'off'}
          fieldDescription={translate('notesFieldDescription')}
        />
      )}
      <span></span>
      <Button marginTop type='submit' data-automation='NextButton'>
        {translate('startDateFormButtonNext')}
      </Button>
    </Form>
  ) : null;
};

interface ComponentProps {
  service: ServiceDataView;
  holidays?: HolidayCalendarDataView[];
  onSubmitDone: (data: StartDateFormData) => void;
}

export default StartDateForm;

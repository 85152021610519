import { LoadingBarAction } from 'contracts/core/action';
import { LoadingBarState, ReduceFunctionMap } from 'contracts/core/state';
import { getReducerBuilder } from 'core/reducerBuilder';
import update from 'immutability-helper';

// Actions Keys
const ROOT_KEY = 'core';
enum ActionKey {
  SET_LOADINGBAR_PROGESS = 'core/SET_LOADINGBAR_PROGESS',
}

// Initial State
const getInitialState: () => LoadingBarState = () => {
  return {
    progress: 0,
  };
};

// Reducer
const reducerKeys = [ActionKey.SET_LOADINGBAR_PROGESS] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  LoadingBarState,
  LoadingBarAction
> = {
  [ActionKey.SET_LOADINGBAR_PROGESS]: (state, action) => {
    const { progress } = action;
    return update(state, { $merge: { progress } });
  },
};

export const reducer = getReducerBuilder<LoadingBarState, LoadingBarAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
export const setLoadingBarProgress = (progress: number): LoadingBarAction => ({
  type: ActionKey.SET_LOADINGBAR_PROGESS,
  progress,
});

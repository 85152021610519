import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { SiteDataView } from 'contracts/models/SiteDataView';
import {
  getLocationActivationFee,
  getLocationMonthlyFee,
} from 'core/helpers/location';
import React from 'react';
import PriceSummary from './PriceSummary';
import ServiceCard from './ServiceCard';
import { LocationServicesList } from './styled';

const ServiceLocationServiceList: React.FC<ComponentProps> = ({
  indexLocation,
  location,
  selectedService,
  hideSummary,
  showActions,
}) => {
  if (!location || location.services.length === 0) {
    return null;
  }
  const entries = [];
  for (
    let indexService = 0;
    indexService < location.services.length;
    indexService++
  ) {
    entries.push(
      <ServiceCard
        key={`service_${indexLocation}_${indexService}`}
        service={location.services[indexService]}
        indexLocation={indexLocation}
        indexService={indexService}
        showServiceActions={showActions}
        isSelected={selectedService === location.services[indexService]}
      />,
    );
  }
  const activationCost = getLocationActivationFee(location);
  const monthlyCost = getLocationMonthlyFee(location);

  return (
    <LocationServicesList>
      {entries}
      {!hideSummary && (
        <PriceSummary
          activationCost={activationCost}
          monthlyCost={monthlyCost}
          managementFee={location.managementFee}
        ></PriceSummary>
      )}
    </LocationServicesList>
  );
};

interface ComponentProps {
  indexLocation: number;
  location: SiteDataView;
  selectedService?: ServiceDataView;
  hideSummary?: boolean;
  showActions?: boolean;
}

export default ServiceLocationServiceList;

import {
  PhoneNumberValidationRequest,
  ValidationCodeConfirmationRequest,
} from 'contracts/core/request';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';
import { httpSavingsCore } from 'core/services/http';

export async function getBillingOptions(
  shoppingCartId: string | null,
): Promise<any> {
  const response = await httpSavingsCore.get(
    `/savings/shoppingcart/${shoppingCartId}/billing-options`,
  );
  return response.data;
}

export async function checkPromoCodeValidity(promocode: string): Promise<any> {
  const response = await httpSavingsCore.get(
    `/sales/people/promocode/${promocode}`,
  );
  return response.data;
}

export async function sendPhoneNumberValidationCode(
  request: PhoneNumberValidationRequest,
): Promise<any> {
  const response = await httpSavingsCore.post(
    `/savings/shoppingcart/activation/send`,
    {
      ...request,
    },
  );
  return response.data;
}

export async function checkValidationCode(
  request: ValidationCodeConfirmationRequest,
): Promise<any> {
  const response = await httpSavingsCore.post(
    `/savings/shoppingcart/activation/validate`,
    {
      Code: request.code,
      RequestToken: request.requestToken,
      UserId: request.userId,
    },
  );
  return response.data;
}

export async function packagePurchase(cart: SaleCartDataView): Promise<any> {
  const response = await httpSavingsCore.post(`/sale/packagepurchase`, {
    ...cart,
  });
  return response.data;
}

export async function checkRecognizedAddress(
  request: {
    email: string;
  },
): Promise<{
  recognized: boolean;
}> {
  const response = await httpSavingsCore.post<{
    recognized: boolean;
  }>(
    `/sale/recognized`,
    {
      ...request,
    },
  );
  return response.data;
}
import * as translationsEn from 'core/assets/locale/locale-en.json';
import { DEFAULT_LOCALE, EN, LOCALES } from 'core/constants/locales';
import { get, reduce, replace } from 'lodash-es';

type MapType = { [key: string]: string };

const getLocale = () => {
  const languageLocale = navigator.language.substr(0, 2);
  return LOCALES.indexOf(languageLocale) > -1 ? languageLocale : DEFAULT_LOCALE;
};

const locale = getLocale();

const translations: { [key: string]: MapType } = {
  [EN]: translationsEn,
};

const getTranslation = (translationKey: string) => {
  return get(translations[locale].default, translationKey, translationKey);
};

const interpolate = (translation: string, map: MapType) =>
  reduce(
    map,
    (interpolatedTranslation, value, key) =>
      replace(interpolatedTranslation, `{{${key}}}`, value),
    translation,
  );

const translate = (translationKey: string, map?: MapType) =>
  map
    ? interpolate(getTranslation(translationKey), map)
    : getTranslation(translationKey);

export default translate;

import React from 'react';
import Script from './Script';

const ZuoraScript: React.FC<ComponentProps> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='zuoraScript'
      isUrl
      content='https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora.js'
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
      children={children}
    />
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
}

export default ZuoraScript;

import { BaseAction } from 'contracts/core/action';
import { BaseState } from 'contracts/core/state';
import { ReducerBuilder } from './ReducerBuilder';

export function getReducerBuilder<S extends BaseState, A extends BaseAction>(
  rootName: string,
  initialState: () => S,
): ReducerBuilder<S, A> {
  const reducer = new ReducerBuilder<S, A>(rootName, initialState);
  return reducer;
}

import { currency } from 'core/services/formatter';
import {
  SaleRuleDataView,
  SalesEditableServiceOptions,
  SaleSelectionValues,
  SaleMarketConfigDataView,
} from 'contracts/models/SaleMarketConfigDataView';
import EquipmentType from 'contracts/enums/EquipmentType';
import {
  FeeDataView,
  IncludedItemDataView,
} from 'contracts/models/QuoteRequestDataView';
import { CheckboxOptionType, CurrentServiceForm } from 'contracts/core/form';
import { ServicePriceDataView } from 'contracts/models/ServicePriceDataView';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { RetailPriceResponseDataView } from 'contracts/models/RetailPriceDataView';
import translate from 'core/helpers/translate';

export const filterFormRulesByEquipmentType = (
  serviceCode: string,
  marketConfig: SaleMarketConfigDataView,
) => {
  const { editableProps } = marketConfig;
  const filteredProps =
    editableProps &&
    editableProps.filter(
      (prop: SalesEditableServiceOptions) => prop.serviceType === serviceCode,
    );
  return filteredProps && filteredProps[0];
};

export const filterFormOptionsByServiceType = (
  serviceCode: string,
  marketConfig: SaleMarketConfigDataView,
  materialType?: string,
): {
  materialLabels: SaleSelectionValues[];
  frequencyLabels: SaleSelectionValues[];
  equipmentLabels: SaleSelectionValues[];
  startDayOffset: number;
} => {
  let { rules, materialLabels, frequencyLabels, equipmentLabels } =
    marketConfig;
  const availableMaterials: string[] = [];
  const availableFrequency: string[] = [];
  const availableEquipment: string[] = [];
  let startDayOffset: number = 0;
  if (rules) {
    if (serviceCode) {
      rules = rules.filter((rule: SaleRuleDataView) => {
        if (rule.service === serviceCode) {
          availableMaterials.indexOf(rule.material) === -1 &&
            availableMaterials.push(rule.material);

          availableFrequency.indexOf(rule.frequency) === -1 &&
            availableFrequency.push(rule.frequency);

          availableEquipment.indexOf(rule.equipment) === -1 &&
            availableEquipment.push(rule.equipment);

          startDayOffset = rule.startDayOffset;
        }
        return null;
      });
    }
    if (materialType && serviceCode) {
      rules = rules.filter((rule: SaleRuleDataView) => {
        if (rule.material === materialType && rule.service === serviceCode) {
          availableFrequency.indexOf(rule.frequency) === -1 &&
            availableFrequency.push(rule.frequency);

          availableEquipment.indexOf(rule.equipment) === -1 &&
            availableEquipment.push(rule.equipment);

          startDayOffset = rule.startDayOffset;
        }
        return null;
      });
    }
  }
  if (materialLabels) {
    materialLabels = materialLabels.filter((option: SaleSelectionValues) =>
      availableMaterials.includes(option.code),
    );
  }
  if (frequencyLabels) {
    frequencyLabels = frequencyLabels.filter((option: SaleSelectionValues) =>
      availableFrequency.includes(option.code),
    );
  }
  if (equipmentLabels) {
    equipmentLabels = equipmentLabels.filter((option: SaleSelectionValues) =>
      availableEquipment.includes(option.code),
    );
  }

  return {
    materialLabels,
    frequencyLabels,
    equipmentLabels,
    startDayOffset,
  };
};

export const filterFormOptionsByMaterialType = (
  materialType: string,
  serviceCode: string,
  marketConfig: SaleMarketConfigDataView,
): {
  frequencyLabels: SaleSelectionValues[];
  equipmentLabels: SaleSelectionValues[];
  startDayOffset: number;
} => {
  let { rules, frequencyLabels, equipmentLabels } = marketConfig;
  const availableFrequency: string[] = [];
  const availableEquipment: string[] = [];
  let startDayOffset: number = 0;
  if (rules) {
    if (materialType && serviceCode) {
      rules = rules.filter((rule: SaleRuleDataView) => {
        if (rule.material === materialType && rule.service === serviceCode) {
          availableFrequency.indexOf(rule.frequency) === -1 &&
            availableFrequency.push(rule.frequency);

          availableEquipment.indexOf(rule.equipment) === -1 &&
            availableEquipment.push(rule.equipment);

          startDayOffset = rule.startDayOffset;
        }

        return null;
      });
    } else if (materialType) {
      rules = rules.filter((rule: SaleRuleDataView) => {
        if (rule.material === materialType) {
          availableFrequency.indexOf(rule.frequency) === -1 &&
            availableFrequency.push(rule.frequency);

          availableEquipment.indexOf(rule.equipment) === -1 &&
            availableEquipment.push(rule.equipment);

          startDayOffset = rule.startDayOffset;
        }

        return null;
      });
    }
  }

  if (frequencyLabels) {
    frequencyLabels = frequencyLabels.filter((option: SaleSelectionValues) =>
      availableFrequency.includes(option.code),
    );
  }
  if (equipmentLabels) {
    equipmentLabels = equipmentLabels.filter((option: SaleSelectionValues) =>
      availableEquipment.includes(option.code),
    );
  }

  return {
    frequencyLabels,
    equipmentLabels,
    startDayOffset,
  };
};

export const getPriceBasedOnEquipmentSizes = (
  equipmentSizes: RetailPriceResponseDataView[],
) => {
  const newOptions = equipmentSizes.map(equipment => {
    let currentOptionLabel: { label: string; price: string }[] = [];
    equipment.base &&
      equipment.base.forEach((base: FeeDataView, i: number) => {
        currentOptionLabel.push({
          label: i === 0 ? translate('basePrice') : '',
          price: `${currency(base.value)} / ${base.uom}`,
        });
        base.includes &&
          base.includes.forEach((include: IncludedItemDataView, j: number) => {
            if (j === 0) {
              currentOptionLabel[
                i
              ].price = `${currentOptionLabel[i].price}, includes`;
            }
            currentOptionLabel[
              i
            ].price = `${currentOptionLabel[i].price} ${include.value} ${include.uom}`;
          });
      });
    return currentOptionLabel;
  });
  return newOptions.flat();
};

export const formatOptionalExtrasOptions = (
  extras: FeeDataView[],
): CheckboxOptionType[] => {
  const newOptions =
    extras &&
    extras.map((extra: FeeDataView) => {
      return {
        name: extra.code,
        code: extra.code,
        label: `${extra.label} ${currency(extra.value)} / ${extra.uom}`,
      };
    });
  return newOptions;
};

export function getEquipmentTypeFromCode(
  code?: string,
): EquipmentType | undefined {
  switch (code) {
    case 'Cart':
      return EquipmentType.CART;
    case 'FrontLoad':
      return EquipmentType.FRONT_LOAD;
    case 'OpenTop':
    case 'OpenTopTemp':
      return EquipmentType.OPEN_TOP;
  }
}

export function getLabelFromCode(
  code?: string,
  labels?: SaleSelectionValues[],
): string | undefined {
  if (code && labels) {
    const label = labels.find(l => l.code === code);
    return label ? label.label : undefined;
  }
}

export function getSelectedExtrasArray(extras?: any[]): string[] {
  const extrasArray = [] as string[];
  if (extras && extras.length > 0) {
    extras.forEach(item => {
      if (item.code) {
        extrasArray.push(item.code);
      } else {
        const key: string = Object.keys(item)[0];
        if (item[`${key}`] !== false) {
          extrasArray.push(key);
        }
      }
    });
  }
  return extrasArray;
}

export function getServiceRatesAndExtras(
  service?: CurrentServiceForm,
  quotes?: ServicePriceDataView[],
) {
  const serviceRates = [] as any[];
  const extrasLabels: string[] = [];
  if (service && service.equipment && quotes && quotes.length > 0) {
    quotes.forEach(quote => {
      if (quote.equipment === service.equipment) {
        if (quote.base) {
          quote.base.forEach(quoteItem => {
            const { code, description, label, uom, value } = quoteItem;
            serviceRates.push({ code, description, label, uom, value });
          });
        }
        if (service.optionalExtras && quote.optionalExtras) {
          quote.optionalExtras
            .filter(extra => service.optionalExtras.includes(extra.code))
            .forEach(optionalExtra => {
              extrasLabels.push(optionalExtra.label);
              const existingRateUom = serviceRates.find(
                rate => rate.uom === optionalExtra.uom,
              );
              if (existingRateUom) {
                existingRateUom.value += optionalExtra.value;
              }
            });
        }
      }
    });
  }
  return { serviceRates, extrasLabels };
}

export function getCurrentServiceDetails(
  currentService: CurrentServiceForm,
  quotes: RetailPriceResponseDataView[],
  formRules?: any,
  formOptions?: any,
): ServiceDataView {
  const service: ServiceDataView = {
    ...quotes.filter(quote => quote.equipment === currentService.equipment)[0],
    editStartDate: Boolean(formRules.editStartDate),
    requiredStartDate: Boolean(formRules.requiredStartDate),
    startDate: '',
    editEndDate: Boolean(formRules.editEndDate),
    requiredEndDate: Boolean(formRules.requiredEndDate),
    editNotes: Boolean(formRules.editNotes),
    requiredNotes: Boolean(formRules.requiredNotes),
    endDate: null,
    notes: '',
    startDayOffset: formOptions?.startDayOffset || 0,
    isEditable: true,
    externUid: null,
    canDelete: currentService.hasOwnProperty('canDelete') ? Boolean(currentService.canDelete) :true,
    isTemp: formRules.isTemp,
    redirectToSpecializedTool: formRules.redirectToSpecializedTool
  };
  if (
    service.optionalExtras &&
    service.optionalExtras.length > 0 &&
    currentService.optionalExtras &&
    currentService.optionalExtras.length > 0
  ) {
    service.optionalExtras = service.optionalExtras.filter(extra =>
      currentService.optionalExtras.includes(extra.code),
    );
  } else {
    service.optionalExtras = [];
  }
  service.schedule = service.schedule ? service.schedule : '';
  return service;
}

import { NavigationItem } from 'contracts/core/routing';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NavigationPrimaryItemsContainer,
  NavItem,
  NavItemContainer,
  NavItemLabel,
  NavItemLabelSubtitle,
  NavItemStep,
} from './styled';

const NavigationPrimaryItems: React.FC<ComponentProps> = ({
  navigationItems,
}) => {
  const navigate = useNavigate();

  const onclick = (item: NavigationItem) => {
    navigate(item.path);
  };

  return (
    <NavigationPrimaryItemsContainer>
      {navigationItems.map(item => (
        <NavItem
          key={item.path}
          active={item.active ? 'true' : undefined}
          completed={item.completed ? 'true' : undefined}
          disabled={item.disabled}
          onClick={() => onclick(item)}
        >
          <NavItemStep>{item.step}</NavItemStep>
          <NavItemContainer>
            <NavItemLabel mobileLabel={item.mobileLabel}>
              <span>{item.label}</span>
            </NavItemLabel>
            {item.subtitle ? (
              <NavItemLabelSubtitle>{item.subtitle}</NavItemLabelSubtitle>
            ) : null}
          </NavItemContainer>
        </NavItem>
      ))}
    </NavigationPrimaryItemsContainer>
  );
};

interface ComponentProps {
  navigationItems: NavigationItem[];
}

export default NavigationPrimaryItems;

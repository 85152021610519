import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fontFamilyBase: 'ArsMaquette, Helvetica Neue, Helvetica, sans-serif',
  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  colors: {
    brandDefaultBlack: '#191b18',
    brandDefaultQuiet: '#9ea39c',
    brandPrimary: '#009d90',
    brandDanger: '#d42727',
    borderGray: '#edeced',
    grayBase: '#242833',
    grayDark: '#b4b4c4',
    grayDarker: '#87879c',
    grayDarkest: '#f9f9f9',
    grayText: '#4d4f53',
    grayLight: '#f2f2f5',
    grayLighter: '#f6f6f6',
    grayLightest: '#fbfbfb',
    white: '#ffffff',
    black: '#000000',
    brandGreenLight: '#e5f6f4',
    brandGreenLighter: '#b9e5e2',
    brandGreenDark: ' #008d80',
    labelTextGrey: '#9CAFC3',
  },
};

export default theme;

import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import Error from './Error';
import { FormGroup, FormLabel, Input as FormInput } from './styled';
import { FieldDescription } from './styled/FormLabel';

const Input = React.forwardRef<HTMLInputElement, ComponentProps>(
  (
    { label, fieldDescription, isHidden, initialized, errors, onBlurOut, ...props },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    useEffect(() => {
      setHasValue(!!props.defaultValue || !!initialized);
    }, [props.defaultValue, initialized, setHasValue]);

    return (
      <FormGroup isFocused={isFocused} hasValue={hasValue}>
        <FormLabel>{label}</FormLabel>
        <FormInput
          {...props}
          ref={ref}
          isHidden={isHidden}
          onFocus={() => setIsFocused(true)}
          onBlur={e => {
            setIsFocused(false);
            setHasValue(!!e.target.value);
            if(onBlurOut){
              onBlurOut(e);
            }
          }}
        />
        {errors && <Error error={errors} />}
        {fieldDescription && (
          <FieldDescription>{fieldDescription}</FieldDescription>
        )}
      </FormGroup>
    );
  },
);

interface OwnProps {
  label: string;
  fieldDescription?: string;
  isHidden?: boolean;
  initialized?: boolean;
  errors?: FieldError;
  onBlurOut?: (e:any) => void;
}

type ComponentProps = OwnProps & React.InputHTMLAttributes<HTMLInputElement>;

export default Input;

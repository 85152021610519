import React from 'react';
import {
  PanelContainer,
  Panel,
  PanelContent,
} from 'core/components/styled/Panel2';
import EquipmentType from 'contracts/enums/EquipmentType';
import translate from 'core/helpers/translate';
import ServiceOptionItem from './ServiceOptionItem';
import { SalesServiceCategory } from 'contracts/models/SaleMarketConfigDataView';
import {
  ServiceOptionsContainer,
  ServiceOptionsModalTitle,
} from '../../Service/styled';

const acceptedServiceTypes = [
  EquipmentType.CART,
  EquipmentType.FRONT_LOAD,
  EquipmentType.OPEN_TOP,
];

const ServiceOptions: React.FC<ComponentProps> = ({
  servicePreselection,
  onModalCloseClick,
}) => {
  const filteredOptions = servicePreselection.filter(service =>
    acceptedServiceTypes.includes(service.category.code),
  );

  return (
    <PanelContainer>
      <Panel>
        <PanelContent noPadding>
          <ServiceOptionsModalTitle>
            {translate('serviceOptionsPageTitle')}
          </ServiceOptionsModalTitle>
          <ServiceOptionsContainer>
            {filteredOptions.map(service => (
              <ServiceOptionItem
                service={service}
                key={service.category.code}
                onModalCloseClick={onModalCloseClick}
              />
            ))}
          </ServiceOptionsContainer>
        </PanelContent>
      </Panel>
    </PanelContainer>
  );
};

interface ComponentProps {
  servicePreselection: SalesServiceCategory[];
  onModalCloseClick: () => void;
}

export default ServiceOptions;

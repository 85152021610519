import { Devices, loadingOverlay, theme } from 'core/styles';
import { transparentize } from 'polished';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

interface PanelTitleParams {
  margin: string;
}
export const PanelTitle = styled.h2<PanelTitleParams>`
  display: block;
  margin: 20px;
  text-transform: capitalize;
  line-height: 30px;
  letter-spacing: 0.4px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 23px;

  ${props =>
    props.margin === 'large' &&
    css`
      margin-bottom: 40px;
    `};
`;

interface PanelContainerTitleParams {
  actionBarTitle?: boolean;
  marginTop?: boolean;
}
export const PanelContainerTitle = styled.h1<PanelContainerTitleParams>`
  width: 100%;
  margin-bottom: 20px;
  line-height: 38px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 28px;
  margin-left: 5px;

  ${props =>
    props.actionBarTitle &&
    css`
      display: inline;
    `};

  ${props =>
    props.marginTop &&
    css`
      margin-top: 35px;
    `};
`;

interface PanelContainerSubtitleParams {
  marginTop?: boolean;
}
export const PanelContainerSubtitle = styled.h4<PanelContainerSubtitleParams>`
  width: 100%;
  margin-top: -15px;
  margin-bottom: 20px;
  line-height: 28px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
  color: ${props => props.theme.colors.brandDefaultQuiet};
  ${props =>
    props.marginTop &&
    css`
      margin-top: 5px;
    `};
`;
export const PanelAccountDetailHeader = styled.div`
  width: 100%;
  display: block;
`;
export const UserInfo = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 46%;
  margin: 0 2%;
  text-transform: capitalize;
  line-height: 30px;
  letter-spacing: 0.4px;
  font-weight: bold;
  font-size: 16px;
  @media ${Devices.tablet} {
    width: 100%;
  }
`;
export const ButtonsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 46%;
  margin: 0 2%;
  @media ${Devices.tablet} {
    margin-top: 20px;
    width: 100%;
  }
`;

export const PanelContainerHeader = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const PanelHeadWithButton = styled.div`
  float: left;
  width: 50%;
`;

interface PanelContainerActionBarParams {
  center?: boolean;
}
export const PanelContainerActionBar = styled.div<
  PanelContainerActionBarParams
>`
  width: 100%;
  margin-bottom: 20px;
  ${props =>
    props.center &&
    css`
      text-align: center;
      margin-bottom: 40px;
    `};
`;

interface PanelSubTitleParams {
  inline: boolean;
}
export const PanelSubTitle = styled.h3<PanelSubTitleParams>`
  margin-bottom: 30px;
  line-height: 22px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 18px;
  color: ${transparentize(0.5, '#000')};
  ${props =>
    props.inline &&
    css`
      display: inline;
    `};
`;

interface PanelContentParams {
  freeMaxWidth?: boolean;
  smallPadding?: boolean;
  paddingless?: boolean;
  scrollable?: boolean;
  wide?: boolean;
  googleMap?: boolean;
}
export const PanelContent = styled.div<PanelContentParams>`
  max-width: 490px;
  margin: 0 auto;
  padding: 40px 60px;

  ${props =>
    props.freeMaxWidth &&
    css`
      max-width: none;
    `}

  ${props =>
    props.smallPadding &&
    css`
      padding: 10px 30px 30px;
    `}

  ${props =>
    props.paddingless &&
    css`
      padding: 0;
    `}

  ${props =>
    props.scrollable &&
    css`
      max-height: 400px;
      overflow: scroll;
    `}

  ${props =>
    props.wide &&
    css`
      max-width: none;
    `}

    ${props =>
      props.googleMap &&
      css`
        max-width: none;
        padding: 0;
      `};
`;

interface PanelContainerParams {
  marginTop?: number;
  wide?: boolean;
}
export const PanelContainer = styled.div<PanelContainerParams>`
  display: flex;
  margin: ${props =>
    props.marginTop || props.marginTop === 0 ? props.marginTop : 40}px auto 0
    auto;
  flex-wrap: wrap;
  max-width: 1200px;

  ${props =>
    props.wide &&
    css`
      max-width: 1340px;
    `}

  @media ${Devices.tablet} {
    margin: ${props =>
      props.marginTop || props.marginTop === 0 ? props.marginTop : 25}px auto 0
      auto;
  }

  @media ${Devices.mobile} {
    margin: ${props =>
      props.marginTop || props.marginTop === 0 ? props.marginTop : 15}px auto 0
      auto;
  }
`;

interface PanelParams extends LoadingStyledComponentProps {
  noBackground?: boolean;
  marginBottom?: boolean;
  fullWidth?: boolean;
  fullWidthPadding?: boolean;
  center?: boolean;
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  flex?: boolean;
  flowRoot?: boolean;
  flexDirection?: string;
  isLoading?: boolean;
}
export const Panel = styled.div<PanelParams>`
  flex: 1 1 auto;
  margin: 0 5px;
  border-radius: 4px;
  min-width: 0;

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};

  ${props =>
    props.flowRoot &&
    css`
      display: flow-root;
    `};

  ${props =>
    !props.noBackground &&
    css`
      background-color: ${theme.colors.white};
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 20px;
    `};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      flex: none;
    `};

  ${props =>
    props.fullWidthPadding &&
    css`
      width: 100%;
      flex: none;
      padding: 20px;
    `};

  ${props => props.isLoading && loadingOverlay(32)};

  ${props =>
    props.center &&
    css`
      align-items: center;
      justify-content: center;
    `};

  ${props =>
    props.primary &&
    css`
      background-color: ${theme.colors.white};
      border-right: 1px solid #edeced;
    `};

  ${props =>
    props.secondary &&
    css`
      background-color: #fafcfc;
    `};

  ${props =>
    props.transparent &&
    css`
      display: flex;
      background-color: transparent;
      border-radius: 0px;
      border-width: 0px;
      box-shadow: none;
    `};

  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};
`;

export const PanelSectionGroup = styled.div`
  position: relative;
`;

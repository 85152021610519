import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, PanelContainer } from 'core/components/styled/Panel2';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import ContactInformationPanel from './ContactInformationPanel';
import ContactInformationSummary from './ContactInformationSummary';
import cart from 'ducks/cart';
import { Routes } from 'routing/routes';
import { useNavigate } from 'react-router';
import queryString from 'query-string';

const ContactInformationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlValues = queryString.parse(window.location.search);
  const { firstName: firstN, lastName: lastN, phone, email: emailAddress } = urlValues;

  const [firstName, setFirstName] = useState<string>(firstN as string | '');
  const [lastName, setLastName] = useState<string>(lastN as string | '');
  const [email, setEmail] = useState<string>(emailAddress as string | '');
  const [phoneNr, setPhoneNr] = useState<string>(phone as string | '');

  const isSignWithCustomer = cart.flags.isSignWithCustomer();
  const shoppingCartId = cart.selectors.getshoppingCartId();

  useEffect(() => {
    if (shoppingCartId || isSignWithCustomer) {
      navigate(Routes.location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignWithCustomer, shoppingCartId]);
  // start page load indicator
  useEffect(() => {
    dispatch(setLoadingBarProgress(50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelContainer>
      <Panel primary width='52%'>
        <ContactInformationPanel
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          setPhoneNr={setPhoneNr}
          defaultValues={{
            firstName,
            lastName,
            email,
            phone: phoneNr
          }}
        />
      </Panel>
      <Panel secondary width='48%'>
        <ContactInformationSummary
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNr={phoneNr}
        />
      </Panel>
    </PanelContainer>
  );
};

export default ContactInformationPage;

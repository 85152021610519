import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PanelContainer,
  PanelTitle,
  Panel,
  PanelContent,
} from 'core/components/styled/Panel2';
import translate from 'core/helpers/translate';
import { SiteDataView } from 'contracts/models/SiteDataView';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { SaleMarketConfigDataView } from 'contracts/models/SaleMarketConfigDataView';
import ServiceDetailsOverview from './ServiceDetailsOverview';
import ServiceDetailsForm from './ServiceDetailsForm';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import serviceDuck from 'ducks/serviceDetails';
import { ApplicationState } from 'contracts/core/state';
import SectionBody from 'core/components/Section';
import { default as FormType } from 'contracts/enums/FormType';
import { CurrentServiceForm } from 'contracts/core/form';
import {
  filterFormOptionsByServiceType,
  filterFormRulesByEquipmentType,
  getSelectedExtrasArray,
} from 'core/helpers/service';
import { Routes } from 'routing/routes';
import { useNavigate } from 'react-router';
import cart from 'ducks/cart';
import redirectToCheckout from 'core/helpers/redirectToCheckout';

const ServiceDetailsConfigurationPage: React.FC<ComponentProps> = ({
  location,
  service,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentLocation, setCurrentLocation] = useState({} as SiteDataView);
  const [currentService, setCurrentService] = useState(
    {} as CurrentServiceForm,
  );
  const [formMode, setFormMode] = useState(FormType.NEW);
  const [isLoadingServiceDetails, setIsLoadingServiceDetails] = useState(false);

  const progress = useSelector(
    (state: ApplicationState) => state.core.loadingBar.progress,
  );
  const marketConfig = useSelector(
    (state: ApplicationState) => state.service.marketConfig,
  );
  const { formOptions, formRules } = useSelector(
    (state: ApplicationState) => state.service.formSettings,
  );
  const limboService = useSelector(
    (state: ApplicationState) => state.service.limboService,
  );
  const allServices = cart.selectors.getAllServices();
  const customerDetails = cart.selectors.getCustomerDetails();

  // Set new service or redirect on refresh
  useEffect(() => {
    if (limboService && service === undefined) {
      setCurrentService(limboService);
    }
    if (limboService === undefined && service === undefined) {
      navigate(Routes.location);
    }
  }, [navigate, limboService, service]);

  // Set edit service
  useEffect(() => {
    if (location) {
      setCurrentLocation(location);
    }
  }, [location]);

  // finish page laod indicator
  useEffect(() => {
    if (progress < 100) {
      dispatch(setLoadingBarProgress(100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (service) {
      if (service && !service.isEditable) {
        navigate(Routes.summary);
      }
      const serviceAlias = {
        ...service,
        optionalExtras: getSelectedExtrasArray(service.optionalExtras),
        schedule: service.schedule || undefined,
      } as CurrentServiceForm;

      setCurrentService(serviceAlias);

      if (
        service.service &&
        (service.material || service.quantity || service.frequency || service.isInEditMode)
      ) {
        if (marketConfig) {
          const options = filterFormOptionsByServiceType(
            service.service,
            marketConfig,
          );
          const rules = filterFormRulesByEquipmentType(
            service.service,
            marketConfig,
          );
          if (rules && rules.redirectToSpecializedTool && currentLocation && currentLocation.address && currentLocation.address.line1) {
            // redirect
            const address = currentLocation.address.line1;
            const firstName = customerDetails ? customerDetails.firstName : undefined;
            const lastName = customerDetails ? customerDetails.lastName : undefined;
            const email = customerDetails ? customerDetails.email : undefined;
            const phone = customerDetails ? customerDetails.phone : undefined;
            redirectToCheckout(address, email, firstName, lastName, phone);
          }
          dispatch(serviceDuck.thunks.updateFormSettings(rules, options));
        }
        setFormMode(FormType.UPDATE);
      }
    } else {
      setFormMode(FormType.NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, marketConfig]);

  useEffect(() => {
    if (
      formRules &&
      formOptions &&
      Object.keys(formOptions).length === 0 &&
      Object.keys(formRules).length === 0
    ) {
      setIsLoadingServiceDetails(true);
    } else {
      setIsLoadingServiceDetails(false);
    }
  }, [formOptions, formRules]);

  useEffect(() => {
    async function fetchMarketConfiguration(): Promise<void> {
      try {
        setIsLoadingServiceDetails(true);
        if (currentLocation && currentLocation.address) {
          const address = currentLocation.address.line1;
          const zipCode = currentLocation.address.zip;
          if (address && zipCode) {
            let hasCartPermanentSelection = false;
            if (allServices.find(s => s.isTemp === false)) {
              hasCartPermanentSelection = true;
            }
            await dispatch(
              serviceDuck.thunks.loadMarketConfig({ zipCode, address, marketConfigToken: currentLocation.marketConfigToken }, hasCartPermanentSelection),
            );
          }
        }
      } catch (err) {
        // TODO: error display
      }
      setIsLoadingServiceDetails(false);
    }
    if (formMode === FormType.UPDATE) {
      fetchMarketConfiguration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation]);

  return (
    <PanelContainer>
      <Panel primary width='52%'>
        <PanelContent>
          <SectionBody isLoading={isLoadingServiceDetails} autoHeight={true}>
            <PanelTitle>
              {translate('serviceDetailsConfigurationPageTitle')}
            </PanelTitle>
            <ServiceDetailsForm
              currentLocation={currentLocation}
              currentService={currentService}
              onCurrentServiceChange={setCurrentService}
              setIsLoadingServiceDetails={setIsLoadingServiceDetails}
              formMode={formMode}
              isInEditMode={service?.isInEditMode}
            />
          </SectionBody>
        </PanelContent>
      </Panel>
      <Panel secondary width='48%'>
        <PanelContent>
          {currentLocation && currentLocation.address && (
            <ServiceDetailsOverview
              address={currentLocation.address}
              service={currentService}
            />
          )}
        </PanelContent>
      </Panel>
    </PanelContainer>
  );
};
interface OwnStateProps {
  currentLocation?: SiteDataView;
  currentService?: CurrentServiceForm;
  marketConfig?: SaleMarketConfigDataView;
}

interface StateProps {
  location?: SiteDataView;
  service?: ServiceDataView;
}

type ComponentProps = StateProps & OwnStateProps;

export default ServiceDetailsConfigurationPage;

import styled, { css, LoadingStyledComponentProps } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper } from 'utils/styles';
import { fadeAndSlideInUp, loadingOverlay, Devices, theme } from 'core/styles';
import Icon from '../Icon';
import { BareButtonStyle } from './Button';

interface ModalTitleProps {
  textAlign?: string;
  bottomPadding?: boolean;
}
export const ModalTitle = styled.h2<ModalTitleProps>`
  margin: 0 0 8px;
  line-height: 29px;
  text-transform: capitalize;
  text-align: ${props =>
    mapper(
      { left: 'left', center: 'center', right: 'right' },
      props.textAlign,
      'center',
    )};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 24px;

  ${props =>
    props.bottomPadding &&
    css`
      padding-bottom: 20px;
    `}
`;

interface ModalSubtitleProps {
  textAlign?: string;
}
export const ModalSubtitle = styled.span<ModalSubtitleProps>`
  display: block;
  line-height: 16px;
  text-align: ${props =>
    mapper(
      { left: 'left', center: 'center', right: 'right' },
      props.textAlign,
      'center',
    )};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 13px;
  color: ${props => props.theme.colors.grayDark};
`;

interface ModalDialogProps {
  size: string;
  padding?: string;
  addCustomScrollbar?: boolean;
  fullWindow?: boolean;
}
export const ModalDialog = styled.div<ModalDialogProps>`
  position: relative;
  width: ${props =>
    mapper(
      {
        xSmall: '400px',
        small: '600px',
        mSmall: '650px',
        medium: '800px',
        large: '1000px',
      },
      props.size,
    )};
  max-height: 80vh;
  padding: ${props => sizeMapper(props.padding)};
  background-color: ${theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 4px 8px
    ${props => transparentize(0.9, props.theme.colors.grayBase)};
  animation: ${fadeAndSlideInUp} 0.3s ease-out;
  overflow: auto;

  @media screen and (max-height: 890px) {
    max-height: 100vh;
  }
  @media ${Devices.tablet} {
    max-width: 80%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media ${Devices.mobile} {
    width: 100%;
    height: 100vh;
    max-width: 100vh;
    max-height: 100vh;
  }

  ${props =>
    props.fullWindow &&
    css`
      max-height: 100vh;
      height: 100vh;
      width: 100%;

      @media screen and (max-height: 890px) {
        max-height: 100vh;
      }
      @media ${Devices.tablet} {
        max-width: 100%;
        max-height: 100vh;
      }
      @media ${Devices.mobile} {
        width: 100%;
        height: 100vh;
        max-width: 100vh;
        max-height: 100vh;
      }
    `};
`;

export const ModalCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 12px;
  height: 12px;
`;

export const ModalClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 16px;
  font-size: 12px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IjAyLU1vZGFsIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM5Ni4wMDAwMDAsIC0yNy4wMDAwMDApIiBmaWxsPSIjMDAwMDAwIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTQwNi4wMzEyNSwzNC45Njg3NSBMMTQwNi4wMzEyNSwyNSBMMTQwMy45Njg3NSwyNSBMMTQwMy45Njg3NSwzNC45Njg3NSBMMTM5NCwzNC45Njg3NSBMMTM5NCwzNy4wMzEyNSBMMTQwMy45Njg3NSwzNy4wMzEyNSBMMTQwMy45Njg3NSw0NyBMMTQwNi4wMzEyNSw0NyBMMTQwNi4wMzEyNSwzNy4wMzEyNSBMMTQxNiwzNy4wMzEyNSBMMTQxNiwzNC45Njg3NSBMMTQwNi4wMzEyNSwzNC45Njg3NSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0MDUuMDAwMDAwLCAzNi4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0xNDA1LjAwMDAwMCwgLTM2LjAwMDAwMCkgIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=')
    center center no-repeat;
  color: ${props => props.theme.colors.grayDark};
  transition: background-color 0.3s ease-out;
  &:active {
    background-color: ${props => props.theme.colors.grayBase};
  }
`;

export const ModalSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.colors.grayBase};
`;

export const ModalSearchInput = styled.input`
  flex: 1;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 22px;
  font-size: 18px;
  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.colors.grayDark};
  }
`;

export const ModalSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: solid ${props => props.theme.colors.grayLight};
  border-width: 1px 0;
`;

interface ModalSectionProps extends LoadingStyledComponentProps {
  margin: string;
  padding: string;
}
export const ModalSection = styled.div<ModalSectionProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay(24)};
    `};
`;

interface ModalFooterProps {
  align: string;
  margin: string;
}
export const ModalFooter = styled.div<ModalFooterProps>`
  display: flex;
  justify-content: ${props =>
    mapper(
      { left: 'flex-start', center: 'center', right: 'flex-end' },
      props.align,
      'left',
    )};
  padding: 20px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-top: 1px solid ${props => props.theme.colors.grayLight};
`;

export interface ModalProps extends LoadingStyledComponentProps {
  zIndex?: number;
  fullWindow?: boolean;
}
export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex || 6000};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: ${props =>
    transparentize(0.7, props.theme.colors.grayBase)};
  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay(24)};
      }
    `};
  ${props =>
    props.fullWindow &&
    css`
      padding-top: 0px;
    `};

  @media screen and (max-height: 890px) {
    padding-top: 50px;
    ${props =>
      props.fullWindow &&
      css`
        padding-top: 0px;
      `};
  }
  @media screen and (max-height: 770px) {
    padding-top: 10px;
    ${props =>
      props.fullWindow &&
      css`
        padding-top: 0px;
      `};
  }
  @media ${Devices.mobile} {
    padding-top: 0px;
    ${props =>
      props.fullWindow &&
      css`
        padding-top: 0px;
      `};
  }
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: ${props =>
    transparentize(0.7, props.theme.colors.grayBase)};
`;

export const ConfirmationDialog = styled.div`
  width: 400px;
  min-height: 180px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
  line-height: 20px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
`;

export const ConfirmationMessage = styled.span`
  display: block;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
`;

interface ConfirmationButtonSetProps {
  center?: boolean;
}

export const ConfirmationButtonSet = styled.div<ConfirmationButtonSetProps>`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  ${props => props.center && css`
    justify-content: center;
  `}
`;

import { httpSavingsCore } from 'core/services/http';
import { FranchiseRequest } from 'contracts/core/request';

export const checkFranchise = async (
  params: FranchiseRequest,
): Promise<any> => {
  const response = await httpSavingsCore.post('customer/price/address/check', {
    standardizedAddress: params.standardizedAddress,
    zipCode: params.zipCode,
  });
  return response.data;
};

export const termsAndConditionsText = {
  clause: 'THESE TERMS CONTAIN AN ARBITRATION CLAUSE. ',
  introduction:
    'These Terms of Use (“Terms“) govern your participation in this recycling recognition program (the “Program”) offered by Rubicon Technologies, Inc (“Rubicon” or the “Company”). As used in these Terms, “you” refers to you individually and/or the company or other legal entity on behalf of which you are participating in the Program. By participating in the Program, you agree to be bound by these Terms and the decisions ofRubiconon all matters related to the Program.The Company’s decisions on participation, including termination, are entirely in its sole discretion.Rubicon reserves the right to terminate, modify, revise, or change the Program and these Terms, in whole or part, at any time with or without notice. Changes may revise, for example, rules for earning or using points, continued availability of Program rewards, expiration dates, program partners and the features of special offers. Changes may result in a reduction in the value of benefits already accumulated, and may also result in forfeiture of benefits accrued.',

  firstTitle: 'Eligibility',
  firstParagraph:
    'You must bea business located in the continental United States that participates in single stream recycling (either currently or with new service purchased from Rubicon).  In addition, if you are in the food business, you must participate in organics recycling (either currently or with new service purchased from Rubicon).  Finally, you must complete Rubicon’s recycling education program, including reviewing all materials made available to you by Rubicon. You may maintain one membership account only. Participation andall benefits associated with the Program are non-transferable.',

  secondTitle: 'Program Description',
  secondParagraph:
    'Customers that meet the eligibility requirements in Section 1 of these Terms shall be eligible to receive, at Rubicon’s sole discretion, all or some of the following benefits:',
  secondParagraphListItemOne:
    'Promotion of your business through various channels as being a participant in the Program;',
  secondParagraphListItemTwo:
    'The provision of a sticker identifying you as a participant in the Program that you can place at your business (as described further in Section 3 below);',
  secondParagraphListItemThree:
    'Access to certain materials related to sustainability;',
  secondParagraphListItemFour:
    'Access to a specialty recycling box and signage for use at your business (subject to your agreement with the provider’s terms and conditions); and',
  secondParagraphListItemFive: 'Access to a rewards program.',

  thirdTitle: 'Trademarks',
  thirdParagraphPartOne:
    'Through your participation in the Program, you are granted a royalty free, non-exclusive, worldwide license to the Program trademark, in the form of a sticker to be used at your business(the “Mark”). You may use the Mark at your physical locationduring the term of your participation. Upon the termination of the Program and/or your participation for any reason, this license is and shall be immediately revoked, and your right to use the Mark immediately ceases. This license to use the Mark is limited to provide an indication of your participation in the Program, and is further limited by any guidelines we may publish in connection with the Program. Your rights in the Program and this license to use the Mark is subject entirely to Rubicon’s sole discretion, all of which may be terminated or revoked at any time.',
  thirdParagraphPartTwo:
    'Furthermore, as part of your participation in the Program, you grant Rubicon a royalty free, non-exclusive, worldwide license to your trademark(s), such that Rubicon may use your trademarks on its website and in all marketing materials to describe your participation in the Program. Should the Program or your participation in the Program terminate for any reason, Rubicon shall have at least 60 days to wind down its use of your trademarks.',

  fourthTitle: 'Limitationof Liability and Additional Terms',
  fourthParagraph:
    'By participating, you agree thatthe Companyand its affiliates and subsidiaries and their respective officers, directors, employees, representatives and agents (collectively, the “Released Parties”) will have no liability or responsibility whatsoever for, and shall be held harmless against, any liability for any injuries, losses, or damages of any kind (including without limitation, direct, indirect, incidental, consequential, or punitive or exemplary damages) to persons, including personal injury or death, or property arising in whole or in part, directly or indirectly, fromparticipation,acceptance, possession, use or misuse of any benefits. In no event shall the Released Parties be liable to you for any delay in or failure to perform due to causes beyondthe Company’s control, including without limitation, any act of God, act of war, natural, disaster, weather, terrorism, or any act or omission of a third party. This section will survive termination ofthe Program and/oryour participation in the Program.',

  fifthTitle: 'Severability',
  fifthParagraph:
    'In the event any of these Terms are found by anycourt to be void or otherwise invalid, the remainder of the terms and conditions shall remain valid and enforceable.',

  sixthTitle: 'Jurisdiction',
  sixthParagraph:
    'This Program and these Terms shall be governed by, construed, and interpreted under the laws of the State of Georgia. ',

  seventhTitle: 'Termination of Program',
  seventhParagraph:
    'The Company reserves the right to terminate this Program at any time, with or without notice.The Companyreserves the right to terminate your accountand/or participation in the Programfor any reason, in its sole discretion. After termination, allbenefits accrued are rendered invalid and without any value, even if value remained in your account.',

  eighthTitle: 'Contact Information',
  eighthParagraph: 'For communications concerning these Terms, please email',

  ninthTitle: 'Important Notices',
  ninthParagraphA:
    'a. The Companyprovides the Program, these Terms, and other services and products “As Is” and, to the fullest extent permitted by applicable law, expressly disclaims any representation or warranties of any kind, express or implied, including but not limited to warranties of merchantability, or fitness for a particular purpose. Under no circumstances, including but not limited to, negligence, shallthe Companybe liable for any direct, indirect, incidental, special or consequential damages arising out of the Program or any such other products or services, even if the Companyhas been advised of the possibility of such damages. In the event the Companyor the Program is held liable for any damages related to these matters, your sole and exclusive remedy will be limited to reimbursement for services or products paid for by you.',
  ninthParagraphB:
    'b. Personal information will be utilized in accordance with the Company’s privacy policy',
  ninthParagraphC:
    'c. If any mistakes are discovered, your exclusive remedy shall be the issuance of such other alternative comparable benefit as determined by the Company. In no event shall the Companybe liable to any member, or anyone claiming through a member, for any direct, indirect or consequential damages, or lost revenue or profits, arising out of the Company’s acts or omissions in connection with the Program.',
  ninthParagraphD:
    'd. The Companyreserves the right to correct any benefit granted in error.',
  ninthParagraphE:
    'e. The Companyis not responsible for late, lost, incomplete, or misdirected information or communications; computer system, phone line, electronic equipment, computer hardware, software or program malfunctions, or other errors; failures or delays in computer transmissions or network connections; or for any other technical problems. The Companyis not responsible for incorrect or inaccurate information, whether caused by any of the equipment or programming associated with or utilized in the Program, or by anytechnical or human error which may occur in the processing of information. CAUTION: ANY ATTEMPT BY A PERSON TO DELIBERATELY DAMAGE ANY WEB SITE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS PROGRAM IS A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SHOULD SUCHAN ATTEMPT BE MADE, THE COMPANY RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.',
  ninthParagraphF:
    'f. You agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity of them or participation in the Program (together, “Disputes”) will be settled by binding confidential arbitration between you and Rubicon, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. All Disputes shall be subject to the arbitration clause provisions set forth at',

  downloadTermsAndConditions: 'Download Terms and Conditions',
};

import React from 'react';
import translate from 'core/helpers/translate';
import { Industries } from 'contracts/models/BusinessTypes';
import { AddressDataView } from 'contracts/models/AddressDataView';
import { PanelTitle, PanelSubTitle } from 'core/components/styled/Panel2';
import LocationPickerForm from './LocationPickerForm';
import { LocationFormList, LocationAddressWrapper } from './styled';

const LocationAddress: React.FC<ComponentProps> = ({
  showAddressOnMap,
  businessOptions,
  defaultValue
}) => (
  <LocationAddressWrapper>
    <PanelTitle>{translate('locationDetails')}</PanelTitle>
    <PanelSubTitle>{translate('initialLocationMessage')}</PanelSubTitle>
    <LocationFormList>
      <LocationPickerForm
        showAddressOnMap={showAddressOnMap}
        businessOptions={businessOptions}
        defaultValue={defaultValue}
      />
    </LocationFormList>
  </LocationAddressWrapper>
);

interface ComponentProps {
  showAddressOnMap: (address: AddressDataView) => void;
  businessOptions?: Industries[];
  defaultValue?: string;
}

export default LocationAddress;

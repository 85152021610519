import React from 'react';
import { termsAndConditionsText } from '../constants/termsAndConditionsText-en';
import { TERMS_AND_CONDTIONS } from '../constants/variables';
import {
  ServiceAgreementBody,
  ServiceAgreementUList,
  ServiceBoldFont,
  ServiceAgreementDownload,
  ServiceFontColorPrimary,
} from './styled';

const TermsAndConditions = ({
  handleScroll,
  isDownloadDisabled,
}: ComponentProps) => (
  <React.Fragment>
    <ServiceAgreementBody
      className='serviceAgreementContainer'
      onScroll={e => handleScroll(e, TERMS_AND_CONDTIONS)}
    >
      <p>{termsAndConditionsText.clause}</p>
      <p>{termsAndConditionsText.introduction}</p>

      <p>
        <ServiceBoldFont>
          1. {termsAndConditionsText.firstTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.firstParagraph}</p>

      <p>
        <ServiceBoldFont>
          2. {termsAndConditionsText.secondTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.secondParagraph}</p>
      <ServiceAgreementUList>
        <li>{termsAndConditionsText.secondParagraphListItemOne}</li>
        <li>{termsAndConditionsText.secondParagraphListItemTwo}</li>
        <li>{termsAndConditionsText.secondParagraphListItemThree}</li>
        <li>{termsAndConditionsText.secondParagraphListItemFour}</li>
        <li>{termsAndConditionsText.secondParagraphListItemFive}</li>
      </ServiceAgreementUList>

      <p>
        <ServiceBoldFont>
          3. {termsAndConditionsText.thirdTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.thirdParagraphPartOne}</p>
      <p>{termsAndConditionsText.thirdParagraphPartTwo}</p>

      <p>
        <ServiceBoldFont>
          4. {termsAndConditionsText.fourthTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.fourthParagraph}</p>

      <p>
        <ServiceBoldFont>
          5. {termsAndConditionsText.fifthTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.fifthParagraph}</p>

      <p>
        <ServiceBoldFont>
          6. {termsAndConditionsText.sixthTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.sixthParagraph}</p>

      <p>
        <ServiceBoldFont>
          7. {termsAndConditionsText.seventhTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.seventhParagraph}</p>

      <p>
        <ServiceBoldFont>
          8. {termsAndConditionsText.eighthTitle}
        </ServiceBoldFont>
      </p>
      <p>
        {termsAndConditionsText.eighthParagraph}
        <span> </span>
        <ServiceFontColorPrimary>
          recognized@rubicon.com
        </ServiceFontColorPrimary>
        .
      </p>

      <p>
        <ServiceBoldFont>
          9. {termsAndConditionsText.ninthTitle}
        </ServiceBoldFont>
      </p>
      <p>{termsAndConditionsText.ninthParagraphA}</p>
      <p>{termsAndConditionsText.ninthParagraphB}</p>
      <p>{termsAndConditionsText.ninthParagraphC}</p>
      <p>{termsAndConditionsText.ninthParagraphD}</p>
      <p>{termsAndConditionsText.ninthParagraphE}</p>
      <p>
        {termsAndConditionsText.ninthParagraphF}
        <span> </span>
        <a
          href='https://www.rubicon.com/terms-conditions'
          target='_blank'
          rel='noopener noreferrer'
        >
          <ServiceFontColorPrimary>
            https://www.rubicon.com/terms-conditions
          </ServiceFontColorPrimary>
        </a>
      </p>
    </ServiceAgreementBody>

    <ServiceAgreementDownload
      href={process.env.REACT_APP_SAVINGS_HOST + 'download/recognition-terms-and-condition.pdf'}
      target='_blank'
      disabled={isDownloadDisabled}
    >
      {termsAndConditionsText.downloadTermsAndConditions}
    </ServiceAgreementDownload>
  </React.Fragment>
);

interface ComponentProps {
  handleScroll: (e: any, agreementName: string) => void;
  isDownloadDisabled?: boolean;
}

export default TermsAndConditions;

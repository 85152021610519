import styled, { css } from 'styled-components';
import { theme, Devices } from 'core/styles';
import customerPortalScrollbar from 'core/components/styled/Scrollbar';
import { ServiceAvatar } from 'core/components/styled/ServiceAvatar';
import { transparentize } from 'polished';

export const ServiceOptionsContainer = styled.div`
  margin: 25px auto 65px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1250px;
  &::nth-child(2) {
    margin-right: 0px;
  }
  @media ${Devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ServiceContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 60px;
  width: 100%;
  @media ${Devices.tablet} {
    padding: 20px 5px 60px;
  }
  @media ${Devices.mobile} {
    width: 80%;
    padding: 10px;
  }
`;
export const ServiceDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ServiceQuestionsContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  &::nth-child(2) {
    animation: slideInBottom 0.3s ease-out 0.2s backwards;
  }
  &::nth-child(3) {
    animation: slideInBottom 0.3s ease-out 0.3s backwards;
  }
`;

interface ServiceOptionItemContainerProps {
  hasQuestions: boolean;
}

export const ServiceOptionItemContainer = styled.div<ServiceOptionItemContainerProps>`
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 32%;
  cursor: pointer;
  background: ${theme.colors.white};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  margin-right: 2%;
  animation: zoomIn 0.3s ease-out;
  ${props =>
    props.hasQuestions &&
    css`
      margin-right: 0px;
    `}
  &:hover {
    animation: zoomIn 0.3s ease-out;
    ${ServiceContentContainer} {
      box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
    }
    ${ServiceQuestionsContainer} {
      display: flex;
    }
    ${props =>
      props.hasQuestions &&
      css`
        ${ServiceDetailsContainer} {
          display: none;
        }
      `}
  }
  @media ${Devices.mobile} {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 25px;
    flex-direction: row;
    ${ServiceAvatar} {
      width: 45%;
      padding-bottom: 35%;
    }
  }
`;

export const ServiceName = styled.div`
  display: block;
  margin-bottom: 8px;
  text-align: center;
  line-height: 29px;
  font-size: 24px;
  width: 100%;
  @media ${Devices.mobile} {
    line-height: 10px;
    font-size: 16px;
  }
`;

export const ServiceDetailsDivider = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  line-height: 0.1em;
  margin: 20px 0 28px;
  @media ${Devices.mobile} {
    margin: 7px 0 12px;
  }
`;

export const ServiceDetailsDividerSubTitle = styled.span`
  background: ${theme.colors.white};
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: ${theme.fontWeightLight};
  font-size: 12px;
  color: ${theme.colors.brandPrimary};
`;

export const ServiceDetailItem = styled.p`
  text-align: center;
  line-height: 48px;
  height: 48px;
  font-weight: ${theme.fontWeightLight};
  font-size: 18px;
  animation: slideInBottom 0.3s ease-out backwards;
  margin: 0px;
  @media ${Devices.mobile} {
    line-height: 16px;
    font-size: 11px;
    height: auto;
  }
`;

export const ServiceQuestion = styled.div`
  display: block;
  margin-bottom: 30px;
  text-align: center;
  font-weight: ${theme.fontWeightLight};
  font-size: 18px;
  @media ${Devices.mobile} {
    line-height: 16px;
    font-size: 11px;
    margin-bottom: 6px;
  }
`;

export const ServiceQuestionAnswer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 25px;
  @media ${Devices.mobile} {
    margin-bottom: 4px;
    align-items: center;
  }
`;

export const ServiceDetailsOverviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ServiceAddressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 410px;
  width: 100%;
  margin-bottom: 15px;
  padding: 0px 0 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const ServiceAddressLabel = styled.div`
  display: block;
  margin-bottom: 5px;
  font-weight: ${theme.fontWeightLight};
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
`;

export const ServiceAddressDetails = styled.div`
  margin: 8px 0 0;
  font-size: 15px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ServiceCardOverview = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 410px;
  background: ${theme.colors.white};
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
  width: 100%;
`;

export const ServiceCardDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 22px;
  width: 100%;
`;

export const ServiceNameOverview = styled.div`
  text-align: center;
  line-height: 32px;
  font-size: 26px;
  font-weight: ${theme.fontWeightMedium};
  display: block;
  width: 100%;
`;

export const ServiceDetailsItemsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  width: 80%;
`;

export const ServiceDetailOverviewItem = styled.div`
  display: block;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
`;

export const ServiceDetailLabel = styled.div`
  font-size: 12px;
  display: block;
  font-weight: ${theme.fontWeightLight};
  color: rgba(0, 0, 0, 0.4);
`;

export const ServiceDetailValue = styled.div`
  font-size: 15px;
  display: block;
`;

export const PerOccurrenceServiceRates = styled.div`
  padding: 12px 14px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const POSRHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.brandPrimary};
`;

export const POSRTitle = styled.div`
  text-transform: capitalize;
  line-height: 28px;
  font-size: 15px;
`;

interface POSRToggleProps {
  isOpen: boolean;
}

export const POSRToggle = styled.div<POSRToggleProps>`
  display: block;
  line-height: 28px;
  font-size: 28px;
  transition: transform 0.5s;

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(45deg);
    `}
`;

interface RatesBodyProps {
  isOpen: boolean;
}

export const RatesBody = styled.div<RatesBodyProps>`
  height: 0;
  margin-top: 0;
  overflow: hidden;
  transition: height 0.5s, margin-top 0.5s;

  ${props =>
    props.isOpen &&
    css`
      height: auto;
      margin-top: 15px;
    `}
`;

interface RateProps {
  font20?: boolean;
}

export const Rate = styled.div<RateProps>`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;

  ${props =>
    props.font20 &&
    css`
      font-size: 18px;
    `}
`;

export const RateName = styled.div`
  text-transform: capitalize;
  color: ${transparentize(0.6, theme.colors.black)};
`;

export const RatePrice = styled.div``;

export const ServicesContainer = styled.div`
  max-height: 640px;
  padding-right: 6px;
  padding-left: 3px;
  overflow-y: auto;

  ${customerPortalScrollbar()}
`;

export const ServiceOptionsModalTitle = styled.div`
  display: block;
  margin: 20px 0 60px;
  text-align: center;
  line-height: 34px;
  font-weight: ${theme.fontWeightLight};
  font-size: 28px;
  @media ${Devices.mobile} {
    margin: 30px 0 20px;
    font-size: 16px;
  }
`;

interface ServiceFormProps {
  wideForm?: boolean;
}

export const ServiceForm = styled.form<ServiceFormProps>`
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  ${props =>
    props.wideForm &&
    css`
      max-width: 400px;
    `}
`;

interface ServiceCardInfoRatesContainerProps {
  horizontal?: boolean;
}
export const ServiceCardInfoRatesContainer = styled.div<ServiceCardInfoRatesContainerProps>`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  flex: 0 0 auto;

  ${props =>
    props.horizontal &&
    css`
      flex-direction: row;
    `}
`;

interface ServiceCardInfoRateProps {
  small?: boolean;
}
export const ServiceCardInfoRate = styled.div<ServiceCardInfoRateProps>`
  color: #008d80;
  font-size: 24px;
  text-align: right;
  margin-bottom: 10px;
  margin-left: 10px;

  ${props =>
    props.small &&
    css`
      font-size: 16px;
    `}
`;

export const ServiceCardInfoRateSubtitle = styled.div<ServiceCardInfoRateProps>`
  color: #008d80;
  font-size: 12px;
  text-align: right;
`;

export const ServiceBottomDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

import { SiteDataView } from 'contracts/models/SiteDataView';

export const getLocationActivationFee = (location: SiteDataView) => {
  let cost = 0;
  if (location.services && location.services.length > 0) {
    location.services.forEach(service => {
      if (
        service.extras &&
        Array.isArray(service.extras) &&
        service.extras.length > 0
      ) {
        const delivery = service.extras.find(e => e.code === 'CLIDELIVER');
        if (delivery && delivery.value) {
          cost += delivery.value;
        }
      }
    });
  }
  return cost;
};

export const getLocationMonthlyFee = (location: SiteDataView) => {
  let cost = 0;
  if (location.services && location.services.length > 0) {
    location.services.forEach(service => {
      if (service.base) {
        service.base.forEach(quoteItem => {
          if (quoteItem.uom === 'month') {
            cost += quoteItem.value;
          }
        });
      }
      if (service.extras) {
        service.extras.forEach(extra => {
          if (extra.uom === 'month' && extra.code === 'CLIRENTFEE') {
            cost += extra.value;
          }
        });
      }
      if (service.optionalExtras) {
        service.optionalExtras.forEach(optionalExtra => {
          if (optionalExtra.uom === 'month') {
            cost += optionalExtra.value;
          }
        });
      }
    });
  }
  return cost;
};

import { ClusterMarkerProps } from 'contracts/models/ClusterMap';
import React, { useState } from 'react';

import { Motion } from 'react-motion';

import useClusterMarkerMemoized from './clusterHooks';
import { ClusterMarkerStyled } from './ClusterMarkerStyled';
import ClusterMarkerHost from './styled/ClusterMarker';

const ClusterMarker: React.FC<ClusterMarkerProps> = ({
  text = '0',
  initialScale = 0.6,
  defaultScale = 1,
  hoveredScale = 1.15,
  stiffness = 320,
  damping = 7,
  precision = 0.001,
}) => {
  const [hovered, setHovered] = useState(false);
  const [defaultMotionStyle, motionStyle = {}] = useClusterMarkerMemoized({
    initialScale,
    defaultScale,
    hoveredScale,
    hovered,
    stiffness,
    damping,
    precision,
  });

  return (
    <Motion defaultStyle={defaultMotionStyle} style={motionStyle}>
      {({ scale }) => (
        <ClusterMarkerHost scale={scale}>
          <ClusterMarkerStyled
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {text}
          </ClusterMarkerStyled>
        </ClusterMarkerHost>
      )}
    </Motion>
  );
};

export default ClusterMarker;

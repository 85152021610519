import PaymentMethodType from 'contracts/enums/PaymentMethodType';
import { PaymentMethodDetailsResponse, ZuoraHostePageRequestDataView, ZuoraHostePageResponseDataView } from 'contracts/models/Zuora';
import { environment } from 'core/services/environment';
import { httpSavingsCore } from 'core/services/http';
import Environments from 'core/constants/Environments';
import { IsMicroSite } from 'App';

export async function getPaymentFormData(paymentMethodType: PaymentMethodType): Promise<ZuoraHostePageResponseDataView> {
  let profile: string | null = environment === Environments.local ? Environments.local : null;
  if (IsMicroSite) {
    switch (environment) {
      case Environments.local:
          profile = 'local-marketplace';
        break;
        case Environments.development:
          profile = 'dev-marketplace';
        break;
        case Environments.qa:
          profile = 'qa-marketplace';
        break;
        case Environments.production:
          profile = 'prod-marketplace';
        break;
      default:
        break;
    }
  }
  const request: ZuoraHostePageRequestDataView = {
    type: paymentMethodType.toLowerCase(),
    profile
  }
  const response = await httpSavingsCore.post<ZuoraHostePageResponseDataView>(
    'payment/savings/formdata',
    request
  );
  return response.data;
}

export async function getPaymentMethodDetails(
  refId: string,
): Promise<PaymentMethodDetailsResponse> {
  const response = await httpSavingsCore.get<PaymentMethodDetailsResponse>(
    `savings/billingidentifier/${refId}`,
  );
  return response.data;
}

import React from 'react';

import { useSelector } from 'react-redux';

import ConfirmationModal from './ConfirmationModal';
import { ModalStateProps } from 'contracts/core/modal';
import { ApplicationState } from 'contracts/core/state';
import ModalType from 'contracts/enums/ModalType';
import { useModalManager } from 'core/helpers/hooks';

const modalDictionary: Record<ModalType, React.ElementType> = {
  [ModalType.confirmation]: ConfirmationModal
};

// Modal manager usage:
// const { openModal } = useModalManager();
// openModal({
//   modalType: ModalType,
//   props: ModalProps extends ModalComponentProps
// });
export const ModalManager: React.FC = () => {
  const { closeModal } = useModalManager();

  const modals = useSelector(
    (state: ApplicationState) => state.core.modalManager.modals
  );

  const renderModal = (modal: ModalStateProps): JSX.Element | null => {
    const Component = modalDictionary[modal.modalType];
    if (Component) {
      const onModalClose = (value?: boolean): void => {
        if (componenetModalClose) {
          componenetModalClose(value);
        }
        closeModal(modal.id);
      };

      const componenetModalClose = modal.props.closeModal;
      modal.props.closeModal = onModalClose;

      return <Component key={modal.id} {...modal.props} />;
    }
    return null;
  };

  return (
    <React.Fragment>
      {modals.map(modal => renderModal(modal))}
    </React.Fragment>
  );
};


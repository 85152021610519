import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, PanelContainer } from 'core/components/styled/Panel2';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import cart from 'ducks/cart';
import ServicesPanel from './ServicesPanel';
import TotalCostPanel from './TotalCostPanel';
import { getSessionCart } from 'core/services/session';
import {
  contactInformationTracking
} from 'services/contactInformation';
import { useLocation } from 'react-router';

const SummaryPage: React.FC = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();

  const shoppingCartId = cart.selectors.getshoppingCartId();
  const locations = cart.selectors.getLocations();

  useEffect(() => {
    if (locations.length > 0) {
      dispatch(cart.actions.deleteEmptyLocation());
      dispatch(setLoadingBarProgress(50));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shoppingCartId) {
      if (location.state &&
        location.state.hasOwnProperty('locationID') &&
        location.state.hasOwnProperty('serviceID') &&
        location.state?.locationID !== undefined &&
        location.state?.serviceID !== undefined) {
        trackServicesModifications(location.state.locationID, location.state.serviceID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const trackServicesModifications = async (locationID: number, serviceID: number): Promise<void> => {
    const cartObj = getSessionCart();
    try {
      const checkoutResponse = await contactInformationTracking({
        ...cartObj,
      });
      dispatch(cart.actions.setTrackingParams(
        checkoutResponse.externalUid || '',
        checkoutResponse.trackingSignature || '',
        checkoutResponse.locations || []
      ));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PanelContainer>
      <Panel primary width='52%'>
        <ServicesPanel showActions />
      </Panel>
      <Panel secondary width='48%'>
        <TotalCostPanel />
      </Panel>
    </PanelContainer>
  );
};

export default SummaryPage;

import update from 'immutability-helper';
import {
  ApplicationState,
  RecognizedState,
  ReduceFunctionMap,
} from 'contracts/core/state';
import { getReducerBuilder } from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { ActionDispatcher, RecognizedAction } from 'contracts/core/action';
import { LocationDataView } from 'contracts/models/RecognizedLocationDataView';
import { getLocations, submitOrder as submitRecognizedOrder } from 'services/recognized';
import { RecognizedSubmitResponseModel } from 'contracts/models/RecognizedSubmitResponseModel';
import { RecognizedSubmitDataView } from 'contracts/models/RecognizedSubmitDataView';

// Actions Keys
const ROOT_KEY = 'recognized';
enum ActionKey {
  HIDE_NAVIGATION_BAR = 'recognized/HIDE_NAVIGATION_BAR',
  LOAD_LOCATIONS = 'recognized/LOAD_LOCATIONS',
  SUBMIT_ORDER = 'recognized/SUBMIT_ORDER',
  RESET = 'recognized/RESET',
}

// Initial state
const getInitialState: () => RecognizedState = () => {
  return {
    hideNavigationBar: false,
    locations: [],
    submitOrder: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.HIDE_NAVIGATION_BAR,
  ActionKey.LOAD_LOCATIONS,
  ActionKey.SUBMIT_ORDER,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RecognizedState,
  RecognizedAction
> = {
  [ActionKey.HIDE_NAVIGATION_BAR]: (state, action) => {
    const { hideNavigationBar } = action;
    return update(state, { $merge: { hideNavigationBar } });
  },
  [ActionKey.LOAD_LOCATIONS]: (state, action) => {
    const { locations } = action;
    return update(state, { $merge: { locations } });
  },
  [ActionKey.SUBMIT_ORDER]: (state, action) => {
    const { submitOrder } = action;
    return update(state, { $merge: { submitOrder } });
  },
};

export const reducer = getReducerBuilder<RecognizedState, RecognizedAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  HIDE_NAVIGATION_BAR: (
    hideNavigationBar?: boolean | undefined,
  ): RecognizedAction => ({
    type: ActionKey.HIDE_NAVIGATION_BAR,
    hideNavigationBar,
  }),
  LOAD_LOCATIONS: (locations?: LocationDataView[]): RecognizedAction => ({
    type: ActionKey.LOAD_LOCATIONS,
    locations,
  }),
  SUBMIT_ORDER: (submitOrder?: RecognizedSubmitResponseModel): RecognizedAction => ({
    type: ActionKey.SUBMIT_ORDER,
    submitOrder,
  }),
  RESET: (): RecognizedAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const hideNavigationBar = (hideNavigationBar?: boolean) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.HIDE_NAVIGATION_BAR,
    async () => {
      dispatch(actionMap.HIDE_NAVIGATION_BAR(hideNavigationBar));
    },
    () => { },
  );

const loadLocations = (alias: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => runTakeLastThunk(
  dispatch,
  getState,
  ActionKey.LOAD_LOCATIONS,
  async () => getLocations(alias),
  result => {
    dispatch(actionMap.LOAD_LOCATIONS(result.locations));
  },
  () => {
    dispatch(actionMap.LOAD_LOCATIONS());
  },
);

const submitOrder = (requestArg: RecognizedSubmitDataView) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => runTakeLastThunk(
  dispatch,
  getState,
  ActionKey.SUBMIT_ORDER,
  async () => submitRecognizedOrder(requestArg),
  result => {
    dispatch(actionMap.SUBMIT_ORDER(result));
  },
  () => {
    dispatch(actionMap.SUBMIT_ORDER());
  },
  true,
);

const recognizedDuck = {
  thunks: { hideNavigationBar, loadLocations, submitOrder },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default recognizedDuck;

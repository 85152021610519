import styled from 'styled-components';

const ZuoraPaymentHost = styled.div`
  iframe {
    height: 450px;
    margin-left: 45px;
    width: 100%;
  }
`;

export default ZuoraPaymentHost;
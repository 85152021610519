import styled from 'styled-components';
import { theme } from 'core/styles';
import { slideInBottom } from 'core/styles/animations';

export const InformationTooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 4px;
`;

export const InformationTooltipIcon = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: ${theme.colors.grayDark};
  border-radius: 7px;
  text-align: center;
  font-weight: ${theme.fontWeightMedium};
  line-height: 14px;
  font-size: 13px;
  color: ${theme.colors.white};
  cursor: default;

  ${InformationTooltipContainer}:hover & {
    background-color: ${theme.colors.brandPrimary};
  }
`;

export const InformationTooltipMessage = styled.div`
  display: none;
  position: absolute;
  top: 30px;
  left: -93px;
  z-index: 99999;
  width: 200px;
  padding: 10px 15px;
  background-color: ${theme.colors.brandGreenLight};
  border: 1px solid ${theme.colors.brandPrimary};
  line-height: 18px;
  font-size: 12px;
  color: ${theme.colors.brandPrimary};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

  ${InformationTooltipContainer}:hover & {
    display: block;
    animation: ${slideInBottom} 0.3s;
  }
`;

import GreenPin from 'core/assets/images/pin_green.svg';
import GrayPin from 'core/assets/images/pin_grey.svg';
import styled, { css } from 'styled-components';

interface SimpleMarkerProps {
  isChecked?: boolean;
}

export const SimpleMarkerStyled = styled.div<SimpleMarkerProps>`
  background-image: url(${GrayPin});
  background-size: 100% 100%;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 27px;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -100%);
  margin: 0;
  padding: 0;

  ${props =>
    props.isChecked &&
    css`
      background-image: url(${GreenPin});
    `}
`;

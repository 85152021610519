import React from 'react';
import { FieldError } from 'react-hook-form';
import { FormError } from './styled';
import translate from 'core/helpers/translate';
import FieldErrorType from 'contracts/enums/FieldErrorType';

const Error: React.FC<ComponentProps> = ({ error }) => {
  if (error.message) {
    return <FormError>{error.message}</FormError>;
  }
  switch (error.type) {
    case FieldErrorType.Required:
      return <FormError>{translate('requiredFieldError')}</FormError>;

    case FieldErrorType.Validate:
      return <FormError>{translate('invalidPromoCodeFieldError')}</FormError>;

    default:
      return <></>;
  }
};
interface ComponentProps {
  error: FieldError;
}

export default Error;

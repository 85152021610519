import { createGlobalStyle } from 'styled-components';
import theme from './theme';
// Using 'Medium' as 'Regular' and 'Regular' as 'Light'
import ArsMaquetteBold from 'core/assets/fonts/ars-maquette/ars-maquette-bold-webfont.woff';
import ArsMaquetteRegular from 'core/assets/fonts/ars-maquette/ars-maquette-regular-webfont.woff';
import ArsMaquetteMedium from 'core/assets/fonts/ars-maquette/ars-maquette-medium-webfont.woff';
import ArsMaquetteLight from 'core/assets/fonts/ars-maquette/ars-maquette-light-webfont.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "ArsMaquette";
    src: url(${ArsMaquetteBold}) format('woff2'),
    url(${ArsMaquetteBold}) format('woff'),
    url(${ArsMaquetteBold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: "ArsMaquette";
    src: url(${ArsMaquetteMedium}) format('woff2'),
    url(${ArsMaquetteMedium}) format('woff'),
    url(${ArsMaquetteMedium}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: "ArsMaquette";
    src: url(${ArsMaquetteRegular}) format('woff2'),
    url(${ArsMaquetteRegular}) format('woff'),
    url(${ArsMaquetteRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: "ArsMaquette";
    src: url(${ArsMaquetteLight}) format('woff2'),
    url(${ArsMaquetteLight}) format('woff'),
    url(${ArsMaquetteLight}) format('truetype');
    font-weight: 300;
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    background: ${theme.colors.white};
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    color: ${theme.colors.brandDefaultBlack};
    line-height: 1.42857;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px;
    font-weight: 400;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;

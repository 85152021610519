import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'core/components';
import serviceDuck from 'ducks/serviceDetails';
import { ApplicationState } from 'contracts/core/state';
import { ModalClose } from 'core/components/styled';
import ServiceOptions from './ServiceOptions';
import { AddressDataView } from 'contracts/models/AddressDataView';
import cart from 'ducks/cart';

const ServiceOptionsModal: React.FC<ComponentProps> = ({
  location,
  marketConfigToken,
  onModalCloseClick,
}) => {
  const dispatch = useDispatch();

  const marketConfig = useSelector(
    (state: ApplicationState) => state.service.marketConfig,
  );
  const allServices = cart.selectors.getAllServices();

  useEffect(() => {
    if (location) {
      const address = location.line1;
      const zipCode = location.zip;
      if (address && zipCode) {
        let hasCartPermanentSelection = false;
        if (allServices.find(s => s.isTemp === false)) {
          hasCartPermanentSelection = true;
        }
        dispatch(serviceDuck.thunks.loadMarketConfig({ zipCode, address, marketConfigToken }, hasCartPermanentSelection));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Modal fullWindow={true} size='large'>
      <ModalClose onClick={onModalCloseClick}></ModalClose>
      {marketConfig && (
        <ServiceOptions
          servicePreselection={marketConfig.servicePreselection}
          onModalCloseClick={onModalCloseClick}
        />
      )}
    </Modal>
  );
};

interface ComponentProps {
  location: AddressDataView;
  marketConfigToken?: string;
  onModalCloseClick: () => void;
}

export default ServiceOptionsModal;


import { AddressDataView, AddressOptionsDataView } from 'contracts/models/AddressDataView';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';
import { httpSavingsCore } from 'core/services/http';
import { setSessionAuthToken } from 'core/services/session';

export async function contactInformationTracking(cart: SaleCartDataView): Promise<SaleCartDataView> {
  const response = await httpSavingsCore.post(`/sale/shoppingcart-tracking`, {
    ...cart,
  });
  const authToken = response.headers.authorization;
  setSessionAuthToken(authToken);
  return response.data;
}

export async function getPublicToken(): Promise<string | undefined> {
  const response = await httpSavingsCore.get(`sale/access`);
  const authToken = response.headers.authorization;
  setSessionAuthToken(authToken);
  return authToken;
}

export async function determineAddressOptions(address: AddressDataView): Promise<AddressOptionsDataView> {
  const response = await httpSavingsCore.post(`sale/address/options`, {
    ...address,
  });
  return response.data;
}

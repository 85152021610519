import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import translate from 'core/helpers/translate';
import { CurrentServiceForm, ServiceQuoteFormData } from 'contracts/core/form';
import { ApplicationState } from 'contracts/core/state';
import {
  getPriceBasedOnEquipmentSizes,
  formatOptionalExtrasOptions,
} from 'core/helpers/service';
import {
  PerOccurrenceServiceRates,
  POSRHeader,
  POSRTitle,
  POSRToggle,
  Rate,
  RateName,
  RatePrice,
  RatesBody,
} from '../styled';
import CheckboxList from 'core/components/CheckboxList';
import { currency } from 'core/services/formatter';

const ServicePricingForm: React.FC<ComponentProps> = ({
  currentService,
  onCurrentServiceChange,
}) => {
  const [isRatesOpen, setIsRatesOpen] = useState(false);
  const { quotes, formSettings } = useSelector(
    (state: ApplicationState) => state.service,
    shallowEqual,
  );
  const { formRules } = formSettings;
  const { getValues, watch, register, setValue } = useForm<ServiceQuoteFormData>({
    mode: 'onChange',
    defaultValues: {
      equipment: currentService?.equipment,
      optionalExtras:
        !currentService ||
          !currentService.optionalExtras ||
          currentService.optionalExtras.length === 0
          ? false
          : currentService?.optionalExtras,
    },
  });

  const equipment = watch('equipment');

  const getPerOccuranceRates = () => {
    if (equipment && quotes) {
      const currentContainer = quotes.filter(
        eq => eq.equipment === equipment,
      )[0];
      const extras = currentContainer ? currentContainer.extras : undefined;
      return (
        extras && (
          <PerOccurrenceServiceRates>
            <POSRHeader onClick={() => setIsRatesOpen(!isRatesOpen)}>
              <POSRTitle>Per Occurrence Service Rates</POSRTitle>
              <POSRToggle isOpen={isRatesOpen}>+</POSRToggle>
            </POSRHeader>

            <RatesBody isOpen={isRatesOpen}>
              {extras.map((extra: any, index: number) => (
                <Rate key={index}>
                  <RateName>{extra.label}</RateName>
                  <RatePrice>
                    {currency(extra.value)} / {extra.uom}
                  </RatePrice>
                </Rate>
              ))}
            </RatesBody>
          </PerOccurrenceServiceRates>
        )
      );
    }
  };

  const getOptionalExtras = () => {
    if (equipment && quotes) {
      const currentContainer = quotes.filter(
        eq => eq.equipment === equipment,
      )[0];
      const optionalExtrasList = currentContainer
        ? currentContainer.optionalExtras
        : undefined;
      return (
        optionalExtrasList &&
        optionalExtrasList.length > 0 && (
          <CheckboxList
            label={translate('optionalExtrasFormLabel')}
            options={formatOptionalExtrasOptions(optionalExtrasList)}
            {...register('optionalExtras')}
            onChange={(e) => {
              const { optionalExtras } = getValues();
              let optionalExtrasList = optionalExtras
              if (!optionalExtras) {
                optionalExtrasList = [];
              }
              if (e.target.checked) {
                optionalExtrasList.push(e.target.value)
              } else {
                optionalExtrasList = optionalExtrasList.filter((value: string) => {
                  return value !== e.target.value;
                });
              }
              setValue('optionalExtras', optionalExtrasList);

              if (optionalExtrasList && optionalExtrasList.length > 0) {
                onCurrentServiceChange({
                  ...currentService,
                  optionalExtras: optionalExtrasList,
                });
              } else {
                onCurrentServiceChange({
                  ...currentService,
                  optionalExtras: [],
                });
              }
            }}
          />
        )
      );
    }
  };

  const getBasePrice = () => {
    const prices = (quotes && getPriceBasedOnEquipmentSizes(quotes)) || [];

    if (!(formRules.editEquipment && quotes && prices.length > 0)) {
      return null;
    }

    return (
      <div style={{ padding: '20px 0' }}>
        {prices.map((item, index) => (
          <Rate key={index} font20>
            <RateName>{item.label}</RateName>
            <RatePrice>{item.price}</RatePrice>
          </Rate>
        ))}
      </div>
    );
  };

  if (!equipment) {
    return null;
  }

  return (
    <>
      {getBasePrice()}
      {getPerOccuranceRates()}
      {getOptionalExtras()}
    </>
  );
};

interface OwnStateProps { }

interface StateProps { }

interface OwnProps {
  currentService?: CurrentServiceForm;
  onCurrentServiceChange: any;
}

type ComponentProps = StateProps & OwnProps & OwnStateProps;

export default ServicePricingForm;

import { IsMicroSite } from "App";
import { getSessionAuthToken, setSavingsUserData } from "core/services/session";

export default function redirectToCheckout(address: string, email?: string, firstName?: string, lastName?: string, phone?: string): void {
  if (IsMicroSite) {
    const token = getSessionAuthToken();
    setSavingsUserData({ address, firstName, lastName, email, phone, token });
    window.location.href = window.location.origin + '/open-top-temp';
  } else {
    let checkoutUrl = process.env.REACT_APP_CHECKOUT_HOST;
    if (checkoutUrl) {
      checkoutUrl = `${checkoutUrl}?address=${encodeURIComponent(address)}`;
      if (email) {
        checkoutUrl += `&email=${encodeURIComponent(email)}`;
      }
      if (firstName) {
        checkoutUrl += `&firstName=${encodeURIComponent(firstName)}`;
      }
      if (lastName) {
        checkoutUrl += `&lastName=${encodeURIComponent(lastName)}`;
      }
      if (phone) {
        checkoutUrl += `&phone=${encodeURIComponent(phone)}`;
      }
      window.location.href = checkoutUrl;
    }
  }
}

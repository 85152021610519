import EquipmentType from 'contracts/enums/EquipmentType';
import styled, { css } from 'styled-components';
import { theme, Devices } from 'core/styles';
import cart from 'core/assets/images/cart.svg';
import frontLoad from 'core/assets/images/front_load.svg';
import openTop from 'core/assets/images/open_top.svg';
import rubiconLogo from 'core/assets/images/rubicon-logo-white.png';
import selfContained from 'core/assets/images/receiver_box.svg';
import receiverBox from 'core/assets/images/self_contained_compactor.svg';

const equipmentToImageMap: { [key: string]: string } = {
  [EquipmentType.CART]: cart,
  [EquipmentType.FRONT_LOAD]: frontLoad,
  [EquipmentType.OPEN_TOP]: openTop,
  [EquipmentType.OPEN_TOP_TEMP]: openTop,
  [EquipmentType.OPEN_TOP_ON_DEMAND]: openTop,
  [EquipmentType.RECEIVER_BOX]: receiverBox,
  [EquipmentType.SELF_CONTAINED]: selfContained,
};

export function mapBackgroundImage(equipmentType?: EquipmentType): string {
  return (equipmentType && equipmentToImageMap[equipmentType]) || rubiconLogo;
}

interface ServiceAvatarProps {
  equipmentType?: EquipmentType;
  small?: boolean;
  square?: boolean;
  width?: number;
  maxWidth?: boolean;
  height?: number;
  backgroundSize?: number;
}
export const ServiceAvatar = styled.div<ServiceAvatarProps>`
  width: 100%;
  height: 0;
  padding-bottom: 73%;
  background-color: ${theme.colors.brandGreenDark};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-image: url(${props => mapBackgroundImage(props.equipmentType)});
  align-self: center;
  margin: 0 auto;
  @media ${Devices.mobile} {
    width: 100%;
  }

  ${props =>
    props.small &&
    css`
      width: 70px;
      height: 70px;
    `};

  ${props =>
    props.square &&
    css`
      border-radius: 0px;
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `};

  ${props =>
    props.maxWidth &&
    css`
      width: 100%;
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `};

  ${props =>
    props.backgroundSize &&
    css`
      background-size: ${props.backgroundSize}% ${props.backgroundSize}%;
    `};
`;

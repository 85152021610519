import React from 'react';
import { Checkbox } from 'core/components';
import {
  FormGroup,
  FormLabel,
} from './styled';
import { CheckboxOptionType } from 'contracts/core/form';
import { OptionList, Option } from './styled/OptionList';


const CheckboxList = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, options, onChange, type, ...props }, ref) => (
    <FormGroup>
      <FormLabel initial>{label}</FormLabel>
      <OptionList animated>
        {options.map((item: CheckboxOptionType, index: number) => (
          <Option key={item.code}>
            <Checkbox
              name={`${props.name}[${index}].${item.name}`}
              label={item.label}
              value={item.code}
              ref={ref as any}
              onChange={onChange}
              {...props}
            />
          </Option>
        ))}
      </OptionList>
    </FormGroup>
  ),
);


interface OwnProps {
  label: string;
  options: CheckboxOptionType[];
}

type ComponentProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & OwnProps;

export default CheckboxList;
import { ApplicationState } from 'contracts/core/state';
import coreReducer from 'core/ducks';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { reducer as cartReducer } from 'ducks/cart';
import { reducer as serviceReducer } from './ducks/serviceDetails';
import { reducer as addressesReducer } from 'ducks/addresses';
import { reducer as agreementSignReducer } from 'ducks/agreementSign';
import { reducer as billingReducer } from 'ducks/billing';
import { reducer as recognizedReducer } from 'ducks/recognized';

const rootReducer = combineReducers<ApplicationState>({
  core: coreReducer,
  cart: cartReducer,
  service: serviceReducer,
  locations: addressesReducer,
  agreement: agreementSignReducer,
  billing: billingReducer,
  recognized: recognizedReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

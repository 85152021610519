import update from 'immutability-helper';
import {
  AddressDataView,
  AddressOptionsDataView,
  Franchise,
  GeocodeAddressDataView,
} from 'contracts/models/AddressDataView';
import { Industries } from 'contracts/models/BusinessTypes';
import { ActionDispatcher, AddressesAction } from 'contracts/core/action';
import {
  AddressesState,
  ApplicationState,
  ReduceFunctionMap,
} from 'contracts/core/state';
import { getReducerBuilder } from 'core/reducerBuilder';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { loadAddress } from 'services/loadAddresses';
import { checkFranchise } from 'services/checkFranchise';
import { loadIndustries } from 'services/bussinessTypes';
import { getCartLimitations } from 'services/services';
import { CartLimitations } from 'contracts/models/CartLimitations';
import { determineAddressOptions } from 'services/contactInformation';

// Actions Keys
const ROOT_KEY = 'addresses';
enum ActionKey {
  LOAD_ADDRESSES = 'addresses/LOAD_ADDRESSES',
  LOAD_BUSINESS_TYPES = 'address/LOAD_BUSINESS_TYPES',
  LOAD_CART_LIMITATIONS = 'address/LOAD_CART_LIMITATIONS',
  CHECK_FRANCHISE = 'addresses/CHECK_FRANCHISE',
  ADDRESS_OPTIONS = 'addresses/ADDRESS_OPTIONS',
  RESET = 'addresses/RESET',
}

// Initial State
const getInitialState: () => AddressesState = () => {
  return {
    addresses: [] as GeocodeAddressDataView[],
    addressErrors: [],
    businessTypes: [],
    cartLimitations: {} as CartLimitations,
    isValidAddress: false,
    askWhoIs: false,
    recognizedAddress: false,
    marketConfigToken: undefined
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_ADDRESSES,
  ActionKey.LOAD_BUSINESS_TYPES,
  ActionKey.LOAD_CART_LIMITATIONS,
  ActionKey.CHECK_FRANCHISE,
  ActionKey.ADDRESS_OPTIONS
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AddressesState,
  AddressesAction
> = {
  [ActionKey.LOAD_ADDRESSES]: (state, action) => {
    return update(state, {
      $merge: { addresses: action.addresses },
    });
  },
  [ActionKey.LOAD_BUSINESS_TYPES]: (state, action) => {
    return update(state, {
      $merge: { businessTypes: action.businessTypes },
    });
  },
  [ActionKey.LOAD_CART_LIMITATIONS]: (state, action) => {
    return update(state, {
      $merge: { cartLimitations: action.cartLimitations },
    });
  },
  [ActionKey.CHECK_FRANCHISE]: (state, action) => {
    return update(state, {
      $merge: {
        addressErrors: action.addressErrors,
        isValidAddress: action.isValidAddress,
      },
    });
  },
  [ActionKey.ADDRESS_OPTIONS]: (state, action) => {
    return update(state, {
      $merge: {
        askWhoIs: action.askWhoIs,
        recognizedAddress: action.recognizedAddress,
        marketConfigToken: action.marketConfigToken
      },
    });
  },
};

export const reducer = getReducerBuilder<AddressesState, AddressesAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_ADDRESS: (addresses?: GeocodeAddressDataView[]): AddressesAction => ({
    type: ActionKey.LOAD_ADDRESSES,
    addresses,
  }),
  LOAD_BUSINESS_TYPES: (businessTypes?: Industries[]): AddressesAction => ({
    type: ActionKey.LOAD_BUSINESS_TYPES,
    businessTypes,
  }),
  LOAD_CART_LIMITATIONS: (cartLimitations?: CartLimitations): AddressesAction => ({
    type: ActionKey.LOAD_CART_LIMITATIONS,
    cartLimitations,
  }),
  CHECK_FRANCHISE: (addressErrors?: string[]): AddressesAction => ({
    type: ActionKey.CHECK_FRANCHISE,
    addressErrors,
    isValidAddress: addressErrors?.length === 0,
  }),
  ADDRESS_OPTIONS: (askWhoIs: boolean, recognizedAddress: boolean, marketConfigToken: string): AddressesAction => ({
    type: ActionKey.ADDRESS_OPTIONS,
    askWhoIs,
    recognizedAddress,
    marketConfigToken
  }),
  RESET: (): AddressesAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadAddresses = (searchTerm: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ADDRESSES,
    async () => loadAddress(searchTerm),
    result => {
      dispatch(actionMap.LOAD_ADDRESS(result));
    },
    () => {
      dispatch(actionMap.LOAD_ADDRESS([]));
    },
  );

const loadBusinessTypes = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_BUSINESS_TYPES,
    async () => loadIndustries(),
    result => {
      dispatch(actionMap.LOAD_BUSINESS_TYPES(result.industries));
    },
    () => {
      dispatch(actionMap.LOAD_BUSINESS_TYPES([]));
    },
  );

  const loadCartLimitations = () => (
    dispatch: ActionDispatcher,
    getState: () => ApplicationState,
  ) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_CART_LIMITATIONS,
      async () => getCartLimitations(),
      result => {
        dispatch(actionMap.LOAD_CART_LIMITATIONS(result));
      },
      () => {
        dispatch(actionMap.LOAD_CART_LIMITATIONS());
      },
    );

const validateAddress = (address: AddressDataView, errosList: any[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CHECK_FRANCHISE,
    async () =>
      checkFranchise({
        standardizedAddress: address.line1,
        zipCode: address.zip,
      }),
    (response: Franchise) => {
      if (response.franchised) {
        errosList.push('We cannot provide service in this area.');
      }
      dispatch(actionMap.CHECK_FRANCHISE(errosList));
    },
    () => {
      errosList.push('Cannot check the address. Please try again.');
      dispatch(actionMap.CHECK_FRANCHISE(errosList));
    },
  );

  const addressOptions = (address: AddressDataView) => (
    dispatch: ActionDispatcher,
    getState: () => ApplicationState,
  ) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADDRESS_OPTIONS,
      async () =>
        determineAddressOptions(address),
      (response: AddressOptionsDataView) => {
          dispatch(actionMap.ADDRESS_OPTIONS(response.askWhoIs, response.recognizedAddress, response.marketConfigToken));
      },
    );

const addressesDuck = {
  thunks: { loadAddresses, loadBusinessTypes, loadCartLimitations, validateAddress, addressOptions },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};

export default addressesDuck;

import update from 'immutability-helper';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';
import { ActionDispatcher, AgreementSignAction } from 'contracts/core/action';
import {
  AgreementSignState,
  ApplicationState,
  ReduceFunctionMap,
} from 'contracts/core/state';
import { getReducerBuilder } from 'core/reducerBuilder';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { signService, signLOA, signTerms } from 'services/signAgreements';

// Actions Keys
const ROOT_KEY = 'agreemrnt';
enum ActionKey {
  SIGN_SERVICE = 'agreemrnt/SIGN_SERVICE',
  SIGN_LOA = 'agreemrnt/SIGN_LOA',
  SIGN_TERMS = 'agreemrnt/SIGN_TERMS',
  RESET = 'agreemrnt/RESET',
}

// Initial State
const getInitialState: () => AgreementSignState = () => {
  return {
    serviceAgreementId: undefined,
    loaAgreementId: undefined,
    rtcAgreementId: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.SIGN_SERVICE,
  ActionKey.SIGN_LOA,
  ActionKey.SIGN_TERMS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AgreementSignState,
  AgreementSignAction
> = {
  [ActionKey.SIGN_SERVICE]: (state, action) => {
    return update(state, {
      $merge: { serviceAgreementId: action.serviceAgreementId },
    });
  },
  [ActionKey.SIGN_LOA]: (state, action) => {
    return update(state, {
      $merge: { loaAgreementId: action.loaAgreementId },
    });
  },
  [ActionKey.SIGN_TERMS]: (state, action) => {
    return update(state, {
      $merge: { rtcAgreementId: action.rtcAgreementId },
    });
  },
};

export const reducer = getReducerBuilder<
  AgreementSignState,
  AgreementSignAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  SIGN_SERVICE: (serviceAgreementId?: string): AgreementSignAction => ({
    type: ActionKey.SIGN_SERVICE,
    serviceAgreementId,
  }),
  SIGN_LOA: (loaAgreementId?: string): AgreementSignAction => ({
    type: ActionKey.SIGN_LOA,
    loaAgreementId,
  }),
  SIGN_TERMS: (rtcAgreementId?: string): AgreementSignAction => ({
    type: ActionKey.SIGN_TERMS,
    rtcAgreementId,
  }),
  RESET: (): AgreementSignAction => ({ type: ActionKey.RESET }),
};

// Thunks
const signServiceAgreement =
  (cart: SaleCartDataView) =>
  (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.SIGN_SERVICE,
      async () => signService(cart),
      response => {
        dispatch(actionMap.SIGN_SERVICE(response.serviceAgreementId));
      },
      () => {
        dispatch(actionMap.SIGN_SERVICE());
      },
    );

const signLOAAgreement =
  (cart: SaleCartDataView) =>
  (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.SIGN_LOA,
      async () => signLOA(cart),
      response => {
        dispatch(actionMap.SIGN_LOA(response.loaAgreementId));
      },
      () => {
        dispatch(actionMap.SIGN_LOA());
      },
    );

const signTermsAgreement =
  (cart: SaleCartDataView) =>
  (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.SIGN_TERMS,
      async () => signTerms(cart),
      response => {
        dispatch(actionMap.SIGN_TERMS(response.rtcAgreementId));
      },
      () => {
        dispatch(actionMap.SIGN_TERMS());
      },
    );

const addressesDuck = {
  thunks: { signServiceAgreement, signLOAAgreement, signTermsAgreement },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};

export default addressesDuck;

import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import translate from 'core/helpers/translate';
import { PromoCodeFormData } from 'contracts/core/form';
import { Input } from 'core/components';
import { SectionTitle } from 'core/components/styled/Panel2';
import { CheckoutForm } from './styled';
import { checkPromoCodeValidity } from 'services/checkout';
import cart from 'ducks/cart';

const PromoCodeForm: React.FC<ComponentProps> = ({ setiIsValidatingPromoCode }) => {
  const dispatch = useDispatch();
  const { setError, register, handleSubmit, formState: { errors } } = useForm<PromoCodeFormData>({
    mode: 'onBlur',
  });

  const handleBlur = async (data: PromoCodeFormData) => {
    if (data && data.promoCode) {
      setiIsValidatingPromoCode(true)
      try {
        await checkPromoCodeValidity(data.promoCode);
        dispatch(cart.actions.setPromoCode(data.promoCode));
        setiIsValidatingPromoCode(false)
        return true;
      }
      catch {
        dispatch(cart.actions.setPromoCode(''));
        setError('promoCode', {
          type: 'validate',
          message: translate('invalidPromoCodeFieldError')
        });
        setiIsValidatingPromoCode(false)
        return false;
      }
    }
  };
  return (
    <CheckoutForm onBlur={handleSubmit(handleBlur)}>
      <SectionTitle>{translate('promoCodeSubTitle')}</SectionTitle>
      <Input
        label={translate('promoCodeFormLabel')}
        errors={errors && errors.promoCode}
        {...register('promoCode')}
      />
    </CheckoutForm>
  );
};

interface ComponentProps {
  setiIsValidatingPromoCode: (flag: boolean) => void;
}
export default PromoCodeForm;
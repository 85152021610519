import { ApplicationState } from 'contracts/core/state';
import { CartLimitations } from 'contracts/models/CartLimitations';
import { Button } from 'core/components/styled';
import { FieldDescription } from 'core/components/styled/FormLabel';
import { PanelContent, PanelTitle } from 'core/components/styled/Panel2';
import translate from 'core/helpers/translate';
import { getSessionAuthToken } from 'core/services/session';
import addressesDuck from 'ducks/addresses';
import cart from 'ducks/cart';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import CheckoutDetailsForm from './CheckoutForm';
import PriceSummary from './PriceSummary';
import { ButtonSet, Notice, NoticeParagraph } from './styled';

const TotalCostPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activationCost = cart.flags.getAllLocationsActivationCost();
  const monthlyCost = cart.flags.getAllLocationsMonthlyCost();
  const managementFee = cart.flags.getAllLocationsManagementFee();
  const token = getSessionAuthToken();

  const setupStartDates = () => navigate(Routes.startDate.replace(':serviceIndex', '1'));

  const goToServiceAgreement = () => navigate(Routes.serviceAgreement);

  const goToBilling = () => navigate(Routes.billing.replace(':locationIndex', '1'));

  const addAdditionalService = () => navigate(Routes.location);

  const cartLimitations: CartLimitations = useSelector(
    (state: ApplicationState) => state.locations.cartLimitations,
  );

  const canAllLocationsNotAddServices = cart.flags.canAllLocationsNotAddServices();
  const haveAllServicesSetDates = cart.flags.haveAllServicesSetDates();
  const areAgreementsSigned = cart.flags.areAgreementsSigned();
  const haveAllServicesBillingDetails =
    cart.flags.haveAllLocationsPaymentMethod();
  const setupStartDatesVisible = !haveAllServicesSetDates;
  const goToServiceAgreementVisible =
    !setupStartDatesVisible && !areAgreementsSigned;
  const goToBillingVisible =
    !setupStartDatesVisible && !goToServiceAgreementVisible;
  const checkoutVisible =
    haveAllServicesSetDates &&
    areAgreementsSigned &&
    haveAllServicesBillingDetails;
  const services = cart.selectors.getAllServices();

  useEffect(() => {
    if (token) {
      dispatch(addressesDuck.thunks.loadCartLimitations());
    }  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelContent>
      <PanelTitle>{translate('summaryPageTotalCostTitle')}</PanelTitle>
      <PriceSummary
        bold
        activationCost={activationCost}
        monthlyCost={monthlyCost}
        managementFee={managementFee}
      ></PriceSummary>
      {checkoutVisible ? (
        <CheckoutDetailsForm />
      ) : (
        <>
          <ButtonSet>
            {setupStartDatesVisible ? (
              <Button
                width='100%'
                onClick={setupStartDates}
                data-automation='SetupStartDates'
              >
                {translate('setupStartDatesButton')}
              </Button>
            ) : null}
            {goToServiceAgreementVisible ? (
              <Button
                width='100%'
                onClick={goToServiceAgreement}
                data-automation='GoToServiceAgreement'
              >
                {translate('goToServiceAgreementButton')}
              </Button>
            ) : null}
            {goToBillingVisible ? (
              <Button
                width='100%'
                onClick={goToBilling}
                data-automation='SetupBillingDetailsService'
              >
                {translate('setupBillingDetailsServiceButton')}
              </Button>
            ) : null}
            <Button
              width='100%'
              inverted
              marginTop
              onClick={addAdditionalService}
              disabled={services.length >= cartLimitations.services || canAllLocationsNotAddServices ? true : false}
            >
              {translate('addAdditionalServiceButton')}
            </Button>
            {services.length >= cartLimitations.services
              && <FieldDescription>{translate('servicessLimitReached')}</FieldDescription>
            }
          </ButtonSet>
          <Notice>
            <NoticeParagraph>
              {translate('initialTermOfAgreementParagraph')}
            </NoticeParagraph>
            <NoticeParagraph>{translate('thankYouParagraph')}</NoticeParagraph>
          </Notice>
        </>
      )}
    </PanelContent>
  );
};

export default TotalCostPanel;

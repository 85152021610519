import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { UrlParamTypes } from 'contracts/core/routing';
import { destroySession } from 'core/services/session';
import { actionIsRunning } from 'core/ducks/running';
import { Routes } from 'routing/routes';
import recognizedDuck from 'ducks/recognized';
import translate from 'core/helpers/translate';
import { Select } from 'core/components';
import { getSelectedItem, getAddressOptions } from '../recognizedHelpers';
import { buildRequestParam } from './requestBuilder';

import { ApplicationState } from 'contracts/core/state';
import { RecognizedSubmitDataView } from 'contracts/models/RecognizedSubmitDataView';

import { recognizedEquipmentsSource, recognizedCollateralEquipmentsSource } from '../source/RecognizedEquipmentSource';

import { Button } from 'core/components/styled';
import {
  RubiconHorizontalLogo,
  ConfirmationItemContainer,
  ConfirmationItemLabel,
  ConfirmationAddressContainer,
  ConfirmationContainer,
  ConfirmationItemPhoto,
  ConfirmationSelectionContainer,
  ConfirmationButton,
  ConfirmationItemsContainer,
  AddressLabel,
  AddressErrorMessage,
} from './styled';

const RecognizedSelectionPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recognizedId, collateralId, alias } = useParams<Partial<UrlParamTypes>>();

  const selectedItem = getSelectedItem(recognizedEquipmentsSource, recognizedId || null);
  const collateralItem = getSelectedItem(recognizedCollateralEquipmentsSource, collateralId || null);
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [isAddressInvalid, setIsAddressInvalid] = useState<boolean>(false);

  const loadedLocations = useSelector(
    (state: ApplicationState) => state.recognized.locations,
  );

  const submitOrderResponse = useSelector(
    (state: ApplicationState) => state.recognized.submitOrder,
  );

  const submitOrder = (requestArg: RecognizedSubmitDataView) => {
    dispatch(recognizedDuck.thunks.submitOrder(requestArg)).then(() => {
      navigate(Routes.recognizedAcknowledgement);
    }).catch(() => {
      navigate(Routes.summary);
    });
  }

  const loadLocations = () => {
    if (alias) {
      dispatch(recognizedDuck.thunks.loadLocations(alias)).catch(() => {
        navigate(Routes.summary);
      });
    }
  }

  const isSubmitOrderLoading = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, recognizedDuck.actionKeys.SUBMIT_ORDER),
  );

  const areLocationsLoading = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, recognizedDuck.actionKeys.LOAD_LOCATIONS),
  );

  const onSelectLocation = (address: string) => {
    setSelectedAddress(address);
    setIsAddressInvalid(false);
  }

  const onSubmitOrder = async () => {
    const selectedLocation = loadedLocations.length > 1 ? loadedLocations?.filter(location => location.line1 === selectedAddress)[0] : loadedLocations[0];
    if (!selectedLocation) {
      setIsAddressInvalid(true);
    }
    else {
      if (alias) {
        const requestArg = buildRequestParam(alias, selectedItem, collateralItem, selectedLocation);
        submitOrder(requestArg);
        destroySession();
      }
    }
  }

  useEffect(() => {
    loadLocations();
    dispatch(recognizedDuck.thunks.hideNavigationBar(true));
    if (submitOrderResponse) {
      navigate(Routes.location);
    }
    return () => {
      dispatch(recognizedDuck.thunks.hideNavigationBar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmationContainer isLoading={areLocationsLoading || isSubmitOrderLoading}>
      <RubiconHorizontalLogo />
      <ConfirmationSelectionContainer>
        <ConfirmationAddressContainer>
          <AddressLabel hasError={isAddressInvalid}>{translate('recognizedShippingInformationLabel')}</AddressLabel>
          {loadedLocations && loadedLocations.length > 1 ?
            (
              <Select
                label={'Select address'}
                name={'locationId'}
                options={getAddressOptions(loadedLocations)}
                value={loadedLocations.map(locationOption => locationOption.line1)}
                onChange={e => onSelectLocation(e.target.value)}
              />
            ) : (
              <AddressLabel>{loadedLocations && loadedLocations[0]?.line1}</AddressLabel>
            )
          }
          <AddressErrorMessage hasError={isAddressInvalid}>{translate('recognizedAddressNotSelectedErrorMessage')}</AddressErrorMessage>
        </ConfirmationAddressContainer>
        <ConfirmationItemsContainer>
          <ConfirmationItemContainer key={selectedItem.file}>
            <ConfirmationItemPhoto
              recognizedEquipmentType={selectedItem.file}
            ></ConfirmationItemPhoto>
            <ConfirmationItemLabel>
              {selectedItem.description}
            </ConfirmationItemLabel>
          </ConfirmationItemContainer>
          <ConfirmationItemContainer key={collateralItem.file}>
            <ConfirmationItemPhoto
              recognizedEquipmentType={collateralItem.file}
            ></ConfirmationItemPhoto>
            <ConfirmationItemLabel>
              {collateralItem.description}
            </ConfirmationItemLabel>
          </ConfirmationItemContainer>
        </ConfirmationItemsContainer>
      </ConfirmationSelectionContainer>
      <ConfirmationButton>
        <Button onClick={onSubmitOrder}>
          {translate('recognizedSubmitOrderButton')}</Button>
      </ConfirmationButton>
    </ConfirmationContainer>
  );
};

export default RecognizedSelectionPage;

import { transparentize } from 'polished';
import { StylesConfig } from 'react-select';
import { theme } from 'core/styles';

const getControlBackgroundImage = (state: any) => {
  if (state.isFocused) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=")';
  }

  if (state.isDisabled) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=")';
  }

  return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iLjQ5OCIvPjwvc3ZnPg==")';
};

export const typeAheadStyles: StylesConfig = {
  container: () => ({}),
  control: (baseStyle: any, state: any) => ({
    width: '100%',
    paddingRight: '26px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    backgroundImage: getControlBackgroundImage(state),
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: state.isFocused
      ? theme.colors.brandPrimary
      : theme.colors.grayDark,
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: theme.fontWeightNormal,
  }),
  valueContainer: (baseStyle: any) => ({
    ...baseStyle,
    padding: '0px 6px 2px 0',
  }),
  singleValue: (baseStyle: any) => ({
    ...baseStyle,
    top: '3px',
    transform: 'none',
  }),
  placeholder: (baseStyle: any) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    textTransform: 'capitalize',
    color: `${transparentize(0.6, theme.colors.black)}`,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    top: '4px',
    right: '22px',
  }),
  dropdownIndicator: () => ({ display: 'none' }),
  clearIndicator: (baseStyle: any, state: any) =>
    ({
      '::after': {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        content: '""',
        backgroundColor: state.isDisabled
          ? theme.colors.grayDark
          : theme.colors.brandPrimary,
        backgroundImage:
          'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjkxOC45TDUuMS4wODJhLjI2NC4yNjQgMCAwIDAtLjM4MiAwTDMgMS44IDEuMjgyLjA4MmEuMjY0LjI2NCAwIDAgMC0uMzgyIDBMLjA4Mi45YS4yNjQuMjY0IDAgMCAwIDAgLjM4MkwxLjggMyAuMDgyIDQuNzE4YS4yNjQuMjY0IDAgMCAwIDAgLjM4MmwuODE4LjgxOGMuMTEuMTEuMjczLjExLjM4MiAwTDMgNC4ybDEuNzE4IDEuNzE4QS4yOTUuMjk1IDAgMCAwIDQuOTEgNmEuMjk1LjI5NSAwIDAgMCAuMTkxLS4wODJsLjgxOC0uODE4YS4yNjQuMjY0IDAgMCAwIDAtLjM4Mkw0LjIgM2wxLjcxOC0xLjcxOGEuMjY0LjI2NCAwIDAgMCAwLS4zODJ6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '> svg': { display: 'none' },
    } as any),
  noOptionsMessage: (baseStyles: any) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '14px',
  }),
  menu: (baseStyle: any) => ({
    ...baseStyle,
    left: 'initial',
    top: 'initial',
    zIndex: 3000,
    width: 'auto',
    minWidth: '100%',
    marginTop: 0,
    borderRadius: '2px',
    boxShadow: `0 4px 8px ${transparentize(0.9, theme.colors.grayBase)}`,
  }),
  option: (baseStyle: any, state: any) => ({
    ...baseStyle,
    ':active': '#f2f2f3',
    paddingRight: '30px',
    backgroundColor: (state.isFocused && '#f3f4f3') || 'transparent',
    color: theme.colors.black,
    fontSize: '14px',
  }),
};

export const groupStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const groupBadgeStyles: React.CSSProperties = {
  display: 'inline-block',
  padding: '3px 6px',
  backgroundColor: '#ebecf0',
  borderRadius: '100%',
  textAlign: 'center',
  lineHeight: '1',
  fontWeight: theme.fontWeightMedium,
  fontSize: '10px',
  color: '#172b4d',
};

/* global google */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { throttle, find, get, has, indexOf } from 'lodash-es';
import { actionIsRunning } from 'core/ducks/running';
import {
  AddressDataView,
  GeocodeAddressDataView,
} from 'contracts/models/AddressDataView';
import { ApplicationState } from 'contracts/core/state';
import addressesDuck from 'ducks/addresses';
import { FormError, FormGroup, FormLabel } from '../styled';
import {
  groupBadgeStyles,
  groupStyles,
  typeAheadStyles,
} from '../styled/TypeAheadStyles';

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const noResultAddressOptions = [
  {
    label: 'No valid address found',
    value: 0,
  },
];

let googleService: google.maps.places.AutocompleteService;
let googlePlaces: google.maps.places.PlacesService;

const LocationPicker = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({
    placeholder,
    label,
    asyncValidating,
    onChange,
    value,
    errors,
    defaultValue,
    ...props
  }, ref) => {
    const [fieldValue, setValue] = useState<string | undefined>(undefined);
    const [option, setOption] = useState<SusggestionOption | undefined>(
      undefined,
    );
    const dispatch = useDispatch();
    const apiAddresses = useSelector(
      (state: ApplicationState): GeocodeAddressDataView[] =>
        state.locations.addresses,
    );
    const isLoading = useSelector((state: ApplicationState): boolean =>
      actionIsRunning(state, addressesDuck.actionKeys.LOAD_ADDRESSES),
    );
    const loadAddresses: (searchTerm: string) => any =
      addressesDuck.thunks.loadAddresses;

    useEffect(() => {
      googleService = new google.maps.places.AutocompleteService();
      if (defaultValue) {
        searchAddress(defaultValue, (option: any) => {
          if (option && option[0] && option[0].label && option[0].label.key) {
            const selectedOption = {
              label: option[0]?.label?.key,
              value: option[0]?.value
            }
            setOption(selectedOption);
            selectAddress(selectedOption.value);
          }
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInputChange = (value: string) => {
      setValue(value);
    };

    const formOnChange = (option: any) => {
      const value = has(option, 'value') ? option.value : null;
      setOption(option);
      selectAddress(value);
    };

    const selectAddress = (value: string | null) => {
      const isGoogleAddress = Number.isNaN(Number(value));

      if (isGoogleAddress && value) {
        const request = {
          placeId: value,
          fields: ['ALL'],
        };

        const map = new google.maps.Map(document.createElement('div'));
        googlePlaces = new google.maps.places.PlacesService(map);
        googlePlaces.getDetails(
          request,
          (place: GooglePlaceType, status: string) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const location = getLocation(place);
              onChange({ id: value || null, ...location });
            }
          },
        );
      } else {
        onChange({
          id: value || null,
          ...apiAddresses.find(address => address.zip === value),
        });
      }
    };

    const getLocation = (place: GooglePlaceType) => ({
      latitude: place.geometry ? place.geometry.location.lat() : undefined,
      longitude: place.geometry ? place.geometry.location.lng() : undefined,
      streetNumber: getStreetNumber(place),
      street: getStreet(place),
      city: getCity(place),
      state: getState(place),
      country: getCountry(place),
      zip: getZip(place),
      line1: place.formatted_address,
    });

    const getStreetNumber = (place: GooglePlaceType) =>
      getAddressComponent(place, 'street_number');

    const getStreet = (place: GooglePlaceType) =>
      getAddressComponent(place, 'route');

    const getCity = (place: GooglePlaceType) =>
      getAddressComponent(place, 'locality') ||
      getAddressComponent(place, 'sublocality') ||
      getAddressComponent(place, 'neighborhood') ||
      getAddressComponent(place, 'administrative_area_level_3');

    const getState = (place: GooglePlaceType) =>
      getAddressComponent(place, 'administrative_area_level_1');

    const getCountry = (place: GooglePlaceType) =>
      getAddressComponent(place, 'country');

    const getZip = (place: GooglePlaceType) =>
      getAddressComponent(place, 'postal_code');

    const getAddressComponent = (place: GooglePlaceType, type: string) => {
      const addressComponent = find(
        place.address_components,
        comp => indexOf(comp.types, type) > -1,
      );

      return get(addressComponent, 'short_name', '');
    };

    const addressFormatter = (
      addressFirstPart: string,
      stringOffset: number,
      stringLength: number,
      addressSecondPart: string,
      wholeAddress: string,
    ) => {
      const highlightedText = addressFirstPart.substr(stringOffset, stringLength);
      const firstPart =
        stringOffset > 0 ? addressFirstPart.substr(0, stringOffset) : '';
      const secondPardt = addressFirstPart.substr(
        stringOffset + stringLength,
        addressFirstPart.length,
      );

      return (
        <span key={wholeAddress}>
          {firstPart}
          <span style={{ color: 'black', fontWeight: 'bold' }}>
            {highlightedText}
          </span>
          {secondPardt}{' '}
          <span style={{ color: 'grey', fontSize: '12px' }}>
            {addressSecondPart}
          </span>
        </span>
      );
    };

    const formatAddressForDropdown = (predictions: AutocompletePrediction[]) => {
      let addressFirstPart;
      let addressSecondPart;
      let stringLength;
      let stringOffset;
      let addressDescription;
      let formatted: any = 'No valid address found';

      // google address structure
      const options = predictions.map(prediction => {
        const addressAttributes = prediction.structured_formatting;
        addressDescription = prediction.description;
        addressFirstPart = addressAttributes.main_text;
        addressSecondPart = addressAttributes.secondary_text;
        stringLength = addressAttributes.main_text_matched_substrings[0].length;
        stringOffset = addressAttributes.main_text_matched_substrings[0].offset;

        formatted = addressFormatter(
          addressFirstPart,
          stringOffset,
          stringLength,
          addressSecondPart,
          addressDescription,
        );

        return {
          label: formatted,
          value: prediction.place_id,
        };
      });

      return options;
    };

    // address parse api address
    const formatRubiconAddressForDropdown = (
      predictions: GeocodeAddressDataView[],
    ) => {
      const options = predictions.map(prediction => {
        return {
          label: prediction.line1,
          value: prediction.zip,
        };
      });

      return options;
    };

    const displaySuggestions = (
      predictions: AutocompletePrediction[],
      status: string,
      searchTerm: string,
      onOptionsLoaded: (addressOptions: any[]) => void,
    ) => {
      let addressOptions;

      if (predictions) {
        addressOptions = formatAddressForDropdown(predictions);
        onOptionsLoaded(addressOptions);
      } else {
        dispatch(loadAddresses(searchTerm))
          .then((result: GeocodeAddressDataView[]) => {
            addressOptions =
              result.length > 0
                ? formatRubiconAddressForDropdown(result)
                : noResultAddressOptions;
            onOptionsLoaded(addressOptions);
          })
          .catch(() => {
            onOptionsLoaded(noResultAddressOptions);
          });
      }
    };

    const searchAddress = throttle((searchTerm, onOptionsLoaded) => {
      if (searchTerm.length < 1) {
        onOptionsLoaded([]);
        return;
      }

      googleService.getPlacePredictions(
        {
          input: searchTerm,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        },
        (predictions: AutocompletePrediction[], status: string) =>
          displaySuggestions(predictions, status, searchTerm, onOptionsLoaded),
      );
    }, 500);

    const findSelectedOption = (value?: AddressDataView) => {
      let formattedOption: any;
      if (option) {
        formattedOption = option;
        if (formattedOption && formattedOption.label.key) {
          formattedOption.label = option && option.label.key;
        }
      } else {
        if (value) {
          formattedOption = {
            label: value.line1,
            value: value.id,
          };
        }
      }

      return formattedOption;
    };

    const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
      return inputValue.length < 2
        ? 'Please type at least 2 characters'
        : 'No results';
    };

    return (
      <FormGroup
        hasValue={!!fieldValue || !!option || !!value}
        isLoading={isLoading || asyncValidating}
        data-automation='ServiceAddress'
      >
        {!!label && <FormLabel>{label}</FormLabel>}

        <AsyncSelect
          {...props}
          ref={ref as any}
          isSearchable
          styles={typeAheadStyles}
          formatGroupLabel={formatGroupLabel}
          loadOptions={searchAddress}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder || ''}
          value={findSelectedOption(value)}
          onChange={formOnChange}
          onInputChange={onInputChange}
        />

        {errors &&
          errors.map(error => <FormError key={error}>{error}</FormError>)}
      </FormGroup>
    );
  });

interface SusggestionOption {
  label: string | any;
  value: string;
}

type GooglePlaceType = google.maps.places.PlaceResult;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;

interface ComponentProps {
  placeholder?: string;
  asyncValidating?: boolean;
  onChange: any;
  label?: string;
  value?: any;
  errors?: string[];
  defaultValue?: string;
}

export default LocationPicker;

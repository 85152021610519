import styled, { css } from 'styled-components';

interface ClusterMarkerHostProps {
  scale?: number;
}

const ClusterMarkerHost = styled.div<
ClusterMarkerHostProps
>`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;

  border: 5px solid #004336;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  color: #333;

  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.scale &&
    css`
      transform: translate3D(0,0,0) scale(${props.scale}, ${props.scale})
    `};
`;

export default ClusterMarkerHost;
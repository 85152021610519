import axios, { AxiosRequestConfig, AxiosResponseTransformer } from 'axios';
import API_KEY from 'core/constants/ApiKey';
import { getAxiosTransformers } from 'core/helpers/axiosTransformers';
import humps from 'humps';
import { isObject } from 'lodash-es';
import { apiBaseURLs } from './environment';
import { ServiceError } from 'contracts/core/service';

const camelizeResponse: AxiosResponseTransformer = (data: any) =>
  isObject(data) ? humps.camelizeKeys(data) : data;

const transformResponse: AxiosResponseTransformer[] =
  getAxiosTransformers(camelizeResponse);

export function transformServiceResponse<ModelInterface>(
  transformFunc: (item: ModelInterface) => void,
): AxiosResponseTransformer[] {
  const responseMapper = (response: any) => {
    if (response) {
      const error = response as ServiceError;
      if (error && error.error && error.message) {
        return response;
      } else if (transformFunc) {
        if (Array.isArray(response)) {
          response.forEach(element => {
            transformFunc(element);
          });
        } else {
          transformFunc(response);
        }
      }
      return response;
    }
  };
  const transformers = getAxiosTransformers(
    ...transformResponse,
    responseMapper,
  );
  return transformers;
}

const defaultHttpSettings: AxiosRequestConfig = {
  responseType: 'json',
  transformResponse,
  headers: {
    'ocp-apim-subscription-key': API_KEY,
  },
};

export const httpSavingsCore = axios.create({
  ...defaultHttpSettings,
  baseURL: `${apiBaseURLs.savingsCore}/`,
});

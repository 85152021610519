import styled from 'styled-components';
import { Devices } from 'core/styles';

export const MainContainer = styled.div`
  display: flex;
  flex-flow: row;

  @media ${Devices.mobile} {
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }
`;

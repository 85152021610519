import styled, { css } from 'styled-components';
import { loading, theme } from 'core/styles';

interface LoadingSectionContainerProps {
  noBackground?: boolean;
}

export const LoadingSectionContent = styled.div`
  ${loading(30)};
`;

export const LoadingSectionContainer = styled.div<LoadingSectionContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: ${theme.colors.white};

  ${props =>
    props.noBackground &&
    css`
      background-color: transparent;
    `}
`;

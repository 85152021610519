import React from 'react';
import translate from 'core/helpers/translate';
import { SiteDataView } from 'contracts/models/SiteDataView';
import { PanelTitle, PanelSubTitle } from 'core/components/styled/Panel2';
import {
  NavIndex,
  DetailsField,
  DetailLabel,
  DetailValue,
  LocationsList,
  LocationsListAddress,
} from './styled';

const LocationAddressList = ({
  locations,
  onLocationAddressSelection,
}: ComponentProps) => {
  const getLocationList = (locationList: SiteDataView[]) =>
    locationList.map((location, index) => (
      <LocationsListAddress
        key={index}
        isDisabled={!location.canAddService}
        onClick={() => onLocationAddressSelection(index)}
      >
        <NavIndex>{index + 1}</NavIndex>
        <DetailsField>
          <DetailLabel>{translate('serviceAddressLabel')}</DetailLabel>
          <DetailValue>{location.address.line1}</DetailValue>
        </DetailsField>
      </LocationsListAddress>
    ));

  return (
    <div>
      <PanelTitle>{translate('locationPageTitle')}</PanelTitle>
      <PanelSubTitle>{translate('locationListMessage')}</PanelSubTitle>
      <LocationsList>{locations && getLocationList(locations)}</LocationsList>
    </div>
  );
};

interface ComponentProps {
  locations: SiteDataView[] | undefined;
  onLocationAddressSelection: (index: number) => void;
}

export default LocationAddressList;

import translate from 'core/helpers/translate';
import React from 'react';

import {
  ContactInformationSection,
  ContactInformationSummaryContainer,
  EmailValue,
  FullNameValue,
  PhoneNrValue,
  UserAvatar
} from './styled';

const ContactInformationSummary: React.FC<ComponentProps> = ({ firstName, lastName, email, phoneNr }) => {
  console.log(firstName)
  return (
    <ContactInformationSummaryContainer>
      <UserAvatar />
      <ContactInformationSection>
        <FullNameValue>{firstName || translate('firstNameFormLabel')}  {lastName || translate('lastNameFormLabel')}</FullNameValue>
        <EmailValue>{email || translate('emailFormLabel')}</EmailValue>
        <PhoneNrValue>{phoneNr || translate('phoneFormLabel')}</PhoneNrValue>
      </ContactInformationSection>
    </ContactInformationSummaryContainer>
  );
};

interface ComponentProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNr?: string;
}

export default ContactInformationSummary;

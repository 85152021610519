import React from 'react';
import { serviceAgreementText } from '../constants/serviceAgreementText-en';
import { SERVICE_AGREEMENT } from '../constants/variables';
import {
  ServiceAgreementBody,
  ServiceFontColorPrimary,
  ServiceAgreementList,
  ServiceBoldFont,
  ServiceAgreementDownload,
} from './styled';

const ServiceAgreementFormatted = ({
  handleScroll,
  isDownloadDisabled,
}: ComponentProps) => (
  <React.Fragment>
    <ServiceAgreementBody
      className='serviceAgreementContainer'
      onScroll={e => handleScroll(e, SERVICE_AGREEMENT)}
      data-automation='ServiceAgreement'
    >
      <p>
        {serviceAgreementText.pleaseReadTheFollowingAgreement}
        <ServiceBoldFont> {serviceAgreementText.iAccept} </ServiceBoldFont>
        {serviceAgreementText.serviceAgreementFirstParagraph}
      </p>

      <p>
        {serviceAgreementText.byClicking}
        <ServiceBoldFont> {serviceAgreementText.iAccept} </ServiceBoldFont>
        {serviceAgreementText.serviceAgreementSecondParagraph}
      </p>

      <ServiceAgreementList>
        <li>
          <ServiceBoldFont>{serviceAgreementText.amendment} </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementThirdParagraph}
          <span> </span>
          <ServiceFontColorPrimary>
            <a
              href='https://www.rubicon.com/legal/serviceagreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.rubicon.com/legal/serviceagreement
            </a>
          </ServiceFontColorPrimary>
          {serviceAgreementText.serviceAgreementFourthParagraph}
          <ServiceBoldFont>
            {serviceAgreementText.rubiconAmendment}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFifthParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.registration}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementSixthParagraph}
          <ServiceBoldFont>
            {serviceAgreementText.serviceAgreementSeventhParagraph}
          </ServiceBoldFont>
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementText.general} </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementEightParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.managementServices}{' '}
          </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>{serviceAgreementText.agency} </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementNinthParagraph}
              <ServiceBoldFont>
                {serviceAgreementText.serviceAgreementTenthParagraph}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementEleventhParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementText.haulers} </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwelveParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.incumbentProviders}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirteenthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.singlePointOfContact}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourteenthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.softwarePlatform}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFifteenthParagraph}
              <span> </span>
              <ServiceFontColorPrimary>
                <a href='https://www.rubicon.com'>www.rubicon.com</a>
              </ServiceFontColorPrimary>
              {serviceAgreementText.serviceAgreementSixteenthParagraph}

              <ServiceAgreementList>
                <li>
                  <ServiceBoldFont>
                    {serviceAgreementText.licenseToUseSoftwarePlatform}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementText.serviceAgreementSeventeenthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementText.licenseToUseWasteAndRecyclingData}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementText.serviceAgreementEighteenthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementText.restrictions}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementText.serviceAgreementNineteenthParagraph}
                </li>
              </ServiceAgreementList>
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.exclusivity}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentyParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.titleToWasteMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentyfirstParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementText.pricing} </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementTwentysecondParagraph}
          <ServiceBoldFont>
            {serviceAgreementText.serviceAgreementTwentythirdParagraph}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementTwentyfourthParagraph}

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementText.pricingAdjustment}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementText.changes} </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentysixthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.incumbentFees}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentyseventhParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.termAndTermination}{' '}
          </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>{serviceAgreementText.term}. </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementTwentyeightParagraph}
              <ServiceBoldFont>
                {serviceAgreementText.serviceAgreementTwentyninthParagraph}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtyParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.terminationByRubicon}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtyfirstParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.terminationForMaterialBreach}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtysecondParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.terminationForEventOfInsolvency}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.earlyCancellationFees}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtyfourthParagraph}
              <ServiceBoldFont>
                {serviceAgreementText.serviceAgreementThirtyfifthParagraph}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtysixthParagraph}
              <ServiceBoldFont>
                {serviceAgreementText.serviceAgreementThirtyseventhParagraph}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementThirtyeightParagraph}
              <ServiceBoldFont>
                {serviceAgreementText.serviceAgreementThirtyninthParagraph}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtyParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.customerObligationRegardingEnvironment}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFourtyfirstParagraph}
          <span> </span>
          <ServiceFontColorPrimary>
            <a
              href='https://rubicon.com/legal/terms/smb'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://rubicon.com/legal/terms/smb
            </a>
          </ServiceFontColorPrimary>
          {serviceAgreementText.serviceAgreementFourtysecondParagraph}
          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementText.acceptedMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.alternativeMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.environmentalLaw}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.medicalWaste}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtysixthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.prohibitedMaterial}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtyseventhParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.recyclableMaterial}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFourtyeightParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.complianceWithLaws}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFourtyninthParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.limitedWarranty}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFiftyParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.limitationOfLiability}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFiftyfirstParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.indemnification}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFiftysecondParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementText.confidentialInformation}{' '}
          </ServiceBoldFont>
          {serviceAgreementText.serviceAgreementFiftythirdParagraph}
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementText.other} </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementText.governingLaw}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFiftyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.disputeResolution}{' '}
              </ServiceBoldFont>

              <ServiceAgreementList>
                <li>
                  <ServiceBoldFont>
                    {serviceAgreementText.arbitration}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementText.serviceAgreementFiftyfifthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementText.arbitrationProcess}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementText.serviceAgreementFiftysixthParagraph}
                </li>
              </ServiceAgreementList>
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.equitableRemediesAndSpecificPerformance}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFiftyseventhParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.forceMajeure}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFiftyeightParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementText.notices} </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementFiftyninthParagraph}
              <span> </span>
              <ServiceFontColorPrimary>
                <a
                  href='https://www.rubicon.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  www.rubicon.com
                </a>
              </ServiceFontColorPrimary>
              {serviceAgreementText.serviceAgreementSixtyParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.completeAgreement}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtyfirstParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.assingnment}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtysecondParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementText.waiver} </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.severability}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.counterparts}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementText.survival}{' '}
              </ServiceBoldFont>
              {serviceAgreementText.serviceAgreementSixtysixthParagraph}
            </li>
          </ServiceAgreementList>
        </li>
      </ServiceAgreementList>

      <p>{serviceAgreementText.serviceAgreementSixtyseventhParagraph}</p>
    </ServiceAgreementBody>

    <ServiceAgreementDownload
      href={process.env.REACT_APP_SAVINGS_HOST + 'download/service-agreement.pdf'}
      target='_blank'
      disabled={isDownloadDisabled}
    >
      {serviceAgreementText.downloadServiceAgreement}
    </ServiceAgreementDownload>
  </React.Fragment>
);

interface ComponentProps {
  handleScroll: (e: any, agreementName: string) => any;
  isDownloadDisabled?: boolean;
}

export default ServiceAgreementFormatted;

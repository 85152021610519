import { BaseAction } from 'contracts/core/action';
import {
  BaseState,
  ReduceFunction,
  ReduceFunctionMap,
} from 'contracts/core/state';
import { Reducer } from 'redux';
import { GLOBAL_RESET } from './globalActionTypes';

export class ReducerBuilder<S extends BaseState, A extends BaseAction> {
  rootName = '';
  getInitialState: () => S;
  reduceFunctionMap: ReduceFunctionMap<string, S, A> = {};

  constructor(rootName: string, getInitialState: () => S) {
    this.rootName = rootName;
    this.getInitialState = getInitialState;
  }

  withReduceFunction = (type: string, reduceFunction: ReduceFunction<S, A>) => {
    if (this.reduceFunctionMap[type]) {
      throw Error(
        `Action with type ${type} already defined at root ${this.rootName}`,
      );
    }

    this.reduceFunctionMap[type] = reduceFunction;
    return this;
  };

  withReduceFunctionMap = (
    reduceFunctions: ReduceFunctionMap<string, S, A>,
  ) => {
    this.reduceFunctionMap = reduceFunctions;
    return this;
  };

  withReset = (type: string) =>
    this.withReduceFunction(type, this.getInitialState);

  buildReducer: () => Reducer<S, A> = () => {
    const initState = this.getInitialState();
    const reduceFunctionMap = this.reduceFunctionMap;
    return (state: S = initState, action) => {
      if (action.type === GLOBAL_RESET) {
        return this.getInitialState();
      }

      const matchingReduceFunctions = reduceFunctionMap[action.type];
      if (matchingReduceFunctions) {
        return matchingReduceFunctions(state, action);
      }
      return state;
    };
  };
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as LoadingBarComp } from 'react-top-loading-bar';
import { theme } from 'core/styles';
import { ApplicationState } from 'contracts/core/state';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';

const LoadingBar: React.FC = () => {
  const dispatch = useDispatch();
  const progress = useSelector(
    (state: ApplicationState) => state.core.loadingBar.progress,
  );

  return (
    <LoadingBarComp
      color={theme.colors.brandPrimary}
      progress={progress}
      onLoaderFinished={() => dispatch(setLoadingBarProgress(100))}
    />
  );
};

export default LoadingBar;

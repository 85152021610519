import { LabelValue } from 'contracts/core/component';
import { FeeDataView } from 'contracts/models/QuoteRequestDataView';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { useModalManager } from 'core/helpers/hooks';
import translate from 'core/helpers/translate';
import { currency } from 'core/services/formatter';
import cart from 'ducks/cart';
import React from 'react';
import { useDispatch } from 'react-redux';
import { editServiceUrl } from 'routing/routes';
import {
  ServiceCardContainer,
  ServiceCardContainerActionDelete,
  ServiceCardContainerActionDeleteContent,
  ServiceCardContainerActionEdit,
  ServiceCardContainerActionEditContent,
  ServiceCardContainerActionLabel,
  ServiceCardContainerActions,
  ServiceCardContainerDetails,
  ServiceCardContainerDetailsHeader,
  ServiceCardContainerDetailsPrice,
  ServiceCardContainerDetailsPriceLabel,
  ServiceCardContainerDetailsPriceValue,
  ServiceCardContainerDetailsProperties,
  ServiceCardContainerDetailsProperty,
  ServiceCardContainerDetailsPropertyDates,
  ServiceCardContainerDetailsPropertyLabel,
  ServiceCardContainerDetailsPropertyValue,
  ServiceCardContainerDetailsTitle,
  ServiceCardContainerImage,
} from './styled';

const ServiceCard: React.FC<ComponentProps> = ({
  service,
  indexLocation,
  indexService,
  showServiceActions,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const { openConfirmation } = useModalManager();

  if (!service) return null;

  const deleteService = async () => {
    openConfirmation(translate('removeService'), response => {
      if (response) {
        dispatch(cart.actions.deleteService(indexLocation, indexService));
      }
    });
  };

  const entries: Array<LabelValue<number | string>> = [];
  entries.push(
    { label: translate('quantityOverviewLabel'), value: service.quantity || 1 },
    {
      label: translate('sizeOverviewLabel'),
      value: service.equipmentLabel || '-',
    },
    {
      label: translate('extrasOverviewLabel'),
      value:
        service.optionalExtras && service.optionalExtras.length > 0
          ? service.optionalExtras.map(e => e.label).join(', ')
          : '-',
    },
    {
      label: translate('wasteTypeOverviewLabel'),
      value: service.materialLabel || '-',
    },
    {
      label: translate('frequencyOverviewLabel'),
      value: service.frequencyLabel || '-',
    },
  );

  const serviceRates: FeeDataView[] = [];
  if (service.base) {
    service.base.forEach(quoteItem => {
      serviceRates.push({ ...quoteItem });
    });
  }
  if (service.optionalExtras) {
    service.optionalExtras.forEach(optionalExtra => {
      const existingRateUom = serviceRates.find(
        rate => rate.uom === optionalExtra.uom,
      );
      if (existingRateUom) {
        existingRateUom.value += optionalExtra.value;
      }
    });
  }

  const serviceRef = React.createRef<HTMLDivElement>();
  if (isSelected) {
    setTimeout(() => {
      if (serviceRef.current) {
        if (
          serviceRef.current.getBoundingClientRect().bottom > window.innerHeight
        ) {
          serviceRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    });
  }

  return (
    <ServiceCardContainer
      showServiceActions={showServiceActions}
      isSelected={isSelected}
      isEditable={service.isEditable}
      ref={serviceRef}
    >
      <ServiceCardContainerImage
        equipmentType={service.service}
      ></ServiceCardContainerImage>
      <ServiceCardContainerDetails>
        <ServiceCardContainerDetailsHeader>
          <ServiceCardContainerDetailsTitle>
            {translate(`serviceTypeLabel${service.service}`)}
          </ServiceCardContainerDetailsTitle>
          {serviceRates.map(rate => (
            <ServiceCardContainerDetailsPrice key={rate.value + '_' + rate.uom}>
              <ServiceCardContainerDetailsPriceValue>
                {rate ? currency(rate.value) : translate('marketRate')}
              </ServiceCardContainerDetailsPriceValue>
              <ServiceCardContainerDetailsPriceLabel>
                per {translate(rate.uom)}
              </ServiceCardContainerDetailsPriceLabel>
            </ServiceCardContainerDetailsPrice>
          ))}
        </ServiceCardContainerDetailsHeader>
        <ServiceCardContainerDetailsProperties>
          {entries
            ? entries.map(entry => (
              <ServiceCardContainerDetailsProperty key={entry.label}>
                <ServiceCardContainerDetailsPropertyLabel>
                  {entry.label}
                </ServiceCardContainerDetailsPropertyLabel>
                <ServiceCardContainerDetailsPropertyValue>
                  {entry.value}
                </ServiceCardContainerDetailsPropertyValue>
              </ServiceCardContainerDetailsProperty>
            ))
            : null}
          <ServiceCardContainerDetailsPropertyDates>
            {service.editStartDate && service.startDate ? (
              <ServiceCardContainerDetailsProperty>
                <ServiceCardContainerDetailsPropertyLabel>
                  {translate('startDateFormFieldStartDate')}
                </ServiceCardContainerDetailsPropertyLabel>
                <ServiceCardContainerDetailsPropertyValue>
                  {service.startDate}
                </ServiceCardContainerDetailsPropertyValue>
              </ServiceCardContainerDetailsProperty>
            ) : null}
            {service.editEndDate && service.endDate ? (
              <ServiceCardContainerDetailsProperty>
                <ServiceCardContainerDetailsPropertyLabel>
                  {translate('startDateFormFieldEndDate')}
                </ServiceCardContainerDetailsPropertyLabel>
                <ServiceCardContainerDetailsPropertyValue>
                  {service.endDate}
                </ServiceCardContainerDetailsPropertyValue>
              </ServiceCardContainerDetailsProperty>
            ) : null}
          </ServiceCardContainerDetailsPropertyDates>
        </ServiceCardContainerDetailsProperties>
      </ServiceCardContainerDetails>
      <ServiceCardContainerActions>
        <ServiceCardContainerActionEdit to={editServiceUrl(indexLocation, indexService)}>
          <ServiceCardContainerActionEditContent />
          <ServiceCardContainerActionLabel>
            {translate('edit')}
          </ServiceCardContainerActionLabel>
        </ServiceCardContainerActionEdit>
        {service.canDelete &&
          <ServiceCardContainerActionDelete onClick={deleteService}>
            <ServiceCardContainerActionDeleteContent />
            <ServiceCardContainerActionLabel>
              {translate('delete')}
            </ServiceCardContainerActionLabel>
          </ServiceCardContainerActionDelete>
        }
      </ServiceCardContainerActions>
    </ServiceCardContainer>
  );
};

interface ComponentProps {
  service: ServiceDataView;
  indexLocation: number;
  indexService: number;
  showServiceActions?: boolean;
  isSelected?: boolean;
}

export default ServiceCard;

import NotificationType from 'contracts/enums/NotificationType';
import React from 'react';
import {
  NotifierNotification,
  NotifierNotificationClose,
  NotifierNotificationMessage,
} from './styled/Notification';

const Notification: React.FC<ComponentProps> = ({
  type,
  message,
  onNotificationClose,
}) => (
  <NotifierNotification type={type}>
    <NotifierNotificationMessage>{message}</NotifierNotificationMessage>
    <NotifierNotificationClose onClick={onNotificationClose} type='button' />
  </NotifierNotification>
);

interface ComponentProps {
  type: NotificationType;
  message: string;
  onNotificationClose: () => void;
}

export default Notification;

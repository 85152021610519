import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import translate from 'core/helpers/translate';
import {
  SalesServiceCategory,
  SalesEditableServiceOptions,
  ServiceDetailsFormOptions,
} from 'contracts/models/SaleMarketConfigDataView';
import { CurrentServiceForm } from 'contracts/core/form';
import { ServiceAvatar } from 'core/components/styled/ServiceAvatar';
import { RadioCheck, RadioText } from 'core/components/styled';
import EquipmentType from 'contracts/enums/EquipmentType';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import {
  ServiceOptionItemContainer,
  ServiceContentContainer,
  ServiceName,
  ServiceDetailsDivider,
  ServiceDetailsDividerSubTitle,
  ServiceDetailsContainer,
  ServiceDetailItem,
  ServiceQuestionsContainer,
  ServiceQuestion,
  ServiceQuestionAnswer,
} from '../../Service/styled';
import {
  filterFormOptionsByServiceType,
  filterFormRulesByEquipmentType,
} from 'core/helpers/service';
import { ApplicationState } from 'contracts/core/state';
import serviceDuck from 'ducks/serviceDetails';

const ServiceOptionItem: React.FC<ComponentProps> = ({
  service,
  onModalCloseClick,
}) => {
  const dispatch = useDispatch();
  const marketConfig = useSelector(
    (state: ApplicationState) => state.service.marketConfig,
  );

  const selectServiceOption = (serviceCode: EquipmentType) => {
    const newService = {} as ServiceDataView;
    newService.service = serviceCode;
    if (marketConfig) {
      const formOptions = filterFormOptionsByServiceType(
        serviceCode,
        marketConfig,
      );
      const formRules = filterFormRulesByEquipmentType(
        serviceCode,
        marketConfig,
      );
      dispatch(
        serviceDuck.actions.setLimboService({
          service: serviceCode,
          quantity: 1,
        } as CurrentServiceForm),
      );
      dispatch(serviceDuck.thunks.updateFormSettings(formRules, formOptions));
      onModalCloseClick(formRules, formOptions);
    }
  };
  return (
    <ServiceOptionItemContainer
      data-automation={service.category.code}
      hasQuestions={service.hasQuestion}
      onClick={
        !service.hasQuestion
          ? () => selectServiceOption(service.category.code)
          : undefined
      }
    >
      <ServiceAvatar equipmentType={service.category.code} maxWidth />
      <ServiceContentContainer>
        <ServiceName>
          {translate(`serviceTypeLabel${service.category.code}`)}
        </ServiceName>
        <ServiceDetailsDivider>
          <ServiceDetailsDividerSubTitle>
            {translate('serviceDetailsSubtitle')}
          </ServiceDetailsDividerSubTitle>
        </ServiceDetailsDivider>
        <ServiceDetailsContainer>
          {service.bestFor &&
            service.bestFor.length > 0 &&
            service.bestFor.map((item, key) => (
              <ServiceDetailItem key={key}>{item}</ServiceDetailItem>
            ))}
          {service.hasQuestion && <ServiceDetailItem></ServiceDetailItem>}
        </ServiceDetailsContainer>
        {service.hasQuestion &&
          service.serviceQuestion &&
          service.serviceAnswers &&
          service.serviceAnswers.length > 0 && (
            <ServiceQuestionsContainer>
              <ServiceQuestion>{service.serviceQuestion}</ServiceQuestion>
              {service.serviceAnswers.map((answer, key) => (
                <ServiceQuestionAnswer
                  key={key}
                  onClick={() => selectServiceOption(answer.code)}
                >
                  <RadioCheck isServiceOption />
                  <RadioText isServiceOption>{answer.label}</RadioText>
                </ServiceQuestionAnswer>
              ))}
            </ServiceQuestionsContainer>
          )}
      </ServiceContentContainer>
    </ServiceOptionItemContainer>
  );
};

interface ComponentProps {
  service: SalesServiceCategory;
  onModalCloseClick: (
    formRules?: SalesEditableServiceOptions,
    formOptions?: ServiceDetailsFormOptions,
  ) => void;
}

export default memo(ServiceOptionItem);

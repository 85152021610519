import { IsMicroSite } from "App";
import { Routes } from "routing/routes";
import { httpSavingsCore } from "./http";
import { destroySession, getSessionAuthToken } from "./session";

const requestInterceptor = (config: any): any => {
  const authToken = getSessionAuthToken();
  if (authToken) {
    config.headers.Authorization = authToken;
  }
  return config;
};

const requestErrorInterceptor = (error: any): Promise<never> => Promise.reject(error);

const responseIncerceptor = (config: any): any => config;

const responseErrorInterceptor = (error: any): Promise<never> => {
  if (error.response.status === 401 || error.response.status === 403) {
    destroySession();
    window.location.href = IsMicroSite ? (Routes.savingsBase + Routes.summary) : Routes.summary;
  }

  return Promise.reject(error);
};

const registerAuthInterceptor = (): void => {
  httpSavingsCore.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor,
  );
  httpSavingsCore.interceptors.response.use(
    responseIncerceptor,
    responseErrorInterceptor,
  );
};

export default registerAuthInterceptor;

import ServicesPanel from 'components/Summary/ServicesPanel';
import SectionBody from 'core/components/Section';
import { Button } from 'core/components/styled';
import {
  Panel, PanelContainer, PanelContent,
  PanelSubTitle, PanelTitle
} from 'core/components/styled/Panel2';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import {
  getExistingBillingDetails,
  getSitePaymentDetails
} from 'core/helpers/billing';
import { useGetIndexParams } from 'core/helpers/hooks';
import translate from 'core/helpers/translate';
import cart from 'ducks/cart';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import BillingDetailForm from './BillingDetailForm';
import BillingPaymentForm from './BillingPaymentForm';

const BillingDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentFormRef = useRef<any>();
  const billingFormRef = useRef<any>();

  const [isPendingPaymentResponse, setIsPendingPaymentResponse] =
    useState(false);

  const { locationIndex } = useGetIndexParams();
  const locations = cart.selectors.getLocations();

  useEffect(() => {
    dispatch(setLoadingBarProgress(50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Number.isNaN(locationIndex)) {
      navigate(Routes.summary);
    }
    if (!locations || locations.length === 0) {
      navigate(Routes.location);
    }
  }, [locationIndex, locations, navigate]);

  if (Number.isNaN(locationIndex) || !locations || locations.length === 0) {
    return null;
  }

  // Billing Detail list
  const selectedBillingDetailIndex =
    locations[locationIndex].selectedBillingDetail;

  const billingDetailList =
    locations[locationIndex].billingDetailList || [];

  const existingBillingDetails = uniqBy(
    [...billingDetailList, ...getExistingBillingDetails(locations)],
    'billingId',
  );

  // Payment Method list
  const selectedPaymentMethodIndex =
    locations[locationIndex].selectedPaymentMethod;

  const paymentMethodList =
    locations[locationIndex].paymentMethodList || [];

  const sitePaymentDetails = [
    ...paymentMethodList,
    ...getSitePaymentDetails(locations),
  ];

  const submitForms = () => {
    billingFormRef.current.submitBillingForm();
  };

  const doSubmitPayment = () => {
    paymentFormRef.current.submitPaymentForm();
  };

  const subtitle = `${translate('billingPageSubTitleAddingFor')} ${locationIndex + 1
    } ${translate('of')} ${locations.length} ${locations.length > 1 ? translate('locations') : translate('location')
    }`;

  return (
    <PanelContainer>
      <Panel primary width='52%'>
        <PanelContent>
          <SectionBody isLoading={false}>
            <PanelTitle>{translate('billingPageTitle')}</PanelTitle>
            <PanelSubTitle>{subtitle}</PanelSubTitle>
            <BillingDetailForm
              ref={billingFormRef}
              locationIndexNumber={locationIndex}
              existingBillingDetails={existingBillingDetails}
              selectedBillingDetailIndex={selectedBillingDetailIndex}
              doSubmitPayment={doSubmitPayment}
            />
            <BillingPaymentForm
              ref={paymentFormRef}
              locationsNr={locations.length}
              locationIndexNumber={locationIndex}
              existingPaymentDetails={sitePaymentDetails}
              selectedPaymentMethodIndex={selectedPaymentMethodIndex}
              setIsPendingPaymentResponse={setIsPendingPaymentResponse}
            />

            <Button
              center
              block
              isLoading={isPendingPaymentResponse}
              onClick={() => submitForms()}
              type='submit'
              data-automation='NextButton'
            >
              {translate('nextBtnLabel')}
            </Button>
          </SectionBody>
        </PanelContent>
      </Panel>
      <Panel secondary width='48%'>
        <ServicesPanel subtitle={translate('addBillingDetailsSubtitle')} />
      </Panel>
    </PanelContainer>
  );
};
export default BillingDetailsPage;

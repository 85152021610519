enum EquipmentType {
  CART = 'Cart',
  FRONT_LOAD = 'FrontLoad',
  OPEN_TOP = 'OpenTop',
  OPEN_TOP_TEMP = 'OpenTopTemp',
  OPEN_TOP_ON_DEMAND = 'OpenTopOnDemand',
  RECEIVER_BOX = 'ReceiverBoxCompactor',
  SELF_CONTAINED = 'SelfContCompactor',
}
export default EquipmentType;

import { useMemo } from 'react';
import update from 'immutability-helper';
import { isNumber } from 'lodash-es';
import { shallowEqual, useSelector } from 'react-redux';
import { createSelector, OutputParametricSelector } from 'reselect';
import { ActionDispatcher, CartAction } from 'contracts/core/action';
import {
  ApplicationState,
  CartState,
  ReduceFunctionMap,
} from 'contracts/core/state';
import PaymentMethodType from 'contracts/enums/PaymentMethodType';
import { Industries } from 'contracts/models/BusinessTypes';
import { AddressDataView } from 'contracts/models/AddressDataView';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';
import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { SiteDataView } from 'contracts/models/SiteDataView';
import { SubscriptionLocationBillingDataView } from 'contracts/models/SubscriptionLocationBillingDataView';
import {
  getLocationActivationFee,
  getLocationMonthlyFee,
} from 'core/helpers/location';
import { getReducerBuilder } from 'core/reducerBuilder';
import { GLOBAL_RESET } from 'core/reducerBuilder/globalActionTypes';
import {
  destroySession,
  getSessionCart,
  setSessionCart,
} from 'core/services/session';
import { IsMicroSite } from 'App';

// Actions Keys
const ROOT_KEY = 'cart';
enum ActionKey {
  SET_CART_FROM_URL = 'cart/SET_CART_FROM_URL',
  ADDRESS_ADD = 'cart/ADDRESS_ADD',
  DELETE_EMPTY_LOCATIONS = 'cart/DELETE_EMPTY_LOCATIONS',
  LOCATION_EDIT = 'cart/LOCATION_EDIT',
  LOCATION_DELETE = 'cart/LOCATION_DELETE',
  SERVICE_ADD = 'cart/SERVICE_ADD',
  SERVICE_EDIT = 'cart/SERVICE_EDIT',
  SERVICE_DELETE = 'cart/SERVICE_DELETE',
  SET_BUSINESS_TYPES = 'cart/SET_BUSINESS_TYPES',
  SET_SERVICE_DATES = 'cart/SET_SERVICE_DATES',
  SET_SERVICE_AGREEMENT = 'cart/SET_SERVICE_AGREEMENT',
  SET_LOA_AGREEMENT = 'cart/SET_LOA_AGREEMENT',
  SET_TERMS_AGREEMENT = 'cart/SET_TERMS_AGREEMENT',
  SET_BILLING_DETAILS = 'cart/SET_BILLING_DETAILS',
  REMOVE_EXISTING_BILLING_DETAILS = 'cart/REMOVE_EXISTING_BILLING_DETAILS',
  SET_PAYMENT_DETAILS = 'cart/SET_PAYMENT_DETAILS',
  SET_CUSTOMER_DETAILS = 'cart/SET_CUSTOMER_DETAILS',
  SET_PROMO_CODE = 'cart/SET_PROMO_CODE',
  SET_TRACKING_PARAMS = 'cart/SET_TRACKING_PARAMS',
}

// Initial State
const getInitialState: () => CartState = () => {
  return getSessionCart();
};

// Reducer
const reducerKeys = [
  ActionKey.SET_CART_FROM_URL,
  ActionKey.ADDRESS_ADD,
  ActionKey.DELETE_EMPTY_LOCATIONS,
  ActionKey.LOCATION_EDIT,
  ActionKey.LOCATION_DELETE,
  ActionKey.SERVICE_ADD,
  ActionKey.SERVICE_EDIT,
  ActionKey.SERVICE_DELETE,
  ActionKey.SET_BUSINESS_TYPES,
  ActionKey.SET_SERVICE_DATES,
  ActionKey.SET_SERVICE_AGREEMENT,
  ActionKey.SET_LOA_AGREEMENT,
  ActionKey.SET_TERMS_AGREEMENT,
  ActionKey.SET_BILLING_DETAILS,
  ActionKey.REMOVE_EXISTING_BILLING_DETAILS,
  ActionKey.SET_PAYMENT_DETAILS,
  ActionKey.SET_CUSTOMER_DETAILS,
  ActionKey.SET_PROMO_CODE,
  ActionKey.SET_TRACKING_PARAMS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<ReducerKey, CartState, CartAction> =
{
  [ActionKey.SET_CART_FROM_URL]: (state, action) => {
    const { urlCart } = action;
    if (!urlCart) {
      return state;
    }
    const newState = update(state, {
      $set: urlCart,
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.ADDRESS_ADD]: (state, action) => {
    const { address, businessTypeId, businessCode, recognizedAddress, marketConfigToken } = action;
    if (!address) {
      return state;
    }
    const location: SiteDataView = {
      externalUid: null,
      isKnownPayment: false,
      paymentNonce: '',
      paymentMethodType: '',
      paymentAutoPay: false,
      businessTypeId,
      businessCode,
      managementFee: null,
      billingDetail: {} as SubscriptionLocationBillingDataView,
      billingDetailList: [],
      paymentMethodList: [],
      selectedPaymentMethod: '',
      selectedBillingDetail: '',
      services: [],
      address,
      canAddService: true,
      recognizedAddress,
      marketConfigToken
    };
    const newState = update(state, {
      locations: {
        $push: [location],
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.LOCATION_EDIT]: (state, action) => {
    const { indexLocation, location } = action;
    if (
      !isNumber(indexLocation) ||
      state.locations.length <= indexLocation ||
      !location
    ) {
      return state;
    }
    if (!location.services) {
      location.services = [];
    }
    const newState = update(state, {
      locations: {
        [indexLocation]: {
          $set: location,
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.LOCATION_DELETE]: (state, action) => {
    const { indexLocation } = action;
    if (!isNumber(indexLocation) || state.locations.length <= indexLocation) {
      return state;
    }
    const newState = update(state, {
      locations: {
        $splice: [[indexLocation, 1]],
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.DELETE_EMPTY_LOCATIONS]: state => {
    if (state.locations.length > 0) {
      const locations = state.locations.filter(
        location => location.services.length > 0,
      );
      if (locations.length !== state.locations.length) {
        const newState = update(state, {
          locations: { $set: locations },
        });
        if (setSessionCart(newState)) {
          return newState;
        }
      }
    }
    return state;
  },
  [ActionKey.SERVICE_ADD]: (state, action) => {
    const { indexLocation, service } = action;
    if (
      !isNumber(indexLocation) ||
      state.locations.length <= indexLocation ||
      !state.locations[indexLocation].services ||
      !service
    ) {
      return state;
    }
    const newState = update(state, {
      serviceAgreementId: { $set: '' },
      loaAgreementId: { $set: '' },
      rtcAgreementId: { $set: '' },
      locations: {
        [indexLocation]: {
          services: { $push: [service] },
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SERVICE_EDIT]: (state, action) => {
    const { indexLocation, indexService, service } = action;
    if (
      !isNumber(indexLocation) ||
      state.locations.length <= indexLocation ||
      !isNumber(indexService) ||
      !state.locations[indexLocation].services ||
      state.locations[indexLocation].services.length <= indexService ||
      !service
    ) {
      return state;
    }
    const newState = update(state, {
      serviceAgreementId: { $set: '' },
      loaAgreementId: { $set: '' },
      rtcAgreementId: { $set: '' },
      locations: {
        [indexLocation]: {
          services: {
            [indexService]: {
              $merge: {
                ...service,
              },
            },
          },
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SERVICE_DELETE]: (state, action) => {
    const { indexLocation, indexService } = action;
    if (
      !isNumber(indexLocation) ||
      state.locations.length <= indexLocation ||
      !isNumber(indexService) ||
      !state.locations[indexLocation].services ||
      state.locations[indexLocation].services.length <= indexService
    ) {
      return state;
    }
    const newState = update(state, {
      serviceAgreementId: { $set: '' },
      loaAgreementId: { $set: '' },
      rtcAgreementId: { $set: '' },
      locations: {
        [indexLocation]: {
          services: { $splice: [[indexService, 1]] },
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_SERVICE_DATES]: (state, action) => {
    const { service, serviceStartDate, serviceEndDate, serviceDateNotes } =
      action;
    if (!service) {
      return state;
    }
    let indexLocation = -1;
    let indexService = -1;
    for (let indexL = 0; indexL < state.locations.length; indexL++) {
      indexLocation = indexL;
      indexService = state.locations[indexL].services.indexOf(service);
      if (indexService >= 0) {
        break;
      }
    }
    if (indexService >= 0) {
      const newState = update(state, {
        serviceAgreementId: { $set: '' },
        loaAgreementId: { $set: '' },
        rtcAgreementId: { $set: '' },
        locations: {
          [indexLocation]: {
            services: {
              [indexService]: {
                $merge: {
                  startDate: serviceStartDate,
                  endDate: serviceEndDate,
                  notes: serviceDateNotes,
                },
              },
            },
          },
        },
      });
      if (setSessionCart(newState)) {
        return newState;
      }
    }
    return state;
  },
  [ActionKey.SET_BUSINESS_TYPES]: (state, action) => {
    const { industries } = action;

    const newState = update(state, {
      industries: { $set: industries || [] },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_SERVICE_AGREEMENT]: (state, action) => {
    const { serviceAgreementId } = action;

    const newState = update(state, {
      serviceAgreementId: { $set: serviceAgreementId || '' },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_LOA_AGREEMENT]: (state, action) => {
    const { loaAgreementId } = action;

    const newState = update(state, {
      loaAgreementId: { $set: loaAgreementId || '' },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_TERMS_AGREEMENT]: (state, action) => {
    const { rtcAgreementId } = action;

    const newState = update(state, {
      rtcAgreementId: { $set: rtcAgreementId || '' },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_BILLING_DETAILS]: (state, action) => {
    const { indexLocation, billingDetails } = action;
    if (!isNumber(indexLocation) || state.locations.length <= indexLocation) {
      return state;
    }

    const bdList = state.locations[indexLocation].billingDetailList;
    const billingDetailIndex = bdList
      ? bdList.findIndex(bd => bd.billingId === billingDetails.billingId)
      : -1;

    const newState = update(state, {
      locations: {
        [indexLocation]: {
          billingDetail: { $set: billingDetails },
          selectedBillingDetail: {
            $set: `${billingDetailIndex > -1 ? billingDetailIndex : bdList?.length
              }`,
          },
          ...(typeof billingDetailIndex === 'number' &&
            billingDetailIndex > -1 && {
            billingDetailList: {
              [billingDetailIndex]: { $set: billingDetails },
            },
          }),
          ...(billingDetailIndex === -1 && {
            billingDetailList: { $push: [billingDetails] },
          }),
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.REMOVE_EXISTING_BILLING_DETAILS]: (state, action) => {
    const { indexLocation } = action;

    if (!isNumber(indexLocation) || state.locations.length <= indexLocation) {
      return state;
    }

    const newState = update(state, {
      locations: {
        [indexLocation]: {
          $merge: {
            existingBillingDetail: undefined,
          },
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_PAYMENT_DETAILS]: (state, action) => {
    const { indexLocation, paymentDetails } = action;
    if (!isNumber(indexLocation) || state.locations.length <= indexLocation) {
      return state;
    }

    const pmList = state.locations[indexLocation].paymentMethodList;
    const paymentMethodIndex = pmList
      ? pmList.findIndex(
        pm => pm.paymentNonce === paymentDetails.paymentNonce,
      )
      : -1;

    const paymentDetail = {
      isKnownPayment: paymentDetails.isKnownPayment,
      paymentNonce: paymentDetails.paymentNonce,
      paymentMethodType: paymentDetails.paymentMethodType,
      paymentAutoPay: paymentDetails.paymentAutoPay,
      paymentMethodLabel: paymentDetails.paymentMethodLabel,
    };

    const newState = update(state, {
      locations: {
        [indexLocation]: {
          $merge: { ...paymentDetail },
          selectedPaymentMethod: {
            $set: `${paymentMethodIndex > -1 ? paymentMethodIndex : pmList?.length
              }`,
          },
          ...(paymentMethodIndex === -1 && {
            paymentMethodList: { $push: [paymentDetail] },
          }),
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_CUSTOMER_DETAILS]: (state, action) => {
    const { customerDetails } = action;

    const newState = update(state, {
      customer: {
        $merge: {
          firstName: customerDetails.firstName,
          lastName: customerDetails.lastName,
          email: customerDetails.email,
          phone: customerDetails.phone,
        },
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_PROMO_CODE]: (state, action) => {
    const { promoCode } = action;

    const newState = update(state, {
      promoCode: { $set: promoCode || '' },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.SET_TRACKING_PARAMS]: (state, action) => {
    const { externalUid, trackingSignature, trackingLocations } = action;

    let newState = update(state, {
      externalUid: { $set: externalUid || '' },
      trackingSignature: { $set: trackingSignature || '' },
      serviceAgreementId: { $set: '' },
      loaAgreementId: { $set: '' },
      rtcAgreementId: { $set: '' },
    });
    if (trackingLocations && trackingLocations.length) {
      for (let indexLocation = 0; indexLocation < trackingLocations.length; indexLocation++) {
        const location = trackingLocations[indexLocation];
        if (location.externalUid) {
          newState = update(newState, {
            locations: {
              [indexLocation]: {
                $merge: {
                  externalUid: location.externalUid
                },
              },
            },
          });
        }
        if (location.services && location.services.length) {
          for (let indexService = 0; indexService < location.services.length; indexService++) {
            const service = location.services[indexService];
            if (service.externUid) {
              newState = update(newState, {
                locations: {
                  [indexLocation]: {
                    services: {
                      [indexService]: {
                        $merge: {
                          externUid: service.externUid,
                        },
                      },
                    },
                  },
                },
              });
            }
          }
        }
      }
    }

    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  }
};

export const reducer = getReducerBuilder<CartState, CartAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(GLOBAL_RESET)
  .buildReducer();

// Selectors
interface LocationIndexSelectorProps {
  indexLocation: number;
}
interface ServiceIndexSelectorProps extends LocationIndexSelectorProps {
  indexService: number;
}
interface LocationSelectorProps {
  location?: SiteDataView;
}
interface ServiceSelectorProps {
  service?: ServiceDataView;
}

const getCustomerDetails = (state: ApplicationState) => {
  return state.cart.customer;
}

const hasCustomerDetails = (state: ApplicationState) =>
  state.cart.customer && state.cart.customer.email &&
    state.cart.customer.firstName && state.cart.customer.lastName &&
    (IsMicroSite || state.cart.customer.phone)
    ? state.cart.customer
    : undefined

const getLocations = (state: ApplicationState) => state.cart.locations;

const getLocation = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, locationSelectorProps: LocationIndexSelectorProps) =>
      locationSelectorProps,
    (locations, locationSelectorProps) => {
      if (locations.length > locationSelectorProps.indexLocation) {
        return locations[locationSelectorProps.indexLocation];
      }
    },
  );

const getNextLocation = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, locationSelectorProps: LocationSelectorProps) =>
      locationSelectorProps,
    (locations, locationSelectorProps) => {
      if (locationSelectorProps.location && locations.length > 0) {
        const index = locations.indexOf(locationSelectorProps.location) + 1;
        if (index < locations.length - 1) {
          return locations[index];
        }
        return locations[0];
      }
    },
  );

const getLocationServices = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, locationSelectorProps: LocationIndexSelectorProps) =>
      locationSelectorProps,
    (locations, locationSelectorProps) => {
      if (locations.length > locationSelectorProps.indexLocation) {
        return locations[locationSelectorProps.indexLocation].services;
      }
      return [];
    },
  );

const getLocationActivationCost = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, locationSelectorProps: LocationIndexSelectorProps) =>
      locationSelectorProps,
    (locations, locationSelectorProps) => {
      let cost = 0;
      if (locations.length > locationSelectorProps.indexLocation) {
        const location = locations[locationSelectorProps.indexLocation];
        cost = getLocationActivationFee(location);
      }
      return cost;
    },
  );

const getAllLocationsActivationCost = (state: ApplicationState) => {
  let cost = 0;
  state.cart.locations.forEach(location => {
    cost += getLocationActivationFee(location);
  });
  return cost;
};

const getLocationMonthlyCost = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, locationSelectorProps: LocationIndexSelectorProps) =>
      locationSelectorProps,
    (locations, locationSelectorProps) => {
      let cost = 0;
      if (locations.length > locationSelectorProps.indexLocation) {
        const location = locations[locationSelectorProps.indexLocation];
        cost = getLocationMonthlyFee(location);
      }
      return cost;
    },
  );

const getAllLocationsMonthlyCost = (state: ApplicationState) => {
  let cost = 0;
  state.cart.locations.forEach(location => {
    cost += getLocationMonthlyFee(location);
  });
  return cost;
};

const getAllLocationsManagementFee = (state: ApplicationState) => {
  let cost = 0;
  state.cart.locations.forEach(location => {
    if (location.managementFee) {
      cost += location.managementFee;
    }
  });
  return cost;
};

const getAllServices = (state: ApplicationState) => {
  const services: ServiceDataView[] = [];
  state.cart.locations.forEach(location => {
    location.services.forEach(service => {
      services.push(service);
    });
  });
  return services;
};

const getBusinessTypes = (state: ApplicationState) => {
  return state.cart.industries;
};

const getshoppingCartId = (state: ApplicationState) => {
  return state.cart.shoppingCartId;
};

const getService = () =>
  createSelector(
    getLocations,
    (_: ApplicationState, serviceSelectorProps: ServiceIndexSelectorProps) =>
      serviceSelectorProps,
    (locations, serviceSelectorProps) => {
      if (
        locations.length > serviceSelectorProps.indexLocation &&
        locations[serviceSelectorProps.indexLocation].services.length >
        serviceSelectorProps.indexService
      ) {
        return locations[serviceSelectorProps.indexLocation].services[
          serviceSelectorProps.indexService
        ];
      }
    },
  );

const getNextService = () =>
  createSelector(
    getAllServices,
    (_: ApplicationState, serviceSelectorProps: ServiceSelectorProps) =>
      serviceSelectorProps,
    (services, serviceSelectorProps) => {
      if (serviceSelectorProps.service && services.length > 0) {
        const index = services.indexOf(serviceSelectorProps.service) + 1;
        if (index < services.length - 1) {
          return services[index];
        }
        return services[0];
      }
    },
  );

// Flags
const countLocationsWithPaymentMethod = (state: ApplicationState) => {
  const locations = getLocations(state);
  return locations.filter(
    l =>
      !!l.paymentNonce ||
      l.isKnownPayment ||
      l.paymentMethodType === PaymentMethodType.check,
  ).length;
};

const countLocationsWithCheckPaymentMethod = (state: ApplicationState) => {
  const locations = getLocations(state);
  return locations.filter(l => l.paymentMethodType === PaymentMethodType.check)
    .length;
};

const hasAnyLocationWithCheckPaymentMethod = (state: ApplicationState) => {
  return countLocationsWithCheckPaymentMethod(state) > 0;
};

const hasAnyLocationWithRecognizedAddress = (state: ApplicationState) => {
  const locations = getLocations(state);
  return locations.filter(l => l.recognizedAddress === true).length > 0;
};

const haveAllLocationsPaymentMethod = (state: ApplicationState) => {
  const locations = getLocations(state);
  return (
    !!locations.length &&
    locations.length === countLocationsWithPaymentMethod(state)
  );
};

const countServicesWithSetDates = (state: ApplicationState) => {
  const services = getAllServices(state);
  return services.filter(
    s =>
      (!s.editStartDate && !s.editEndDate) ||
      (s.editStartDate && !!s.startDate) ||
      (s.editEndDate && !!s.endDate),
  ).length;
};

const countLocationsWithNotAddService = (state: ApplicationState) => {
  const locations = getLocations(state);
  return locations.filter(
    l =>
      (!l.canAddService),
  ).length;
};

const countServicesWithAdvancedProgram = (state: ApplicationState) => {
  const services = getAllServices(state);
  return services.filter(s => s.isAdvancedProgram === true).length;
};

const countServicesWithRecognition = (state: ApplicationState) => {
  const services = getAllServices(state);
  return services.filter(s => s.isRecognition === true).length;
};

const haveAllServicesSetDates = (state: ApplicationState) => {
  const services = getAllServices(state);
  return (
    !!services.length && services.length === countServicesWithSetDates(state)
  );
};

const canAllLocationsNotAddServices = (state: ApplicationState) => {
  const locations = getLocations(state);
  return (
    !!locations.length && locations.length === countLocationsWithNotAddService(state)
  );
};


const areAgreementsSigned = (state: ApplicationState) => {
  const hasRecognitionAndSigned = isRecognition(state)
    ? !!state.cart.rtcAgreementId
    : true;
  return (
    !!state.cart.loaAgreementId &&
    !!state.cart.serviceAgreementId &&
    hasRecognitionAndSigned
  );
};

const isSignWithCustomer = (state: ApplicationState) => {
  return state.cart.signWithCustomer;
};

const isAdvancedProgram = (state: ApplicationState) => {
  const services = getAllServices(state);
  return !!services.length && countServicesWithAdvancedProgram(state) > 0;
};

const isRecognition = (state: ApplicationState) => {
  const services = getAllServices(state);
  return !!services.length && countServicesWithRecognition(state) > 0;
};

const readonlyCheckoutEmail = (state: ApplicationState) => {
  return state.cart.readonlyCheckoutEmail;
};

// Selector helpers
function GetSelector<DataType>(
  selector: (state: ApplicationState) => DataType,
): DataType {
  const data = useSelector((state: ApplicationState) => selector(state));
  return data;
}

function GetShallowSelector<DataType>(
  selector: (state: ApplicationState) => DataType,
): DataType {
  const data = useSelector(
    (state: ApplicationState) => selector(state),
    shallowEqual,
  );
  return data;
}

function GetParametricSelector<DataType, PropsType, Res1Type = SiteDataView[]>(
  selector: () => OutputParametricSelector<
    ApplicationState,
    PropsType,
    DataType,
    (res1: Res1Type, res2: PropsType) => DataType
  >,
  props: PropsType,
): DataType {
  const memoizedSelector = useMemo(selector, [selector]);
  const data = useSelector((state: ApplicationState) =>
    memoizedSelector(state, props),
  );
  return data;
}

const selectors = {
  getCustomerDetails: () => GetShallowSelector(getCustomerDetails),
  getLocations: () => GetShallowSelector(getLocations),
  getLocation: (indexLocation: number) =>
    GetParametricSelector(getLocation, { indexLocation }),
  getNextLocation: (location?: SiteDataView) =>
    GetParametricSelector(getNextLocation, { location }),
  getLocationServices: (indexLocation: number) =>
    GetParametricSelector(getLocationServices, { indexLocation }),
  getAllServices: () => GetShallowSelector(getAllServices),
  getBusinessTypes: () => GetShallowSelector(getBusinessTypes),
  getService: (indexLocation: number, indexService: number) =>
    GetParametricSelector(getService, { indexLocation, indexService }),
  getNextService: (service?: ServiceDataView) =>
    GetParametricSelector(getNextService, { service }),
  getshoppingCartId: () => GetShallowSelector(getshoppingCartId),
};

const flags = {
  getLocationActivationCost: (indexLocation: number) =>
    GetParametricSelector(getLocationActivationCost, { indexLocation }),
  getAllLocationsActivationCost: () =>
    GetSelector(getAllLocationsActivationCost),
  getLocationMonthlyCost: (indexLocation: number) =>
    GetParametricSelector(getLocationMonthlyCost, { indexLocation }),
  getAllLocationsMonthlyCost: () => GetSelector(getAllLocationsMonthlyCost),
  getAllLocationsManagementFee: () => GetSelector(getAllLocationsManagementFee),
  countLocationsWithPaymentMethod: () =>
    GetSelector(countLocationsWithPaymentMethod),
  countLocationsWithCheckPaymentMethod: () =>
    GetSelector(countLocationsWithCheckPaymentMethod),
  hasAnyLocationWithCheckPaymentMethod: () =>
    GetSelector(hasAnyLocationWithCheckPaymentMethod),
  hasAnyLocationWithRecognizedAddress: () =>
    GetSelector(hasAnyLocationWithRecognizedAddress),
  haveAllLocationsPaymentMethod: () =>
    GetSelector(haveAllLocationsPaymentMethod),
  countServicesWithSetDates: () => GetSelector(countServicesWithSetDates),
  haveAllServicesSetDates: () => GetSelector(haveAllServicesSetDates),
  areAgreementsSigned: () => GetSelector(areAgreementsSigned),
  isSignWithCustomer: () => GetSelector(isSignWithCustomer),
  isAdvancedProgram: () => GetSelector(isAdvancedProgram),
  isRecognition: () => GetSelector(isRecognition),
  isReadonlyCheckoutEmail: () => GetSelector(readonlyCheckoutEmail),
  hasCustomerDetails: () => GetSelector(hasCustomerDetails),
  canAllLocationsNotAddServices: () => GetSelector(canAllLocationsNotAddServices),
};
// Actions
type CartActionDispatcher = ActionDispatcher<CartAction>;

const actions = {
  setCartFromUrl:
    (urlCart: SaleCartDataView) => (dispatch: CartActionDispatcher) => {
      dispatch({ type: ActionKey.SET_CART_FROM_URL, urlCart });
    }, 
  addAddress:
    (address: AddressDataView, businessTypeId: number, businessCode?: string, recognizedAddress?: boolean, marketConfigToken?: string) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({
          type: ActionKey.ADDRESS_ADD,
          address,
          businessTypeId,
          businessCode,
          recognizedAddress,
          marketConfigToken
        });
      },
  editLocation:
    (indexLocation: number, location: SiteDataView) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({ type: ActionKey.LOCATION_EDIT, indexLocation, location });
      },
  deleteLocation:
    (indexLocation: number) => (dispatch: CartActionDispatcher) => {
      dispatch({ type: ActionKey.LOCATION_DELETE, indexLocation });
    },
  deleteEmptyLocation: () => (dispatch: CartActionDispatcher) => {
    dispatch({ type: ActionKey.DELETE_EMPTY_LOCATIONS });
  },
  addService:
    (indexLocation: number, service: ServiceDataView) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({ type: ActionKey.SERVICE_ADD, indexLocation, service });
      },
  editService:
    (indexLocation: number, indexService: number, service: ServiceDataView) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({
          type: ActionKey.SERVICE_EDIT,
          indexLocation,
          indexService,
          service,
        });
      },
  deleteService:
    (indexLocation: number, indexService: number) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({ type: ActionKey.SERVICE_DELETE, indexLocation, indexService });
      },
  setServiceDates:
    (
      service: ServiceDataView,
      serviceStartDate?: string,
      serviceEndDate?: string | null,
      serviceDateNotes?: string,
    ) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({
          type: ActionKey.SET_SERVICE_DATES,
          service,
          serviceStartDate,
          serviceEndDate,
          serviceDateNotes,
        });
      },
  setBusinessTypes:
    (industries: Industries[]) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.SET_BUSINESS_TYPES,
        industries,
      });
    },
  setServiceAgreement:
    (serviceAgreementId: string) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.SET_SERVICE_AGREEMENT,
        serviceAgreementId,
      });
    },
  setLOAAgreement:
    (loaAgreementId: string) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.SET_LOA_AGREEMENT,
        loaAgreementId,
      });
    },
  setTermsAgreement:
    (rtcAgreementId: string) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.SET_TERMS_AGREEMENT,
        rtcAgreementId,
      });
    },
  setBillingDetails:
    (indexLocation: number, billingDetails: any) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({
          type: ActionKey.SET_BILLING_DETAILS,
          indexLocation,
          billingDetails,
        });
      },
  removeExistingBillingDetails:
    (indexLocation: number) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.REMOVE_EXISTING_BILLING_DETAILS,
        indexLocation,
      });
    },
  setPaymentDetails:
    (indexLocation: number, paymentDetails: any) =>
      (dispatch: CartActionDispatcher) => {
        dispatch({
          type: ActionKey.SET_PAYMENT_DETAILS,
          indexLocation,
          paymentDetails,
        });
      },

  setCustomerDetails:
    (customerDetails: any) => (dispatch: CartActionDispatcher) => {
      dispatch({
        type: ActionKey.SET_CUSTOMER_DETAILS,
        customerDetails,
      });
    },

  setPromoCode: (promoCode: string) => (dispatch: CartActionDispatcher) => {
    dispatch({
      type: ActionKey.SET_PROMO_CODE,
      promoCode,
    });
  },

  setTrackingParams: (externalUid: string, trackingSignature: string, trackingLocations: SiteDataView[]) => (dispatch: CartActionDispatcher) => {
    dispatch({
      type: ActionKey.SET_TRACKING_PARAMS,
      externalUid,
      trackingSignature,
      trackingLocations
    });
  },

  reset: () => (dispatch: CartActionDispatcher) => {
    destroySession();
    dispatch({ type: GLOBAL_RESET });
  },
};

const cart = {
  selectors,
  flags,
  actions,
};

export default cart;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import memoizeOne from 'memoize-one';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import GOOGLE_MAP_API_KEY from 'core/constants/GoogleMapApiKey';
import ClusterMarker from './markers/ClusterMarker';
import SimpleMarker from './markers/SimpleMarker';
import {
  MapInfoWindow,
  MapInfoClose,
  MapLoadingOverlay,
  MapLoadingCircle,
  MapLoadingContent,
} from './styled/MapInfoWindow';
import { ClusterHookProps, ClusterMapLocation, ClusterMarkerType, ClusterPointsProps, GMapProps } from 'contracts/models/ClusterMap';

const initialPlaceCoords = [
  { lat: 56.846108152602426, lng: -134.7806640625 },
  { lat: 16.462672723808936, lng: -61.91933593749998 },
];


// Return map bounds based on list of places
const getMapBounds = (_: any, maps: any, places: any) => {
  const bounds = new maps.LatLngBounds();
  const locationsCoords = places.length > 0 ? places : initialPlaceCoords;

  locationsCoords.forEach((place: ClusterMapLocation) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (
  map: any,
  maps: any,
  bounds: google.maps.LatLngBounds,
): void => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit bounds to available markers
const fitBounds = (markers: ClusterMarkerType[], googleMap: any): void => {
  const bounds = getMapBounds(googleMap.map, googleMap.maps, markers);
  googleMap.map.fitBounds(bounds);
  bindResizeListener(googleMap.map, googleMap.maps, bounds);
};

// Show info window or Zoom into cluster
const onChildClickEvent = (
  hoverKey: string,
  { id, points }: { id: string; points: ClusterMarkerType[] },
  clickEnabled: boolean,
  onChildClick: any,
  getClusterPoints: any,
  clickedMarkerId?: string,
) => {
  if (clickEnabled && id) {
    onChildClick(hoverKey, id);
  }
  if (id === clickedMarkerId) {
    onChildClick(null, null);
  }
  if (!id) {
    onChildClick(null, null);
    getClusterPoints(points);
  }
};

const getMarker = (markers: ClusterMarkerType[], markerProps: ClusterMapLocation) =>
  markers.find(
    (marker: ClusterMarkerType) =>
      marker.lat === markerProps.lat && marker.lng === markerProps.lng,
  );
const memoizedMarker = memoizeOne(getMarker);

const infoWindowContent = (
  markers: ClusterMarkerType[],
  markerProps: ClusterMapLocation,
  clickedMarkerId: string,
  {
    setOpenedMarkerId,
    openedMarkerId,
    onChildClick,
    InfoWindowContent,
    googleMap,
  }: any,
) => {
  if (openedMarkerId !== clickedMarkerId) {
    googleMap.map.panTo({ lat: markerProps.lat, lng: markerProps.lng });
    setOpenedMarkerId(clickedMarkerId);
  }

  const marker = memoizedMarker(markers, markerProps);

  return (
    <MapInfoWindow>
      <InfoWindowContent marker={marker} />
      <MapInfoClose onClick={() => onChildClick(null, null)}>X</MapInfoClose>
    </MapInfoWindow>
  );
};

const renderMarkersAndClusters = (
  clusters: ClusterPointsProps[],
  markers: ClusterMarkerType[],
  clickedMarkerId: string,
  infoWindowObject: any,
  hideZeroCoords: boolean,
): Array<JSX.Element | null> => {
  const getIsChecked = (markers: ClusterMarkerType[], markerProps: ClusterMapLocation): boolean => {
    const memmodMarker = memoizedMarker(markers, markerProps);
    return Boolean(memmodMarker && memmodMarker.isChecked);
  };

  return clusters.map(({ numPoints, clusterId, ...markerProps }) => {
    if (hideZeroCoords && !(markerProps.lng && markerProps.lat)) {
      return null;
    }

    return numPoints === 1 ? (
      <SimpleMarker
        id={clusterId}
        key={clusterId}
        {...markerProps}
        isChecked={getIsChecked(markers, markerProps)}
      >
        {clickedMarkerId === `${markerProps.lng}:${markerProps.lat}` &&
          infoWindowContent(
            markers,
            markerProps,
            clickedMarkerId,
            infoWindowObject,
          )}
      </SimpleMarker>
    ) : (
      <ClusterMarker key={clusterId} {...markerProps} />
    );
  });
};

const memoizedMarkersAndClusters = memoizeOne(renderMarkersAndClusters);

const ClusterMap: React.FC<GMapProps> = ({
  style,
  hoverDistance = 30,
  clickEnabled = false,
  height = '600px',
  options = { minZoom: 3, maxZoom: 15 },
  InfoWindowContent,
  legendItems,
  isLoadingLocations = false,
  onSearchBounds = 0,
  hideZeroCoords = false,
  initialZoomSetting = undefined,
  markersList,
  clusterRadius,
  initialZoom,
  getBounds,
  onMarkerClick,
}) => {
  const [didMount, setDidMount] = useState(true);
  const [googleMap, setMap] = useState<any | undefined>(undefined);
  const [selectedCluster, getClusterPoints] = useState(null);
  const [openedMarkerId, setOpenedMarkerId] = useState(null);
  const [prevSearchBounds, setPrevSearchBounds] = useState<number>(0);

  const [
    mapProps,
    onChange,
    onChildClick,
    clickedMarkerId,
    clusters,
  ] = useClusters({
    clusterRadius,
    initialZoom,
    options,
    getBounds,
    onMarkerClick,
    markersList,
  });

  const { center, zoom } = mapProps;

  const infoWindowObject = {
    openedMarkerId,
    setOpenedMarkerId,
    googleMap,
    onChildClick,
    InfoWindowContent,
  };

  useEffect(() => {
    const fitBoundsCondition = googleMap && !isLoadingLocations;

    if (didMount && fitBoundsCondition) {
      fitBounds(markersList, googleMap);
      setDidMount(false);
    }

    if (prevSearchBounds !== onSearchBounds && fitBoundsCondition) {
      fitBounds(markersList, googleMap);
    }

    setPrevSearchBounds(onSearchBounds);
  }, [
    onSearchBounds,
    prevSearchBounds,
    isLoadingLocations,
    googleMap,
    markersList,
    didMount,
  ]);

  useEffect(() => {
    if (selectedCluster !== null) {
      fitBounds(selectedCluster, googleMap);
    }
  }, [googleMap, selectedCluster]);

  const compStyles = style || {
    position: 'relative',
    margin: 0,
    padding: 0,
    flex: 1,
  };

  return (
    <div style={{ position: 'relative' }}>
      <GoogleMapReact
        style={{ height, ...compStyles }}
        options={options}
        hoverDistance={hoverDistance}
        center={center}
        zoom={initialZoomSetting || zoom}
        resetBoundsOnResize
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
        onChange={onChange}
        onChildClick={(hoverKey, props) =>
          onChildClickEvent(
            hoverKey,
            props,
            clickEnabled,
            onChildClick,
            getClusterPoints,
            clickedMarkerId,
          )
        }
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap({ map, maps });
        }}
      >
        <MapLoadingOverlay isLoadingLocations={isLoadingLocations}>
          <MapLoadingContent>
            Loading map locations...
            <MapLoadingCircle />
          </MapLoadingContent>
        </MapLoadingOverlay>
        {memoizedMarkersAndClusters(
          clusters,
          markersList,
          clickedMarkerId,
          infoWindowObject,
          hideZeroCoords,
        )}
      </GoogleMapReact>
    </div>
  );
};


export const useClusters = ({
  clusterRadius = 60,
  initialZoom = 5,
  options,
  getBounds = () => { },
  onMarkerClick = () => { },
  markersList,
}: ClusterHookProps): any[] => {
  const [clickedMarkerId, setClickedMarkerId] = useState('');
  const [clusters, setClusters] = useState<ClusterPointsProps[]>([]);
  const [mapProps, setMapProps] = useState<any>({
    center: { lat: 39.5, lng: -98.35 },
    zoom: initialZoom,
  });

  const onChange = ({ center, zoom, bounds }: any): void => {
    setMapProps({ center, zoom, bounds });
  };

  const onChildClick = (id: string): void => {
    setClickedMarkerId(id);
    onMarkerClick(id);
  };

  const getCluster = supercluster(markersList, {
    minZoom: options.minZoom, // min zoom to generate clusters on
    maxZoom: options.maxZoom - 1, // max zoom level to cluster the points on
    radius: clusterRadius, // cluster radius in pixels
  });

  useEffect(() => {
    if (mapProps.bounds) {
      setClusters(getCluster(mapProps).map(
        ({ wx, wy, points, numPoints }: ClusterProps) => ({
          lat: wy,
          lng: wx,
          text: numPoints,
          numPoints,
          points,
          clusterId: `${wx}:${wy}`,
        }),
      ));
    } else {
      setClusters([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapProps]);

  // get map bounds to filter location list
  useEffect(() => {
    getBounds(mapProps.bounds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapProps]);

  return [
    mapProps,
    onChange,
    onChildClick,
    clickedMarkerId,
    clusters,
    markersList,
  ];
};



interface ClusterProps {
  wx: number;
  wy: number;
  points: any[];
  numPoints: any[];
}

export default ClusterMap;
import { AxiosError } from 'axios';
import { ServiceError } from 'contracts/core/service';

export default function formatServiceError(
  error: AxiosError<ServiceError>,
): string {
  let message: string =
    'An error occurred loading this page. We are working to address this error as soon as possible.';
  if (error && error.response) {
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
      if (message === '{"Message":"An error has occurred."}') {
        message = 'An error has occured, please try again!';
      }
    } else if (error.response.status === 401) {
      message = 'You are not authorized to view this page.';
    }
  }
  return message;
}

import theme from 'core/styles/theme';
import { ImgHTMLAttributes } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const IconLinkStyle = (props: IconLinkProps) => `
  font-size: 25px;
  line-height: 25px;
  color: ${props.color || theme.colors.brandPrimary};
`;

interface OwnIconLinkProps {
  right?: boolean;
  small?: boolean;
  color?: string;
}
export type IconLinkProps = OwnIconLinkProps &
  ImgHTMLAttributes<HTMLImageElement> &
  LinkProps;
const IconLink = styled(NavLink)<IconLinkProps>`
  ${IconLinkStyle};

  ${props =>
    props.right &&
    css`
      float: right;
    `};

  ${props =>
    props.small &&
    css`
      font-size: 17px;
      line-height: 17px;
    `};
`;

export default IconLink;

import { Panel } from 'core/components/styled/Panel2';
import React from 'react';
import {
  LoadingSectionContainer,
  LoadingSectionContent,
} from './styled/LoadingSection';

const defaultText = 'Loading Data';
const LoadText: React.FC<LoadTextProps> = ({ loadText }) => (
  <div style={{ marginTop: 10 }}>{loadText}</div>
);

interface LoadTextProps {
  loadText: string;
}

const LoadingSection: React.FC<LoadingSectionProps> = ({
  isLoading = false,
  autoHeight,
  children,
  loadText = defaultText,
  style,
  transparent,
}) =>
  isLoading ? (
    <Panel autoHeight={autoHeight}>
      <LoadingSectionContainer>
        <LoadingSectionContent />
        <LoadText loadText={loadText} />
      </LoadingSectionContainer>
    </Panel>
  ) : (
    <>{children}</>
  );

interface LoadingSectionProps {
  isLoading: boolean;
  autoHeight?: boolean;
  embedded?: boolean;
  loadText?: string;
  style?: React.CSSProperties;
  transparent?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
}

export default LoadingSection;

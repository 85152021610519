import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroySession } from 'core/services/session';
import { ApplicationState } from 'contracts/core/state';
import { Routes } from 'routing/routes';
import recognizedDuck from 'ducks/recognized';
import translate from 'core/helpers/translate';

import { Button } from 'core/components/styled';
import {
  AcknowledgementContainer,
  RubiconHorizontalLogo,
  AcknowledgementMessageContainer,
  AcknowledgementMessage,
  ConfirmationButton,
} from './styled';
import { useNavigate } from 'react-router';

const RecognizedAcknowledgementPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitOrderResponse = useSelector(
    (state: ApplicationState) => state.recognized.submitOrder,
  );

  const hideNavigationBar = (hideNavigation: boolean) => {
    dispatch(recognizedDuck.thunks.hideNavigationBar(hideNavigation));
  };

  const onClick = () => {
    window.location.href = submitOrderResponse?.url as string;
    destroySession();
  }

  useEffect(() => {
    hideNavigationBar(true);
    if (!submitOrderResponse) {
      navigate(Routes.location);
    }
    return () => {
      hideNavigationBar(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AcknowledgementContainer>
      <RubiconHorizontalLogo />
      <AcknowledgementMessageContainer>
        <AcknowledgementMessage>
          {translate('recognizedOrderPlacedSuccessfull')}
        </AcknowledgementMessage>
      </AcknowledgementMessageContainer>
      <ConfirmationButton>
        <Button onClick={() => onClick()}>{translate('recognizedContinueToConnectButton')}</Button>
      </ConfirmationButton>
    </AcknowledgementContainer>
  );
};

export default RecognizedAcknowledgementPage;

import React, { useEffect, useState } from 'react';
import { PanelContent, PanelTitle } from 'core/components/styled/Panel2';
import translate from 'core/helpers/translate';
import ContactInformationForm from './ContactInformationForm';
import { ContactInformationFormData } from 'contracts/core/form';
import { getSessionCart } from 'core/services/session';
import cart from 'ducks/cart';
import { useDispatch } from 'react-redux';
import {
  contactInformationTracking
} from 'services/contactInformation';
import { Routes } from 'routing/routes';
import { useNavigate } from 'react-router';
import queryString from 'query-string';

const ContactInformationPanel: React.FC<ComponentProps> = ({
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNr,
  defaultValues
}) => {
  const urlValues = queryString.parse(window.location.search);
  const { address, zipCode } = urlValues;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValues && typeof address === 'string' && typeof zipCode === 'string') {
      setIsLoading(true)
      dispatch(cart.actions.setCustomerDetails(defaultValues));
      const cartObj = getSessionCart();
      try {
        contactInformationTracking({
          ...cartObj,
        }).then((trackingResponse) => {
          dispatch(cart.actions.setTrackingParams(
            trackingResponse.externalUid || '',
            trackingResponse.trackingSignature || '',
            trackingResponse.locations || []
          ));
        }).catch(() => { });
        setIsLoading(false)

        navigate(Routes.location, {
          state: address
        });
      } catch (err) {
        console.log(err);
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitDone = async (formData: ContactInformationFormData) => {
    setIsLoading(true)
    dispatch(cart.actions.setCustomerDetails(formData));
    const cartObj = getSessionCart();
    try {
      const trackingResponse = await contactInformationTracking({
        ...cartObj,
      });
      dispatch(cart.actions.setTrackingParams(
        trackingResponse.externalUid || '',
        trackingResponse.trackingSignature || '',
        trackingResponse.locations || []
      ));
      setIsLoading(false)
      navigate(Routes.location);
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  }

  return (
    <PanelContent isLoading={false}>
      <PanelTitle>{translate('contactInformationPageTitle')}</PanelTitle>
      <ContactInformationForm
        onSubmitDone={onSubmitDone}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPhoneNr={setPhoneNr}
        isLoading={isLoading}
        defaultValues={defaultValues}
      />
    </PanelContent>
  );
};

interface ComponentProps {
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhoneNr: (value: string) => void;
  defaultValues?: ContactInformationFormData;
}

export default ContactInformationPanel;

import update from 'immutability-helper';
import {
  ApplicationState,
  BillingState,
  ReduceFunctionMap,
} from 'contracts/core/state';
import { getReducerBuilder } from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getPaymentFormData } from '../services/zuoraServices';
import { ActionDispatcher, BillingAction } from 'contracts/core/action';
import { getBillingOptions } from 'services/checkout';
import PaymentMethodType from 'contracts/enums/PaymentMethodType';
import { ZuoraHostePageResponseDataView } from 'contracts/models/Zuora';

// Actions Keys
const ROOT_KEY = 'billing';
enum ActionKey {
  ALLOW_CHECK_PAYMENT = 'billing/ALLOW_CHECK_PAYMENT',
  UPDATE_AUTOPAY_SETTINGS = 'billing/UPDATE_AUTOPAY_SETTINGS',
  GET_ACH_FORM_DATA = 'billing/GET_ACH_FORM_DATA',
  GET_CC_FORM_DATA = 'billing/GET_CC_FORM_DATA',
  RESET = 'billing/RESET',
}

// Initial state
const getInitialState: () => BillingState = () => {
  return {
    allowCheckPayment: false,
    zuoraACHFormData: undefined,
    zuoraCCFormData: undefined,
    isNewCreditCardAutopayEditable: false,
    newCreditCardAutopayDefaultValue: true,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.ALLOW_CHECK_PAYMENT,
  ActionKey.UPDATE_AUTOPAY_SETTINGS,
  ActionKey.GET_ACH_FORM_DATA,
  ActionKey.GET_CC_FORM_DATA,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  BillingState,
  BillingAction
> = {
  [ActionKey.ALLOW_CHECK_PAYMENT]: (state, action) => {
    const { allowCheckPayment } = action;
    return update(state, { $merge: { allowCheckPayment } });
  },
  [ActionKey.UPDATE_AUTOPAY_SETTINGS]: (state, action) => {
    const { isNewCreditCardAutopayEditable, newCreditCardAutopayDefaultValue } = action;
    return update(state, { $merge: { isNewCreditCardAutopayEditable, newCreditCardAutopayDefaultValue } });
  },
  [ActionKey.GET_ACH_FORM_DATA]: (state, action) => {
    const { zuoraACHFormData } = action;
    return update(state, { $merge: { zuoraACHFormData } });
  },
  [ActionKey.GET_CC_FORM_DATA]: (state, action) => {
    const { zuoraCCFormData } = action;
    return update(state, { $merge: { zuoraCCFormData } });
  },
};

export const reducer = getReducerBuilder<BillingState, BillingAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  GET_ACH_SIGNATURE: (zuoraACHFormData?: ZuoraHostePageResponseDataView): BillingAction => ({
    type: ActionKey.GET_ACH_FORM_DATA,
    zuoraACHFormData,
  }),
  GET_CC_SIGNATURE: (zuoraCCFormData?: ZuoraHostePageResponseDataView): BillingAction => ({
    type: ActionKey.GET_CC_FORM_DATA,
    zuoraCCFormData,
  }),
  ALLOW_CHECK_PAYMENT: (allowCheckPayment?: boolean): BillingAction => ({
    type: ActionKey.ALLOW_CHECK_PAYMENT,
    allowCheckPayment,
  }),
  UPDATE_AUTOPAY_SETTINGS: (isNewCreditCardAutopayEditable?:boolean, newCreditCardAutopayDefaultValue?:boolean ): BillingAction => ({
    type: ActionKey.UPDATE_AUTOPAY_SETTINGS,
    isNewCreditCardAutopayEditable,
    newCreditCardAutopayDefaultValue
  }),
  RESET: (): BillingAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadPaymentFormData = (paymentMethodType: PaymentMethodType) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    paymentMethodType === PaymentMethodType.ach ? ActionKey.GET_ACH_FORM_DATA : ActionKey.GET_CC_FORM_DATA,
    async () => getPaymentFormData(paymentMethodType),
    paymentFormData => {
      if (paymentMethodType === PaymentMethodType.ach) {
        dispatch(actionMap.GET_ACH_SIGNATURE(paymentFormData));
      } else {
        dispatch(actionMap.GET_CC_SIGNATURE(paymentFormData));
      }
    },
    () => {
      if (paymentMethodType === PaymentMethodType.ach) {
        dispatch(actionMap.GET_ACH_SIGNATURE());
      } else {
        dispatch(actionMap.GET_CC_SIGNATURE());
      }
    },
    true,
  );

const getBillingOptionsMethod = (shoppingCartId: string | null) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ALLOW_CHECK_PAYMENT,
    async () => getBillingOptions(shoppingCartId),
    response => {
      dispatch(actionMap.ALLOW_CHECK_PAYMENT(response.allowCheck));
      dispatch(actionMap.UPDATE_AUTOPAY_SETTINGS(response.isNewCreditCardAutopayEditable, response.newCreditCardAutopayDefaultValue))
    },
    () => {
      dispatch(actionMap.ALLOW_CHECK_PAYMENT());
      dispatch(actionMap.UPDATE_AUTOPAY_SETTINGS());
    },
    true,
  );

const billingDuck = {
  thunks: { loadPaymentFormData, getBillingOptionsMethod },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default billingDuck;

import React from 'react';
import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled';

const Checkbox = React.forwardRef<HTMLInputElement, ComponentProps>(
  (
    { label, size, onChange, checkboxLabelLineCount, disabled, ...props },
    ref,
  ) => {
    return (
      <CheckboxContainer isDisabled={disabled}>
        <CheckboxInput
          {...props}
          type='checkbox'
          ref={ref as any}
          onChange={onChange}
        />
        <CheckboxCheck />
        <CheckboxText
          size={size}
          checkboxLabelLineCount={checkboxLabelLineCount}
        >
          {label}
        </CheckboxText>
      </CheckboxContainer>
    );
  },
);

interface OwnProps {
  label: string;
  size?: number;
  checkboxLabelLineCount?: number;
}

type ComponentProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  OwnProps;

export default Checkbox;

import { NavigationItemMap } from 'contracts/core/routing';
import { ApplicationState } from 'contracts/core/state';
import translate from 'core/helpers/translate';
import { isLocationMatched } from 'core/helpers/wizard';
import cart from 'ducks/cart';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Routes, RoutesRegex } from 'routing/routes';
import NavigationCartItem from './NavigationCartItem';
import NavigationPrimaryItems from './NavigationPrimaryItems';
import {
  NavigationBar as NavigationBarContainer,
  NavigationLogo,
  NavigationLogoContainer,
} from './styled';
import { IsMicroSite } from 'App';
import { getSavingsUserData, setSessionAuthToken } from 'core/services/session';

const NavigationBar: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isStorefrontDataSet, setIsStorefrontDataSet] = useState<boolean>();

  const hideNavigationBar = useSelector(
    (state: ApplicationState) => state.recognized.hideNavigationBar || false,
  );

  useEffect(() => {
    if (IsMicroSite && !isStorefrontDataSet) {
      const savingsUserData = getSavingsUserData();
      if (savingsUserData) {
        const { firstName, lastName, email, token } = savingsUserData;
        if (firstName && lastName && email && token) {
          setSessionAuthToken(token);
          dispatch(cart.actions.setCustomerDetails({
            firstName,
            lastName,
            email
          }));
          setIsStorefrontDataSet(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const locations = cart.selectors.getLocations();
  const allServices = cart.selectors.getAllServices();
  const servicesWithSetDates = cart.flags.countServicesWithSetDates();
  const areAgreementsSigned = cart.flags.areAgreementsSigned();
  const locationsWithPaymentMethod = cart.flags.countLocationsWithPaymentMethod();
  const hasCustomerDetails = cart.flags.hasCustomerDetails();
  const isSignWithCustomer = cart.flags.isSignWithCustomer();

  const haveAllServicesSetDates =
    allServices.length > 0 && servicesWithSetDates === allServices.length;
  const haveAllLocationsPaymentMethod =
    locations.length > 0 && locationsWithPaymentMethod === locations.length;

  const isUserSelfService = !hasCustomerDetails && !isSignWithCustomer;

  const navigationItemsMap: NavigationItemMap = {
    location: {
      ...navigationItemsMapInit.location,
      subtitle:
        locations.length > 0
          ? `${translate('youAdded')} ${locations.length} ${locations.length > 1
            ? translate('locations')
            : translate('location')
          }`
          : undefined,
      active: isLocationMatched(location.pathname, RoutesRegex.location),
      disabled: isUserSelfService,
      completed: locations.length > 0,
    },
    service: {
      ...navigationItemsMapInit.service,
      subtitle:
        allServices.length > 0
          ? `${translate('youAdded')} ${allServices.length} ${allServices.length > 1
            ? translate('services')
            : translate('service')
          }`
          : undefined,
      active: isLocationMatched(location.pathname, RoutesRegex.service),
      disabled: allServices.length === 0,
      completed: allServices.length > 0,
    },
    startDate: {
      ...navigationItemsMapInit.startDate,
      subtitle:
        allServices.length > 0 && servicesWithSetDates > 0
          ? `${translate('completedFor')} ${servicesWithSetDates} ${translate(
            'of',
          )} ${allServices.length} ${allServices.length > 1
            ? translate('services')
            : translate('service')
          }`
          : undefined,
      active: isLocationMatched(location.pathname, RoutesRegex.startDate),
      disabled: allServices.length === 0,
      completed: haveAllServicesSetDates,
    },
    serviceAgreement: {
      ...navigationItemsMapInit.serviceAgreement,
      active: isLocationMatched(
        location.pathname,
        RoutesRegex.serviceAgreement,
      ),
      disabled: !haveAllServicesSetDates,
      completed: areAgreementsSigned,
    },
    billing: {
      ...navigationItemsMapInit.billing,
      subtitle:
        locations.length > 0 && locationsWithPaymentMethod > 0
          ? `${translate(
            'completedFor',
          )} ${locationsWithPaymentMethod} ${translate('of')} ${locations.length
          } ${locations.length > 1
            ? translate('locations')
            : translate('location')
          }`
          : undefined,
      active: isLocationMatched(location.pathname, RoutesRegex.billing),
      disabled: !haveAllServicesSetDates || !areAgreementsSigned,
      completed: haveAllLocationsPaymentMethod,
    },
  };

  const navigationItems = Object.values(navigationItemsMap);

  return (
    <NavigationBarContainer hideNavigationBar={hideNavigationBar}>
      <NavigationLogoContainer>
        <NavigationLogo to='/' />
      </NavigationLogoContainer>
      <NavigationPrimaryItems navigationItems={navigationItems} />
      <NavigationCartItem count={allServices.length} disabled={isUserSelfService} />
    </NavigationBarContainer>
  );
};

const navigationItemsMapInit: NavigationItemMap = {
  location: {
    path: Routes.location,
    label: translate('locationMenuItem'),
    step: 1,
    active: false,
    disabled: true,
    completed: true,
  },
  service: {
    path: Routes.summary,
    label: translate('serviceMenuItem'),
    step: 2,
    active: false,
    disabled: true,
    completed: true,
  },
  startDate: {
    path: Routes.startDate.replace(':serviceIndex', '1'),
    label: translate('startDateMenuItem'),
    mobileLabel: translate('startDateMobileMenuItem'),
    step: 3,
    active: false,
    disabled: true,
    completed: true,
  },
  serviceAgreement: {
    path: Routes.serviceAgreement,
    label: translate('serviceAgreementMenuItem'),
    mobileLabel: translate('serviceAgreementMobileMenuItem'),
    step: 4,
    active: false,
    disabled: true,
    completed: true,
  },
  billing: {
    path: Routes.billing.replace(':locationIndex', '1'),
    label: translate('billingMenuItem'),
    step: 5,
    active: false,
    disabled: true,
    completed: true,
  },
};

export default NavigationBar;

import moment from 'moment';

export const decimal = (numberValue?: any) => Number(numberValue).toFixed(2);
export const localDate = (dateValue?: string | number | Date) =>
  moment(dateValue).format('L');
export const time = (dateValue?: string | number | Date) =>
  moment(dateValue).format('LT');
export const dateTime = (dateValue?: string | number | Date) =>
  moment(dateValue).format('MM/DD/YY h:mm A');

const currencyStyle = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const currency = (numberValue?: number, cutDecimals = false) => {
  const formatter = new Intl.NumberFormat(
    'en-US',
    cutDecimals ? currencyStyle : { style: 'currency', currency: 'USD' },
  );

  return formatter.format(numberValue || 0);
};

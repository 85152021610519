import { useEffect, useState } from 'react';

import memoizeOne from 'memoize-one';
import { PlainStyle, spring, Style } from 'react-motion';

const useClusterMarker = ({
  initialScale = 0.6,
  defaultScale = 1,
  hoveredScale = 1.15,
  hovered,
  stiffness,
  damping,
  precision,
}: ClusterMarkerHookProps): ClusterMarkerHookReturnType => {
  const [defaultMotionStyle, setDefaultMotionStyle] = useState<PlainStyle>({ scale: initialScale });
  const [motionStyle, setMotionStyle] = useState<Style>();

  useEffect(() => {
    setDefaultMotionStyle({ scale: defaultScale });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMotionStyle({
      scale: spring(hovered ? hoveredScale : defaultScale, {
        stiffness,
        damping,
        precision,
      }),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return [
    defaultMotionStyle,
    motionStyle,
  ];
};

type ClusterMarkerHookProps = {
  initialScale?: number,
  defaultScale?: number,
  hoveredScale?: number,
  hovered?: boolean,
  stiffness?: number,
  damping?: number,
  precision?: number,
}

type ClusterMarkerHookReturnType = [
  defaultMotionStyle?: PlainStyle,
  motionStyle?: Style,
]

const useClusterMarkerMemoized = memoizeOne(useClusterMarker);

export default useClusterMarkerMemoized;

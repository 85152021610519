import styled, { css } from 'styled-components';
import { theme, Devices } from 'core/styles';

interface RadioCheckProps {
  isServiceOption?: boolean;
}
export const RadioCheck = styled.span<RadioCheckProps>`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  border: 2px solid ${theme.colors.brandDefaultBlack};
  border-radius: 10px;
  cursor: pointer;
  ${props =>
    props.isServiceOption &&
    css`
      @media ${Devices.mobile} {
        width: 10px;
        height: 10px;
        margin-right: 6px;
        border-width: 1px;
      }
    `};
`;

interface RadioTextProps {
  isServiceOption?: boolean;
}
export const RadioText = styled.span<RadioTextProps>`
  vertical-align: middle;
  font-size: 15px;
  ${props =>
    props.isServiceOption &&
    css`
      @media ${Devices.mobile} {
        line-height: 16px;
        font-size: 11px;
        height: auto;
        font-weight: ${theme.fontWeightLight};
      }
    `};
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + ${RadioCheck} {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTggM0M1LjIgMyAzIDUuMiAzIDhzMi4yIDUgNSA1IDUtMi4yIDUtNS0yLjItNS01LTV6bTAtNUMyLjUtMi0yIDIuNS0yIDhTMi41IDE4IDggMThzMTAtNC41IDEwLTEwUzEzLjUtMiA4LTJ6TTAgOGMwLTQuNCAzLjYtOCA4LThzOCAzLjYgOCA4LTMuNiA4LTggOC04LTMuNi04LTh6IiBmaWxsPSIjMDA5Njg4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
    background-size: 100%;
    border-color: ${theme.colors.brandPrimary};
  }
`;

interface RadioProps {
  isDisabled?: boolean;
}

export const Radio = styled.label<RadioProps>`
  & + & {
    margin-left: 60px;
  }

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
    `};
`;

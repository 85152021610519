import styled from 'styled-components';

export const EmptySectionContainer = styled.div`
  display: flex;
  flex-direction: 'column';
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: #555;
  background-color: #fff;

  text-align: center;
  font-size: 12px;
  font-style: normal;
`;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import translate from 'core/helpers/translate';
import { CheckoutFormData } from 'contracts/core/form';
import { Button, FormError, ModalTitle } from 'core/components/styled';
import { Input, Modal, RadioGroup } from 'core/components';
import { FormFieldsWrapper } from 'core/components/styled/FormGroup';
import { SectionTitle } from 'core/components/styled/Panel2';
import NotificationType from 'contracts/enums/NotificationType';
import { createNotificationMessage } from 'core/ducks/notifier';
import {
  CheckoutCompleteModalContainer,
  CheckoutCompleteModalContent,
  CheckoutCompleteModalLogo,
  CheckoutCompleteModalSubTitle,
  CheckoutCompleteModalTitle,
  CheckoutForm,
  InvalidCodeModalContainer,
  RecognizedAddressContainer,
  RecognizedAddressNote,
  RecognizedAddressSubtitle,
} from './styled';
import cart from 'ducks/cart';
import PromoCodeForm from './PromoCodeForm';
import {
  checkRecognizedAddress,
  checkValidationCode,
  packagePurchase,
  sendPhoneNumberValidationCode,
} from 'services/checkout';
import {
  PhoneNumberValidationRequest,
  PhoneNumberValidationResponse,
  ValidationCodeConfirmationRequest,
} from 'contracts/core/request';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { destroySession, getSavingsUserData, getSessionCart } from 'core/services/session';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSectionContent } from 'core/components/styled/LoadingSection';
import { FieldDescription } from 'core/components/styled/FormLabel';
import { CartLimitations } from 'contracts/models/CartLimitations';
import { ApplicationState } from 'contracts/core/state';
import { IsMicroSite } from 'App';

const phoneValidationOptions = [
  { label: translate('textPhoneValidationLabel'), code: 'text' },
  { label: translate('voicePhoneValidationLabel'), code: 'voice' },
];

const CheckoutDetailsForm: React.FC = () => {
  const [showCheckoutCompleteModal, setShowCheckoutCompleteModal] =
    useState(false);
  const [showVerificationCodeField, setShowVerificationCodeField] =
    useState(false);
  const [isValidationCodeInvalid, setisValidationCodeInvalid] = useState(false);
  const [validationCodeDetails, setValidationCodeDetails] = useState(
    {} as PhoneNumberValidationResponse,
  );
  const [isValidatingPromoCode, setiIsValidatingPromoCode] = useState(false);
  const [checkoutError, setCheckoutError] = useState<string | undefined>(undefined);

  const { getValues, register, setValue, handleSubmit, watch, formState: { errors } } =
    useForm<CheckoutFormData>({
      defaultValues: {
        phoneValidationType: 'text',
      },
    });
  const cartLimitations: CartLimitations = useSelector(
    (state: ApplicationState) => state.locations.cartLimitations,
  );

  const isSignWithCustomer = cart.flags.isSignWithCustomer();
  const locationsWithCheckPayment =
    cart.flags.countLocationsWithCheckPaymentMethod();
  const isReadonlyCheckoutEmail = cart.flags.isReadonlyCheckoutEmail();
  const canAllLocationsNotAddServices = cart.flags.canAllLocationsNotAddServices();
  const shoppingCartId = cart.selectors.getshoppingCartId();
  const customerDetails = cart.selectors.getCustomerDetails();
  const services = cart.selectors.getAllServices();
  const hasAnyLocationWithRecognizedAddress = cart.flags.hasAnyLocationWithRecognizedAddress();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useRecognizedAddress = watch('useRecognizedAddress');

  useEffect(() => {
    if (!isSignWithCustomer && !shoppingCartId) {
      if (customerDetails?.email) {
        setValue('email', customerDetails.email)
      }
    } else if (isReadonlyCheckoutEmail && customerDetails?.email) {
      setValue('email', customerDetails.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  useEffect(() => {
    if (hasAnyLocationWithRecognizedAddress) {
      setValue('useRecognizedAddress', true);
    }
  }, [hasAnyLocationWithRecognizedAddress, setValue]);

  const onSubmit = async () => {
    const checkoutDetails = getValues();
    if (checkoutDetails && checkoutDetails.useRecognizedAddress) {
      const recognizedAddress = await checkRecognizedAddress({ email: checkoutDetails.email });
      if (recognizedAddress.recognized === false) {
        setCheckoutError(translate('recognizedEmailError'));
        return;
      }
    }
    if (isSignWithCustomer || locationsWithCheckPayment > 0) {
      if (
        checkoutDetails &&
        checkoutDetails.phoneValidationType &&
        !checkoutDetails.validationCode
      ) {
        const phoneValidationRequest: PhoneNumberValidationRequest = {
          countryCode: '+1',
          shoppingCartId: shoppingCartId === '' ? null : shoppingCartId,
          email: checkoutDetails.email,
          phoneNumber: checkoutDetails.phone,
          sendVoiceMessage:
            checkoutDetails.phoneValidationType === 'voice' ? true : false,
        };
        try {
          const response = await sendPhoneNumberValidationCode(
            phoneValidationRequest,
          );
          setValidationCodeDetails(response);
          setShowVerificationCodeField(true);
        } catch (err) {
          console.log(err);
          dispatch(
            createNotificationMessage(
              NotificationType.Error,
              translate('generalErrorMessage'),
            ),
          );
        }
      } else if (
        checkoutDetails &&
        checkoutDetails.phoneValidationType &&
        checkoutDetails.validationCode
      ) {
        const validationCodeConfirmationRequest: ValidationCodeConfirmationRequest =
        {
          code: checkoutDetails.validationCode,
          userId: validationCodeDetails?.userId,
          requestToken: validationCodeDetails.resultToken,
        };
        try {
          const response = await checkValidationCode(
            validationCodeConfirmationRequest,
          );
          if (response.success) {
            setShowCheckoutCompleteModal(true);
            setCheckoutError(undefined)
            dispatch(cart.actions.setCustomerDetails(checkoutDetails));
            const cartObj = getSessionCart();
            try {
              const checkoutResponse = await packagePurchase({
                ...cartObj,
                notificationToken: response.resultToken,
              });
              window.location.href = checkoutResponse.url;
              destroySession();
            } catch (err: any) {
              console.log(err);
              if (err.response.status === 422) {
                setCheckoutError(err.response.data.exceptionMessage)
              }
            }
          }
        } catch (err) {
          setisValidationCodeInvalid(true);
        }
      }
    } else {
      if (checkoutDetails) {
        setShowCheckoutCompleteModal(true);
        setCheckoutError(undefined)
        dispatch(cart.actions.setCustomerDetails(checkoutDetails));
        const cartObj = getSessionCart();
        try {
          const checkoutResponse = await packagePurchase({
            ...cartObj,
            shoppingCartId: cartObj.shoppingCartId
              ? cartObj.shoppingCartId
              : null,
          });
          window.location.href = checkoutResponse.url;
          destroySession();
        } catch (err: any) {
          setShowCheckoutCompleteModal(false);
          if (err.response.status === 422) {
            setCheckoutError(err.response.data.exceptionMessage)
          }
          console.log(err);
        }
      }
    }
  };

  const onChangePhoneNr = () => {
    setShowVerificationCodeField(false);
    setValidationCodeDetails({} as PhoneNumberValidationResponse);
  };

  const addAdditionalService = () => navigate(Routes.location);

  let disabledFromStorefront = false;
  if (IsMicroSite) {
    const savingsUserData = getSavingsUserData();
    if (savingsUserData) {
      const { firstName, lastName, email } = savingsUserData;
      disabledFromStorefront = !!firstName && !!lastName && !!email;
    }
  }

  const onCreateProfile = () => {
    setCheckoutError('');
    setValue('useRecognizedAddress', false);
  };

  return (
    <CheckoutForm onSubmit={handleSubmit(onSubmit)}>
      <SectionTitle>{useRecognizedAddress ? translate('recognizedAddressTitle') : translate('checkoutFormSubTitle')}</SectionTitle>
      {useRecognizedAddress ?
        <RecognizedAddressContainer>
          <RecognizedAddressSubtitle>{translate('recognizedAddressSubtitle')}</RecognizedAddressSubtitle>
          <RecognizedAddressNote>{translate('recognizedAddressNote')}</RecognizedAddressNote>
        </RecognizedAddressContainer> :
        <FormFieldsWrapper>
          <Input
            label={translate('firstNameFormLabel')}
            errors={errors && errors.firstName}
            readOnly={disabledFromStorefront && customerDetails?.firstName ? true : false}
            {...register('firstName', {
              required: {
                value: true,
                message: translate('contactFirstNameRequiredError'),
              },
            })}
            defaultValue={
              !isSignWithCustomer && !shoppingCartId
                ? customerDetails?.firstName
                : ''
            }
          />
          <Input
            label={translate('lastNameFormLabel')}
            errors={errors && errors.lastName}
            readOnly={disabledFromStorefront && customerDetails?.lastName ? true : false}
            {...register('lastName', {
              required: {
                value: true,
                message: translate('contactLastNameRequiredError'),
              },
            })}
            defaultValue={
              !isSignWithCustomer && !shoppingCartId
                ? customerDetails?.lastName
                : ''
            }
          />
        </FormFieldsWrapper>}
      <Input
        label={useRecognizedAddress ? translate('emailFormLabelRecognized') : translate('emailFormLabel')}
        errors={errors && errors.email}
        readOnly={(disabledFromStorefront || isReadonlyCheckoutEmail) && customerDetails?.email ? true : false}
        {...register('email', {
          required: {
            value: true,
            message: translate('emailRequiredError'),
          },
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: translate('invalidEmailFieldError'),
          },
        })}
        defaultValue={
          !isSignWithCustomer && !shoppingCartId
            ? customerDetails?.email
            : isReadonlyCheckoutEmail && customerDetails?.email
              ? customerDetails.email
              : ''
        }
      />
      {useRecognizedAddress ? null :
        <Input
          label={translate('phoneFormLabel')}
          errors={errors && errors.phone}
          {...register('phone', {
            required: {
              value: true,
              message: translate('phoneRequiredError'),
            },
            pattern: {
              value:
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
              message: translate('invalidPhoneNrFieldError'),
            },
          })}
          defaultValue={
            !isSignWithCustomer && !shoppingCartId ? customerDetails?.phone : ''
          }
          onChange={e => {
            setValue('phone', e.target.value);
            onChangePhoneNr();
          }}
        />}
      {isSignWithCustomer || locationsWithCheckPayment > 0 ? (
        showVerificationCodeField ? (
          <Input
            label={translate('validationCodeFormLabel')}
            errors={errors && errors.validationCode}
            {...register('validationCode', {
              required: {
                value: true,
                message: translate('validationCodeRequiredError'),
              },
            })}
          />
        ) : (
          <RadioGroup
            label={translate('phoneNrVerificationFormLabel')}
            options={phoneValidationOptions}
            {...register('phoneValidationType', {
              required: true,
            })}
            defaultValue='text'
          />
        )
      ) : null}
      <PromoCodeForm setiIsValidatingPromoCode={setiIsValidatingPromoCode} />
      {checkoutError && <FormError>{checkoutError}</FormError>}
      <Button
        marginTop
        disabled={isValidatingPromoCode}
        isLoading={false}
        type='submit'
        data-automation='CheckoutButton'
      >
        {isSignWithCustomer || locationsWithCheckPayment > 0
          ? !validationCodeDetails?.resultToken
            ? translate('sendVerificationCodeBtnLabel')
            : translate('checkoutBtnLabel')
          : translate('checkoutBtnLabel')}
      </Button>
      {useRecognizedAddress ?
        <Button onClick={onCreateProfile} marginTop inverted>
          {translate('registerNewProfile')}
        </Button> : null}
      <Button
        disabled={services.length >= cartLimitations.services || canAllLocationsNotAddServices ? true : false}
        width='100%' inverted marginTop onClick={addAdditionalService}>
        {translate('addAdditionalServiceButton')}
      </Button>
      {services.length >= cartLimitations.services
        && <FieldDescription>{translate('servicessLimitReached')}</FieldDescription>
      }
      {isValidationCodeInvalid && (
        <Modal size='xSmall'>
          <ModalTitle>{translate('invalidCodeModalTitle')}</ModalTitle>
          <InvalidCodeModalContainer>
            <p>{translate('invalidCodeModalContent')}</p>
            <Button
              onClick={() => setisValidationCodeInvalid(false)}
              isLoading={false}
            >
              {translate('okBtnLabel')}
            </Button>
          </InvalidCodeModalContainer>
        </Modal>
      )}
      {showCheckoutCompleteModal && (
        <Modal fullWindow={true} size='large' padding='0'>
          <CheckoutCompleteModalContainer>
            <CheckoutCompleteModalContent>
              <LoadingSectionContent />
              <CheckoutCompleteModalTitle>
                {translate('checkoutCompleteModalTitle')}
              </CheckoutCompleteModalTitle>
              <CheckoutCompleteModalSubTitle>
                {translate('checkoutCompleteModalDescription')}
              </CheckoutCompleteModalSubTitle>
              <CheckoutCompleteModalLogo />
            </CheckoutCompleteModalContent>
          </CheckoutCompleteModalContainer>
        </Modal>
      )}
    </CheckoutForm>
  );
};

export default CheckoutDetailsForm;

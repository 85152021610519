import { loadingOverlay, theme, Devices } from 'core/styles';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

export const PanelContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100vh;
  flex: 1 1 72%;

  @media ${Devices.mobile} {
    flex-direction: row;
    height: auto;
    width: 100%;
  }
`;

interface PanelParams extends LoadingStyledComponentProps {
  center?: boolean;
  flexDirection?: string;
  flex?: string;
  width?: string;
  autoHeight?: boolean;
  primary?: boolean;
  secondary?: boolean;
  resetOverFlowY?: boolean;
}

export const Panel = styled.div<PanelParams>`
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
  width: 100%;

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};

  ${props =>
    props.autoHeight &&
    css`
      height: auto;
    `};

  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.primary &&
    css`
      background-color: ${theme.colors.white};
      border-right: 1px solid ${theme.colors.borderGray};
      box-sizing: border-box;
    `};

  ${props =>
    props.secondary &&
    css`
      background-color: ${theme.colors.grayLightest};
    `};

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  ${props => props.isLoading && loadingOverlay(32)};

  ${props =>
    props.resetOverFlowY &&
    css`
      overflow-y: inherit;
    `};

  @media ${Devices.mobile} {
    flex-direction: row;
    height: auto;
    width: 100%;
  }
`;

interface PanelContentParams extends LoadingStyledComponentProps {
  noPadding?: boolean;
}

export const PanelContent = styled.div<PanelContentParams>`
  margin: 0 auto;
  padding: 30px 30px 40px;

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}

  ${props => props.isLoading && loadingOverlay(32)};
`;

interface PanelTitleParams {
  fontSize?: number;
}

export const PanelTitle = styled.h1<PanelTitleParams>`
  display: block;
  margin: 10px 0;
  text-align: center;
  line-height: 38px;
  letter-spacing: 0.4px;
  font-weight: ${theme.fontWeightBold};
  font-size: 32px;
  color: ${theme.colors.brandDefaultBlack};

  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
`;

export const PanelSubTitle = styled.h2`
  display: block;
  margin: 10px 0;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
`;

export const SectionTitle = styled.h2`
  display: block;
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 18px;
  font-weight: ${theme.fontWeightLight};
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
`;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import translate from 'core/helpers/translate';
import { ApplicationState } from 'contracts/core/state';
import { AddressDataView } from 'contracts/models/AddressDataView';
import { Select } from 'core/components';
import { actionIsRunning } from 'core/ducks/running';
import { LocationPicker } from 'core/components/LocationPicker';
import {
  hasCoordinates,
  hasCountry,
  hasState,
  hasZip,
  hasCity,
  hasStreet,
  hasStreetNumber,
} from 'utils/services/validator';
import addressesDuck from 'ducks/addresses';
import { Industries } from 'contracts/models/BusinessTypes';
import { LocationPickerFormData } from 'contracts/core/form';

const validationFunctions = [
  hasCoordinates,
  hasCountry,
  hasState,
  hasZip,
  hasCity,
  hasStreet,
  hasStreetNumber,
];

const LocationPickerForm: React.FC<ComponentProps> = ({
  showAddressOnMap,
  businessOptions,
  defaultValue
}) => {
  const dispatch = useDispatch();
  const { getValues, formState: { errors }, register, control } = useFormContext<LocationPickerFormData>();
  const [selectedAddress, setAddress] = useState<AddressDataView>();
  const reset: () => any = addressesDuck.actions.reset;

  const addressErrors = useSelector(
    (state: ApplicationState) => state.locations.addressErrors,
  );

  const isAsyncValidating = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, addressesDuck.actionKeys.CHECK_FRANCHISE),
  );

  const checkFranchise: (address: AddressDataView, errors: string[]) => any =
    addressesDuck.thunks.validateAddress;

  const onAddressChange = (address: AddressDataView) => {
    const addressError = validationFunctions
      .map(validate => validate(address))
      .filter(message => message !== undefined) as string[];

    dispatch(reset());
    dispatch(checkFranchise(address, addressError));
    showAddressOnMap(address);
    dispatch(addressesDuck.thunks.addressOptions(address));
    setAddress(address);
  };

  const formOptions = businessOptions
    ? businessOptions.map(business => ({
      label: business.name,
      value: business.id,
      code: business.id,
    }))
    : [
      {
        label: 'No business types',
        value: 0,
        code: 0,
      },
    ];

  return (
    <>
      <Controller
        name='address'
        control={control}
        render={({ field }) => (
          <LocationPicker
            {...field}
            label={translate('serviceAddressLabel')}
            asyncValidating={isAsyncValidating}
            onChange={onAddressChange}
            value={selectedAddress}
            errors={addressErrors}
            defaultValue={defaultValue}
          />
        )}
      />
      <Select
        label={translate('businessTypeLabel')}
        {...register('businessTypeId', {
          required: {
            value: true,
            message: translate('errorPleaseSelectBusiness'),
          },
        })}
        options={formOptions}
        errors={errors && errors.businessTypeId}
        value={getValues('businessTypeId')}
      />
    </>
  );
};

interface ComponentProps {
  showAddressOnMap: (address: AddressDataView) => void;
  businessOptions?: Industries[];
  defaultValue?: string;
}

export default LocationPickerForm;

import React, { useEffect } from 'react';

import { Button } from '../styled/Button';
import { ModalComponentProps } from 'contracts/core/modal';
import { ConfirmationButtonSet, ConfirmationContainer, ConfirmationDialog, ConfirmationMessage } from '../styled/Modal';
import translate from 'core/helpers/translate';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  closeModal,
  noBtnVisible = true,
  noBtnText = translate('no'),
  yesBtnText = translate('yes') }) => {

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false);
    return (() => {
      document.removeEventListener('keydown', keyboardHandler, false);
    });
  });
  const keyboardHandler = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (event.code === 'Escape') {
      cancel();
    } else if (event.code === 'Enter') {
      accept();
    }
  };

  const accept = (): void => {
    closeModal(true);
  };

  const cancel = (): void => {
    closeModal(false);
  };

  return (<ConfirmationContainer>
    <ConfirmationDialog>
      <ConfirmationMessage>{message}</ConfirmationMessage>
      <ConfirmationButtonSet center={!noBtnVisible}>
        <Button
          type='submit'
          modalButton
          onClick={() => accept()}
          data-automation={noBtnVisible ? 'YesButton' : 'OkButton'}
        >
          {noBtnVisible ? yesBtnText : translate('okBtnLabel')}
        </Button>
        {noBtnVisible && (
          <Button
            type='submit'
            modalButton
            inverted
            onClick={() => cancel()}
            data-automation={'NoButton'}
          >
            {noBtnText}
          </Button>
        )}
      </ConfirmationButtonSet>
    </ConfirmationDialog>
  </ConfirmationContainer>
  );
};

export interface ConfirmationModalProps extends ModalComponentProps {
  message: string;
  noBtnVisible?: boolean;
  yesBtnText?: string;
  noBtnText?: string;
}

export default ConfirmationModal;

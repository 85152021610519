import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  ForwardedRef,
} from 'react';
import { generate } from 'short-uuid';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import translate from 'core/helpers/translate';
import { BillingDetailsFormData } from 'contracts/core/form';
import { Input, Select } from 'core/components';
import STATES from 'core/constants/states';
import { FormFieldsWrapper } from 'core/components/styled/FormGroup';
import cart from 'ducks/cart';
import { SectionTitle } from 'core/components/styled/Panel2';
import { SubscriptionLocationBillingDataView } from 'contracts/models/SubscriptionLocationBillingDataView';
import { formatExistingBillingDetailsOptions } from 'core/helpers/billing';
import { BillingForm } from './styled';

const BillingDetailForm: React.FC<ComponentProps> = forwardRef(
  (
    {
      locationIndexNumber,
      existingBillingDetails,
      selectedBillingDetailIndex,
      doSubmitPayment,
    },
    ref,
  ) => {
    const [submitting, setSubmitting] = useState(false);
    const [showBillingDetailForm, setShowBillingDetailForm] = useState(true);
    const [existBillingDetail, setExistBillingDetail] = useState(
      {} as SubscriptionLocationBillingDataView,
    );
    const [isFormInitialized, setIsFormInitialized] = useState(false);
    const dispatch = useDispatch();

    const { getValues, setValue, reset, register, handleSubmit, formState: { errors } } =
      useForm<BillingDetailsFormData>();

    useEffect(() => {
      const billIndex = selectedBillingDetailIndex
        ? parseInt(selectedBillingDetailIndex, 10)
        : -1;
      if (existingBillingDetails && existingBillingDetails.length > 0) {
        onChangeExistingBillingDetails(
          selectedBillingDetailIndex ? selectedBillingDetailIndex : '',
        );
        const selectedBill = selectedBillingDetailIndex && billIndex > -1;
        setShowBillingDetailForm(!selectedBill);
      } else {
        setShowBillingDetailForm(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingBillingDetails]);

    useEffect(() => {
      if (_.isEmpty(errors) && submitting) {
        doSubmitPayment();
      }
      if (submitting) {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const onSubmit = () => {
      const billingDetails = getValues();
      reset();
      const billingId =
        existBillingDetail && existBillingDetail.billingId
          ? existBillingDetail.billingId
          : generate();
      if (existBillingDetail && existBillingDetail.isExistingBilling) {
        dispatch(
          cart.actions.setBillingDetails(locationIndexNumber, {
            billingId,
            isExistingBilling: true,
            ...billingDetails,
          }),
        );
      } else {
        dispatch(
          cart.actions.setBillingDetails(locationIndexNumber, {
            billingId,
            isExistingBilling: false,
            ...(Object.keys(billingDetails).length > 1 && !billingDetails.billingDetail
              ? billingDetails
              : existBillingDetail),
          }),
        );
      }
    };

    useImperativeHandle(ref, () => ({
      submitBillingForm() {
        handleSubmit(onSubmit)();
        setSubmitting(true);
      },
    }));

    const onChangeExistingBillingDetails = (value: string) => {
      if (
        existingBillingDetails &&
        existingBillingDetails.length > 0 &&
        value
      ) {
        const selectedBillingDetails = existingBillingDetails[
          parseInt(value, 10)
        ] as SubscriptionLocationBillingDataView;
        const isExistingBillingInfo = Boolean(
          selectedBillingDetails && selectedBillingDetails.isExistingBilling,
        );
        setExistBillingDetail(selectedBillingDetails);
        setShowBillingDetailForm(isExistingBillingInfo);
        setIsFormInitialized(isExistingBillingInfo);

        if (isExistingBillingInfo) {
          reset({ billingDetail: value, ...selectedBillingDetails });
        }

        setValue('billingDetail', value);
      } else {
        setExistBillingDetail({} as SubscriptionLocationBillingDataView);
        setShowBillingDetailForm(true);
        setIsFormInitialized(false);
        reset({});
      }
    };

    return (
      <BillingForm onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>{translate('billingDetailsSubtitle')}</SectionTitle>
        {existingBillingDetails && existingBillingDetails.length > 0 && (
          <Select
            label={translate('addNewBillingDetailsFormLabel')}
            options={formatExistingBillingDetailsOptions(
              existingBillingDetails,
            )}
            {...register('billingDetail', {
              required: {
                value: false,
                message: '',
              },
            })}
            onChange={e => {
              setValue('billingDetail', e.target.value)
              onChangeExistingBillingDetails(e.target.value);
            }}
          />
        )}
        {showBillingDetailForm && (
          <>
            <Input
              label={translate('businessNameFormLabel')}
              errors={errors && errors.businessName}
              {...register('businessName', {
                required: {
                  value: true,
                  message: translate('businessNameRequiredError'),
                },
              })}
              initialized={isFormInitialized}
            />
            <FormFieldsWrapper>
              <Input
                label={translate('contactFirstNameFormLabel')}
                errors={errors && errors.contactFirstName}
                {...register('contactFirstName', {
                  required: {
                    value: true,
                    message: translate('contactFirstNameRequiredError'),
                  },
                })}
                initialized={isFormInitialized}
              />
              <Input
                label={translate('contactLastNameFormLabel')}
                errors={errors && errors.contactLastName}
                {...register('contactLastName', {
                  required: {
                    value: true,
                    message: translate('contactLastNameRequiredError'),
                  },
                })}
                initialized={isFormInitialized}
              />
            </FormFieldsWrapper>
            <Input
              label={translate('streetAddressFormLabel')}
              errors={errors && errors.streetAddress}
              {...register('streetAddress', {
                required: {
                  value: true,
                  message: translate('streetAddressRequiredError'),
                },
              })}
              initialized={isFormInitialized}
            />
            <Input
              label={translate('cityFormLabel')}
              errors={errors && errors.city}
              {...register('city', {
                required: {
                  value: true,
                  message: translate('cityRequiredError'),
                },
              })}
              initialized={isFormInitialized}
            />
            <FormFieldsWrapper>
              <Select
                label={translate('stateFormLabel')}
                selectFieldLabel={translate('stateFormLabel')}
                {...register('state', {
                  required: {
                    value: true,
                    message: translate('stateRequiredError'),
                  },
                })}
                options={STATES}
                errors={errors && errors.state}
              />
              <Input
                label={translate('zipFormLabel')}
                errors={errors && errors.zip}
                {...register('zip', {
                  required: {
                    value: true,
                    message: translate('zipRequiredError'),
                  },
                })}
                initialized={isFormInitialized}
              />
            </FormFieldsWrapper>
          </>
        )}
      </BillingForm>
    );
  },
);
interface OwnStateProps { }

interface StateProps { }

interface OwnProps {
  ref: ForwardedRef<unknown>;
  locationIndexNumber: number;
  existingBillingDetails?: SubscriptionLocationBillingDataView[];
  selectedBillingDetailIndex?: string;
  doSubmitPayment: () => void;
}

type ComponentProps = StateProps & OwnProps & OwnStateProps;

export default BillingDetailForm;

import axios, { AxiosResponseTransformer } from 'axios';

export const getAxiosTransformers = (
  ...concatWithItems: AxiosResponseTransformer[]
): AxiosResponseTransformer[] => {
  const transformResponse = Array.isArray(axios.defaults.transformResponse)
    ? axios.defaults.transformResponse
    : axios.defaults.transformResponse
    ? [axios.defaults.transformResponse]
    : [];
  return transformResponse.concat(concatWithItems);
};

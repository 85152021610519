import mapper from './mapper';
import { Sizes } from 'core/styles';

const SIZE_MAP = {
  [Sizes.SIZE_AUTO]: 'auto',
  [Sizes.SIZE_NO]: '0',
  [Sizes.SIZE_XX_SMALL]: '5px',
  [Sizes.SIZE_X_SMALL]: '10px',
  [Sizes.SIZE_SMALL]: '15px',
  [Sizes.SIZE_S_MEDIUM]: '20px',
  [Sizes.SIZE_MEDIUM]: '30px',
  [Sizes.SIZE_L_MEDIUM]: '40px',
  [Sizes.SIZE_LARGE]: '45px',
  [Sizes.SIZE_X_LARGE]: '60px',
  [Sizes.SIZE_XX_LARGE]: '90px',
  [Sizes.SIZE_GRANDE]: '120px',
  [Sizes.SIZE_X_GRANDE]: '150px',
  [Sizes.SIZE_XX_GRANDE]: '180px',
};

const sizeMapper = (size?: string, defaultSize: string = Sizes.SIZE_MEDIUM) =>
  mapper(SIZE_MAP, size, defaultSize);

export default sizeMapper;

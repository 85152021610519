import React from 'react';
import { serviceAgreementText } from '../constants/serviceAgreementText-en';
import { LETTER_OF_AUTHORIZATION } from '../constants/variables';
import {
  ServiceAgreementBody,
  ServiceAgreementUList,
  ServiceUnderlineFont,
  ServiceTitle,
  ServiceBoldFont,
  ServiceAgreementDownload,
  ServiceFontColorPrimary,
} from './styled';

const LetterOfAuthorizationFormatted = ({
  handleScroll,
  isDownloadDisabled,
}: ComponentProps) => (
  <React.Fragment>
    <ServiceAgreementBody
      className='serviceAgreementContainer'
      onScroll={e => handleScroll(e, LETTER_OF_AUTHORIZATION)}
      data-automation='LetterOfAuthorization'
    >
      <p>{serviceAgreementText.dearVendor}</p>

      <p>
        {serviceAgreementText.pursuantLetterOfAuthorization}
        <ServiceUnderlineFont>
          {' '}
          {serviceAgreementText.exhibitA}{' '}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementSixtyeigthParagraph}
        <ServiceUnderlineFont>
          {' '}
          {serviceAgreementText.rubicon}{' '}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementSixtyninthParagraph}
        <ServiceUnderlineFont>
          {' '}
          {serviceAgreementText.exhibitA}{' '}
        </ServiceUnderlineFont>{' '}
        (“
        <ServiceUnderlineFont>
          {serviceAgreementText.customer}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementSeventyParagraph}
        <ServiceUnderlineFont>
          {' '}
          {serviceAgreementText.exhibitA}{' '}
        </ServiceUnderlineFont>{' '}
        (“
        <ServiceUnderlineFont>
          {serviceAgreementText.serviceAddresses}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementSeventyfirstParagraph}
      </p>

      <p>
        {serviceAgreementText.serviceAgreementSeventysecondParagraph}
        <span> </span>
        <ServiceFontColorPrimary>
          SMBNotifications@rubicon.com
        </ServiceFontColorPrimary>
      </p>

      <ServiceAgreementUList>
        <li>
          {serviceAgreementText.serviceAgreementSeventythirdParagraph}
          <ServiceUnderlineFont>
            {serviceAgreementText.vendorAgreement}
          </ServiceUnderlineFont>
          ”)
        </li>
        <li>{serviceAgreementText.copiesOfAllAmendments}</li>
        <li>{serviceAgreementText.purchaseOrdersIssued}</li>
        <li>{serviceAgreementText.pastInvoicesIssued}</li>
        <li>{serviceAgreementText.serviceRelatedIssue}</li>
        <li>{serviceAgreementText.prospectiveBids}</li>
        <li>{serviceAgreementText.weightTickets}</li>
        <li>{serviceAgreementText.pickupHistory}</li>
        <li>{serviceAgreementText.volumes}</li>
        <li>{serviceAgreementText.currentDispositionOfMaterials}</li>
      </ServiceAgreementUList>

      <p>
        <ServiceBoldFont>
          {serviceAgreementText.serviceAgreementSeventyfourthParagraph}
        </ServiceBoldFont>
        {serviceAgreementText.serviceAgreementSeventyfifthParagraph}
        <ServiceUnderlineFont>
          {serviceAgreementText.exhibitA}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementSeventysixthParagraph}
      </p>

      <ServiceAgreementUList>
        <li>{serviceAgreementText.serviceAgreementSeventyseventhParagraph}</li>
        <li>{serviceAgreementText.currentChanges}</li>
        <li>{serviceAgreementText.invoicesOrStatementsForServices}</li>
        <li>{serviceAgreementText.anyOtherApplicableFutureBilling}</li>
      </ServiceAgreementUList>

      <p>
        {serviceAgreementText.serviceAgreementSeventyeightParagraph}
        <span> </span>
        <ServiceFontColorPrimary>
          SMBNotifications@rubicon.com
        </ServiceFontColorPrimary>
      </p>

      <ServiceTitle>{serviceAgreementText.exhibitA}</ServiceTitle>

      <p>
        {serviceAgreementText.serviceAgreementSeventyninthParagraph}
        <ServiceUnderlineFont>
          {serviceAgreementText.rubicon}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementEightyParagraph}
        <ServiceUnderlineFont>
          {serviceAgreementText.incumbentProvider}
        </ServiceUnderlineFont>
        {serviceAgreementText.serviceAgreementEightyfirstParagraph}
      </p>
    </ServiceAgreementBody>

    <ServiceAgreementDownload
      href={process.env.REACT_APP_SAVINGS_HOST + 'download/letter-of-authorization.pdf'}
      target='_blank'
      disabled={isDownloadDisabled}
    >
      {serviceAgreementText.downloadLetterOfAuthorization}
    </ServiceAgreementDownload>
  </React.Fragment>
);

interface ComponentProps {
  handleScroll: (e: any, agreementName: string) => void;
  isDownloadDisabled?: boolean;
}

export default LetterOfAuthorizationFormatted;

import styled from 'styled-components';
import { theme, Devices, loadingOverlay } from 'core/styles';
import RubiconLogoWithText from 'core/assets/images/rubicon_logo_black_on_white.svg';

interface ConfimationContainerProps {
  isLoading?: boolean;
}
export const AcknowledgementContainer = styled.div<ConfimationContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.grayLightest};
  width: 100%;
  background-size: 100%;
  min-height: ${window.innerHeight}px;

  ${props => props.isLoading && loadingOverlay(32)};
`;

export const RubiconHorizontalLogo = styled.div`
  width: 165px;
  height: 30px;
  margin: 45px 30px 30px 10px;
  background: url(${RubiconLogoWithText});
  align-self: center;
`;

export const AcknowledgementMessageContainer = styled.div`
 margin: 35px 35px 35px 35px;
 padding: 10px;
 align-self: center;
 text-align: center;
 font-weight: ${theme.fontWeightMedium};
 background-color: ${theme.colors.white};
 box-shadow: 0 0 10px ${theme.colors.grayLight};
`

export const AcknowledgementMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
`

export const ConfirmationButton = styled.div`
  margin: 10px 10px 20px 10px;
  width: 100%;
  text-align: center;

  @media ${Devices.tablet}, ${Devices.mobile} {
    margin-top: 0px;
  }
`

import { DateRange } from 'contracts/core/component';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BeforeModifier, DayModifiers, DayPickerProps, DaysOfWeekModifier, Modifier, Modifiers } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FieldError } from 'react-hook-form';
import Error from './Error';
import { DatePicker as DatePickerContainer, FormGroup } from './styled';

function checkIsPrevDayDisabled(date: Date, disabledDays: Modifier[]) {
  const from = moment(date).subtract(1, 'days').toDate();
  let status = false;
  if (disabledDays && disabledDays.length > 0) {
    let before: Date | undefined = undefined;
    let daysOfWeek: number[] | undefined = undefined;
    disabledDays.forEach((item: any) => {
      if (typeof item === 'object') {
        if (item.hasOwnProperty('before')) {
          before = (item as BeforeModifier).before;
        }
        else if (item.hasOwnProperty('daysOfWeek')) {
          daysOfWeek = (item as DaysOfWeekModifier).daysOfWeek;
        }
      }
    });
    if (before) {
      if (moment(from).isSameOrBefore(before as Date, 'day')) {
        status = true;
      }
      if (daysOfWeek && !status) {
        if (moment(from).subtract(1, 'days').toDate().toDateString() === (before as Date).toDateString() &&
          (daysOfWeek as number[]).includes(moment(from).subtract(1, 'days').weekday())) {
          status = true;
        }
      }
    }
  }
  return status;
}

function getThreeDayRange(date: Date, disabledDays: Modifier[]): DateRange<Date> {
  let from = moment(date).subtract(1, 'days').toDate();
  let to = moment(date).add(1, 'days').toDate();
  let daysOfWeek = [] as number[];
  if (disabledDays && disabledDays.length > 0) {
    disabledDays.forEach((item: any) => {
      if (item instanceof Date) {
        if (from === item) {
          from = moment(date).subtract(1, 'days').toDate();
        }
        if (to === item) {
          moment(date).add(1, 'days').toDate();
        }
      } else if (typeof item === 'object') {
        if (item.hasOwnProperty('before')) {
          if (moment(from).isBefore(item.before, 'day')) {
            from = moment(date).toDate();
            to = moment(date).add(2, 'days').toDate();
            if (daysOfWeek.includes(moment(to).day())) {
              to = moment(date).add(4, 'days').toDate();
            }
          }
        }
        if (item.hasOwnProperty('daysOfWeek') && item.daysOfWeek) {
          daysOfWeek = item.daysOfWeek;
          if (item['daysOfWeek'].includes(moment(from).day())) {
            from = moment(date).subtract(3, 'days').toDate();
          }
          if (item['daysOfWeek'].includes(moment(to).day())) {
            to = moment(date).add(3, 'days').toDate();
          }
        }

      }

    })
  }
  return {
    from,
    to
  };
}


const DatePicker = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, disabledDays, onDateChanged, isThreeDayRange, value, errors, ...props }, ref) => {

    const dateFormat = 'MM/DD/YYYY';

    const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined);
    const [hoverRange, setRange] = useState<DateRange<Date> | undefined>(undefined);

    useEffect(() => {
      if (!selectedDay && value) {
        const initDate = moment(value as string, dateFormat).toDate();
        setSelectedDay(initDate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onDayChange = (day: Date, DayModifiers: DayModifiers, dayPickerInput: DayPickerInput): void => {

      const isPrevDayBefore = checkIsPrevDayDisabled(day, disabledDays as any);
      if (isThreeDayRange && isPrevDayBefore) {
        let daysOfWeekObj = {} as any
        daysOfWeekObj = disabledDays.find((item) => item && item.hasOwnProperty('daysOfWeek'));

        let newDate = moment(day).add(1, 'days').toDate();
        if (daysOfWeekObj && daysOfWeekObj.daysOfWeek && daysOfWeekObj.daysOfWeek.includes(moment(newDate).day())) {
          newDate = moment(newDate).add(2, 'days').toDate();
        }
        onDateChanged(moment(newDate).format(dateFormat));
        setSelectedDay(newDate);
        if (selectedDay && selectedDay.toDateString() === newDate.toDateString()) {
          const value = formatDate(newDate, dateFormat);
          dayPickerInput.setState({ value, typedValue: '', month: day });
        }
      } else {
        onDateChanged(moment(day).format(dateFormat));
        setSelectedDay(day)
      }
    };

    const handleDayEnter = (day: Date, modifiers = {} as DayModifiers): void => {
      if (!modifiers.disabled) {
        setRange(getThreeDayRange(day, disabledDays));
      }
    };

    const handleDayLeave = (): void => {
      setRange(undefined);
    };

    const formatDate = (date: Date, format: string) =>
      moment(date).format(format);

    const getDayPickerProps = (): DayPickerProps => {
      // 3 Day selection
      const props: DayPickerProps = {};
      props.disabledDays = disabledDays;

      if (isThreeDayRange) {
        const modifiers: Modifiers = {
          hoverRange,
          hoverRangeStart: hoverRange && hoverRange.from,
          hoverRangeEnd: hoverRange && hoverRange.to,
          today: undefined,
          outside: undefined,
        };
        props.modifiers = modifiers;
        props.onDayMouseEnter = handleDayEnter;
        props.onDayMouseLeave = handleDayLeave;
      }

      if (!selectedDay) {
        props.month = findFirstAvailableDay();
      } else {
        props.month = selectedDay;
      }

      return props;
    };

    const findFirstAvailableDay = (): Date => {
      const today = new Date();
      let day = today;
      while (isNextDayDisabled(day, disabledDays.sort())) {
        day = moment(day).add(1, 'days').toDate();
      }
      return day;
    };

    const isNextDayDisabled = (date: Date, disabledDays: Modifier[]): boolean => {
      const nextDay = moment(date).add(1, 'days').toDate();
      return disabledDays.some((disabled) => {
        if (disabled && 'before' in disabled) {
          return moment(nextDay).isSameOrBefore(disabled.before, 'day');
        } else if (disabled && 'after' in disabled) {
          return moment(nextDay).isSameOrAfter(disabled.after, 'day');
        } else if (disabled && 'daysOfWeek' in disabled) {
          return disabled.daysOfWeek.includes(nextDay.getDay());
        } else if (disabled instanceof Date) {
          return moment(nextDay).isSame(disabled, 'day');
        } else if (disabled && 'from' in disabled && 'to' in disabled) {
          return moment(nextDay).isBetween(disabled.from, disabled.to, 'day', '[]');
        }
        return false;
      });
    }

    return (
      <FormGroup>
        <DatePickerContainer>
          <DayPickerInput
            inputProps={{ ...props, ref, autoComplete: 'off' }}
            format={dateFormat}
            value={value as string}
            formatDate={formatDate}
            placeholder={label}
            onDayChange={onDayChange}
            dayPickerProps={getDayPickerProps()}
          />
        </DatePickerContainer>
        {errors && <Error error={errors} />}
      </FormGroup>
    );
  },
);

interface OwnProps {
  label: string;
  isThreeDayRange?: boolean;
  disabledDays: Modifier[];
  onDateChanged: (date: string) => void;
  errors?: FieldError;
}

type ComponentProps = OwnProps & React.InputHTMLAttributes<HTMLInputElement>;

export default DatePicker;

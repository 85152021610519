import { Panel } from 'core/components/styled/Panel2';
import React from 'react';
import { EmptySection } from '.';
import LoadingSection from './LoadingSection';

const SectionBody: React.FC<ComponentProps> = ({
  isLoading = false,
  isEmpty = false,
  emptyText,
  children,
  autoHeight,
}) => (
  <LoadingSection isLoading={isLoading} autoHeight={autoHeight}>
    <Panel resetOverFlowY autoHeight={autoHeight}>
      <EmptySection isEmpty={isEmpty} emptyText={emptyText}>
        {children}
      </EmptySection>
    </Panel>
  </LoadingSection>
);

interface ComponentProps {
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyText?: string;
  autoHeight?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
}

export default SectionBody;

import styled from 'styled-components';
import userAvatar from 'core/assets/images/user-avatar.svg';
import { theme } from 'core/styles';

export const ContactInformationForm = styled.form`
  max-width: 350px;
  margin: 100px auto 0; 
  text-align: center;
`;

export const ContactInformationSummaryContainer = styled.div`
  width: 90%;
  margin: 100px auto 0; 
  box-shadow: 0px 30px 25px rgba(0, 0, 0, 0.2);
  display:flex;
  flex-direction: row;
`;

export const UserAvatar = styled.div`
  display: block;
  width: 25%;
  background-size: 90px 90px;
  background: #02978b no-repeat center center;
  background-image: url(${userAvatar});
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const ContactInformationSection = styled.div`
  display: block;
  min-height: 180px;
  padding: 30px 15px;
`;

export const FullNameValue = styled.div`
  display: block;
  font-size: 24px;
  font-weight: ${theme.fontWeightMedium};
  line-height: 28px;
`;

export const EmailValue = styled.div`
  display: block;
  font-size: 14px;
  margin:10px 0;
  line-height: 16px;
`;

export const PhoneNrValue = styled.div`
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: ${theme.fontWeightMedium};
`;
import { ContactInformationFormData } from 'contracts/core/form';
import { Input } from 'core/components';
import { Button, FormFieldsWrapper } from 'core/components/styled';
import translate from 'core/helpers/translate';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ContactInformationForm as Form } from './styled';

const ContactInformationForm: React.FC<ComponentProps> = ({
  onSubmitDone,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNr,
  isLoading,
  defaultValues
}) => {
  const { getValues, register, handleSubmit, formState: { errors } } =
    useForm<ContactInformationFormData>({
      mode: 'onChange',
      defaultValues
    });

  const onSubmit = () => {
    onSubmitDone(getValues());
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsWrapper>
        <Input
          label={translate('firstNameFormLabel')}
          errors={errors && errors.firstName}
          {...register('firstName', {
            required: {
              value: true,
              message: translate('contactFirstNameRequiredError'),
            },
          })}
          onBlurOut={e => setFirstName(e.target.value)}
          defaultValue={defaultValues?.firstName}
        />
        <Input
          label={translate('lastNameFormLabel')}
          errors={errors && errors.lastName}
          {...register('lastName', {
            required: {
              value: true,
              message: translate('contactLastNameRequiredError'),
            },
          })}
          onBlurOut={e => setLastName(e.target.value)}
          defaultValue={defaultValues?.lastName}
        />
      </FormFieldsWrapper>
      <Input
        label={translate('emailFormLabel')}
        errors={errors && errors.email}
        {...register('email', {
          required: {
            value: true,
            message: translate('emailRequiredError'),
          },
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: translate('invalidEmailFieldError'),
          },
        })}
        onBlurOut={e => setEmail(e.target.value)}
        defaultValue={defaultValues?.email}
      />
      <Input
        label={translate('phoneFormLabel')}
        errors={errors && errors.phone}
        {...register('phone', {
          required: {
            value: true,
            message: translate('phoneRequiredError'),
          },
          pattern: {
            value:
              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            message: translate('invalidPhoneNrFieldError'),
          },
        })}
        onBlurOut={e => setPhoneNr(e.target.value)}
        defaultValue={defaultValues?.phone}
      />
      <span></span>
      <Button
        isLoading={isLoading}
        marginTop
        type='submit'
        data-automation='NextButton'
      >
        {translate('nextBtnLabel')}
      </Button>
    </Form>
  );
};

interface ComponentProps {
  onSubmitDone: (data: ContactInformationFormData) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhoneNr: (value: string) => void;
  defaultValues?: ContactInformationFormData;
  isLoading: boolean;
}

export default ContactInformationForm;

import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Routes as SavingsRoutes } from './routes';
import Environments from 'core/constants/Environments';
import { environment } from 'core/services/environment';
import { FullStoryAPI } from 'react-fullstory';
import cart from 'ducks/cart';
import { ZuoraScript } from 'core/components/Scripts';
import LocationPage from 'components/Location/LocationPage';
import ServiceDetailsConfigurationPageWrapper from 'components/Service/ServiceDetailsConfiguration/ServiceDetailsConfigurationPageWrapper';
import BillingDetailsPage from 'components/Billing/BillingDetailsPage';
import ContactInformationPage from 'components/ContactInformation/ContactInformationPage';
import StartDatePage from 'components/Startdate/StartDatePage';
import ServiceAgreementPage from 'components/ServiceAgreement/ServiceAgreementPage';
import SummaryPage from 'components/Summary/SummaryPage';
import OpenCartPage from 'components/Summary/OpenCartPage';
import RecognizedSelectionPage from 'components/Recognized/Confirmation/RecognizedConfirmationPage';
import RecognizedAcknowledgementPage from 'components/Recognized/Acknowledgement/RecognizedAcknowledgementPage';
import RecognizedConfirmationPage from 'components/Recognized/Confirmation/RecognizedConfirmationPage';
import cuid from 'cuid';
import { logEvent } from 'core/services/logging';

// const LocationPage = lazy(() => import('components/Location/LocationPage'));
// const ServiceDetailsConfigurationPageWrapper = lazy(() => import('components/Service/ServiceDetailsConfiguration/ServiceDetailsConfigurationPageWrapper'));
// const BillingDetailsPage = lazy(() => import('components/Billing/BillingDetailsPage'));
// const ContactInformationPage = lazy(() => import('components/ContactInformation/ContactInformationPage'));
// const StartDatePage = lazy(() => import('components/Startdate/StartDatePage'));
// const ServiceAgreementPage = lazy(() => import('components/ServiceAgreement/ServiceAgreementPage'));
// const SummaryPage = lazy(() => import('components/Summary/SummaryPage'));
// const OpenCartPage = lazy(() => import('components/Summary/OpenCartPage'));
// const RecognizedSelectionPage = lazy(() => import('components/Recognized/Selection/RecognizedSelectionPage'));
// const RecognizedConfirmationPage = lazy(() => import('components/Recognized/Confirmation/RecognizedConfirmationPage'));
// const RecognizedAcknowledgementPage = lazy(() => import('components/Recognized/Acknowledgement/RecognizedAcknowledgementPage'));

const MainRouter: React.FC = () => {
  //window.ga('send', 'pageview');

  const userDetails = cart.selectors.getCustomerDetails();

  useEffect(() => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      const uniqueId = cuid();
      sessionStorage.setItem('sessionId', uniqueId);
      sessionId = uniqueId;
    }
    if (window?.newrelic) {
      window?.newrelic.setCustomAttribute('uniqueId', sessionId);
    }
  }, []);

  useEffect(() => {
    try {
      if (userDetails && environment !== Environments.local) {
        const sessionId = sessionStorage.getItem('sessionId');
        FullStoryAPI('identify', userDetails.email, {
          email: userDetails.email,
          custName: userDetails.firstName || '' + userDetails.lastName || '',
          company: userDetails.company,
          phone: userDetails.phone,
          title: userDetails.title,
          uniqueId: sessionId as string,
        });
      }
    } catch (err) {
      logEvent('ROUTING_EVENT_ERROR', { err })
    }
  }, [userDetails])

  return (
    <Routes>
      <Route path={SavingsRoutes.location} element={<LocationPage />} />
      <Route path={SavingsRoutes.service} element={<ServiceDetailsConfigurationPageWrapper />} />
      <Route path={SavingsRoutes.billing} element={<ZuoraScript><BillingDetailsPage /></ZuoraScript>} />
      <Route path={SavingsRoutes.contactInformation} element={<ContactInformationPage />} />
      <Route path={SavingsRoutes.startDate} element={<StartDatePage />} />
      <Route path={SavingsRoutes.serviceAgreement} element={<ServiceAgreementPage />} />
      <Route path={SavingsRoutes.summary} element={<SummaryPage />} />
      <Route path={SavingsRoutes.openCart} element={<OpenCartPage />} />
      <Route path={SavingsRoutes.recognizedSelection} element={<RecognizedSelectionPage />} />
      <Route path={SavingsRoutes.recognizedConfirmation} element={<RecognizedConfirmationPage />} />
      <Route path={SavingsRoutes.recognizedAcknowledgement} element={<RecognizedAcknowledgementPage />} />
      {/* Default Route */}
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route index element={<Navigate to={SavingsRoutes.summary} />} />
    </Routes>
  );
};

export default MainRouter;

import React from 'react';
import { serviceAgreementRubiconAdvancedText } from '../constants/serviceAgreementText-en';
import { SERVICE_AGREEMENT } from '../constants/variables';
import {
  ServiceAgreementBody,
  ServiceFontColorPrimary,
  ServiceAgreementList,
  ServiceBoldFont,
  ServiceAgreementDownload,
} from './styled';

const ServiceAgreementAdvancedFormatted = ({
  handleScroll,
  isDownloadDisabled,
}: ComponentProps) => (
  <React.Fragment>
    <ServiceAgreementBody
      className='serviceAgreementContainer'
      onScroll={e => handleScroll(e, SERVICE_AGREEMENT)}
    >
      <p>
        {serviceAgreementRubiconAdvancedText.pleaseReadTheFollowingAgreement}
        <ServiceBoldFont> {serviceAgreementRubiconAdvancedText.iAccept} </ServiceBoldFont>
        {serviceAgreementRubiconAdvancedText.serviceAgreementFirstParagraph}
      </p>

      <p>
        {serviceAgreementRubiconAdvancedText.byClicking}{' '}
        <ServiceBoldFont> {serviceAgreementRubiconAdvancedText.iAccept} </ServiceBoldFont>
        {serviceAgreementRubiconAdvancedText.serviceAgreementSecondParagraph}
      </p>

      <ServiceAgreementList>
        <li>
          <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.amendment} </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementThirdParagraph}
          <span> </span>
          <ServiceFontColorPrimary>
            <a
              href='https://www.rubicon.com/legal/serviceagreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.rubicon.com/legal/serviceagreement
            </a>
          </ServiceFontColorPrimary>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFourthParagraph}
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.rubiconAmendment}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFifthParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.registration}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementSixthParagraph}
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.serviceAgreementSeventhParagraph}
          </ServiceBoldFont>
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.general} </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementEightParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.managementServices}{' '}
          </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.agency} </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementNinthParagraph}
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.serviceAgreementTenthParagraph}
              </ServiceBoldFont>
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.haulers} </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwelveParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.singlePointOfContact}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourteenthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.softwarePlatform}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFifteenthParagraph}
              <span> </span>
              <ServiceFontColorPrimary>
                <a href='https://www.rubicon.com'>
                  www.rubicon.com
                </a>
              </ServiceFontColorPrimary>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixteenthParagraph}

              <ServiceAgreementList>
                <li>
                  <ServiceBoldFont>
                    {serviceAgreementRubiconAdvancedText.licenseToUseSoftwarePlatform}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementRubiconAdvancedText.serviceAgreementSeventeenthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementRubiconAdvancedText.licenseToUseWasteAndRecyclingData}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementRubiconAdvancedText.serviceAgreementEighteenthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementRubiconAdvancedText.restrictions}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementRubiconAdvancedText.serviceAgreementNineteenthParagraph}
                </li>
              </ServiceAgreementList>
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.exclusivity}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwentyParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.titleToWasteMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwentyfirstParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.pricing} </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementTwentysecondParagraph}
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.serviceAgreementTwentythirdParagraph}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementTwentyfourthParagraph}

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.pricingAdjustment}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwentyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.changes} </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwentysixthParagraph}
            </li>

          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.termAndTermination}{' '}
          </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.term}. </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementTwentyeightParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.terminationByRubicon}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementThirtyfirstParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.terminationForMaterialBreach}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementThirtysecondParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.terminationForEventOfInsolvency}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementThirtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.earlyCancellationFees}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementThirtyfourthParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.customerObligationRegardingEnvironment}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyfirstParagraph}
          <span> </span>
          <ServiceFontColorPrimary>
            <a
              href='https://www.rubicon.com/legal/terms/smb'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://rubicon.com/legal/terms/smb
            </a>
          </ServiceFontColorPrimary>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFourtysecondParagraph}
          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.acceptedMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.alternativeMaterials}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.environmentalLaw}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.medicalWaste}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtysixthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.prohibitedMaterial}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyseventhParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.recyclableMaterial}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyeightParagraph}
            </li>
          </ServiceAgreementList>
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.complianceWithLaws}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFourtyninthParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.limitedWarranty}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.limitationOfLiability}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyfirstParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.indemnification}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFiftysecondParagraph}
        </li>

        <li>
          <ServiceBoldFont>
            {serviceAgreementRubiconAdvancedText.confidentialInformation}{' '}
          </ServiceBoldFont>
          {serviceAgreementRubiconAdvancedText.serviceAgreementFiftythirdParagraph}
        </li>

        <li>
          <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.other} </ServiceBoldFont>

          <ServiceAgreementList>
            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.governingLaw}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.disputeResolution}{' '}
              </ServiceBoldFont>

              <ServiceAgreementList>
                <li>
                  <ServiceBoldFont>
                    {serviceAgreementRubiconAdvancedText.arbitration}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyfifthParagraph}
                </li>

                <li>
                  <ServiceBoldFont>
                    {serviceAgreementRubiconAdvancedText.arbitrationProcess}{' '}
                  </ServiceBoldFont>
                  {serviceAgreementRubiconAdvancedText.serviceAgreementFiftysixthParagraph}
                </li>
              </ServiceAgreementList>
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.equitableRemediesAndSpecificPerformance}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyseventhParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.forceMajeure}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyeightParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.notices} </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementFiftyninthParagraph}
              <span> </span>
              <ServiceFontColorPrimary>
                <a
                  href='https://www.rubicon.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  www.rubicon.com
                </a>
              </ServiceFontColorPrimary>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtyParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.completeAgreement}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtyfirstParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.assingnment}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtysecondParagraph}
            </li>

            <li>
              <ServiceBoldFont>{serviceAgreementRubiconAdvancedText.waiver} </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtythirdParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.severability}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtyfourthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.counterparts}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtyfifthParagraph}
            </li>

            <li>
              <ServiceBoldFont>
                {serviceAgreementRubiconAdvancedText.survival}{' '}
              </ServiceBoldFont>
              {serviceAgreementRubiconAdvancedText.serviceAgreementSixtysixthParagraph}
            </li>
          </ServiceAgreementList>
        </li>
      </ServiceAgreementList>

      <p>{serviceAgreementRubiconAdvancedText.serviceAgreementSixtyseventhParagraph}</p>
    </ServiceAgreementBody>

    <ServiceAgreementDownload
      href={process.env.REACT_APP_SAVINGS_HOST + 'download/service-agreement-advanced.pdf'}
      target='_blank'
      disabled={isDownloadDisabled}
    >
      {serviceAgreementRubiconAdvancedText.downloadServiceAgreement}
    </ServiceAgreementDownload>
  </React.Fragment>
);

interface ComponentProps {
  handleScroll: (e: any, agreementName: string) => any;
  isDownloadDisabled?: boolean;
}

export default ServiceAgreementAdvancedFormatted;
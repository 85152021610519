import React from 'react';
import Script from './Script';

const GoogleMapsScript: React.FC<ComponentProps> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='googleMaps'
      isUrl
      content='https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBEPAkh2NlU4LSfqi5GxJZYz-saLmk0obs&language=en'
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
      children={children}
    />
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  children?: React.ReactNode;
}

export default GoogleMapsScript;

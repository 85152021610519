import { isEmpty } from 'lodash';
import {
  PartialSitePaymentDetails,
  SiteDataView,
} from 'contracts/models/SiteDataView';
import { SubscriptionLocationBillingDataView } from 'contracts/models/SubscriptionLocationBillingDataView';
import { OptionType } from 'contracts/core/form';
import { PaymentMethodDetailsResponse } from 'contracts/models/Zuora';

export const getExistingBillingDetails = (
  locations: SiteDataView[],
): SubscriptionLocationBillingDataView[] => {
  const bilingDetailsArr = [] as SubscriptionLocationBillingDataView[];
  locations.forEach(location => {
    if (
      !isEmpty(location.billingDetail) &&
      location.billingDetail &&
      location.billingDetail.businessName &&
      location.billingDetail.contactFirstName &&
      location.billingDetail.contactLastName &&
      location.billingDetail.streetAddress &&
      location.billingDetail.state &&
      location.billingDetail.city &&
      location.billingDetail.zip
    ) {
      bilingDetailsArr.push({
        ...location.billingDetail,
        isExistingBilling: false,
      });
    }
  });
  return bilingDetailsArr;
};

export const formatExistingBillingDetailsOptions = (
  bilingDetailsArr: SubscriptionLocationBillingDataView[],
): OptionType[] => {
  const options = [] as OptionType[];
  const filteredBilingDetailsArr = bilingDetailsArr.filter(
    (thing, index, self) =>
      index ===
      self.findIndex(
        t =>
          t.businessName === thing.businessName &&
          t.city === thing.city &&
          t.contactFirstName === thing.contactFirstName &&
          t.contactLastName === thing.contactLastName &&
          t.state === thing.state &&
          t.streetAddress === thing.streetAddress &&
          t.zip === thing.zip,
      ),
  );

  filteredBilingDetailsArr.forEach((set, key) => {
    options.push({
      label: `${set.businessName} billing details`,
      code: `${key}`,
    });
  });
  return options;
};

export const getPaymentMethodLabel = (
  paymentDetailsLabel: PaymentMethodDetailsResponse,
): string => {
  let paymentMethodLabel = '';
  if (
    paymentDetailsLabel.isCreditCard &&
    paymentDetailsLabel.creditCardMarkNumber &&
    paymentDetailsLabel.creditCardType
  ) {
    paymentMethodLabel = `${paymentDetailsLabel.creditCardType} ending in ${paymentDetailsLabel.creditCardMarkNumber}`;
  }
  if (
    !paymentDetailsLabel.isCreditCard &&
    paymentDetailsLabel.achAccountNumberMask
  ) {
    paymentMethodLabel = `Account number ending in ${paymentDetailsLabel.achAccountNumberMask}`;
  }
  paymentMethodLabel = paymentMethodLabel.replaceAll('*', '');
  return paymentMethodLabel;
};

export const getSitePaymentDetails = (
  locations: SiteDataView[],
): PartialSitePaymentDetails[] => {
  const paymentDetailsArr = [] as PartialSitePaymentDetails[];
  locations.forEach(location => {
    if (
      !isEmpty(location) &&
      location.paymentNonce &&
      location.paymentMethodType &&
      location.paymentMethodLabel
    ) {
      // Add unique payment methods only
      if (
        paymentDetailsArr.findIndex(
          pObj =>
            pObj.paymentNonce && pObj.paymentNonce === location.paymentNonce,
        ) === -1
      ) {
        paymentDetailsArr.push({
          paymentNonce: location.paymentNonce,
          paymentMethodType: location.paymentMethodType,
          paymentMethodLabel: location.paymentMethodLabel,
          paymentAutoPay: location.paymentAutoPay,
        });
      }
    }
  });
  return paymentDetailsArr;
};

export const formatExistingPaymentDetailsOptions = (
  paymentDetailsArr: PartialSitePaymentDetails[],
): OptionType[] => {
  const filteredPaymentDetailsArr = paymentDetailsArr.filter(
    (thing, index, self) =>
      index === self.findIndex(t => t.paymentNonce === thing.paymentNonce),
  );
  const options = [] as OptionType[];
  filteredPaymentDetailsArr.forEach((set, key) => {
    options.push({
      label: set.paymentMethodLabel || '',
      code: `${key}`,
    });
  });
  return options;
};

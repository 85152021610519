import styled from 'styled-components';
import { theme } from 'core/styles';
import { Button } from 'core/components/styled';

export const BillingForm = styled.form`
  max-width: 350px;
  margin: 35px auto;
  text-align: center;
`;

export const PaymentFormWrapper = styled.div`
  display: block;
  max-width: 350px;
  margin: 35px auto;
  text-align: center;
`;

export const PaymentMethodRadioButtonContainer = styled.div`
  margin: 0px 0px 40px 0px;
`;

export const ZuoraWrapper = styled.div`
  height: 500px;
`;

export const CheckPaymentModalContentWrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 40px 90px;
`;

export const CheckPaymentModalTitle = styled.div`
  display: block;
  margin-bottom: 60px;
  line-height: 38px;
  letter-spacing: 0.4px;
  font-weight: ${theme.fontWeightBold};
  font-size: 32px;
  text-align: center;
`;

export const SavePaperImg = styled.img`
  display: block;
  height: 165px;
  margin: 0 auto 60px;
`;

export const CheckPaymentModalDescription = styled.div`
  display: block;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 20px;
  font-weight: ${theme.fontWeightMedium};
  color: ${theme.colors.grayText};
  text-align: center;
`;

export const CheckPaymentButtonsWrapper = styled.div`
  display: block;
  margin-top: 60px;
  text-align: center;
  ${Button} {
    display: block;
  }
`;

import React from 'react';
import {
  InformationTooltipContainer,
  InformationTooltipIcon,
  InformationTooltipMessage,
} from './styled';

const InformationTooltip: React.FC<ComponentProps> = ({ message }) => (
  <InformationTooltipContainer>
    <InformationTooltipIcon>i</InformationTooltipIcon>
    <InformationTooltipMessage>{message}</InformationTooltipMessage>
  </InformationTooltipContainer>
);

interface ComponentProps {
  message: string;
}

export default InformationTooltip;

import { AxiosError } from 'axios';
import { ActionDispatcher } from 'contracts/core/action';
import { ServiceError } from 'contracts/core/service';
import { ApplicationState } from 'contracts/core/state';
import NotificationType from 'contracts/enums/NotificationType';
import {
  createNotificationMessage,
  createServiceErrorNotificationMessage,
} from 'core/ducks/notifier';
import RunningActions from 'core/ducks/running';
import { logError as logErrorFunc } from 'core/logging';

export async function runParallelThunk(
  dispatch: ActionDispatcher,
  actionName: string,
  bodyFunc: () => Promise<void>,
  trackRunning: boolean = true,
  showErrorNotification: boolean = false,
  customErrorMessage?: string,
  logError: boolean = true,
): Promise<void> {
  let timestamp = '';
  if (trackRunning) {
    timestamp = RunningActions.getRunningTimestamp();
    dispatch(RunningActions.startAction(actionName, timestamp));
  }
  try {
    await bodyFunc();
  } catch (error:any) {
    if (showErrorNotification) {
      if (customErrorMessage) {
        dispatch(
          createNotificationMessage(NotificationType.Error, customErrorMessage),
        );
      } else {
        dispatch(createServiceErrorNotificationMessage(error));
      }
    }
    if (logError) {
      logErrorFunc(error);
    }
  } finally {
    if (trackRunning) {
      dispatch(RunningActions.completeAction(actionName, timestamp));
    }
  }
}

export async function runTakeLastThunk<T>(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
  actionName: string,
  bodyFunc: () => Promise<T>,
  onDone: (result: T) => void,
  onFail?: (error: AxiosError<ServiceError>) => void,
  showErrorNotification: boolean = false,
  customErrorMessage?: string,
  logError: boolean = true,
): Promise<T> {
  const timestamp = RunningActions.getRunningTimestamp();
  dispatch(RunningActions.startAction(actionName, timestamp, true));
  try {
    const result = await bodyFunc();
    const lastTimestamp = RunningActions.getLastTimestamp(
      getState(),
      actionName,
    );
    if (timestamp === lastTimestamp && onDone) {
      onDone(result);
    }
    return Promise.resolve(result);
  } catch (error: any) {
    const lastTimestamp = RunningActions.getLastTimestamp(
      getState(),
      actionName,
    );
    if (timestamp === lastTimestamp && onFail) {
      onFail(error);
    }
    if (showErrorNotification) {
      if (customErrorMessage) {
        dispatch(
          createNotificationMessage(NotificationType.Error, customErrorMessage),
        );
      } else {
        dispatch(createServiceErrorNotificationMessage(error));
      }
    }
    if (logError) {
      logErrorFunc(error);
    }
    return Promise.reject(error);
  } finally {
    dispatch(RunningActions.completeAction(actionName, timestamp, true));
  }
}

import React from 'react';
import { EmptySectionContainer } from './styled/EmptySection';

const defaultText =
  'We could not find any relevant data. Please extend your search criteria.';

const EmptySection: React.FC<ComponentProps> = ({
  isEmpty,
  children,
  emptyText = defaultText,
}): JSX.Element =>
  isEmpty ? (
    <React.Fragment>
      <EmptySectionContainer>{emptyText}</EmptySectionContainer>
    </React.Fragment>
  ) : (
    <>{children}</>
  );

interface ComponentProps {
  isEmpty: boolean;
  emptyText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
}

export default EmptySection;

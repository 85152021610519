import { CartLimitations } from 'contracts/models/CartLimitations';
import { HolidayCalendarDataView } from 'contracts/models/HolidayCalendarDataView';
import { MarketConfigAddressDataView } from 'contracts/models/MarketConfigZipCodesDataView';
import { OpenSaleCartDataView } from 'contracts/models/OpenSaleCartDataView';
import {
  RetailPricesDataView,
  RetailPricesResponseDataView
} from 'contracts/models/RetailPriceDataView';
import {
  SaleCartDataView
} from 'contracts/models/SaleCartDataView';
import { SaleMarketConfigDataView } from 'contracts/models/SaleMarketConfigDataView';
import { apiCartToCart, cartToApiCart } from 'core/helpers/wizard';
import { httpSavingsCore } from 'core/services/http';
import { setSessionAuthToken } from 'core/services/session';

export async function getShoppingCartByID(
  ID: string,
): Promise<SaleCartDataView> {
  const response = await httpSavingsCore.get<OpenSaleCartDataView>(
    `/sale/shoppingcart/${ID}`,
  );
  const authToken = response.headers.authorization;
  setSessionAuthToken(authToken);
  const data = apiCartToCart(response.data);
  return data;
}

export async function getMarketConfiguration(
  request: MarketConfigAddressDataView,
  hasCartPermanentSelection = false
): Promise<SaleMarketConfigDataView> {
  const response = await httpSavingsCore.post<SaleMarketConfigDataView>(
    `/sale/marketconfig?hasCartPermanentSelection=${hasCartPermanentSelection}`,
    {
      marketConfigToken: request.marketConfigToken,
      zipCode: request.zipCode,
      address: request.address,
    },
  );
  return response.data;
}

export async function getServiceQuote(
  request: RetailPricesDataView,
): Promise<RetailPricesResponseDataView> {
  const response = await httpSavingsCore.post<RetailPricesResponseDataView>(
    '/sale/price/retail',
    {
      ...request,
    },
  );
  return response.data;
}

export async function confirmLicenseAgreement(
  cart: SaleCartDataView,
): Promise<any> {
  const apiCart = cartToApiCart(cart);
  const response = await httpSavingsCore.post(
    '/sale/packagepurchase/agreementconfirmation',
    {
      apiCart,
    },
  );
  return response.data;
}

export async function getHolidays(
  dayCount: number,
): Promise<{ holidays: HolidayCalendarDataView[] }> {
  const response = await httpSavingsCore.get<{
    holidays: HolidayCalendarDataView[];
  }>(`/holidaycalendar/nextdays/${dayCount}`);
  return response.data;
}

export async function getCartLimitations(): Promise<CartLimitations> {
  const response = await httpSavingsCore.get<CartLimitations>(`/sale/limit`);
  return response.data;
}


import { InformationTooltip } from 'core/components';
import translate from 'core/helpers/translate';
import { currency } from 'core/services/formatter';
import React from 'react';
import {
  PriceSummaryContainer,
  PriceSummaryItem,
  PriceSummaryItemLabel,
  PriceSummaryItemValue,
} from './styled';

const PriceSummary: React.FC<ComponentProps> = ({
  bold,
  activationCost,
  monthlyCost,
  managementFee,
}) => {
  return (
    <PriceSummaryContainer>
      <PriceSummaryItem>
        <PriceSummaryItemLabel>
          {translate('serviceActivation')}
          <InformationTooltip
            message={translate('serviceActivationInformationTooltip')}
          ></InformationTooltip>
        </PriceSummaryItemLabel>
        <PriceSummaryItemValue bold={bold}>
          {currency(activationCost)}
        </PriceSummaryItemValue>
      </PriceSummaryItem>
      <PriceSummaryItem>
        <PriceSummaryItemLabel>
          {translate('monthly')}
          <InformationTooltip
            message={translate('pricingInformationTooltip')}
          ></InformationTooltip>
        </PriceSummaryItemLabel>
        <PriceSummaryItemValue bold={bold}>
          {currency(monthlyCost)}
        </PriceSummaryItemValue>
      </PriceSummaryItem>
      {managementFee ? (
        <PriceSummaryItem>
          <PriceSummaryItemLabel>
            {translate('managementFee')}
            <InformationTooltip
              message={translate('managementInformationTooltip')}
            ></InformationTooltip>
          </PriceSummaryItemLabel>
          <PriceSummaryItemValue bold={bold}>
            {currency(managementFee)}
          </PriceSummaryItemValue>
        </PriceSummaryItem>
      ) : null}
    </PriceSummaryContainer>
  );
};

interface ComponentProps {
  bold?: boolean;
  activationCost: number;
  monthlyCost: number;
  managementFee?: number | null;
}

export default PriceSummary;

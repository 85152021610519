import { RecognizedEquipmentSourceItem } from "contracts/models/RecognizedEquipmentSourceItem";
import aerosol24 from 'components/Recognized/assets/aerosol-24.png';
import aerosol12 from 'components/Recognized/assets/aerosol-12.png';
import plasticBags from 'components/Recognized/assets/plasticBags.png';
import footFl4 from 'components/Recognized/assets/4Foot-FL.png';
import footLed4 from 'components/Recognized/assets/4Foot-LED.png';
import lampsFl from 'components/Recognized/assets/lamps-FL.png';
import lampsLed from 'components/Recognized/assets/lamps-LED.png';
import electronics from 'components/Recognized/assets/electronics.png';
import bucket06 from 'components/Recognized/assets/0.6Bucket.png';
import bucket125 from 'components/Recognized/assets/1.25Bucket.png';
import bucket35 from 'components/Recognized/assets/3.5Bucket.png';
import bucket06Alkaline from 'components/Recognized/assets/0.6Bucket-Alkaline.png';
import bucket125Alkaline from 'components/Recognized/assets/1.25Bucket-Alkaline.png';
import bucket35Alkaline from 'components/Recognized/assets/3.5Bucket-Alkaline.png';
import ppe from 'components/Recognized/assets/ppe.png';
import collateralKit1 from 'components/Recognized/assets/Kit-1.png';
import collateralKit2 from 'components/Recognized/assets/Kit-2.png';
import collateralKit3 from 'components/Recognized/assets/Kit-3.png';
import collateralKit4 from 'components/Recognized/assets/Kit-4.png';

export const recognizedEquipmentsSource: RecognizedEquipmentSourceItem[] = [
  {
    id: 1,
    file: aerosol24,
    description: "Aerosol Can Recycling (24 can)",
    sku: "RUBMKT0004",
    isSelected: false
  },
  {
    id: 2,
    file: aerosol12,
    description: "Aerosol Can Recycling (12 can)",
    sku: "RUBMKT0003",
    isSelected: false
  },
  {
    id: 3,
    file: plasticBags,
    description: "Plastic Bags and Film Recycling",
    sku: "RUBMKT0001",
    isSelected: false
  },
  //Id 4 - Mixed recycling was removed as RSP-44 CR
  {
    id: 5,
    file: footFl4,
    description: "Fluorescent Tube Recycling (4 Foot)",
    sku: "RUBMKT0005",
    isSelected: false
  },
  {
    id: 6,
    file: footLed4,
    description: "LED Tube Recycling (4 Foot)",
    sku: "RUBMKT0006",
    isSelected: false
  },
  {
    id: 7,
    file: lampsFl,
    description: "Fluorescent Light Bulb Recycling",
    sku: "RUBMKT0007",
    isSelected: false
  },
  {
    id: 8,
    file: lampsLed,
    description: "LED Light Bulb Recycling",
    sku: "RUBMKT0008",
    isSelected: false
  },
  {
    id: 9,
    file: electronics,
    description: "Electronics Recycling",
    sku: "RUBMKT0009",
    isSelected: false
  },
  {
    id: 10,
    file: bucket06,
    description: "Mixed Battery Recycling (0.6 gallon)",
    sku: "RUBMKT0010",
    isSelected: false
  },
  {
    id: 11,
    file: bucket125,
    description: "Mixed Battery Recycling (1.25 gallon)",
    sku: "RUBMKT0011",
    isSelected: false
  },
  {
    id: 12,
    file: bucket35,
    description: "Mixed Battery Recycling (3.5 gallon)",
    sku: "RUBMKT0012",
    isSelected: false
  },
  {
    id: 13,
    file: bucket06Alkaline,
    description: "Alkaline Battery Recycling (0.6 gallon)",
    sku: "RUBMKT0013",
    isSelected: false
  },
  {
    id: 14,
    file: bucket125Alkaline,
    description: "Alkaline Battery Recycling (1.25 gallon)",
    sku: "RUBMKT0014",
    isSelected: false
  },
  {
    id: 15,
    file: bucket35Alkaline,
    description: "Alkaline Battery Recycling (3.5 gallon)",
    sku: "RUBMKT0015",
    isSelected: false
  },
  {
    id: 16,
    file: ppe,
    description: "PPE to Power",
    sku: "RUBMKT0016",
    isSelected: false
  },
]

export const recognizedCollateralEquipmentsSource: RecognizedEquipmentSourceItem[] = [
  {
    id: 1,
    file: collateralKit1,
    description: "Rubicon Recognized Collateral Kit #1: 11”x17” Posters (Landfill-1, SSR, and Composting) + Window Cling",
    sku: "RUBMKT0019",
    isSelected: false
  },
  {
    id: 2,
    file: collateralKit2,
    description: "Rubicon Recognized Collateral Kit #2: 18”x24” Posters (Landfill-1, SSR, and Composting) + Window Cling",
    sku: "RUBMKT0020",
    isSelected: false
  },
  {
    id: 3,
    file: collateralKit3,
    description: "Rubicon Recognized Collateral Kit #3: 11”x17” Posters (Landfill-2 and SSR) + Window Cling",
    sku: "RUBMKT0021",
    isSelected: false
  },
  {
    id: 4,
    file: collateralKit4,
    description: "Rubicon Recognized Collateral Kit #4: 18”x24” Posters (Landfill-2 and SSR) + Window Cling",
    sku: "RUBMKT0022",
    isSelected: false
  },
];

import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { pulse, theme } from '../../styles';
import { iterator } from '../../../utils/styles';

export const Option = styled.li`
  display: block;
  padding: 14px 12px;
  cursor: pointer;
  border: 1px solid transparent;
  border-top-color: ${transparentize(0.9, theme.colors.black)};

  &:hover {
    border-color: transparent;
    box-shadow: 0 4px 32px ${transparentize(0.9, theme.colors.black)};
  }

  &:hover + &,
  &:first-child {
    border-top-color: transparent;
  }

  &:last-child {
    border-bottom-color: ${transparentize(0.9, theme.colors.black)};
  }
`;

interface OptionListProps {
  animated?: boolean;
  inLineDisplay?: boolean;
}

export const OptionList = styled.ul<OptionListProps>`
  ${props =>
    props.animated &&
    css`
      ${Option} {
        animation: ${pulse} 0.3s ease-out backwards;
        ${iterator(
      6,
      step =>
        `&:nth-child(${step + 1}) {animation-delay: ${(step + 1) * 0.1}s;}`,
    )};
     &:last-child {
        border-bottom: 0px;
      }
      }
    `};
    ${props =>
    props.inLineDisplay &&
    css`
      ${Option} {
        display: inline-block;
        border: 0px;
        padding: 14px 24px 14px 0px;
      }
    `}
`;

import { OptionType } from "contracts/core/form";
import { RecognizedEquipmentSourceItem } from "contracts/models/RecognizedEquipmentSourceItem";
import { LocationDataView } from "contracts/models/RecognizedLocationDataView";
import { Routes } from 'routing/routes';
import { NavigateFunction } from 'react-router';

export const getSelectedId = (recognizedEquipmentSourceItem: RecognizedEquipmentSourceItem[]): number => {
  const selectedId = recognizedEquipmentSourceItem.filter(eq => eq.isSelected === true)[0]?.id
  return selectedId;
}

export const getIsRecognizedSelectionInvalid = (recognizedEquipmentSourceItem: RecognizedEquipmentSourceItem[]): boolean => {
  const isRecognizedSelectionInvalid = recognizedEquipmentSourceItem.filter(eq => eq.isSelected === true).length === 0;
  return isRecognizedSelectionInvalid;
}

export const goToConfirmationPage = (recognizedId: number, collateralId: number, alias: string, navigate: NavigateFunction): void => {
  const route = Routes.recognizedConfirmation.replace(':recognizedId', recognizedId.toString()).replace(':collateralId', collateralId.toString()).replace(':alias', alias);
  navigate(route);
}

export const getSelectedItem = (recognizedEquipmentSourceItem: RecognizedEquipmentSourceItem[], selectedItemId: string | null): RecognizedEquipmentSourceItem => {
  const selectedItem = recognizedEquipmentSourceItem.filter(eq => eq.id.toString() === selectedItemId)[0];
  return selectedItem;
}

export const getAddressOptions = (locations: LocationDataView[]): OptionType[] => {
  const addressOptions = locations.map(location => {
    const option: OptionType = {
      code: `${location.line1}`,
      label: `${location.line1}`
    }
    return option;
  })
  return addressOptions;
}

export const markEquipmentAsSelected = (equipments: RecognizedEquipmentSourceItem[], description: string): RecognizedEquipmentSourceItem[] => {
  const processedEquipments = equipments.map(eq => {
    if (eq.description === description) eq.isSelected = true;
    else eq.isSelected = false;
    return eq;
  });
  return processedEquipments;
}

import { loadingOverlay, theme } from 'core/styles';
import { darken } from 'polished';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

export const BareButtonStyle = `
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  user-select: none;
`;

export interface ButtonProps extends LoadingStyledComponentProps {
  dropdownButton?: boolean;
  inverted?: boolean;
  normalText?: boolean;
  capitalize?: boolean;
  withMargins?: boolean;
  marginBottom?: boolean;
  marginTop?: boolean;
  width?: string;
  right?: boolean;
  spaced?: boolean;
  rectangle?: boolean;
  isLoading?: boolean;
  isFlex?: boolean;
  modalButton?: boolean;
  center?: boolean;
  block?: boolean;
  simpleText?: boolean;
  recognized?: boolean;
}
export const Button = styled.button<ButtonProps>`
  ${BareButtonStyle};
  min-width: 160px;
  padding: 11px 30px;
  background-color: ${props => props.theme.colors.brandPrimary};
  border-radius: 40px;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 14px;
  color: ${theme.colors.white};
  transition: all 0.2s ease;

  &:hover:enabled {
    background-color: ${props =>
    darken(0.025, props.theme.colors.brandPrimary)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${props =>
    props.dropdownButton &&
    css`
      min-width: 80px;
      width: 80px !important;
      padding-top: 11px;
      padding-bottom: 11px;
    `}

  ${props =>
    props.inverted &&
    css`
      background-color: transparent;
      border: 1px solid ${props.theme.colors.brandPrimary};
      color: ${props.theme.colors.brandPrimary};

      &:hover:enabled {
        background-color: transparent;
        border: 1px solid ${darken(0.1, props.theme.colors.brandPrimary)};
        color: ${darken(0.1, props.theme.colors.brandPrimary)};
      }
    `};
  ${props =>
    props.simpleText &&
    css`
      text-transform: none;
      background-color: transparent;
      border: none;
      color: ${props.theme.colors.brandPrimary};
      text-decoration: underline;
      &:hover:enabled {
        background-color: transparent;
        color: ${darken(0.1, props.theme.colors.brandPrimary)};
      }
    `};
  ${props =>
    props.normalText &&
    css`
      text-transform: none;
    `};
  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
  ${props =>
    props.withMargins &&
    css`
      margin: 0px 10px;
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 15px;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin-top: 20px;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.right &&
    css`
      float: right;
    `};
  ${props =>
    props.spaced &&
    css`
      margin-right: 15px;
    `};
  ${props =>
    props.rectangle &&
    css`
      min-width: 130px;
      padding: 10px 10px;
      border-radius: 5px;
      letter-spacing: 0.5px;
      line-height: 10px;
      font-weight: ${props.theme.fontWeightNormal};
      font-size: 12px;
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay(24, theme.colors.white, 0.5)};
      pointer-events: none;
    `};
  ${props =>
    props.isFlex &&
    css`
      display: flex;
      margin: 40px auto 0;
    `};

  ${props =>
    props.modalButton &&
    css`
      min-width: 100px !important;
      margin-left: 10px;
      margin-right: 10px;
    `}
  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
  ${props =>
    props.block &&
    css`
      display: block;
    `};

    ${props =>
    props.recognized &&
    css`
      min-width: 110px !important;
      height: 26px;
      padding: 0px;
    `};
`;

interface EditButtonProps {
  smallPadding?: boolean;
  spaced?: boolean;
  capitalize?: boolean;
}
export const EditButton = styled.button<EditButtonProps>`
  ${BareButtonStyle};
  min-width: 10px;
  padding: 5px 20px;
  background-color: ${props => props.theme.colors.brandPrimary};
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 14px;
  color: ${theme.colors.white};
  transition: all 0.2s ease;
  float: right;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.brandPrimary};
  color: ${props => props.theme.colors.brandPrimary};
  margin-bottom: 5px;

  ${props =>
    props.smallPadding &&
    css`
      padding: 5px 10px;
    `}

  &:hover {
    color: white;
  }
  &:hover {
    background-color: ${props =>
    darken(0.025, props.theme.colors.brandPrimary)};
  }
  ${props =>
    props.spaced &&
    css`
      margin-left: 10px;
    `};
  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

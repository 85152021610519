import React from 'react';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { deleteNotificationMessage } from '../ducks/notifier';
import { NotificationItem } from 'contracts/core/component';
import { ApplicationState } from 'contracts/core/state';
import Notification from './Notification';

const Notifier: React.FC<ComponentProps> = ({
  notifications,
  deleteNotification,
}) => {
  const onNotificationClose = (notification: NotificationItem) => {
    if (notification) {
      deleteNotification(notification.id);
    }
  };

  const displayNotifications: JSX.Element[] = [];
  notifications.forEach(notification => {
    displayNotifications.push(
      <CSSTransition
        key={notification.id}
        classNames='notifier-'
        timeout={{ enter: 500, exit: 300 }}
      >
        <Notification
          type={notification.type}
          message={notification.message}
          onNotificationClose={() => onNotificationClose(notification)}
        />
      </CSSTransition>,
    );
    if (notification.duration && !notification.timer) {
      notification.timer = setTimeout(() => {
        onNotificationClose(notification);
      }, notification.duration);
    }
  });

  return (
    <TransitionGroup className='notifier'>
      {displayNotifications}
    </TransitionGroup>
  );
};

interface StateProps {
  notifications: NotificationItem[];
}

interface DispatchProps {
  deleteNotification: (id: number) => any;
}

interface OwnProps { }

type ComponentProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  ApplicationState
> = (state: ApplicationState): StateProps => {
  const { notifications } = state.core.notifier;
  return {
    notifications,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  deleteNotification: deleteNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);

import React, { SVGProps } from 'react';

const renderIcon = (icon: string) => {
  switch (icon) {
    case 'overview':
      return (
        <g transform='translate(1 1)' fill='none' fillRule='evenodd'>
          <circle
            stroke='currentColor'
            strokeWidth='2'
            cx='15'
            cy='15'
            r='15'
          />
          <path
            d='M7.97916667,8 L23.0208333,8 C24.1138969,8 25,8.83946609 25,9.875 L25,21.125 C25,22.1605339 24.1138969,23 23.0208333,23 L7.97916667,23 C6.8861031,23 6,22.1605339 6,21.125 L6,9.875 C6,8.83946609 6.8861031,8 7.97916667,8 Z M9.2316625,21.803075 C9.62874275,21.9762944 10.0988846,21.8121334 10.2823229,21.4362125 L12.1816896,17.5391 L13.7542167,19.02785 C13.9372404,19.2012262 14.1981658,19.2788964 14.4529697,19.2358492 C14.7077736,19.192802 14.9242758,19.034474 15.0327583,18.81185 L16.8518896,15.0794375 L18.4309875,17.073275 C18.6078041,17.296317 18.9006234,17.4080338 19.1912547,17.3633332 C19.481886,17.3186326 19.7223594,17.1248926 19.8156125,16.8603125 L22.1912458,10.1125625 C22.3294309,9.71953597 22.1051405,9.29479971 21.6902792,9.16388752 C21.2754178,9.03297532 20.8270851,9.24546097 20.6889,9.6384875 L18.7913146,15.028775 L17.3227729,13.1744375 C17.1553833,12.9632415 16.8831191,12.8509675 16.6058964,12.8788181 C16.3286737,12.9066687 16.0873202,13.0705422 15.9704083,13.3103 L14.0710812,17.2074125 L12.4985937,15.7186625 C12.3155099,15.5453104 12.0545569,15.4676635 11.7997287,15.5107142 C11.5449006,15.553765 11.3283633,15.7120793 11.2198146,15.9347 L8.84457708,20.8078625 C8.66139519,21.1839483 8.83469511,21.6295095 9.2316625,21.803075 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      );

    case 'edit':
      return (
        <path
          d='M.023 31.299l1.469-4.897A5.815 5.815 0 0 1 2.95 23.96L21.617 5.29a.727.727 0 0 1 1.028 0l4.06 4.062a.727.727 0 0 1 0 1.028L8.038 29.049a5.82 5.82 0 0 1-2.443 1.46L.702 31.977a.546.546 0 0 1-.679-.678zM29.365.637l1.999 1.998c.848.849.848 2.224 0 3.073l-2.436 2.435a.724.724 0 0 1-1.025 0l-4.047-4.047a.725.725 0 0 1 0-1.024L26.292.636a2.172 2.172 0 0 1 3.073 0z'
          fill='currentColor'
          fillRule='nonzero'
        />
      );

    case 'delete':
      return (
        <path
          d='M26.82 9.332H4.323C3.593 9.332 3 8.735 3 8V6.665c0-.736.592-1.333 1.323-1.333h3.97c0-1.954.407-3.095 1.313-4.008C10.512.41 11.642 0 13.58 0h3.982c1.94 0 3.069.41 3.974 1.324.906.913 1.314 2.054 1.314 4.008h3.97c.73 0 1.323.597 1.323 1.333V8c0 .736-.593 1.333-1.323 1.333zm-7.273-6.004a2.529 2.529 0 0 0-1.988-.662h-3.976a2.529 2.529 0 0 0-1.987.662 2.578 2.578 0 0 0-.656 2.004h9.263a2.578 2.578 0 0 0-.656-2.004zm5.943 7.338a.66.66 0 0 1 .483.21.67.67 0 0 1 .18.498l-1.168 18.75A1.996 1.996 0 0 1 22.997 32H8.144a1.996 1.996 0 0 1-1.988-1.875L4.988 11.374a.67.67 0 0 1 .18-.499.66.66 0 0 1 .483-.21H25.49z'
          fill='currentColor'
          fillRule='nonzero'
        />
      );

    case 'close':
      return (
        <g
          stroke='currentColor'
          strokeWidth='5'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='square'
        >
          <path d='M28 4L4 28M28 28L4 4' />
        </g>
      );

    case 'download':
      return (
        <g transform='translate(1 1)' fill='none' fillRule='evenodd'>
          <circle
            stroke='currentColor'
            strokeWidth='2'
            cx='15'
            cy='15'
            r='15'
          />
          <path
            d='M395.25,153h-102V0h-153v153h-102l178.5,178.5L395.25,153z M38.25,382.5v51h357v-51H38.25z'
            fill='currentColor'
          />
        </g>
      );

    case 'information':
      return (
        <path
          d='M14.4 24h3.2v-9.6h-3.2V24zM16 0C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16S24.832 0 16 0zm0 28.8C8.944 28.8 3.2 23.056 3.2 16 3.2 8.944 8.944 3.2 16 3.2c7.056 0 12.8 5.744 12.8 12.8 0 7.056-5.744 12.8-12.8 12.8zm-1.6-17.6h3.2V8h-3.2v3.2z'
          fill='currentColor'
          fillRule='nonzero'
        />
      );

    default:
      return false;
  }
};

export const renderViewBox = (viewBox: string) => {
  switch (viewBox) {
    case 'download':
      return '0 0 433.5 433.5';

    default:
      return '0 0 32 32';
  }
};

const Icon: React.FC<ComponentProps> = ({ className, icon, onClick }) => (
  <svg
    viewBox={renderViewBox(icon)}
    preserveAspectRatio='xMidYMid meet'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={e => {
      if (onClick) {
        onClick(e);
      }
    }}
  >
    {renderIcon(icon)}
  </svg>
);

interface OwnProps {
  icon: string;
}
type ComponentProps = OwnProps & SVGProps<any>;

export default Icon;

import {
  CurrentServiceForm,
  ServiceDetailsFormData
} from 'contracts/core/form';
import { ApplicationState } from 'contracts/core/state';
import EquipmentType from 'contracts/enums/EquipmentType';
import FormType from 'contracts/enums/FormType';
import { RetailPricesDataView } from 'contracts/models/RetailPriceDataView';
import { SalesEditableServiceOptions, ServiceDetailsFormOptions } from 'contracts/models/SaleMarketConfigDataView';
import { SiteDataView } from 'contracts/models/SiteDataView';
import { RadioGroup, Select } from 'core/components';
import Amount from 'core/components/Amount';
import SectionBody from 'core/components/Section';
import { Button, FormError } from 'core/components/styled';
import { actionIsRunning } from 'core/ducks/running';
import { useGetIndexParams } from 'core/helpers/hooks';
import {
  filterFormOptionsByMaterialType,
  getCurrentServiceDetails
} from 'core/helpers/service';
import translate from 'core/helpers/translate';
import cart from 'ducks/cart';
import serviceDuck from 'ducks/serviceDetails';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Routes } from 'routing/routes';
import { ServiceForm } from '../styled';
import { usePrevious } from './hooks';
import ServicePricingForm from './ServicePricingForm';

const ServiceDetailsForm: React.FC<ComponentProps> = ({
  currentLocation,
  currentService,
  onCurrentServiceChange,
  formMode,
  isInEditMode
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFormValid, setIsFormValid] = useState(false);

  const { formSettings, marketConfig, quotes } = useSelector(
    (state: ApplicationState) => state.service,
  );

  const { formOptions, formRules }: { formOptions: Required<ServiceDetailsFormOptions>, formRules: SalesEditableServiceOptions } = formSettings;
  const { locationIndex, serviceIndex } = useGetIndexParams();

  const { getValues, control, register, setValue, handleSubmit, formState: { errors } } =
    useForm<ServiceDetailsFormData>({
      mode: 'onChange',
      defaultValues: {
        material: currentService?.material,
        frequency: currentService?.frequency,
        equipment: currentService?.equipment,
        schedule: currentService?.schedule
          ? currentService?.schedule
          : formOptions?.scheduleLabels
            ? formOptions?.scheduleLabels[0].code
            : '',
        quantity: currentService?.quantity ? currentService.quantity : 1,
      },
    });
  const min = 1;
  const max = formRules.maxQuantity;
  const prevServiceDetails = usePrevious(currentService);
  const isLoading = useSelector(
    (state: ApplicationState): boolean =>
      actionIsRunning(state, serviceDuck.actionKeys.LOAD_SERVICE_QUOTE) ||
      actionIsRunning(state, serviceDuck.actionKeys.LOAD_MARKET_CONFIG),
  );

  // show frequency if editFrequency flag is set and if schedule is not required or schedule required but selected schedule also has requiredFrequency set
  const editFrequency = formRules.editFrequency && (
    !formRules.editSchedule ||
    Boolean(formOptions.scheduleLabels && getValues('schedule') && formOptions.scheduleLabels.filter(f => f.code === getValues('schedule') && f.requiredFrequency === true).length === 1)
  );
  const requiredFrequency = formRules.requiredFrequency && editFrequency;

  const increaseContainerNR = () => {
    const quantity = getValues('quantity');
    setValue('quantity', Math.min(quantity + 1, max));
    if (currentService) {
      onCurrentServiceChange({
        ...currentService,
        quantity: Math.min(quantity + 1, max),
      });
    }
  };

  const decreaseContainerNR = () => {
    const quantity = getValues('quantity');
    setValue('quantity', Math.max(quantity - 1, min));
    if (currentService) {
      onCurrentServiceChange({
        ...currentService,
        quantity: Math.max(quantity - 1, min),
      });
    }
  };

  const onMaterialTypeChange = (e: any) => {
    const materialType = e.currentTarget.value;
    if (
      marketConfig &&
      materialType &&
      currentService &&
      currentService.service
    ) {
      const options = filterFormOptionsByMaterialType(
        materialType,
        currentService.service,
        marketConfig,
      );
      dispatch(serviceDuck.actions.updateFormOptions(options));
    }
  };

  const checkFormValidity = (): boolean => {
    const formData = getValues();
    const {
      editMaterial,
      editEquipment,
      requiredMaterial,
      requiredEquipment,
      editNumberOfContainers,
      requiredNumberOfContainers,
      editSchedule,
      requiredSchedule,
    } = formRules;

    if (!currentService || !currentService.service) {
      return false;
    }
    if (editMaterial && requiredMaterial && !formData.material) {
      return false;
    }
    if (editFrequency && requiredFrequency && !formData.frequency) {
      return false;
    }
    if (editEquipment && requiredEquipment && !formData.equipment) {
      return false;
    }
    if (editSchedule && requiredSchedule && !formData.schedule) {
      return false;
    }
    if (
      editNumberOfContainers &&
      !requiredNumberOfContainers &&
      formData.quantity
    ) {
      return false;
    }
    return true;
  };

  const getServiceQuote = () => {
    const formData = getValues();
    if (currentLocation && currentLocation.address) {
      const request = {
        prices: [
          {
            businessTypeId: currentLocation.businessTypeId,
            businessCode: currentLocation.businessCode,
            address: currentLocation.address.line1,
            zipCode: currentLocation.address.zip,
            service: currentService?.service,
            quantity: formData?.quantity,
            material: formData?.material,
            schedule: formData?.schedule,
            frequency: formData?.frequency,
            equipment: formData?.equipment,
          },
        ],
      } as RetailPricesDataView;
      dispatch(serviceDuck.thunks.loadServiceQuote(request));
    }
  };

  useEffect(() => {
    const formData = getValues();
    const valid = checkFormValidity();
    setIsFormValid(valid);
    if (!valid) {
      dispatch(serviceDuck.thunks.resetServiceQuote());
    } else if (
      valid &&
      (formData?.material !== prevServiceDetails?.material ||
        formData?.frequency !== prevServiceDetails?.frequency ||
        formData?.quantity !== prevServiceDetails?.quantity ||
        formData?.equipment !== prevServiceDetails?.equipment ||
        formData?.schedule !== prevServiceDetails?.schedule)
    ) {
      getServiceQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService]);

  const onSubmit = async () => {
    if (quotes && quotes.length > 0 && currentService) {
      const serviceDetails = getCurrentServiceDetails(
        currentService,
        quotes,
        formRules,
        formOptions,
      );
      if (
        locationIndex >= 0 &&
        serviceIndex >= 0 &&
        serviceDetails
      ) {
        if (formMode === FormType.NEW) {
          dispatch(cart.actions.addService(locationIndex, serviceDetails));
        }
        if (formMode === FormType.UPDATE) {
          dispatch(
            cart.actions.editService(locationIndex, serviceIndex, serviceDetails),
          );
        }
        const serviceIDProp = serviceIndex
          ? serviceIndex
          : currentLocation.services.length > 0
            ? currentLocation.services.length - 1
            : 0;
        navigate(Routes.summary, {
          state: {
            locationID: locationIndex,
            serviceID: serviceIDProp,
          }
        });
      }
    }
  };

  return (
    <ServiceForm
      onSubmit={handleSubmit(onSubmit)}
      wideForm={
        currentService?.service === EquipmentType.OPEN_TOP ||
        currentService?.service === EquipmentType.OPEN_TOP_TEMP
      }
    >
      {formRules.editMaterial && (
        <Select
          label={translate('materialFormLabel')}
          {...register('material', {
            required: {
              value: formRules.requiredMaterial,
              message: translate('wasteTypeRequiredError'),
            },
          })}
          disabled={isLoading}
          options={formOptions.materialLabels}
          onChange={e => {
            onMaterialTypeChange(e);
            setValue('material', e.target.value);
            if (currentService) {
              onCurrentServiceChange({
                ...currentService,
                material: e.target.value,
              });
            }
          }}
          errors={errors && errors.material}
          value={currentService?.material}
        />
      )}
      {formRules.editSchedule && (
        <RadioGroup
          label={translate('scheduleFormLabel')}
          options={formOptions.scheduleLabels}
          disabled={isLoading}
          {...register('schedule', {
            required: formRules.requiredSchedule,
          })}
          onChange={e => {
            setValue('schedule', e.target.value);
            setValue('frequency', undefined);
            if (currentService) {
              onCurrentServiceChange({
                ...currentService,
                schedule: e.target.value,
                frequency: ''
              });
            }
          }}
          inLineDisplay={true}
        />
      )}
      {formRules.editNumberOfContainers && (
        <Controller
          render={({ field }) =>
            <Amount
              {...field}
              min={min}
              max={max}
              label={translate('quantityFormLabel')}
              add={increaseContainerNR}
              subtract={decreaseContainerNR}
              name='quantity'
              errors={errors && errors.quantity}
              disabled={isLoading}
            />
          }
          defaultValue={min}
          name='quantity'
          control={control}
        />
      )}
      {editFrequency && (
        <Select
          label={translate('frequencyFormLabel')}
          {...register('frequency', {
            required: {
              value: requiredFrequency,
              message: translate('frequencyRequiredError'),
            },
          })}
          options={formOptions.frequencyLabels}
          onChange={e => {
            setValue('frequency', e.target.value);
            if (currentService) {
              onCurrentServiceChange({
                ...currentService,
                frequency: e.target.value,
              });
            }
          }}
          errors={errors && errors.frequency}
          value={currentService?.frequency}
          disabled={isLoading}
        />
      )}
      {formRules.editEquipment && (
        <Select
          label={translate('containerSizeFormLabel')}
          {...register('equipment', {
            required: {
              value: formRules.requiredEquipment,
              message: translate('equipmentRequiredError'),
            },
          })}
          options={formOptions.equipmentLabels}
          onChange={e => {
            setValue('equipment', e.target.value);
            if (currentService) {
              onCurrentServiceChange({
                ...currentService,
                equipment: e.target.value,
              });
            }
          }}
          errors={errors && errors.equipment}
          value={currentService?.equipment}
          disabled={isLoading}
        />
      )}
      <SectionBody isLoading={isLoading} autoHeight={true}>
        {isFormValid && quotes && quotes.length > 0 && (
          <ServicePricingForm
            currentService={currentService}
            onCurrentServiceChange={onCurrentServiceChange}
          />
        )}
        {isFormValid && quotes && quotes.length === 0 && (
          <FormError>
            {translate('serviceUnavailableInSpecifiedArea')}
          </FormError>
        )}
      </SectionBody>
      <Button
        data-automation='AddToCart'
        marginTop
        type='submit'
        disabled={
          !isFormValid ||
          (quotes && quotes.length === 0) ||
          (currentService && !currentService.hasOwnProperty('equipment'))
        }
      >
        {formMode === FormType.NEW
          ? translate('addToCartBtnLabel')
          : isInEditMode
            ? translate('addToCartBtnLabel')
            : translate('updateServiceBtnLabel')}
      </Button>
    </ServiceForm>
  );
};

interface OwnStateProps { }

interface StateProps { }

interface OwnProps {
  currentLocation: SiteDataView;
  currentService?: CurrentServiceForm;
  onCurrentServiceChange: React.Dispatch<React.SetStateAction<CurrentServiceForm>>;
  formMode: FormType;
  setIsLoadingServiceDetails: React.Dispatch<React.SetStateAction<boolean>>;
  isInEditMode?: boolean;
}

type ComponentProps = StateProps & OwnProps & OwnStateProps;

export default ServiceDetailsForm;

import React from 'react';
import { FieldError } from 'react-hook-form';
import { FormGroup, Select as FormSelect } from './styled';
import { OptionType } from 'contracts/core/form';
import { FieldDescription } from './styled/FormLabel';
import Error from './Error';

const Select = React.forwardRef<HTMLSelectElement, ComponentProps>(
  ({ label, selectFieldLabel, options, errors, value, ...rest }, ref) => (
    <FormGroup hasLabel={!!selectFieldLabel}>
      {selectFieldLabel && (
        <FieldDescription>{selectFieldLabel}</FieldDescription>
      )}
      <FormSelect ref={ref as any} {...rest} isEmpty={!value}>
        <option value=''>{label}</option>
        {options.map((option: OptionType) => (
          <option value={option.code} key={option.code}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      {errors && <Error error={errors} />}
    </FormGroup>
  ),
);

interface OwnProps {
  label: string;
  selectFieldLabel?: string;
  errors?: FieldError;
  options: OptionType[];
}

type ComponentProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  OwnProps;

export default Select;

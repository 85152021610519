import { ApplicationState } from 'contracts/core/state';
import {
  PanelContent,
  PanelSubTitle,
  PanelTitle
} from 'core/components/styled/Panel2';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import { useGetIndexParams } from 'core/helpers/hooks';
import translate from 'core/helpers/translate';
import cart from 'ducks/cart';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import ServiceLocationAddress from './ServiceLocationAddress';
import ServiceLocationServiceList from './ServiceLocationServiceList';
import { CartSummary, ServiceLocationServices } from './styled';

const ServicesPanel: React.FC<ComponentProps> = ({
  subtitle,
  hideSummary,
  showActions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locations = cart.selectors.getLocations();
  const services = cart.selectors.getAllServices();

  const progress = useSelector(
    (state: ApplicationState) => state.core.loadingBar.progress,
  );

  // finish page laod indicator
  useEffect(() => {
    if (progress < 100) {
      dispatch(setLoadingBarProgress(100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const { locationIndex, serviceIndex } = useGetIndexParams();

  const selectedService = !Number.isNaN(serviceIndex)
    ? services[serviceIndex]
    : undefined;

  if (
    locations.length === 0 ||
    services.length === 0 ||
    (serviceIndex >= 0 && !selectedService)
  ) {
    navigate(Routes.location);
    return null;
  }

  const entries = [];
  for (
    let indexLocation = 0;
    indexLocation < locations.length;
    indexLocation++
  ) {
    const location = locations[indexLocation];
    entries.push(
      <ServiceLocationServices key={`location_services_${indexLocation}`}>
        <ServiceLocationAddress
          indexLocation={indexLocation}
          location={location}
          curentLocationIndex={locationIndex}
        />
        <ServiceLocationServiceList
          indexLocation={indexLocation}
          location={location}
          selectedService={selectedService}
          hideSummary={hideSummary}
          showActions={showActions}
        />
      </ServiceLocationServices>,
    );
  }

  return (
    <PanelContent>
      <PanelTitle>{translate('summaryPageSummaryTitle')}</PanelTitle>
      {subtitle && <PanelSubTitle>{subtitle}</PanelSubTitle>}
      <CartSummary>{entries}</CartSummary>
    </PanelContent>
  );
};

interface ComponentProps {
  subtitle?: string;
  hideSummary?: boolean;
  showActions?: boolean;
}

export default ServicesPanel;

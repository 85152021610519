import React from 'react';
import Radio from './Radio';
import { OptionType } from 'contracts/core/form';
import { FormGroup, SimpleFormLabel } from './styled';
import { OptionList, Option } from './styled/OptionList';

const RadioGroup = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, options, onChange, noMargin, inLineDisplay, type, ...props }, ref) => (
    <FormGroup noMargin={noMargin}>
      {label && <SimpleFormLabel>{label}</SimpleFormLabel>}
      <OptionList animated inLineDisplay={inLineDisplay}>
        {options.map((item: OptionType, key) => (
          <Option key={item.code}>
            <Radio
              name={props.name}
              label={item.label}
              value={item.code}
              ref={ref as any}
              onChange={onChange}
            />
          </Option>
        ))}
      </OptionList>

    </FormGroup>
  ),
);

interface OwnProps {
  label?: string;
  noMargin?: boolean;
  inLineDisplay?: boolean;
  options: OptionType[];
}

type ComponentProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & OwnProps;


export default RadioGroup;

import styled from 'styled-components';

interface ButtonSetProps {
  marginLarge?: boolean;
  marginSmall?: boolean;
}
const ButtonSet = styled.div<ButtonSetProps>`
  margin-top: ${props =>
    (props.marginLarge && '45px') || (props.marginSmall && '15px') || '30px'};
  text-align: center;
`;

export default ButtonSet;

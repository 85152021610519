import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  ForwardedRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import translate from 'core/helpers/translate';
import { BillingDetailsFormData } from 'contracts/core/form';
import { Select } from 'core/components';
import cart from 'ducks/cart';
import { SectionTitle } from 'core/components/styled/Panel2';
import {
  formatExistingPaymentDetailsOptions,
  getPaymentMethodLabel,
} from 'core/helpers/billing';
import { Routes } from 'routing/routes';
import { getPaymentMethodDetails } from 'services/zuoraServices';
import { PartialSitePaymentDetails } from 'contracts/models/SiteDataView';
import { BillingForm } from './styled';
import PaymentMethodSection from './PaymentMethodSection';
import { useNavigate } from 'react-router';
import billingDuck from 'ducks/billing';

const BillingPaymentForm: React.FC<ComponentProps> = forwardRef(
  (
    {
      locationIndexNumber,
      locationsNr,
      existingPaymentDetails,
      selectedPaymentMethodIndex,
      setIsPendingPaymentResponse,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(true);
    const [isLoadingZuora, setIsLoadingZuora] = useState(false);
    const [existPaymentDetail, setExistPaymentDetail] = useState(
      {} as PartialSitePaymentDetails,
    );
    const [paymentRefId, setPaymentRefId] = useState('');
    const [paymentMethodType, setPaymentMethodType] = useState('');
    const [paymentAutoPay, setPaymentAutoPay] = useState(false);

    const childRef = useRef<any>();

    const { setValue, register, handleSubmit, reset } =
      useForm<BillingDetailsFormData>();

    const sendZuoraRequest = () => {
      window.Z.submit();
      setIsPendingPaymentResponse(true);
      setIsLoadingZuora(true);
    };

    const onZuoraFieldErros = () => {
      setIsPendingPaymentResponse(false);
      setIsLoadingZuora(false);
    };

    const paymentSubmit = (
      zuoraResponse: any,
      paymentMethod: string,
      autoPay: boolean,
    ) => {
      setPaymentAutoPay(autoPay);
      setPaymentRefId(zuoraResponse.refId);
      setPaymentMethodType(paymentMethod);
    };

    useEffect(() => {
      async function fetchPaymentMethodDetails() {
        if (paymentRefId) {
          const paymentDetailsLabel = await getPaymentMethodDetails(
            paymentRefId,
          );
          if (paymentRefId && paymentMethodType && paymentDetailsLabel) {
            const paymentDetails = {
              isKnownPayment: false,
              paymentNonce: paymentRefId,
              paymentMethodType,
              paymentAutoPay,
              paymentMethodLabel: getPaymentMethodLabel(paymentDetailsLabel),
            };
            dispatch(
              cart.actions.setPaymentDetails(
                locationIndexNumber,
                paymentDetails,
              ),
            );
            setIsPendingPaymentResponse(false);
            setIsLoadingZuora(false);
            redirectOnSubmit();
          }
        }
      }
      fetchPaymentMethodDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentRefId, paymentMethodType]);

    useEffect(() => {
      if (
        existingPaymentDetails &&
        existingPaymentDetails.length > 0 &&
        !isLoadingZuora
      ) {
        const paymentListIndex = selectedPaymentMethodIndex
          ? selectedPaymentMethodIndex
          : '';

        onChangeExistingPaymentDetails(paymentListIndex);
        setShowPaymentMethodForm(paymentListIndex === '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingPaymentDetails]);

    useImperativeHandle(ref, () => ({
      submitPaymentForm() {
        onSubmit();
      },
    }));

    const onSubmit = () => {
      const paymentMethodType = childRef.current?.getPaymentMethodType();

      if (paymentMethodType !== 'CHECK') {
        if (!_.isEmpty(existPaymentDetail)) {
          dispatch(
            cart.actions.setPaymentDetails(
              locationIndexNumber,
              existPaymentDetail,
            ),
          );
          redirectOnSubmit();
        } else {
          sendZuoraRequest();
        }
      } else {
        childRef.current.showCheckPaymentModal();
      }
    };

    const continueWithCheckPayment = () => {
      dispatch(
        cart.actions.setPaymentDetails(locationIndexNumber, {
          paymentNonce: '',
          paymentMethodType: 'CHECK',
          paymentAutoPay: false,
          paymentMethodLabel: 'Paper Payment',
        }),
      );
      redirectOnSubmit();
    };

    const redirectOnSubmit = () => {
      reset();
      dispatch(billingDuck.actions.reset());
      const nextLocationID = locationIndexNumber + 2;
      if (nextLocationID <= locationsNr) {
        const prevPage = Routes.billing.replace(
          ':locationIndex',
          (nextLocationID - 1).toString(),
        );
        const nextPage = Routes.billing.replace(
          ':locationIndex',
          nextLocationID.toString(),
        );
        const nextUrl = window.location.href.replace(prevPage, nextPage);
        window.location.href = nextUrl;
      } else {
        navigate(Routes.summary);
      }
    };

    const onChangeExistingPaymentDetails = (value: string) => {
      if (
        existingPaymentDetails &&
        existingPaymentDetails.length > 0 &&
        value
      ) {
        const selectedPaymentDetails = existingPaymentDetails[
          parseInt(value, 10)
        ] as PartialSitePaymentDetails;
        setShowPaymentMethodForm(false);
        setExistPaymentDetail(selectedPaymentDetails);
        setValue('paymentDetail', value);
      } else {
        setExistPaymentDetail({});
        setShowPaymentMethodForm(true);
      }
    };

    return (
      <BillingForm onSubmit={handleSubmit(onSubmit)}>
        {existingPaymentDetails && existingPaymentDetails.length > 0 && (
          <Select
            label={translate('addNewPaymentMenthodFormLabel')}
            options={formatExistingPaymentDetailsOptions(
              existingPaymentDetails,
            )}
            {...register('paymentDetail', {
              required: {
                value: false,
                message: '',
              },
            })}
            onChange={e => {
              setValue('paymentDetail', e.target.value)
              onChangeExistingPaymentDetails(e.target.value);
            }}
          />
        )}
        {showPaymentMethodForm && (
          <PaymentMethodSection
            ref={childRef}
            paymentSubmit={paymentSubmit}
            onZuoraFieldErros={onZuoraFieldErros}
            continueWithCheckPayment={continueWithCheckPayment}
          />
        )}

        {!showPaymentMethodForm &&
          existPaymentDetail &&
          existingPaymentDetails &&
          existingPaymentDetails.length > 0 ? (
          <>
            <SectionTitle>{translate('paymentMethodSubtitle')}</SectionTitle>
            <p>{existPaymentDetail.paymentMethodLabel}</p>
          </>
        ) : null}
      </BillingForm>
    );
  },
);
interface OwnStateProps { }

interface StateProps { }

interface OwnProps {
  ref: ForwardedRef<unknown>;
  locationIndexNumber: number;
  locationsNr: number;
  existingPaymentDetails?: PartialSitePaymentDetails[];
  selectedPaymentMethodIndex?: string;
  setIsPendingPaymentResponse: (isPending: boolean) => void;
}

type ComponentProps = StateProps & OwnProps & OwnStateProps;

export default BillingPaymentForm;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ServicesPanel from 'components/Summary/ServicesPanel';
import { Panel, PanelContainer } from 'core/components/styled/Panel2';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import StartDatePanel from './StartDatePanel';

const StartDatePage: React.FC = () => {
  const dispatch = useDispatch();

  // start page load indicator
  useEffect(() => {
    dispatch(setLoadingBarProgress(50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelContainer>
      <Panel primary width='52%'>
        <StartDatePanel />
      </Panel>
      <Panel secondary width='48%'>
        <ServicesPanel />
      </Panel>
    </PanelContainer>
  );
};

export default StartDatePage;

import React from 'react';
import { ClusterMarkerProps } from 'contracts/models/ClusterMap';

import { Motion } from 'react-motion';

import useClusterMarkerMemoized from './clusterHooks';
import { SimpleMarkerStyled } from './SimpleMarkerStyled';

const SimpleMarker: React.FC<SimpleMarkerProps> = ({
  children,
  initialScale = 0.3,
  defaultScale = 0.6,
  hoveredScale = 0.7,
  isChecked = true,
  stiffness = 320,
  damping = 7,
  precision = 0.001,
}) => {
  const [motionStyle = {}] = useClusterMarkerMemoized({
    initialScale,
    defaultScale,
    hoveredScale,
    stiffness,
    damping,
    precision,
  });

  return (
    <Motion style={motionStyle}>
      {() => (
        <div>
          <SimpleMarkerStyled isChecked={isChecked} />
          {children}
        </div>
      )}
    </Motion>
  );
};

interface SimpleMarkerProps extends ClusterMarkerProps {
  isChecked?: boolean;
  children?: React.ReactNode;
  id?: string;
}

export default SimpleMarker;

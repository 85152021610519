import { OpenSaleCartDataView } from 'contracts/models/OpenSaleCartDataView';
import {
  SaleCartDataView,
  SaleCartDataViewApi,
} from 'contracts/models/SaleCartDataView';
import {
  ServiceDataView,
  ServiceDataViewApi,
} from 'contracts/models/ServiceDataView';
import { SiteDataView } from 'contracts/models/SiteDataView';
import moment from 'moment';
import { RoutesRegex } from 'routing/routes';
import { generate } from 'short-uuid';

export const formatIndex = (index: string) => {
  return parseInt(index, 10) - 1;
};

export const isLocationMatched = (url: string, regEx: RoutesRegex) => {
  if (url && regEx) {
    const regExp = new RegExp(regEx, 'g');
    const match = url.match(regExp);
    return Boolean(match && match.length === 1 && match.pop() === url);
  }
  return false;
};

export const cartToApiCart = (cart: SaleCartDataView) => {
  const apiCart: SaleCartDataViewApi = {
    ...cart,
    locations: cart.locations.map(location => ({
      ...location,
      recognizedAddress: undefined,
      marketConfigToken: undefined,
      services: location.services.map(service => {
        const {
          editStartDate,
          requiredStartDate,
          editEndDate,
          requiredEndDate,
          ...apiService
        } = service;
        return {
          ...apiService,
          externUid: '',
        } as ServiceDataViewApi;
      }),
    })),
  };
  return apiCart;
};

export const apiCartToCart = (data: OpenSaleCartDataView) => {
  const cart: SaleCartDataView = {
    ...data.cart,
    locations: [],
  };
  data.cart.locations.forEach(apiLocation => {
    const { existingBillingDetail, existingPaymentDetails } = apiLocation;

    let formattedPaymentDetail: any[] = [];

    if (existingPaymentDetails) {
      const {
        autoPay,
        creditCardType,
        creditCardMarkNumber,
        paymentMethodType,
      } = existingPaymentDetails;

      const paymentMethodLabel =
        paymentMethodType === 'ACH'
          ? 'Existing ACH payment method'
          : `${creditCardType} ending in ${creditCardMarkNumber?.slice(-4)}`;

      formattedPaymentDetail = [
        {
          paymentAutoPay: autoPay,
          paymentMethodLabel,
          paymentMethodType: paymentMethodType,
          paymentNonce: null,
          isKnownPayment: true,
        },
      ];
    }
    const location: SiteDataView = {
      ...apiLocation,
      billingDetailList: existingBillingDetail
        ? [
            {
              billingId: generate(),
              isExistingBilling: true,
              ...existingBillingDetail,
            },
          ]
        : [],
      paymentMethodList: formattedPaymentDetail,
      selectedPaymentMethod: existingPaymentDetails ? '0' : '',
      selectedBillingDetail: existingBillingDetail ? '0' : '',
      services: [],
      canAddService: apiLocation.hasOwnProperty('canAddService') ? apiLocation.canAddService : true,
    };
    const config = data.configs.find(
      c =>
        c.address === apiLocation.address.line1 &&
        c.zipCode === apiLocation.address.zip,
    );
    if (config) {
      apiLocation.services.forEach(apiService => {
        const editableProps = config.editableProps.find(
          prop => prop.serviceType === apiService.service,
        );
        const startDate = moment(apiService.startDate).format('MM/DD/YYYY');

        const service: ServiceDataView = {
          ...apiService,
          startDate: startDate !== '01/01/0001' ? startDate : '',
          serviceLabel:
            config.serviceLabels.find(
              label => label.code === apiService.service,
            )?.label || '',
          materialLabel:
            config.materialLabels.find(
              label => label.code === apiService.material,
            )?.label || '',
          scheduleLabel:
            config.scheduleLabels.find(
              label => label.code === apiService.schedule,
            )?.label || '',
          frequencyLabel:
            config.frequencyLabels.find(
              label => label.code === apiService.frequency,
            )?.label || '',
          equipmentLabel:
            config.equipmentLabels.find(
              label => label.code === apiService.equipment,
            )?.label || '',
          editStartDate: editableProps?.editStartDate || false,
          requiredStartDate: editableProps?.requiredStartDate || false,
          editEndDate: editableProps?.editEndDate || false,
          requiredEndDate: editableProps?.requiredEndDate || false,
          editNotes: editableProps?.editNotes || false,
          requiredNotes: editableProps?.requiredNotes || false,
          address: config.address,
          zipCode: config.zipCode,
          businessTypeId: null,
          businessQualityCode: '',
          materialOrderId: 0,
          frequencyOrderId: 0,
          timesPerWeek: 0,
          equipmentOrderId: 0,
          startDayOffset:
            config.rules.find(rule => rule.service === apiService.service)
              ?.startDayOffset || 0,
          isTemp: editableProps?.isTemp,
          redirectToSpecializedTool: editableProps?.redirectToSpecializedTool,
        };
        location.services.push(service);
      });
    }
    // cart.locations.push(omit(location, 'existingBillingDetail'));
    cart.locations.push(location);
  });
  return cart;
};

import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { theme, Devices } from 'core/styles';

export const LocationsList = styled.div`
  margin-top: 82px;
  margin-bottom: 75px;
  max-height: 370px;
  overflow-y: auto;
  padding-top: 25px;
  padding-bottom: 25px;
`;

interface LocationsListAddressProps {
  isDisabled?: boolean;
}
export const LocationsListAddress = styled.div<LocationsListAddressProps>`
  display: flex;
  margin: 5px auto;
  padding: 10px;
  max-width: 500px;
  cursor: pointer;

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
  `}

  &:hover {
    border-color: transparent;
    box-shadow: 0 4px 32px ${transparentize(0.9, theme.colors.black)};
  }
`;

export const LocationAddressWrapper = styled.div`
  max-width: 390px;
  margin: 0px auto;
  padding: 0 20px;
`;

export const LocationFormList = styled.div`
  height: 400px;
  padding-top: 36px;
  overflow-y: auto;
  text-align: left;
  @media ${Devices.mobile} {
    height: auto;
    margin-bottom: 20px;
  }
`;

export const WeightListItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 5px 10px 20px;
  cursor: pointer;

  span {
    font-size: 10px;
    padding-top: 4px;
    color: ${theme.colors.brandPrimary};
  }

  :hover {
    background-color: #efefef;
  }
`;

interface NavIndexProps {
  isSelectedChecked?: boolean;
}

export const NavIndex = styled.div<NavIndexProps>`
  height: 30px;
  width: 30px;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  color: ${theme.colors.brandPrimary};
  border: 2px solid ${theme.colors.brandPrimary};
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 14px;
  margin-left: 0px;

  ${props =>
    props.isSelectedChecked &&
    css`
      background-color: ${theme.colors.brandPrimary};
      color: ${theme.colors.white};
    `}
`;

export const DetailsField = styled.div`
  display: block;
  text-align: left;
  width: 80%;
  max-width: 410px;
  border-bottom: 1px solid ${theme.colors.grayDark};
  margin-left: 5px;
  padding: 5px 0;
`;

export const DetailLabel = styled.div`
  font-size: 12px;
  color: #b1b1b1;
  line-height: 16px;
`;

export const DetailValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
`;

import { RunningAction } from 'contracts/core/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  RunningState,
} from 'contracts/core/state';
import RunningStateOperation from 'core/helpers/runningOperationObject';
import { getReducerBuilder } from 'core/reducerBuilder';
import update from 'immutability-helper';
import { last } from 'lodash-es';

// Actions Keys
const ROOT_KEY = 'core/running';
enum ActionKey {
  START_ACTION = 'core/running/START_ACTION',
  COMPLETE_ACTION = 'core/running/COMPLETE_ACTION',
}

// Initial State
const getInitialState: () => RunningState = () => {
  return {
    Example: RunningStateOperation.buildRunningOperation(
      'Example',
      getRunningTimestamp(),
    ),
  };
};

// Reducers
const reducerKeys = [
  ActionKey.START_ACTION,
  ActionKey.COMPLETE_ACTION,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RunningState,
  RunningAction
> = {
  [ActionKey.START_ACTION]: (state, action) => {
    const { actionName, timestamp, takeLast } = action;
    const existingObj = state[actionName];
    let newObj;
    if (!existingObj) {
      newObj = RunningStateOperation.buildRunningOperation(
        actionName,
        timestamp,
      );
    } else {
      newObj = RunningStateOperation.runAction(
        existingObj,
        actionName,
        timestamp,
        takeLast,
      );
      if (newObj === existingObj) {
        return state;
      }
    }
    return update(state, {
      $merge: { ...state, [actionName]: newObj },
    });
  },
  [ActionKey.COMPLETE_ACTION]: (state, action) => {
    const { actionName, timestamp, takeLast } = action;
    const existingObj = state[actionName];

    if (!existingObj) {
      return state;
    }
    const newObj = RunningStateOperation.stopAction(
      existingObj,
      actionName,
      timestamp,
      takeLast,
    );
    if (newObj === existingObj) {
      return state;
    }

    return update(state, {
      $merge: { ...state, [actionName]: newObj },
    });
  },
};

export const reducer = getReducerBuilder<RunningState, RunningAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const actionMap = {
  START_ACTION: (
    actionName: string,
    timestamp: string,
    takeLast?: boolean,
  ): RunningAction => ({
    type: ActionKey.START_ACTION,
    actionName,
    timestamp,
    takeLast,
  }),
  COMPLETE_ACTION: (
    actionName: string,
    timestamp: string,
    takeLast?: boolean,
  ): RunningAction => ({
    type: ActionKey.COMPLETE_ACTION,
    actionName,
    timestamp,
    takeLast,
  }),
};

function getRunningTimestamp(): string {
  return new Date().toISOString();
}

function getLastTimestamp(
  state: ApplicationState,
  name: string,
): string | null | undefined {
  const { running } = state.core;
  const runningObj = running[name];
  return runningObj && runningObj.timestamps
    ? last(runningObj.timestamps)
    : null;
}

export function actionIsRunning(
  state: ApplicationState,
  name: string,
): boolean {
  const { running } = state.core;
  const runningObj = running[name];
  return !!runningObj && runningObj.isRunning;
}

const RunningActions = {
  startAction: actionMap.START_ACTION,
  completeAction: actionMap.COMPLETE_ACTION,
  getRunningTimestamp,
  getLastTimestamp,
  actionIsRunning,
};

export default RunningActions;

import { httpSavingsCore } from 'core/services/http';
import { RecognizedLocationDataView } from 'contracts/models/RecognizedLocationDataView';
import { RecognizedSubmitDataView } from 'contracts/models/RecognizedSubmitDataView';
import { RecognizedSubmitResponseModel } from 'contracts/models/RecognizedSubmitResponseModel';

export const getLocations = async (alias: string) => {
  const response = await httpSavingsCore.get<RecognizedLocationDataView>(
    `sale/extraorder?alias=${alias}`,
  );
  return response.data;
};

export const submitOrder = async (request: RecognizedSubmitDataView): Promise<RecognizedSubmitResponseModel> => {
  const response = await httpSavingsCore.post<RecognizedSubmitResponseModel>(
    'sale/extraorder',
    {
      alias: request.alias,
      details: request.details,
    },
  );
  return response.data;
}

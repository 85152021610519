import { ZuoraHostePageResponseDataView } from 'contracts/models/Zuora';
import React, { PureComponent, Fragment } from 'react';
import ZuoraPaymentHost from './styled/ZuoraPaymentIframe';

// TODO: refactor this with hooks
class ZuoraPaymentIframe extends PureComponent<ComponentProps, OwnStateProps> {
  state: OwnStateProps = {
    zuoraError: undefined,
  };

  componentDidMount() {
    this.loadHostedPages();
  }

  loadHostedPages = () => {
    const { formData } = this.props;
    const zuoraParams = { ...formData, style: 'inline', submitEnabled: 'true' };
    window.Z.renderWithErrorHandler(
      zuoraParams,
      {},
      this.onZuoraResponse,
      this.onZuoraError,
    );
  };

  onZuoraResponse = (response: any) => {
    const { forwardPaymentResponse } = this.props;

    if (response.success) {
      forwardPaymentResponse(response);
    } else {
      const zuoraError = response.errorMessage
        ? response.errorMessage
        : 'Something went wrong while loading the payment form.';
      this.setState({ zuoraError });
    }
  };

  onZuoraError = (key: any, code: any, message: any) => {
    window.Z.sendErrorMessageToHpm(key, message);
    const { onZuoraFieldErros } = this.props;
    onZuoraFieldErros();
  };

  render() {
    const { zuoraError } = this.state;
    return (
      <Fragment>
        <div>{zuoraError}</div>
        <ZuoraPaymentHost
          className="payment fs-exclude"
          id='zuora_payment'
        />
      </Fragment>
    );
  }
}
interface OwnStateProps {
  zuoraError: string | undefined;
}

interface ComponentProps {
  forwardPaymentResponse: (response: any) => void;
  formData: ZuoraHostePageResponseDataView;
  onZuoraFieldErros: () => void;
}

export default ZuoraPaymentIframe;

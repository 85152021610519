import styled, { css } from 'styled-components';

export const FormStyle = () => `
`;

interface FormProps {
  actionBarContent?: boolean;
  flexed?: boolean;
  widthPercentage?: number;
  marginTop?: boolean;
}
export const Form = styled.form<FormProps>`
  ${FormStyle};

  ${props =>
    props.actionBarContent &&
    css`
      float: right;
    `};

  ${props =>
    props.flexed &&
    css`
      display: flex;
      align-items: center;
    `};
  ${props =>
    props.marginTop &&
    css`
      margin-top: 40px;
    `};

  ${props =>
    props.widthPercentage &&
    css`
      width: ${props.widthPercentage}%;
    `};
`;

import validator from 'validator';
import { has } from 'lodash-es';

const phoneRegex = new RegExp(/^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/);
const zipCodeRegex = new RegExp(/^\d{5}$/);

const isEmpty = (value: string | number, allowZero = true) =>
  !(!!value || (allowZero && value === 0));

export const isRequired = (value: any) =>
  !isEmpty(value) ? undefined : 'You can not leave this empty';

export const isNumber = (value: any) =>
  !Number.isNaN(value) ? undefined : 'Please provide a number';

export const isEmail = (value: string) =>
  validator.isEmail(value) ? undefined : 'Please provide a valid Email address';

export const isPhone = (value: string) =>
  phoneRegex.test(value) ? undefined : 'Please provide a valid Phone number';

export const isZipCode = (value: string) =>
  zipCodeRegex.test(value) ? undefined : 'Please provide a valid zip code';

export const hasCoordinates = (value: any) =>
  has(value, 'latitude') && has(value, 'longitude')
    ? undefined
    : 'Please select an address with Coordinates';

export const hasCountry = (value: any) =>
  has(value, 'country') && !!value.country
    ? undefined
    : 'Please select an address with a Country';

export const hasState = (value: any) =>
  has(value, 'state') && !!value.state
    ? undefined
    : 'Please select an address with a State';

export const hasCity = (value: any) =>
  has(value, 'city') && !!value.city
    ? undefined
    : 'Please select an address with a City';

export const hasZip = (value: any) =>
  has(value, 'zip') && !!value.zip
    ? undefined
    : 'Please select an address with a ZipCode';

export const hasStreet = (value: any) =>
  has(value, 'street') && !!value.street
    ? undefined
    : 'Please select an address with a Street';

export const hasStreetNumber = (value: any) =>
  has(value, 'streetNumber') && !!value.streetNumber
    ? undefined
    : 'Please select an address with a Street Number';

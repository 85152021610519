import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AddressDataView,
  AddressIdentifierType,
} from 'contracts/models/AddressDataView';
import {
  SalesEditableServiceOptions,
  ServiceDetailsFormOptions,
} from 'contracts/models/SaleMarketConfigDataView';
import { ApplicationState } from 'contracts/core/state';
import ClusterMap from 'core/components/ClusterMap';
import { Button } from 'core/components/styled';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import {
  Panel,
  PanelContainer,
  PanelContent,
} from 'core/components/styled/Panel2';
import ServiceOptionsModal from './ServiceOptions/ServiceOptionsModal';
import LocationAddress from './LocationAddress';
import LocationAddressList from './LocationAddressList';
import { Routes } from 'routing/routes';
import cart from 'ducks/cart';
import addressesDuck from 'ducks/addresses';
import { getSavingsUserData, getSessionAuthToken, getSessionCart } from 'core/services/session';
import { contactInformationTracking, getPublicToken } from 'services/contactInformation';
import { FieldDescription } from 'core/components/styled/FormLabel';
import translate from 'core/helpers/translate';
import { useLocation, useNavigate } from 'react-router';
import redirectToCheckout from 'core/helpers/redirectToCheckout';
import isEmpty from 'lodash-es/isEmpty';

const LocationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationProps = useLocation().state;
  const [selectedAddressDetails, setSelectedAddressDetails] =
    useState<AddressDataView>({} as AddressDataView);
  const [selectedAddressOnMap, setSelectedAddressOnMaps] = useState<
    AddressIdentifierType[]
  >([]);
  const [invalidValidAddress, setInvalidValidAddress] = useState<boolean>(true);
  const [addressMapId, setAddressMapId] = useState<number>(0);
  const [redirectLink, setRedirectLink] = useState<string | undefined>(
    undefined,
  );
  const [showExistingLocations, setShowExistingLocations] =
    useState<boolean>(false);
  const [isOpenServiceOptionsModal, setIsOpenServiceOptionsModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMarketConfigToken, setSelectedMarketConfigToken] =
    useState<string | undefined>();

  const token = getSessionAuthToken();

  const { isValidAddress, cartLimitations, askWhoIs, recognizedAddress, marketConfigToken } = useSelector(
    (state: ApplicationState) => state.locations,
  );

  const canAddLocation: boolean = useSelector(
    (state: ApplicationState) => state.cart.canAddLocation,
  );

  const methods = useForm({ mode: 'onChange' });

  const locations = cart.selectors.getLocations();
  const businessOptions = cart.selectors.getBusinessTypes();
  const progress = useSelector(
    (state: ApplicationState) => state.core.loadingBar.progress,
  );

  const hasCustomerDetails = cart.flags.hasCustomerDetails();
  const isSignWithCustomer = cart.flags.isSignWithCustomer();
  const shoppingCartId = cart.selectors.getshoppingCartId();
  const customerDetails = cart.selectors.getCustomerDetails();
  const savigsUserData = getSavingsUserData();

  useEffect(() => {
    if (!shoppingCartId && !isSignWithCustomer && !hasCustomerDetails) {
      navigate(Routes.location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCustomerDetails, isSignWithCustomer, shoppingCartId]);

  // fix effects with selectors outsite them
  useEffect(() => {
    if (locations && locations.length > 0) {
      const selectedAddress: AddressIdentifierType[] = locations.map(
        location => {
          const lat = location.address.latitude;
          const lng = location.address.longitude;
          const id = `${lat}:${lng}`;
          return { id, lat, lng };
        },
      );

      setSelectedAddressOnMaps(selectedAddress);
      setAddressMapId(addressMapId ? 0 : 1);
      setShowExistingLocations(true);
    }
    dispatch(addressesDuck.thunks.loadBusinessTypes());
    if (token) {
      dispatch(addressesDuck.thunks.loadCartLimitations());
    } else {
      getPublicToken();
    }
    dispatch(setLoadingBarProgress(40));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // finish page laod indicator
  useEffect(() => {
    if (progress < 100) {
      dispatch(setLoadingBarProgress(100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const businessTypes = useSelector(
    (state: ApplicationState) => state.locations.businessTypes,
    shallowEqual,
  );

  useEffect(() => {
    if (businessTypes.length > 0) {
      dispatch(cart.actions.setBusinessTypes(businessTypes));
    }
  }, [businessTypes, dispatch]);

  useEffect(() => {
    setInvalidValidAddress(!isValidAddress);
  }, [isValidAddress]);

  const showAddressOnMap = (address: AddressDataView) => {
    if (address) {
      const lat = address.latitude;
      const lng = address.longitude;
      const id = `${lat}:${lng}`;

      setSelectedAddressOnMaps(address.latitude ? [{ id, lat, lng }] : []);
      setSelectedAddressDetails(address);
      setAddressMapId(addressMapId ? 0 : 1);
    }
  };

  const trackLocationAdd = async (locationIndex: number): Promise<void> => {
    const cartObj = getSessionCart();
    if (!isEmpty(customerDetails)) {
      try {
        const trackingResponse = await contactInformationTracking({
          ...cartObj,
        });

        dispatch(
          cart.actions.setTrackingParams(
            trackingResponse.externalUid || '',
            trackingResponse.trackingSignature || '',
            trackingResponse.locations || []
          ),
        );
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const setAddressGoToNextPage = async (businessTypeId: number) => {
    if (!invalidValidAddress && selectedAddressDetails) {
      const existingLocationIndex = locations.findIndex(
        location => location.address.id === selectedAddressDetails.id,
      );

      const isNewAddress = !locations || existingLocationIndex < 0;

      if (isNewAddress) {
        const locationIndex = locations ? locations.length + 1 : 1;
        let serviceRedirectUrl = Routes.service.replace(
          ':locationIndex',
          locationIndex.toString(),
        );
        serviceRedirectUrl = serviceRedirectUrl.replace(':serviceIndex', '1');

        setRedirectLink(serviceRedirectUrl);
        const businessCode =
          businessOptions &&
          businessOptions.find(business => businessTypeId === business.id)
            ?.qualityCode;

        dispatch(
          cart.actions.addAddress(
            selectedAddressDetails,
            businessTypeId,
            businessCode,
            recognizedAddress,
            marketConfigToken
          ),
        );
        if (!shoppingCartId) {
          await trackLocationAdd(locationIndex);
        }
        if (askWhoIs && isEmpty(savigsUserData) && isEmpty(customerDetails)) {
          navigate(Routes.contactInformation);
        }
        setSelectedMarketConfigToken(marketConfigToken);
        setIsOpenServiceOptionsModal(true);
      } else {
        setIsLoading(false);
        selectExistingAddress(existingLocationIndex);
      }
    }
  };

  const onModalClose = (
    formRules?: SalesEditableServiceOptions,
    formOptions?: ServiceDetailsFormOptions,
  ) => {
    if (formRules && formOptions) {
      if (formRules.redirectToSpecializedTool && selectedAddressDetails && selectedAddressDetails.line1) {
        // redirect
        const address = selectedAddressDetails.line1;
        const firstName = customerDetails ? customerDetails.firstName : undefined;
        const lastName = customerDetails ? customerDetails.lastName : undefined;
        const email = customerDetails ? customerDetails.email : undefined;
        const phone = customerDetails ? customerDetails.phone : undefined;
        redirectToCheckout(address, email, firstName, lastName, phone);
      } else {
        navigate(redirectLink ? redirectLink : '/');
      }
    } else {
      setIsOpenServiceOptionsModal(false);
    }
  };

  const selectExistingAddress = (locationIndex: number) => {
    const serviceIndex = locations
      ? locations[locationIndex].services.length + 1
      : 1;

    let serviceRedirectUrl = Routes.service.replace(
      ':locationIndex',
      (locationIndex + 1).toString(),
    );
    serviceRedirectUrl = serviceRedirectUrl.replace(
      ':serviceIndex',
      serviceIndex.toString(),
    );

    setSelectedAddressDetails(locations[locationIndex].address);
    setSelectedMarketConfigToken(locations[locationIndex].marketConfigToken);
    setIsOpenServiceOptionsModal(true);
    setRedirectLink(serviceRedirectUrl);
  };

  const addNewAddress = () => {
    setShowExistingLocations(false);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setAddressGoToNextPage(Number(data.businessTypeId));
  };

  const getCartLimitNotification = (): JSX.Element | null => {
    if (isSignWithCustomer && shoppingCartId) {
      if (locations.length >= cartLimitations.locations) {
        return <FieldDescription>{translate('locationsLimitReached')}</FieldDescription>
      } else return null;
    } else {
      if (locations.length >= cartLimitations.selfServiceLocations) {
        return <FieldDescription>{translate('locationsLimitReached')}</FieldDescription>
      } else return null;
    }
  }

  const renderLocationsList = () => {
    return (
      <React.Fragment>
        <LocationAddressList
          onLocationAddressSelection={selectExistingAddress}
          locations={locations}
        />
        <Button
          disabled={
            !canAddLocation
              ? true
              : isSignWithCustomer && shoppingCartId
                ? locations.length >= cartLimitations.locations ? true : false
                : locations.length >= cartLimitations.selfServiceLocations ? true : false
          }
          onClick={addNewAddress}
        >
          {translate('addNewLocation')}
        </Button>
        {getCartLimitNotification()}
      </React.Fragment>
    );
  };

  return (
    <>
      <PanelContainer>
        <Panel primary width='52%' center>
          <PanelContent>
            {showExistingLocations ? (
              renderLocationsList()
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <LocationAddress
                    showAddressOnMap={showAddressOnMap}
                    businessOptions={businessOptions}
                    defaultValue={locationProps || ''}
                  />
                  <Button
                    isLoading={isLoading}
                    disabled={invalidValidAddress}
                    type='submit'
                    data-automation='NextButton'
                  >
                    {translate('nextBtnLabel')}
                  </Button>
                </form>
              </FormProvider>
            )}
          </PanelContent>
        </Panel>
        <Panel secondary width='48%'>
          <ClusterMap
            height='100vh'
            initialZoomSetting={4}
            markersList={
              selectedAddressOnMap.length > 0 ? selectedAddressOnMap : []
            }
            onSearchBounds={addressMapId}
            hideZeroCoords
          />
        </Panel>
      </PanelContainer>
      {isOpenServiceOptionsModal && (
        <ServiceOptionsModal
          location={selectedAddressDetails}
          marketConfigToken={selectedMarketConfigToken}
          onModalCloseClick={onModalClose}
        />
      )}
    </>
  );
};

export default LocationPage;

import styled from 'styled-components';
import theme from 'core/styles/theme';

export const ClusterMarkerStyled = styled.div`
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  top: -15px;
  left: -15px;
  transform-origin: 15px 15px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background-color: ${theme.colors.white};
  border: 3px solid ${theme.colors.brandPrimary};
  text-align: center;
  line-height: 25px;
  color: ${theme.colors.brandPrimary};
`;

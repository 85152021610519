import translate from 'core/helpers/translate';
import React, { memo } from 'react';
import { Routes } from 'routing/routes';
import {
  CartItem,
  CartItemLabel,
  NavigationCartItemContainer,
  NumberOfItems,
} from './styled';

const NavigationCartItem: React.FC<ComponentProps> = ({ count,disabled }) => (
  <NavigationCartItemContainer>
    <CartItem to={Routes.summary} disabled={disabled}>
      <NumberOfItems>{count}</NumberOfItems>
      <CartItemLabel>{translate('summaryMenuItem')}</CartItemLabel>
    </CartItem>
    <NumberOfItems mobile>{count}</NumberOfItems>
  </NavigationCartItemContainer>
);

interface ComponentProps {
  count: number;
  disabled: boolean;
}

export default memo(NavigationCartItem);

import { omit } from 'lodash';
import { httpSavingsCore } from 'core/services/http';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';

interface SignServiceResponse {
  serviceAgreementId: string;
}

interface SignLOAResponse {
  loaAgreementId: string;
}

interface SignTermsResponse {
  rtcAgreementId: string;
}

export const signService = async (
  cart: SaleCartDataView,
): Promise<SignServiceResponse> => {
  const response = await httpSavingsCore.post<SignServiceResponse>(
    '/sale/packagepurchase/agreementconfirmation',
    omit(cart, 'customer'),
  );
  return response.data;
};

export async function signLOA(
  cart: SaleCartDataView,
): Promise<SignLOAResponse> {
  const response = await httpSavingsCore.post<SignLOAResponse>(
    '/sale/packagepurchase/loaconfirmation',
    omit(cart, 'customer'),
  );
  return response.data;
}

export async function signTerms(
  cart: SaleCartDataView,
): Promise<SignTermsResponse> {
  const response = await httpSavingsCore.post<SignTermsResponse>(
    '/sale/packagepurchase/rtcconfirmation',
    omit(cart, 'customer'),
  );
  return response.data;
}

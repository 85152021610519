import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { theme, Devices } from 'core/styles';
import customerPortalScrollbar from 'core/components/styled/Scrollbar';

interface AgreementWrapperProps {
  heightShrinked?: boolean;
}

export const AgreementWrapper = styled.div<AgreementWrapperProps>`
  height: calc(50vh - 45px);
  padding: 30px 45px;
  text-align: center;
  transition: height 0.15s ease-out;

  @media ${Devices.mobile} {
    height: auto;
    padding: 30px 30px 0;
  }

  ${props =>
    props.heightShrinked &&
    css`
      height: 245px;
    `}
`;

export const AgreementContainer = styled.div`
  margin-top: 10px;
  height: calc(100% - 76px);

  @media ${Devices.mobile} {
    height: auto;
  }
`;

export const ServiceAgreementBody = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 30px);
  ${customerPortalScrollbar()}
  p {
    margin: 0 0 30px;
  }
  @media ${Devices.mobile} {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    font-weight: ${theme.fontWeightLight};
    height: auto;
    overflow-x: auto;
  }
`;

export const ServiceAgreementList = styled.ol`
  padding-left: 18px;
  li {
    margin-bottom: 30px;
  }
`;

export const ServiceBoldFont = styled.span`
  font-weight: bold;
`;

interface ServiceAgreementDownloadProps {
  disabled?: boolean;
}

export const ServiceAgreementDownload = styled.a<ServiceAgreementDownloadProps>`
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px 0;
  color: ${theme.colors.brandPrimary};
  &:hover {
    border-color: transparent;
    box-shadow: 0 4px 32px ${transparentize(0.9, '#000')};
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const ServiceAgreementUList = styled.ul`
  list-style-type: disc;
  padding-left: 18px;
  margin-bottom: 30px;
`;

export const ServiceTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const ServiceFontColorPrimary = styled.span`
  color: ${theme.colors.brandPrimary};
`;

export const ServiceUnderlineFont = styled.span`
  text-decoration: underline;
`;

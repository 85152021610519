import { RecognizedEquipmentSourceItem } from "contracts/models/RecognizedEquipmentSourceItem"
import { LocationDataView } from "contracts/models/RecognizedLocationDataView"
import { RecognizedOrderDetails, RecognizedSubmitDataView } from "contracts/models/RecognizedSubmitDataView";

export const buildRequestParam = (
  alias: string,
  selectedItem: RecognizedEquipmentSourceItem,
  collateralItem: RecognizedEquipmentSourceItem,
  selectedLocation: LocationDataView
): RecognizedSubmitDataView => {
  const orderDetails: RecognizedOrderDetails[] = [{
    itemUid: selectedItem.sku,
    itemDescription: selectedItem.description,
    city: selectedLocation.city,
    line1: selectedLocation.line1,
    streetNumber: selectedLocation.streetNumber,
    zip: selectedLocation.zip,
    countryId: selectedLocation.countryId,
    latitude: selectedLocation.latitude,
    longitude: selectedLocation.longitude,
    stateId: selectedLocation.stateId,
    street: selectedLocation.street,
  },
  {
    itemUid: collateralItem.sku,
    itemDescription: collateralItem.description,
    city: selectedLocation.city,
    line1: selectedLocation.line1,
    streetNumber: selectedLocation.streetNumber,
    zip: selectedLocation.zip,
    countryId: selectedLocation.countryId,
    latitude: selectedLocation.latitude,
    longitude: selectedLocation.longitude,
    stateId: selectedLocation.stateId,
    street: selectedLocation.street,
  }
  ];
  const requestParams: RecognizedSubmitDataView = {
    alias: alias,
    details: orderDetails,
  }
  return requestParams;
}

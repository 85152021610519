import { environment } from 'core/services/environment';
import Environments from 'core/constants/Environments';

export function logError(err: any): void {
  const errorInfo =
    err && err.response && err.response.data ? err.response.data : null;
  if (environment !== Environments.production) {
    if (err) {
      // tslint:disable-next-line: no-console
      console.error(err);
      if (errorInfo) {
        // tslint:disable-next-line: no-console
        console.error(errorInfo);
      }
    }
  }
}

export function logWarning(msg: any): void {
  if (msg && environment !== Environments.production) {
    // tslint:disable-next-line: no-console
    console.log(msg);
  }
}

export function logMessage(msg: any): void {
  if (msg && environment !== Environments.production) {
    // tslint:disable-next-line: no-console
    console.info(msg);
  }
}

import React from 'react';
import { FieldError } from 'react-hook-form';
import {
  Amount as AmountContainer,
  AmountButton,
  AmountValue,
  FormGroup,
  SimpleFormLabel,
  Input,
} from './styled';
import Error from './Error';


const Amount = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({
    label,
    min,
    max,
    add,
    subtract,
    errors,
    disabled,
    ...props
  },
    ref) => (
      <FormGroup>
        <SimpleFormLabel>{label}</SimpleFormLabel>
        <AmountContainer>
          <AmountButton
            subtract
            disabled={disabled}
            onClick={subtract}
          />
          <AmountValue disabled={disabled}>{props.value}</AmountValue>
          <AmountButton
            add
            disabled={disabled}
            onClick={add}
          />
          <Input
            value={min}
            isHidden={true}
            type='number'
            ref={ref as any}
            name={props.name}
            disabled={disabled}
            {...props}
          />
        </AmountContainer>
        {errors && <Error error={errors} />}
      </FormGroup>
    ),
);

interface OwnProps {
  label: string;
  min: number;
  max: number;
  add: () => any;
  subtract: () => any;
  errors?: FieldError;
}

type ComponentProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & OwnProps;

export default Amount;

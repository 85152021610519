import styled, { css } from 'styled-components';
import { theme, Devices, loadingOverlay } from 'core/styles';
import rubiconLogo from 'core/assets/images/rubicon-logo-white.png';
import RubiconLogoWithText from 'core/assets/images/rubicon_logo_black_on_white.svg';

interface ConfimationContainerProps {
  isLoading?: boolean;
}
export const ConfirmationContainer = styled.div<ConfimationContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.grayLightest};
  width: 100%;
  background-size: 100%;
  min-height: ${window.innerHeight}px;

  ${props => props.isLoading && loadingOverlay(32)};
`;

export const RubiconHorizontalLogo = styled.div`
  width: 165px;
  height: 30px;
  margin: 45px 30px 30px 10px;
  background: url(${RubiconLogoWithText});
  align-self: center;
`;

interface SelectionItemProps {
  recognizedEquipmentType?: string;
};

export const ConfirmationSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-size: 100%;
  margin: 0 50px 50px 50px;
  max-width: 1040px;
  width:100%;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 10px ${theme.colors.grayLight};

  @media ${Devices.tablet}, ${Devices.mobile} {
    flex-direction: column;
  };
`
interface AddressSelectionProps {
  hasError?: boolean;
}
export const ConfirmationAddressContainer = styled.div<AddressSelectionProps>`
 margin: 35px 35px 35px 35px;
 align-self: flex-start;
 font-weight: ${theme.fontWeightMedium};
 max-width: 350px;
 width: 100%;
 border: 1px solid ${theme.colors.white};
`

export const AddressErrorMessage = styled.div<AddressSelectionProps>`
display: none;
height: 10px;
margin-top: -15px;
${props => props.hasError && css`
    display: flex;
    color: red;
  `};
`

export const AddressLabel = styled.div<AddressSelectionProps>`
  line-height: 24px;
  font-size: 16px;
  border: 1px solid ${theme.colors.white};
`;

export const ConfirmationItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;

  @media ${Devices.mobile} {
    flex-direction: column;
  };
`

export const ConfirmationItemContainer = styled.div`
  margin: 50px;
  align-self: center;

  @media ${Devices.tablet} {
    margin-bottom: 0px;
    margin-top:20px;
  }

  @media ${Devices.mobile} {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

export const ConfirmationItemPhoto = styled.div<SelectionItemProps>`
  background-image: url(${props => props.recognizedEquipmentType || rubiconLogo});
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px ${theme.colors.grayLight};
  height: 200px;
  width: 200px;
`

export const ConfirmationItemLabel = styled.div`
  font-weight: ${theme.fontWeightMedium};
  width:200px;
  text-align: center;
  padding:15px;
  height: 125px;
`
export const ConfirmationButton = styled.div`
  margin: 10px 10px 20px 10px;
  width: 100%;
  text-align: center;

  @media ${Devices.tablet}, ${Devices.mobile} {
    margin-top: 0px;
  }
`

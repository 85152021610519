import update from 'immutability-helper';
import { ActionDispatcher, ServiceDetailsAction } from 'contracts/core/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceDetailsState,
} from 'contracts/core/state';
import { HolidayCalendarDataView } from 'contracts/models/HolidayCalendarDataView';
import { MarketConfigAddressDataView } from 'contracts/models/MarketConfigZipCodesDataView';
import { CurrentServiceForm } from 'contracts/core/form';
import {
  RetailPriceResponseDataView,
  RetailPricesDataView,
} from 'contracts/models/RetailPriceDataView';
import {
  SaleMarketConfigDataView,
  SalesEditableServiceOptions,
  ServiceDetailsFormOptions,
} from 'contracts/models/SaleMarketConfigDataView';
import { getReducerBuilder } from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getHolidays,
  getMarketConfiguration,
  getServiceQuote,
} from 'services/services';

// Actions Keys
const ROOT_KEY = 'service';
enum ActionKey {
  LOAD_MARKET_CONFIG = 'service/LOAD_MARKET_CONFIG',
  LOAD_SERVICE_QUOTE = 'service/LOAD_SERVICE_QUOTE',
  RESET_SERVICE_QUOTE = 'service/RESET_SERVICE_QUOTE',
  UPDATE_FORM_OPTIONS = 'service/UPDATE_FORM_OPTIONS',
  UPDATE_FORM_RULES = 'service/UPDATE_FORM_RULES',
  LOAD_HOLIDAYS = 'service/LOAD_HOLIDAYS',
  SET_LIMBO_SERVICE = 'service/SET_LIMBO_SERVICE',
  RESET = 'service/RESET',
}

// Initial State
const getInitialState: () => ServiceDetailsState = () => {
  return {
    marketConfig: undefined,
    quotes: [],
    formSettings: {
      formRules: {},
      formOptions: {},
    },
    limboService: undefined,
    holidays: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_MARKET_CONFIG,
  ActionKey.LOAD_SERVICE_QUOTE,
  ActionKey.RESET_SERVICE_QUOTE,
  ActionKey.UPDATE_FORM_OPTIONS,
  ActionKey.UPDATE_FORM_RULES,
  ActionKey.LOAD_HOLIDAYS,
  ActionKey.SET_LIMBO_SERVICE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceDetailsState,
  ServiceDetailsAction
> = {
  [ActionKey.LOAD_MARKET_CONFIG]: (state, action) => {
    const { marketConfig } = action;
    return update(state, { $merge: { marketConfig } });
  },
  [ActionKey.LOAD_SERVICE_QUOTE]: (state, action) => {
    const { quotes } = action;
    return update(state, { $merge: { quotes } });
  },
  [ActionKey.RESET_SERVICE_QUOTE]: state => {
    return update(state, {
      $merge: { quotes: [] },
    });
  },
  [ActionKey.UPDATE_FORM_OPTIONS]: (state, action) => {
    const formOptions = action.formSettings;
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          formOptions: {
            ...state.formSettings.formOptions,
            ...formOptions,
          },
        },
      },
    });
  },
  [ActionKey.UPDATE_FORM_RULES]: (state, action) => {
    const formRules = action.formSettings;
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          formRules: {
            ...state.formSettings.formRules,
            ...formRules,
          },
        },
      },
    });
  },
  [ActionKey.LOAD_HOLIDAYS]: (state, action) => {
    const { holidays } = action;
    return update(state, { $merge: { holidays } });
  },
  [ActionKey.SET_LIMBO_SERVICE]: (state, action) => {
    const { limboService } = action;
    return update(state, { $merge: { limboService } });
  },
};

export const reducer = getReducerBuilder<
  ServiceDetailsState,
  ServiceDetailsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_MARKET_CONFIG: (
    marketConfig?: SaleMarketConfigDataView,
  ): ServiceDetailsAction => ({
    type: ActionKey.LOAD_MARKET_CONFIG,
    marketConfig,
  }),
  LOAD_SERVICE_QUOTE: (
    quotes?: RetailPriceResponseDataView[],
  ): ServiceDetailsAction => ({
    type: ActionKey.LOAD_SERVICE_QUOTE,
    quotes,
  }),
  LOAD_HOLIDAYS: (
    holidays?: HolidayCalendarDataView[],
  ): ServiceDetailsAction => ({
    type: ActionKey.LOAD_HOLIDAYS,
    holidays,
  }),
  RESET_SERVICE_QUOTE: (): ServiceDetailsAction => ({
    type: ActionKey.RESET_SERVICE_QUOTE,
  }),
  UPDATE_FORM_OPTIONS: (
    formSettings?: ServiceDetailsFormOptions,
  ): ServiceDetailsAction => ({
    type: ActionKey.UPDATE_FORM_OPTIONS,
    formSettings,
  }),
  UPDATE_FORM_RULES: (
    formSettings?: SalesEditableServiceOptions,
  ): ServiceDetailsAction => ({
    type: ActionKey.UPDATE_FORM_RULES,
    formSettings,
  }),
  SET_LIMBO_SERVICE: (
    limboService?: CurrentServiceForm,
  ): ServiceDetailsAction => ({
    type: ActionKey.SET_LIMBO_SERVICE,
    limboService,
  }),
  RESET: (): ServiceDetailsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadMarketConfig = (request: MarketConfigAddressDataView, hasCartPermanentSelection = false) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_MARKET_CONFIG,
    async () => getMarketConfiguration(request, hasCartPermanentSelection),
    result => {
      dispatch(actionMap.LOAD_MARKET_CONFIG(result));
      const formOptions: ServiceDetailsFormOptions = {
        serviceLabels: result.serviceLabels,
        materialLabels: result.materialLabels,
        frequencyLabels: result.frequencyLabels,
        scheduleLabels: result.scheduleLabels,
        equipmentLabels: result.equipmentLabels,
      };
      dispatch(actionMap.UPDATE_FORM_OPTIONS(formOptions));
    },
    () => {
      dispatch(actionMap.LOAD_MARKET_CONFIG());
    },
    true,
  );

const loadServiceQuote = (request: RetailPricesDataView) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_QUOTE,
    async () => getServiceQuote(request),
    result => {
      dispatch(actionMap.LOAD_SERVICE_QUOTE(result.prices));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_QUOTE());
    },
    true,
  );

const loadHolidays = (dayCount: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_HOLIDAYS,
    async () => getHolidays(dayCount),
    result => {
      dispatch(actionMap.LOAD_HOLIDAYS(result.holidays));
    },
    () => {
      dispatch(actionMap.LOAD_HOLIDAYS());
    },
    true,
  );

const resetServiceQuote = () => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.RESET_SERVICE_QUOTE());
};

const updateFormSettings = (
  formRules: SalesEditableServiceOptions,
  formOptions: ServiceDetailsFormOptions,
) => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.UPDATE_FORM_RULES(formRules));
  dispatch(actionMap.UPDATE_FORM_OPTIONS(formOptions));
};

const serviceDuck = {
  thunks: {
    loadMarketConfig,
    loadServiceQuote,
    loadHolidays,
    resetServiceQuote,
    updateFormSettings,
  },
  actions: {
    reset: actionMap.RESET,
    updateFormOptions: actionMap.UPDATE_FORM_OPTIONS,
    setLimboService: actionMap.SET_LIMBO_SERVICE,
  },
  actionKeys: ActionKey,
};

export default serviceDuck;

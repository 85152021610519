import App, { IsMicroSite } from 'App';
import {
  GoogleAnalyticsScript,
  GoogleMapsScript,
} from 'core/components/Scripts';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

if (!IsMicroSite) {
  ReactDOM.render(
    <GoogleMapsScript>
      <GoogleAnalyticsScript>
        <App />
      </GoogleAnalyticsScript>
    </GoogleMapsScript>,
    document.getElementById('root'));
} else {
  // mount micro frontend function
  window.renderSavingsmicro = (containerId: string) => {
    ReactDOM.render(
      <GoogleAnalyticsScript>
        <App />
      </GoogleAnalyticsScript>,
      document.getElementById(containerId));
  };

  // unmount micro frontend function
  window.unmountSavingsmicro = (containerId: string) => {
    const containerElem = document.getElementById(containerId)
    if (containerElem) {
      ReactDOM.unmountComponentAtNode(containerElem);
    }
  };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
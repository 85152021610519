import React from 'react';
import {
  Radio as RadioContainer,
  RadioCheck,
  RadioInput,
  RadioText,
} from './styled';

const Radio = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, type, ...props }, ref) => (
    <RadioContainer>
      <RadioInput {...props} type='radio' ref={ref as any} />
      <RadioCheck />
      <RadioText>{label}</RadioText>
    </RadioContainer>
  ),
);

interface OwnProps {
  label: string;
}

type ComponentProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & OwnProps;

export default Radio;

import React from 'react';
import { SiteDataView } from 'contracts/models/SiteDataView';
import translate from 'core/helpers/translate';
import {
  DetailLabel,
  DetailsField,
  DetailValue,
  NavIndex,
} from '../Location/styled';
import { LocationAddress } from './styled';

const ServiceLocationAddress: React.FC<ComponentProps> = ({
  indexLocation,
  location,
  curentLocationIndex,
}) => {
  return location ? (
    <LocationAddress>
      <NavIndex isSelectedChecked={curentLocationIndex === indexLocation}>
        {indexLocation + 1}
      </NavIndex>
      <DetailsField>
        <DetailLabel>{translate('serviceAddressLabel')}</DetailLabel>
        <DetailValue>{location.address.line1}</DetailValue>
      </DetailsField>
    </LocationAddress>
  ) : null;
};

interface ComponentProps {
  indexLocation: number;
  location: SiteDataView;
  curentLocationIndex: number;
}

export default ServiceLocationAddress;

import styled, { css } from 'styled-components';
import { theme } from 'core/styles';
import errorIcon from 'core/assets/images/icon-notification-error.svg';
import warningIcon from 'core/assets/images/icon-notification-warning.svg';
import successIcon from 'core/assets/images/icon-notification-success.svg';
import closeIcon from 'core/assets/images/button-x.svg';

interface NotifierNotificationProps {
  type: string;
}
export const NotifierNotification = styled.div<NotifierNotificationProps>`
  display: flex;

  height: 80px;
  width: 440px;
  position: relative;
  left: -50%;
  margin: 10px;
  border: 1px solid ${theme.colors.grayDarkest};
  border-radius: 20px;
  background-color: white;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);

  ${props =>
    props.type === 'error' &&
    css`
      background-image: url(${errorIcon});
    `};

  ${props =>
    props.type === 'warning' &&
    css`
      background-image: url(${warningIcon});
    `};

  ${props =>
    props.type === 'success' &&
    css`
      background-image: url(${successIcon});
    `};
`;

export const NotifierNotificationMessage = styled.div`
  align-self: center;
  margin-left: 75px;
  max-height: 70px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 17px;
`;

export const NotifierNotificationClose = styled.button`
  margin-left: auto;
  background: url(${closeIcon}) no-repeat center;
  min-width: 60px;
  border: none;
  outline: 0;
  cursor: pointer;
`;

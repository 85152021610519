import styled, { css } from 'styled-components';
import { theme } from 'core/styles';
import { ImgHTMLAttributes, AnchorHTMLAttributes } from 'react';

interface OwnIconActionProps {
  small?: boolean;
  medium?: boolean;
  margin?: boolean;
  big?: boolean;
  flipHorizontal?: boolean;
  grey?: boolean;
  paddingLeft?: boolean;
  white?: boolean;
  cursor?: string;
  color?: string;
}
export type IconActionProps = OwnIconActionProps &
  ImgHTMLAttributes<HTMLImageElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;
const IconActionStyle = (props: IconActionProps) => `
  font-size: 25px;
  line-height: 25px;
  cursor: ${props.cursor || 'pointer'};
  color: ${props.color || theme.colors.brandPrimary};

`;

const IconAction = styled.a<IconActionProps>`
  ${IconActionStyle};

  ${props =>
    props.small &&
    css`
      font-size: 14px;
    `};
  ${props =>
    props.medium &&
    css`
      font-size: 16px;
    `};
  ${props =>
    props.big &&
    css`
      font-size: 38px;
    `};
  ${props =>
    props.margin &&
    css`
      margin-left: 10px;
    `};
  ${props =>
    props.flipHorizontal &&
    css`
      transform: rotateY(180deg);
      display: inline-block;
    `};
  ${props =>
    props.grey &&
    css`
      color: grey;
    `};
  ${props =>
    props.paddingLeft &&
    css`
      padding-left: 35px;
    `}
  ${props =>
    props.white &&
    css`
      color: ${theme.colors.white};
    `};
`;

export default IconAction;

import { ServiceDataView } from 'contracts/models/ServiceDataView';
import { SiteDataView } from 'contracts/models/SiteDataView';
import { setLoadingBarProgress } from 'core/ducks/loadingBar';
import { useGetIndexParams } from 'core/helpers/hooks';
import cart from 'ducks/cart';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import ServiceDetailsConfigurationPage from './ServiceDetailsConfigurationPage';

const ServiceDetailsConfigurationPageWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { locationIndex, serviceIndex } = useGetIndexParams();

  useEffect(() => {
    if (Number.isNaN(locationIndex) || Number.isNaN(serviceIndex)) {
      navigate(Routes.summary);
    }
  }, [locationIndex, navigate, serviceIndex]);

  // start page laod indicator
  useEffect(() => {
    dispatch(setLoadingBarProgress(50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Number.isNaN(locationIndex) || Number.isNaN(serviceIndex)) {
    return null;
  }

  let location: SiteDataView | undefined;
  let service: ServiceDataView | undefined;

  location = cart.selectors.getLocation(locationIndex);
  service = cart.selectors.getService(locationIndex, serviceIndex);

  return (
    <ServiceDetailsConfigurationPage
      location={location}
      service={service}
    ></ServiceDetailsConfigurationPage>
  );
};
export default ServiceDetailsConfigurationPageWrapper;

import update from 'immutability-helper';

import { ConfirmationModalProps } from 'core/components/Modal/ConfirmationModal';
import { uuid } from 'short-uuid';
import { ModalManagerState, ReduceFunctionMap } from 'contracts/core/state';
import { ModalManagerAction } from 'contracts/core/action';
import { ModalActionProps, ModalComponentProps, ModalStateProps } from 'contracts/core/modal';
import { getReducerBuilder } from 'core/reducerBuilder';
import ModalType from 'contracts/enums/ModalType';

// Actions Keys
const ROOT_KEY = 'core/modals';
enum ActionKey {
  OPEN_MODAL = 'core/modals/OPEN_MODAL',
  CLOSE_MODAL = 'core/modals/CLOSE_MODAL',
  RESET = 'core/modals/RESET',
}

// Initial State
const getInitialState = (): ModalManagerState => {
  return {
    modals: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.OPEN_MODAL,
  ActionKey.CLOSE_MODAL,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ModalManagerState,
  ModalManagerAction
> = {
  [ActionKey.OPEN_MODAL]: (state, action) => {
    if (!action.props) {
      return state;
    }
    const index = state.modals.findIndex(m => m.modalType === action.props?.modalType);
    if (index >= 0) {
      // modal type already opened
      return state;
    }

    const newModal: ModalStateProps = {
      ...action.props,
      id: uuid()
    };
    const newState = update(state, {
      modals: { $push: [newModal] },
    });
    return newState;
  },
  [ActionKey.CLOSE_MODAL]: (state, action) => {
    if (!action.id) {
      return state;
    }
    const index = state.modals.findIndex(m => m.id === action.id);
    if (index < 0) {
      return state;
    }
    const newState = update(state, {
      modals: { $splice: [[index, 1]] },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<
ModalManagerState,
ModalManagerAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const openModal = <T extends ModalComponentProps>(props: ModalActionProps<T>): ModalManagerAction => ({
  type: ActionKey.OPEN_MODAL,
  props,
});

const openConfirmationModal = (
  message: string,
  closeModal: (value?: boolean) => void,
  noBtnVisible?: boolean,
  yesBtnText?: string,
  noBtnText?: string): ModalManagerAction => {
  const props: ModalActionProps<ConfirmationModalProps> = {
    modalType: ModalType.confirmation,
    props: {
      message,
      closeModal,
      noBtnVisible,
      yesBtnText,
      noBtnText
    }
  };
  return ({
    type: ActionKey.OPEN_MODAL,
    props,
  });
};

const closeModal = (id: string): ModalManagerAction => ({
  type: ActionKey.CLOSE_MODAL,
  id,
});

const modalManager = {
  openModal,
  openConfirmationModal,
  closeModal,
};
export default modalManager;

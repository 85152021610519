import { StartDateFormData } from 'contracts/core/form';
import { ApplicationState } from 'contracts/core/state';
import {
  PanelContent,
  PanelSubTitle,
  PanelTitle
} from 'core/components/styled/Panel2';
import { actionIsRunning } from 'core/ducks/running';
import { useGetIndexParams } from 'core/helpers/hooks';
import translate from 'core/helpers/translate';
import cart from 'ducks/cart';
import serviceDuck from 'ducks/serviceDetails';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routing/routes';
import StartDateForm from './StartDateForm';

const StartDatePanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allServices = cart.selectors.getAllServices();

  const holidays = useSelector(
    (state: ApplicationState) => state.service.holidays,
  );
  const isLoading = useSelector((state: ApplicationState): boolean =>
    actionIsRunning(state, serviceDuck.actionKeys.LOAD_HOLIDAYS),
  );

  useEffect(() => {
    if (!holidays) {
      dispatch(serviceDuck.thunks.loadHolidays(360));
    }
  }, [holidays, dispatch]);

  const { serviceIndex } = useGetIndexParams();

  if (
    allServices.length === 0 ||
    Number.isNaN(serviceIndex) ||
    serviceIndex >= allServices.length
  ) {
    navigate(Routes.location);
    return null;
  }
  const service = allServices[serviceIndex];

  const onSubmitDone = (formData: StartDateFormData) => {
    if (formData) {
      dispatch(
        cart.actions.setServiceDates(
          service,
          formData.startDate,
          formData.endDate || null,
          formData.notes || undefined,
        ),
      );
      if (serviceIndex === allServices.length - 1) {
        navigate(Routes.summary);
      } else {
        navigate(
          Routes.startDate.replace(
            ':serviceIndex',
            (serviceIndex + 2).toString(),
          ),
        );
      }
    }
  };

  const subtitle = `${translate('startDatePageSubtitleAddingFor')} ${serviceIndex + 1
    } ${translate('of')} ${allServices.length} ${allServices.length > 1 ? translate('services') : translate('service')
    }`;

  return (
    <PanelContent isLoading={isLoading}>
      <PanelTitle>{translate('startDatePageTitle')}</PanelTitle>
      <PanelSubTitle>{subtitle}</PanelSubTitle>
      <StartDateForm
        service={service}
        holidays={holidays}
        onSubmitDone={onSubmitDone}
      ></StartDateForm>
    </PanelContent>
  );
};

export default StartDatePanel;

import styled, {
  css,
  DefaultTheme,
  ThemedStyledProps,
} from 'styled-components';
import { transparentize } from 'polished';
import { theme } from 'core/styles';

type InputStyleProps = ThemedStyledProps<InputProps, DefaultTheme>;
export const InputStyle = (props: InputStyleProps) => `
  width: 100%;
  padding: 2px 2px 8px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${transparentize(0.9, theme.colors.black)};
  border-radius: 0;
  line-height: 24px;
  font-size: 16px;
    font-family: ${props.theme.fontFamilyBase};
  color: ${transparentize(0.1, theme.colors.black)};

  &:focus {
    border-bottom-color: ${props.theme.colors.brandPrimary};
    color: ${transparentize(0.1, theme.colors.black)};
  }
  &::placeholder {
    font-size: 16px;
  }`;

const SelectStyle = `
  appearance: none;
  padding-right: 36px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iLjQ5OCIvPjwvc3ZnPg==') right 2px center no-repeat;

  &:focus {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
  }
  &::-ms-expand {
    display: none;
}
`;

interface InputProps {
  isHidden?: boolean;
  inline?: boolean;
  halfWidth?: boolean;
  withSelectStyle?: boolean;
}
export const Input = styled.input<InputProps>`
  ${InputStyle};

  ${props =>
    props.isHidden &&
    css`
      display: none;
    `};

  ${props =>
    props.inline &&
    css`
      min-width: 200px;
      width: auto;
      margin-right: 10px;
    `};

  ${props =>
    props.halfWidth &&
    css`
      width: 50%;
    `};

  ${props =>
    props.withSelectStyle &&
    css`
      ${SelectStyle};
    `};
`;

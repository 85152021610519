import { combineReducers } from 'redux';
import { reducer as modalManagerReducer } from './modalManager';
import { reducer as notifierReducer } from './notifier';
import { reducer as runningReducer } from './running';
import { reducer as loadingBarReducer } from './loadingBar';

const reducer = combineReducers({
  modalManager: modalManagerReducer,
  notifier: notifierReducer,
  running: runningReducer,
  loadingBar: loadingBarReducer,
});

export default reducer;

import { get, set, remove } from 'local-storage';
import { Industries } from 'contracts/models/BusinessTypes';
import { ProfileDataView } from 'contracts/models/ProfileDataView';
import { SaleCartDataView } from 'contracts/models/SaleCartDataView';

enum LocalStorageKey {
  cart = 'rubiconSavingsCart',
  authToken = 'rubiconSavingsAuthToken',
  savingsUserData = 'savingsUserData'
}

function setSessionItem<T>(key: LocalStorageKey, value: T): boolean {
  return set<T>(key, value);
}

function getSessionItem<T>(key: LocalStorageKey): T {
  return get<T>(key);
}

function checkCartValidity(cart: SaleCartDataView): boolean {
  if (
    !cart ||
    !cart.locations ||
    cart.hasOwnProperty('isExistingCustomer') ||
    cart.hasOwnProperty('cartId')
  ) {
    return false;
  }
  return true;
}

export function destroySession(...exceptions: LocalStorageKey[]): void {
  if (!exceptions.includes(LocalStorageKey.cart)) {
    remove(LocalStorageKey.cart);
  }
  if (!exceptions.includes(LocalStorageKey.authToken)) {
    remove(LocalStorageKey.authToken);
  }
  if (!exceptions.includes(LocalStorageKey.savingsUserData)) {
    remove(LocalStorageKey.savingsUserData);
  }
}

export function getSessionCart(): SaleCartDataView {
  let cart: SaleCartDataView = getSessionItem(LocalStorageKey.cart);
  const isCartValid = checkCartValidity(cart);
  if (!cart || !isCartValid) {
    cart = {
      locations: [],
      externalUid: null,
      trackingSignature: null,
      notificationToken: '',
      signWithCustomer: false,
      readonlyCheckoutEmail: false,
      shoppingCartId: '',
      serviceAgreementId: '',
      loaAgreementId: '',
      rtcAgreementId: '',
      promoCode: '',
      customer: {} as ProfileDataView,
      industries: [] as Industries[],
      canAddLocation: true,
      acknowledgedCommercialUse: false,
    } as SaleCartDataView;
    setSessionCart(cart);
  }
  return cart;
}

export function setSessionCart(cart: SaleCartDataView): boolean {
  return setSessionItem(LocalStorageKey.cart, cart);
}

export const getSessionAuthToken = (): string => getSessionItem(LocalStorageKey.authToken);

export const setSessionAuthToken = (authToken: string | undefined): void => {
  if (authToken) {
    setSessionItem(LocalStorageKey.authToken, authToken);
  } else {
    remove(LocalStorageKey.authToken);
  }
}

export interface SavingsUserDataType {
  token?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  address?: string;
}
export const getSavingsUserData = (): SavingsUserDataType => getSessionItem(LocalStorageKey.savingsUserData);

export const setSavingsUserData = (data?: SavingsUserDataType): void => {
  if (data) {
    setSessionItem(LocalStorageKey.savingsUserData, data)
  } else {
    remove(LocalStorageKey.savingsUserData);
  }
}
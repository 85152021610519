import { Notifier } from 'core/components';
import LoadingBar from 'core/components/LoadingBar';
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { MainContainer } from './core/components/styled';
import { GlobalStyle, theme } from './core/styles';
import MainRouter from './routing/MainRouter';
import NavigationBar from './routing/NavigationBar';
import { store } from './store';

import registerAuthInterceptor from 'core/services/registerAuthInterceptor';
import 'core/styles/fontastic.css';
import 'core/styles/notifier.css';
import 'core/styles/search-input.css';
import FullStory from 'react-fullstory';
import { BrowserRouter } from 'react-router-dom';
import { ModalManager } from 'core/components/Modal/ModalManager';
import { Routes } from 'routing/routes';
import { environment } from 'core/services/environment';
import Environments from 'core/constants/Environments';

declare global {
  interface Window {
    ga: (...data: any[]) => any;
    Z: {
      renderWithErrorHandler: (...data: any) => any;
      sendErrorMessageToHpm: (...data: any) => any;
      submit: (...data: any) => any;
    };
    renderSavingsmicro?: (containerId: string) => void;
    unmountSavingsmicro?: (containerId: string) => void;
    newrelic?: any;
  }
}

const ORG_ID = '186P4G'; // Full Story org ID

registerAuthInterceptor();

export const IsMicroSite = Boolean(document.getElementById('Savingsmicro-container'));
const baseName = IsMicroSite ? Routes.savingsBase : '';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Provider store={store}>
          <BrowserRouter basename={baseName}>
            <MainContainer>
              <LoadingBar />
              <NavigationBar />
              <MainRouter />
              <Notifier />
              <ModalManager />
            </MainContainer>
          </BrowserRouter>
        </Provider>
        <Normalize />
        <GlobalStyle />
        {environment !== Environments.local &&
          <FullStory org={ORG_ID} />
        }
      </React.Fragment>
    </ThemeProvider>
  );
};

export default App;

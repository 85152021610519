import styled, { css, LoadingStyledComponentProps } from 'styled-components';
import { theme, loading } from 'core/styles';
import { TdHTMLAttributes } from 'react';

interface TableContainerProps {
  noScroll: boolean;
  adjustToPanel?: boolean;
  noBoxShadow?: boolean;
}
export const TableContainer = styled.div<TableContainerProps>`
  display: block;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 40px auto;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);

  overflow-y: scroll;

  ${props =>
    props.noScroll &&
    css`
      overflow-y: hidden;
    `}

  ${props =>
    props.adjustToPanel &&
    css`
      max-width: 1190px;
    `}

  ${props =>
    props.noBoxShadow &&
    css`
      box-shadow: none;
      margin-bottom: 20px;
    `}
`;

interface TableCellProps extends TdHTMLAttributes<HTMLTableDataCellElement> {
  width?: number;
  noLeftPadding?: boolean;
  whiteBackground?: boolean;
  centered?: boolean;
  SLA?: string;
  greenThemeTextColor?: boolean;
  noRecords?: boolean;
}
export const TableCell = styled.div<TableCellProps>`
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-grow: 1;
  width: ${props => (props.width ? `${props.width}%` : '25%')};
  padding-left: 30px;
  list-style: none;
  overflow-wrap: break-word;

  ${props =>
    props.noLeftPadding &&
    css`
      padding-left: 0px;
    `}

  ${props =>
    props.whiteBackground &&
    css`
      background-color: white;
    `};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.SLA === 'Missed' &&
    css`
      color: ${props.theme.colors.brandDanger};
    `};

  ${props =>
    props.greenThemeTextColor &&
    css`
      color: ${theme.colors.brandPrimary};
    `};

  ${props =>
    props.noRecords &&
    css`
      display: flex;
      -webkit-flex-direction: 'column';
      -ms-flex-direction: 'column';
      flex-direction: 'column';
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;
      color: #555;
      background-color: ${theme.colors.white};
      text-align: center;
      font-size: 12px;
      font-style: normal;
    `};
`;

interface TableRowProps {
  hasNoBorder?: boolean;
  hoverEffect?: boolean;
  clickable?: boolean;
  hideLast?: boolean;
  titleHead?: boolean;
  titleHeadLight?: boolean;
  visibilityAtHover?: boolean;
  greyThemeTextColor?: boolean;
  normalFontHeight?: boolean;
  smallTitleHead?: boolean;
  smallVersion?: boolean;
}
export const TableRow = styled.div<TableRowProps>`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 56px;
  padding: 10px 10px 11px 10px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;

  ${props =>
    props.smallVersion &&
    css`
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    `}

  ${props =>
    props.smallTitleHead &&
    css`
      color: ${theme.colors.black};
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      line-height: 14px;
    `}

  ${props =>
    props.hasNoBorder &&
    css`
      border-bottom: none;
    `}

  ${props =>
    props.hoverEffect &&
    css`
      :hover {
        padding: 10px;
        border: 2px solid #00a599;
      }
    `}

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.hideLast &&
    css`
      ${TableCell}:last-child {
        visibility: hidden;
      }
    `}

  ${props =>
    props.titleHead &&
    css`
      color: ${theme.colors.black};
      font-weight: 700;
      font-size: 14px;
    `}

  ${props =>
    props.titleHeadLight &&
    css`
      color: ${theme.colors.grayDark};
    `}

  ${props =>
    props.visibilityAtHover &&
    css`
      ${TableCell}:last-child {
        visibility: hidden;
      }
      :hover {
        border: 2px solid #00a599;
        ${TableCell}:last-child {
          visibility: visible;
        }
        &:first-child {
          border: 2px solid transparent;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    `}

  ${props =>
    props.greyThemeTextColor &&
    css`
      color: #9dafc2;
    `}

  ${props =>
    props.normalFontHeight &&
    css`
      font-weight: normal;
    `}
`;

interface ShowMoreButtonProps {
  isShowMoreButtonGrey?: boolean;
}
export const ShowMoreButton = styled.div<ShowMoreButtonProps>`
  color: ${props => props.theme.colors.brandPrimary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }

  ${props =>
    props.isShowMoreButtonGrey &&
    css`
      color: #9dafc2;
    `}
`;

export const NavLinkText = styled.span`
  color: ${theme.colors.brandDefaultBlack};
`;

export const ShowMoreButtonContainer = styled.div<LoadingStyledComponentProps>`
  display: flex;
  justify-content: center;

  ${props =>
    props.isLoading &&
    css`
      ${loading(30)};

      & > div {
        visibility: hidden;
      }
    `}
`;
